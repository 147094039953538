import React, { lazy, Suspense, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { ScrollToTop } from '../../Components'
import { FeatureReleaseContent } from '../../Components/FeatureReleaseContent'
import { FixLocationModal } from '../../Components/FixLocationModal'
import { FuelTypeAlertModal } from '../../Components/FuelTypeModals/FuelTypeAlertModal'
import { MainLoading } from '../../Components/MainLoading'
import { TopLine } from '../../Components/TopLine'
import { UpdatedTermsModal } from '../../Components/UpdatedTermsModal'
import { useTrackPageView } from '../../Hooks/useTrackPageView/useTrackPageView'
import { useGetPermissionPerNavigationItemQuery, useGetTermsStatusQuery } from '../../Lib/graphql'
import { platform } from '../../Lib/platform'
import Billing from '../../Pages/Billing'
import SessionExpiredPage from '../../Pages/ErrorPages/401'
import { CurrentUserContext, CurrentUserProvider, MediaQueriesContext, SidebarCollapsedProvider } from '../../Providers'
import { MainTopBar, SideBar } from './components'
import { IdleTimerModal } from './components/IdleTimerModal'
import { IProps, ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Dashboard = lazy(() => import(/* webpackChunkName: "DashboardPage" */ '../../Pages/Dashboard'))
const Settlements = lazy(() => import(/* webpackChunkName: "SettlementsPage" */ '../../Pages/Settlements'))
const Expenses = lazy(() => import(/* webpackChunkName: "ExpensesPage" */ '../../Pages/Expenses/Overview'))
const Approvals = lazy(() => import(/* webpackChunkName: "ApprovalsPage" */ '../../Pages/Approvals'))
const ApprovalsSubordinate = lazy(
  () => import(/* webpackChunkName: "ApprovalsSubordinate" */ '../../Pages/Approvals/Subordinate')
)
const ApprovalsSubordinateDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ApprovalsSubordinateDetails" */ '../../Pages/Approvals/Subordinate/MobilityEventDetails'
    )
)
const Favorites = lazy(() => import(/* webpackChunkName: "Favorites" */ '../../Pages/Favorites'))
const Help = lazy(() => import(/* webpackChunkName: "HelpPage" */ '../../Pages/Help'))
const Privacy = lazy(() => import(/* webpackChunkName: "Privacy" */ '../../Pages/Privacy')) // landing page voor gegevens en voorwaarden
const Profile = lazy(() => import(/* webpackChunkName: "ProfilePage" */ '../../Pages/Profile')) // landing page voor gegevens en voorwaarden
const ProfileInformation = lazy(
  () => import(/* webpackChunkName: "ProfileInformation" */ '../../Pages/Profile/ProfileInformation')
)
const ProfileSettings = lazy(
  () => import(/* webpackChunkName: "ProfileSettings" */ '../../Pages/Profile/ProfileSettings')
)
const ProfileQR = lazy(() => import(/* webpackChunkName: "ProfileQR" */ '../../Pages/Profile/ProfileQR'))
const Products = lazy(() => import(/* webpackChunkName: "ProductOptions" */ '../../Pages/Modalities'))
const ProductDetails = lazy(
  () => import(/* webpackChunkName: "ProductDetails" */ '../../Pages/Modalities/ModalityDetails')
)
const ProductDetailsMileage = lazy(
  () => import(/* webpackChunkName: "ProductDetailsMileage" */ '../../Pages/Modalities/Mileage')
)
const Transport = lazy(() => import(/* webpackChunkName: "Transport" */ '../../Pages/Transport'))
const TransportReservation = lazy(
  () => import(/* webpackChunkName: "TransportReservation" */ '../../Pages/Transport/Details')
)
const ReservationsOverview = lazy(
  () => import(/* webpackChunkName: "ReservationsOverview" */ '../../Pages/Transport/SharedVehicle/Overview')
)
const SharedVehiclePreReservationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "SharedVehiclePreReservationDetails" */ '../../Pages/Transport/SharedVehicle/PreReservationDetails'
    )
)
const SharedVehiclePostReservationDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "SharedVehiclePostReservationDetails" */ '../../Pages/Transport/SharedVehicle/PostReservationDetails'
    )
)
const SubstituteManager = lazy(
  () => import(/* webpackChunkName: "SubstituteManager" */ '../../Pages/SubstituteManager')
)
const TaxiPreReservationDetails = lazy(
  () => import(/* webpackChunkName: "TaxiPreReservationDetails" */ '../../Pages/Transport/Taxi/Summary')
)
const TaxiReservationDetails = lazy(
  () => import(/* webpackChunkName: "TaxiReservationDetails" */ '../../Pages/Transport/Taxi/TaxiDetails')
)
const TranzerReservationDetails = lazy(
  () => import(/* webpackChunkName: "TranzerReservationDetails" */ '../../Pages/Transport/TranzerDetails')
)
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ '../../Pages/ErrorPages/404'))
const InternalServerError = lazy(() => import(/* webpackChunkName: "PageNotFound" */ '../../Pages/ErrorPages/500'))
const Redirecting = lazy(() => import(/* webpackChunkName: "Redirecting" */ '../../Pages/Redirecting'))
const ServicesCardDetails = lazy(
  () => import(/* webpackChunkName: "Services" */ '../../Pages/CardsAndServices/Pages/CardDetails')
)
const CardOrderDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "MobilityCardOrderDetails" */ '../../Pages/CardsAndServices/Pages/CardDetails/MobilityCardOrderDetails'
    )
)
const ServicesServiceDetails = lazy(
  () => import(/* webpackChunkName: "Services" */ '../../Pages/CardsAndServices/Pages/ServiceDetails')
)
const Reauthorize = lazy(() => import(/* webpackChunkName: "NewSettlement" */ '../../Pages/Reauthorize'))
const AllConditions = lazy(
  () => import(/* webpackChunkName: "AllConditions" */ '../../Pages/Profile/ProfileSettings/Conditions/AllConditions')
)
const MobilityEventDetails = lazy(
  () => import(/* webpackChunkName: "MobilityEventDetails" */ '../../Pages/Expenses/MobilityEventDetails')
)
const Finances = lazy(() => import(/* webpackChunkName: "Finances" */ '../../Pages/Finances'))

const WelcomePersonalInformation = lazy(
  () => import(/* webpackChunkName: "WelcomePersonalInformation" */ '../../Pages/Welcome/Steps/PersonalInformation')
)
const WelcomeWorkInformation = lazy(
  () => import(/* webpackChunkName: "WelcomeWorkInformation" */ '../../Pages/Welcome/Steps/WorkInformation')
)
const WelcomeTermsAndConditions = lazy(
  () => import(/* webpackChunkName: "WelcomeTermsAndConditions" */ '../../Pages/Welcome/Steps/TermsAndConditions')
)
const WelcomeDone = lazy(() => import(/* webpackChunkName: "WelcomeDone" */ '../../Pages/Welcome/Steps/Done'))
const RoutePlanner = lazy(() => import(/* webpackChunkName: "RoutePlanner" */ '../../Pages/RoutePlanner'))
const Emissions = lazy(() => import(/* webpackChunkName: "Emissions" */ '../../Pages/Emissions'))
const AllInvoices = lazy(() => import(/* webpackChunkName: "AllInvoices" */ '../../Pages/Billing/Pages/AllInvoices'))
const AllMandates = lazy(() => import(/* webpackChunkName: "AllMandates" */ '../../Pages/Billing/Pages/AllMandates'))
const CardsAndServices = lazy(() => import(/* webpackChunkName: "CardsAndServices" */ '../../Pages/CardsAndServices'))
const FeatureDetails = lazy(
  () => import(/* webpackChunkName: "FeatureDetails" */ '../../Pages/CardsAndServices/Pages/FeatureDetails')
)
const DonkeyDetails = lazy(() => import(/* webpackChunkName: "DonkeyDetails" */ '../../Pages/Transport/DonkeyDetails'))

const Router: React.FC<IProps> = ({ showOnboarding }): JSX.Element => {
  const { email } = useContext(CurrentUserContext)
  const { data } = useGetPermissionPerNavigationItemQuery({ skip: showOnboarding })
  const { isMDScreen } = useContext(MediaQueriesContext)

  const permissionData = data?.me?.permissions
  const allowLoginByQrCode = isMDScreen && permissionData?.allowLoginByQrCode
  const viewSplitBillingInvoices = !!permissionData?.viewSplitBillingInvoices
  const viewDirectDebitInvoices = !!permissionData?.viewDirectDebitInvoices

  const AuthorizedWelcomeRoutes = ((): JSX.Element => {
    return (
      <React.Fragment>
        <Route path={routes[ROUTE_NAMES.WELCOME_PERSONAL_INFORMATION]} element={<WelcomePersonalInformation />} />
        <Route path={routes[ROUTE_NAMES.WELCOME_WORK_INFORMATION]} element={<WelcomeWorkInformation />} />
        <Route path={routes[ROUTE_NAMES.WELCOME_TERMS_AND_CONDITIONS]} element={<WelcomeTermsAndConditions />} />
        <Route path={routes[ROUTE_NAMES.WELCOME_DONE]} element={<WelcomeDone />} />
        <Route path={routes[ROUTE_NAMES.HELP]} element={<Help />} />
      </React.Fragment>
    )
  })()

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderRoutes = (): JSX.Element => {
    if (showOnboarding) {
      return (
        <React.Fragment>
          {AuthorizedWelcomeRoutes}
          <Route path={routes[ROUTE_NAMES.REAUTHORIZE]} element={<Reauthorize />} />
          <Route path="*" element={<WelcomePersonalInformation />} />
        </React.Fragment>
      )
    }

    const showReservation = ((): boolean => {
      if (!permissionData) return false
      const { taxiFeature, sharedVehicleFeature, canViewLeaseBikeFeature, rentalCarFeature } = permissionData

      return taxiFeature || sharedVehicleFeature || canViewLeaseBikeFeature || rentalCarFeature
    })()

    return (
      <React.Fragment>
        <Route path={routes[ROUTE_NAMES.DASHBOARD]} element={<Dashboard />} />

        <Route path={routes[ROUTE_NAMES.SETTLEMENTS]} element={<Settlements />} />

        <Route path={`${routes[ROUTE_NAMES.MOBILITY_EVENTS]}/*`} element={<Expenses />} />

        <Route path={routes[ROUTE_NAMES.MOBILITY_EVENT_DETAILS]} element={<MobilityEventDetails />} />

        {/* exact */}
        <Route
          path={routes[ROUTE_NAMES.APPROVALS]}
          element={permissionData?.approvals ? <Approvals /> : <Navigate to="/" />}
        />

        <Route
          path={routes[ROUTE_NAMES.APPROVALS_SUBORDINATE]}
          element={permissionData?.approvals ? <ApprovalsSubordinate /> : <Navigate to="/" />}
        />

        <Route
          path={routes[ROUTE_NAMES.APPROVALS_SUBORDINATE_DECLARATION]}
          element={permissionData?.approvals ? <ApprovalsSubordinateDetails /> : <Navigate to="/" />}
        />

        <Route path={routes[ROUTE_NAMES.FAVOURITES]} element={<Favorites />} />

        <Route
          path={routes[ROUTE_NAMES.PROFILE]}
          element={!isMDScreen ? <Profile /> : <Navigate to={routes[ROUTE_NAMES.PROFILE_INFORMATION]} />}
        />
        <Route path={routes[ROUTE_NAMES.PROFILE_INFORMATION]} element={<ProfileInformation />} />
        <Route path={routes[ROUTE_NAMES.PROFILE_SETTINGS]} element={<ProfileSettings />} />
        <Route path={routes[ROUTE_NAMES.PROFILE_CONDITIONS]} element={<AllConditions />} />
        <Route
          path={routes[ROUTE_NAMES.PROFILE_QR]}
          element={allowLoginByQrCode ? <ProfileQR /> : <Navigate to={routes[ROUTE_NAMES.PROFILE_INFORMATION]} />}
        />

        <Route path={routes[ROUTE_NAMES.TRANSPORT]} element={showReservation ? <Transport /> : <Navigate to="/" />} />

        <Route
          path={routes[ROUTE_NAMES.TRANSPORT_RESERVATION]}
          element={showReservation ? <TransportReservation /> : <Navigate to="/" />}
        />

        <Route path={routes[ROUTE_NAMES.TRANSPORT_DONKEY_REPUBLIC_DETAILS]} element={<DonkeyDetails />} />

        <Route
          path={routes[ROUTE_NAMES.TRANSPORT_SHARED_VEHICLE_OVERVIEW]}
          element={permissionData?.sharedVehicleFeature ? <ReservationsOverview /> : <Navigate to="/" />}
        />

        <Route
          path={routes[ROUTE_NAMES.TRANSPORT_SHARED_VEHICLE_OVERVIEW_DETAILS]}
          element={permissionData?.sharedVehicleFeature ? <SharedVehiclePreReservationDetails /> : <Navigate to="/" />}
        />

        <Route
          path={routes[ROUTE_NAMES.TRANSPORT_SHARED_VEHICLE_DETAILS]}
          element={permissionData?.sharedVehicleFeature ? <SharedVehiclePostReservationDetails /> : <Navigate to="/" />}
        />

        <Route
          path={routes[ROUTE_NAMES.TRANSPORT_POST_TAXI_DETAILS]}
          element={permissionData?.taxiFeature ? <TaxiReservationDetails /> : <Navigate to="/" />}
        />

        <Route
          path={routes[ROUTE_NAMES.TRANSPORT_PRE_TAXI_DETAILS]}
          element={permissionData?.taxiFeature ? <TaxiPreReservationDetails /> : <Navigate to="/" />}
        />

        <Route path={routes[ROUTE_NAMES.TRANSPORT_TRANZER_DETAILS]} element={<TranzerReservationDetails />} />

        <Route path={routes[ROUTE_NAMES.PRODUCTS]} element={<Products />} />
        <Route path={routes[ROUTE_NAMES.PRODUCT_DETAILS]} element={<ProductDetails />} />
        <Route path={routes[ROUTE_NAMES.PRODUCT_DETAILS_MILEAGE]} element={<ProductDetailsMileage />} />

        <Route path={routes[ROUTE_NAMES.PRIVACY]} element={<Privacy />} />

        {/* eCurring */}
        <Route
          path={routes[ROUTE_NAMES.PAYMENTS]}
          element={viewDirectDebitInvoices ? <Billing isSplitBilling={false} /> : <Navigate to="/" />}
        />
        <Route
          path={routes[ROUTE_NAMES.PAYMENTS_INVOICES]}
          element={viewDirectDebitInvoices ? <AllInvoices isSplitBilling={false} /> : <Navigate to="/" />}
        />
        <Route
          path={routes[ROUTE_NAMES.PAYMENTS_MANDATES]}
          element={viewDirectDebitInvoices ? <AllMandates isSplitBilling={false} /> : <Navigate to="/" />}
        />

        {/* Twikey */}
        <Route
          path={routes[ROUTE_NAMES.SPLIT_BILLING_PAYMENTS]}
          element={viewSplitBillingInvoices ? <Billing isSplitBilling /> : <Navigate to="/" />}
        />
        <Route
          path={routes[ROUTE_NAMES.SPLIT_BILLING_PAYMENTS_INVOICES]}
          element={viewSplitBillingInvoices ? <AllInvoices isSplitBilling /> : <Navigate to="/" />}
        />
        <Route
          path={routes[ROUTE_NAMES.SPLIT_BILLING_PAYMENTS_MANDATES]}
          element={viewSplitBillingInvoices ? <AllMandates isSplitBilling /> : <Navigate to="/" />}
        />

        <Route path={routes[ROUTE_NAMES.FINANCES]} element={<Finances />} />
        <Route path={routes[ROUTE_NAMES.ERROR]} element={<InternalServerError />} />
        <Route path={routes[ROUTE_NAMES.CARDS_AND_SERVICES]} element={<CardsAndServices />} />
        <Route path={routes[ROUTE_NAMES.CARDS_AND_SERVICES_FEATURE_DETAILS]} element={<FeatureDetails />} />
        <Route path={routes[ROUTE_NAMES.CARDS_AND_SERVICES_CARD_DETAILS]} element={<ServicesCardDetails />} />
        <Route path={routes[ROUTE_NAMES.REUPLOAD_PHOTO]} element={<ServicesCardDetails showReuploadPhotoModal />} />
        <Route path={routes[ROUTE_NAMES.CARDS_AND_SERVICES_CARD_ORDER_DETAILS]} element={<CardOrderDetails />} />
        <Route path={routes[ROUTE_NAMES.CARDS_AND_SERVICES_SERVICE_DETAILS]} element={<ServicesServiceDetails />} />
        <Route path={routes[ROUTE_NAMES.REDIRECTING]} element={<Redirecting />} />
        <Route path={routes[ROUTE_NAMES.REAUTHORIZE]} element={<Reauthorize />} />
        <Route path={routes[ROUTE_NAMES.ROUTE_PLANNER]} element={<RoutePlanner />} />
        <Route path={routes[ROUTE_NAMES.EMISSION]} element={<Emissions />} />
        <Route path={routes[ROUTE_NAMES.SUBSTITUTE_MANAGER]} element={<SubstituteManager />} />

        {AuthorizedWelcomeRoutes}

        {/* Keep as the last path! */}
        <Route path="*" element={<PageNotFound />} />
      </React.Fragment>
    )
  }

  // FIXME this is a side effect of detecting if our session is expired or not. The currentUserContext runs a query which gets no data returned (as the graphql returns an error about the login being invalid) and we piggyback on that for now
  if (email === '') return <SessionExpiredPage />

  return (
    <CSSTransition timeout={{ enter: 250, exit: 0 }} classNames="fade">
      <Routes>{renderRoutes()}</Routes>
    </CSSTransition>
  )
}

export const AuthorizedLayout: React.FC = (): JSX.Element => {
  const { data } = useGetTermsStatusQuery()
  const me = data?.me
  const becameEmployee = !!me?.becameEmployee
  const hasAgreedToGeneralTerms = !!me?.hasAgreedToGeneralTerms
  const hasAgreedToPrivacyStatement = !!me?.hasAgreedToPrivacyStatement
  const canAccessDashboard = becameEmployee || (hasAgreedToPrivacyStatement && hasAgreedToGeneralTerms)

  useTrackPageView(routes)

  return (
    <div className={Styles.authorizedLayout}>
      <ScrollToTop />
      <IdleTimerModal />
      <CurrentUserProvider>
        <SidebarCollapsedProvider>
          <FeatureReleaseContent />
          <UpdatedTermsModal shouldCompleteOnboarding={!canAccessDashboard} />

          <FuelTypeAlertModal />

          {platform !== 'shuttel' && <FixLocationModal />}
          <TopLine />
          <MainTopBar hideNavigation={!canAccessDashboard} />
          <SideBar isVisible={canAccessDashboard}>
            <Suspense fallback={<MainLoading />}>
              <Router showOnboarding={!canAccessDashboard} />
            </Suspense>
          </SideBar>
        </SidebarCollapsedProvider>
      </CurrentUserProvider>
    </div>
  )
}
