import { TLocale } from '../sharedInterfaces'

export const generateHeaders = (): Record<string, string> => {
  const conditionalHeaders: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (import.meta.env.MODE !== 'production') conditionalHeaders['X-Requested-With'] = 'XMLHttpRequest'
  return conditionalHeaders
}

export const generateApolloClientHeaders = ({
  accessToken,
  withAuthorization,
  locale,
}: {
  accessToken?: string | null
  withAuthorization: boolean
  locale: TLocale
}): Record<string, string> => {
  const sharedHeaders = {
    ...generateHeaders(),
    Accept: 'application/json; api-version=3',
    'Accept-Language': locale,
    'User-Selected-Language': locale,
  }

  if (!withAuthorization || !accessToken) return sharedHeaders

  return {
    ...sharedHeaders,
    ...(accessToken && { authorization: `Bearer ${accessToken}` }),
  }
}
