import React from 'react'
import { useNavigate } from 'react-router'

import { Icon } from '../../../Icon'
import { Typography } from '../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const MobileBreadcrumbs: React.FC<IProps> = ({ breadcrumbs }): JSX.Element | null => {
  const navigate = useNavigate()

  const currentPage = breadcrumbs.at(-1)
  const previousPage = breadcrumbs.at(-2)

  if (!currentPage) return null

  return (
    <div className={Styles.mobileBreadcrumbs} data-testid="mobileBreadcrumbs">
      {previousPage && (
        <div
          className={Styles.button}
          role="button"
          tabIndex={0}
          onClick={(): void => {
            navigate(previousPage.url)
          }}
          data-testid="previousPageBtn"
        >
          <Icon name="chevron-left" width={8} height={12} />
        </div>
      )}
      <div className={Styles.textContainer}>
        <Typography variant="h2" element="h1" className={Styles.title}>
          {currentPage.title}
        </Typography>

        {currentPage.subTitle && (
          <Typography variant="h3" element="h2" className={Styles.subTitle}>
            {currentPage.subTitle}
          </Typography>
        )}
      </div>
      {previousPage && <div className={Styles.buttonPlaceholder} />}
    </div>
  )
}
