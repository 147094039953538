/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../FormComponents'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const GenericErrorComponent: React.FC<IProps> = ({ error }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={Styles.container} aria-label={t('Loading error')}>
      <h1>{t('Loading error')}</h1>
      <p className={Styles.errorMessage}>
        {t(
          `Something went wrong loading this page. Please try again or go to your dashboard. The literal error is: {{ error }}`,
          { error: error.message }
        )}
      </p>
      <div className={Styles.buttonWrapper}>
        <Button id="reload-button" variant="primary" onClick={(): void => window.location.reload()}>
          {t('Reload page')}
        </Button>
        <Button id="home-button" variant="secondary" onClick={(): void => navigate('/')}>
          {t('Go to my dashboard')}
        </Button>
      </div>
    </div>
  )
}
