import React from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from '../../Components/EmptyState'
import { GenericErrorComponent } from '../../Components/GenericErrorComponent'
import { PageContainer } from '../../Components/PageContainer'
import { useGetBillingDataQuery } from '../../Lib/graphql'
import { LoadingSkeleton } from './Components/LoadingSkeleton'
import ECurring from './ECurring'
import { IProps as IECurringProps } from './ECurring/interfaces'
import { IProps } from './interfaces'
import Twikey from './Twikey'
import { IProps as ITwikeyProps } from './Twikey/interfaces'

const Billing: React.FC<IProps> = ({ isSplitBilling }): JSX.Element => {
  const { t } = useTranslation()
  const title = isSplitBilling ? t('Billing') : t('Payments & Authorizations')

  const { data, loading, error } = useGetBillingDataQuery({
    variables: {
      isSplitBilling,
    },
    fetchPolicy: 'cache-and-network',
  })

  const me = data?.me

  const sortedTwikeyInvoices =
    me?.twikeyInvoices?.slice().sort((first, second) => {
      return new Date(second.invoiceDate).getTime() - new Date(first.invoiceDate).getTime()
    }) || []

  const sortedTwikeyContracts =
    me?.twikeyContracts?.slice().sort((first, second) => {
      return Number(second.id) - Number(first.id)
    }) || []

  const twikeyProps: ITwikeyProps = {
    invoices: sortedTwikeyInvoices,
    contracts: sortedTwikeyContracts,
    canRequestContract: !!me?.permissions.requestSplitBillingContract,
  }

  const sortedECurringInvoices =
    me?.eCurringInvoices?.slice().sort((first, second) => {
      return new Date(second.invoiceDate).getTime() - new Date(first.invoiceDate).getTime()
    }) || []

  const sortedECurringMandates =
    me?.eCurringMandates?.slice().sort((first, second) => {
      return Number(second.id) - Number(first.id)
    }) || []

  const eCurringProps: IECurringProps = {
    invoices: sortedECurringInvoices,
    mandates: sortedECurringMandates,
    financialConsequences: me?.financialConsequences?.tentativeSettlement,
    eMandateRequired: !!me?.eMandateRequired,
  }

  return (
    <PageContainer title={title}>
      {((): JSX.Element | null => {
        if (loading) return <LoadingSkeleton />
        if (error) return <GenericErrorComponent error={error} />
        if (!data) return <EmptyState icon="receipt-light" title={t("You don't have any bills yet")} description="" />

        if (isSplitBilling) return <Twikey {...twikeyProps} />
        return <ECurring {...eCurringProps} />
      })()}
    </PageContainer>
  )
}

export default Billing
