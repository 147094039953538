import React from 'react'

import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const EmptyState: React.FC<IProps> = ({ title, description, icon, iconColor }): JSX.Element => {
  return (
    <div className={Styles.emptyState}>
      <Icon name={icon} width={114} height={142} {...(iconColor ? { color: iconColor } : {})} />
      <Typography variant="h1-large" className={Styles.title}>
        {title}
      </Typography>
      {description && (
        <Typography variant="body-2" className={Styles.description}>
          {description}
        </Typography>
      )}
    </div>
  )
}
