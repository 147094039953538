import React, { lazy, Suspense, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { ScrollToTop } from '../../Components'
import { MainLoading } from '../../Components/MainLoading'
import { TopLine } from '../../Components/TopLine'
import { useTrackPageView } from '../../Hooks/useTrackPageView/useTrackPageView'
import { AuthContext } from '../../Providers'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Help = lazy(() => import(/* webpackChunkName: "HelpPage" */ '../../Pages/Help'))
const Login = lazy(() => import(/* webpackChunkName: "LoginPage" */ '../../Pages/Login'))
const MFAConfigure = lazy(() => import(/* webpackChunkName: "MFAPage" */ '../../Pages/MFAConfigure'))
const MFALogin = lazy(() => import(/* webpackChunkName: "MFAPage" */ '../../Pages/MFALogin'))
const ForgotPassword = lazy(() => import(/* webpackChunkName: "ForgotPasswordPage" */ '../../Pages/ForgotPassword'))
const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPasswordPage" */ '../../Pages/ResetPassword'))
const Reauthorize = lazy(() => import(/* webpackChunkName: "ResetPasswordPage" */ '../../Pages/Reauthorize'))
const Welcome = lazy(() => import(/* webpackChunkName: "WelcomePage" */ '../../Pages/Welcome/Steps/Welcome'))
const InternalServerError = lazy(() => import(/* webpackChunkName: "PageNotFound" */ '../../Pages/ErrorPages/500'))
const SessionExpiredPage = lazy(() => import(/* webpackChunkName: "PageNotFound" */ '../../Pages/ErrorPages/401'))

const Router: React.FC = (): JSX.Element => {
  const { loggedIn } = useContext(AuthContext)

  useTrackPageView(routes)

  return (
    <React.Fragment>
      {/*
      // @ts-ignore */}
      <CSSTransition timeout={{ enter: 250, exit: 0 }} className="fade">
        <Suspense fallback={<MainLoading />}>
          <Routes>
            <Route path={routes[ROUTE_NAMES.ERROR]} element={<InternalServerError />} />

            <Route path={routes[ROUTE_NAMES.LOGIN]} element={<Login />} />

            <Route path={routes[ROUTE_NAMES.MFA_CONFIGURE]} element={<MFAConfigure />} />

            <Route path={routes[ROUTE_NAMES.MFA_LOGIN]} element={<MFALogin />} />

            <Route path={routes[ROUTE_NAMES.HELP]} element={<Help />} />

            <Route path={routes[ROUTE_NAMES.FORGOT_PASSWORD]} element={<ForgotPassword />} />

            <Route path={routes[ROUTE_NAMES.WELCOME]} element={<Welcome />} />

            <Route path={routes[ROUTE_NAMES.RESET_PASSWORD]} element={<ResetPassword />} />

            <Route path={routes[ROUTE_NAMES.REAUTHORIZE]} element={<Reauthorize />} />

            <Route path={routes[ROUTE_NAMES.SESSION_EXPIRED]} element={<SessionExpiredPage />} />

            <Route
              path="*"
              element={!loggedIn ? <Navigate to="/" state={{ redirectPath: window.location.pathname }} /> : null}
            />
          </Routes>
        </Suspense>
      </CSSTransition>
    </React.Fragment>
  )
}

export const UnauthorizedLayout: React.FC = (): JSX.Element => {
  return (
    <div className={Styles.unauthorizedLayout}>
      <ScrollToTop />
      <TopLine />
      <div className={Styles.content}>
        <Router />
      </div>
    </div>
  )
}
