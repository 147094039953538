import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore as there is no type definition file anymore.
import ReactSelect, { ActionMeta, CSSObjectWithLabel, StylesConfig, ValueType } from 'react-select'

// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import colors from '../../../Theme/colors.module.scss'
import { MaybeRenderWithLabelHOC } from '../Lib/MaybeRenderWithLabelHOC'
import { selectStyle } from './helpers'
import { IProps } from './interfaces'

export const SelectComponent = <T,>(props: IProps<T>): JSX.Element => {
  const { id, disabled, onChange, placeholder, styles, label, value, width, errors, ariaLabel, ...otherProps } = props
  const { i18n } = useTranslation()

  const baseStyles: StylesConfig<T, false> = {
    container: (provided) => {
      return {
        ...provided,
        marginTop: label ? '14px !important' : '0',
        marginBottom: errors?.length ? '0.5em' : '0',
        width: width || '100%',
      }
    },
    control: (provided: CSSObjectWithLabel, state: any) => {
      const borderColor = ((): string => {
        if (errors?.length) return colors.red

        return state.isFocused ? colors.gray600 : colors.gray200
      })()

      return {
        ...provided,
        borderColor,
        '&:hover': {
          borderColor,
        },
        '&:focus': {
          borderColor,
        },
      }
    },
  }

  const handleOnChange = (newValue: ValueType<T, false>, _actionMeta: ActionMeta<T>): void => {
    if (onChange) onChange(newValue)
  }

  return (
    <ReactSelect<T>
      {...otherProps}
      aria-label={ariaLabel}
      classNamePrefix={id}
      isDisabled={disabled}
      inputId={id}
      noOptionsMessage={(_obj: { inputValue: string }): string | null => i18n.t('no results found...')}
      onChange={handleOnChange}
      placeholder={placeholder || (i18n.t('Please select an option') as ReactNode)}
      styles={{ ...(selectStyle as StylesConfig<T>), ...baseStyles, ...styles }}
      value={value}
      isMulti={false}
    />
  )
}

export const Select = MaybeRenderWithLabelHOC(SelectComponent) as <T>(props: IProps<T>) => JSX.Element
