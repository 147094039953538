/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Base64 encoded data type */
  Base64: { input: string; output: string; }
  /** Big decimal type */
  BigDecimal: { input: number; output: number; }
  /** Coordinate type */
  Coordinate: { input: number; output: number; }
  /** Date type */
  Date: { input: string; output: string; }
  /** Date time type */
  DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: { [key: string]: unknown }; output: { [key: string]: unknown }; }
  ObjMap: { input: any; output: any; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any; }
}

/** Autogenerated input type of AcceptMobilityEvents */
export interface IAcceptMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of AcceptMobilityEvents. */
export interface IAcceptMobilityEventsPayload {
  __typename?: 'AcceptMobilityEventsPayload';
  acceptedMobilityEvents: Array<IMobilityEvent>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  unacceptedMobilityEvents: Array<IMobilityEvent>;
}

export interface IActionRequired extends ITask {
  __typename?: 'ActionRequired';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IActions {
  __typename?: 'Actions';
  /** Whether this mobility event will be unconfirmable after confirming, or unacceptable after accepting */
  canRestoreToInitial: Scalars['Boolean']['output'];
  categoryAndVehicleEditable: Scalars['Boolean']['output'];
  changeCombinedRide: Scalars['Boolean']['output'];
  /** Whether the travel purpose of this mobility event can be changed. */
  changeTravelPurpose: Scalars['Boolean']['output'];
  /** Whether the mobility event can be confirmed */
  confirmable: Scalars['Boolean']['output'];
  copyable: Scalars['Boolean']['output'];
  deletable: Scalars['Boolean']['output'];
  distanceOverridable: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
  mergeable: Scalars['Boolean']['output'];
  missingCheckout: Scalars['Boolean']['output'];
  /** @deprecated Submit used to be a synonym for confirm. We now only use confirm, so use confirmable instead */
  submittable: Scalars['Boolean']['output'];
  unacceptable: Scalars['Boolean']['output'];
  unconfirmable: Scalars['Boolean']['output'];
  /** Whether this mobility event can be viewed in detail */
  viewDetails: Scalars['Boolean']['output'];
}

export interface IActivatableDongle extends ITask {
  __typename?: 'ActivatableDongle';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

/** Autogenerated input type of ActivateEnecoDongle */
export interface IActivateEnecoDongleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobideskOwnershipToken: Scalars['Int']['input'];
}

/** Autogenerated return type of ActivateEnecoDongle. */
export interface IActivateEnecoDonglePayload {
  __typename?: 'ActivateEnecoDonglePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enecoDongle?: Maybe<IEnecoDongle>;
  result: IResult;
}

/** Autogenerated input type of ActivatePersonalMeansOfRegistration */
export interface IActivatePersonalMeansOfRegistrationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of ActivatePersonalMeansOfRegistration. */
export interface IActivatePersonalMeansOfRegistrationPayload {
  __typename?: 'ActivatePersonalMeansOfRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  result: IResult;
}

export interface IActiveAdditional extends IAvailableServiceInterface {
  __typename?: 'ActiveAdditional';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IActiveGreenwheels extends IAvailableServiceInterface {
  __typename?: 'ActiveGreenwheels';
  /** Whether an employee can activate the greenwheels service */
  activatable: Scalars['Boolean']['output'];
  /** Activation link for Greenwheels */
  activationLink?: Maybe<Scalars['String']['output']>;
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** List of countries whose driving licenses can be used for Greenwheels */
  driversLicenseCountries: Array<IDriversLicenseCountry>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** URL to the Terms and Conditions document of Greenwheels. This is different then the terms_and_conditions document, which always comes from the organization the employee belongs to. */
  greenwheelsTermsAndConditionsUrl: Scalars['String']['output'];
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether an employee can register for greenwheels */
  registerable: Scalars['Boolean']['output'];
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
  urls?: Maybe<IAppUrls>;
}

export interface IActiveSubscription extends IAvailableServiceInterface {
  __typename?: 'ActiveSubscription';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Star value, relevant for bus transport. Only present for specific subscriptions. */
  starValue?: Maybe<Scalars['String']['output']>;
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
  /** Train class for this subscription. Only present for subscriptions that have something to do with trains. */
  trainClass?: Maybe<ITrainClass>;
  /** Grouped fields that are about train class change */
  trainClassChange: ITrainClassChange;
  /** Specific traject you can travel on. Most of the times the names of two cities. Only present for specific subscriptions. */
  traject?: Maybe<ITraject>;
  /** Zone for this subscription, represented as four digits. Only present for specific subscriptions. */
  zone?: Maybe<Scalars['String']['output']>;
}

export interface IActiveYellowbrickSp extends IAvailableServiceInterface {
  __typename?: 'ActiveYellowbrickSp';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
  /** Yellowbrick uesrname */
  username?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of AddPushNotificationToken */
export interface IAddPushNotificationTokenInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
}

/** Autogenerated return type of AddPushNotificationToken. */
export interface IAddPushNotificationTokenPayload {
  __typename?: 'AddPushNotificationTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of AddToWhitelist */
export interface IAddToWhitelistInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Reisbalans mobility card id */
  mobilityCardId: Scalars['ID']['input'];
}

/** Autogenerated return type of AddToWhitelist. */
export interface IAddToWhitelistPayload {
  __typename?: 'AddToWhitelistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  studentTravelRequestDates: IRequestDates;
  validationErrors: Array<IValidationError>;
}

export interface IAddableVehiclePermission {
  __typename?: 'AddableVehiclePermission';
  /** Allowed fuel types for this vehicle permission */
  allowedFuelTypes: Array<IFuel>;
  /** Icon for this modality permission */
  icon: IIcon;
  /** Vehicle modality information for this permission */
  name: IVehicleModalityKind;
}

export interface IAddress {
  __typename?: 'Address';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  location: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  type?: Maybe<ITypeEnum>;
}

/** Autogenerated input type of AdminLogin */
export interface IAdminLoginInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account */
  email: Scalars['String']['input'];
  /** One Time Password of the account (2fa code) */
  otp?: InputMaybe<Scalars['String']['input']>;
  /** Password of the account */
  password: Scalars['String']['input'];
}

/** Autogenerated return type of AdminLogin. */
export interface IAdminLoginPayload {
  __typename?: 'AdminLoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The JWT Token that can be used as authentication */
  jwt?: Maybe<Scalars['String']['output']>;
  result: IResult;
  tokens?: Maybe<IAuth>;
}

export interface IAmber extends IAvailableFeatureInterface {
  __typename?: 'Amber';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  /**
   * This is only available when the request for this feature has been approved and the employee is allowed to order this feature
   * @deprecated This URL is not valid as we want to redirect users to the mobile app, not the website. Dont use this field anymore.
   */
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IAppUrls {
  __typename?: 'AppUrls';
  /** Android app URL */
  android: Scalars['String']['output'];
  /** iOs app URL */
  ios: Scalars['String']['output'];
}

export interface IAssetAvailability {
  __typename?: 'AssetAvailability';
  /** Number of available bikes at this Station */
  bikes: Scalars['Int']['output'];
  /** Number of available bike drop-off points at this Station */
  dropoff: Scalars['Int']['output'];
  /** Number of available e-bikes at this Station */
  ebikes: Scalars['Int']['output'];
}

export interface IAssetLocation {
  __typename?: 'AssetLocation';
  /** Country code for this City */
  countryCode: Scalars['String']['output'];
  geoLocation: ICoordinates;
  /** This City's identifier */
  identifier: Scalars['String']['output'];
  /** Human readable name for this City */
  name: Scalars['String']['output'];
  /** A list of Drop-off & pickup locations within this City */
  stations: Array<IAssetStation>;
}

export interface IAssetStation {
  __typename?: 'AssetStation';
  availability?: Maybe<IAssetAvailability>;
  geoLocation?: Maybe<ICoordinates>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of AssignReplacementManager */
export interface IAssignReplacementManagerInput {
  /** The end of the approval period for this replacement manager */
  approvalPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  /** The start of the approval period for this replacement manager */
  approvalPeriodStart: Scalars['Date']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The email of the employee that is to be assigned as a replacement manager */
  email: Scalars['String']['input'];
  /** If this ID is given, update the replacement manager. Otherwise it is a create mutation. */
  replacementManagerId?: InputMaybe<Scalars['ID']['input']>;
}

/** Autogenerated return type of AssignReplacementManager. */
export interface IAssignReplacementManagerPayload {
  __typename?: 'AssignReplacementManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  replacementManagerId?: Maybe<Scalars['ID']['output']>;
  result: IResult;
}

export interface IAttachment {
  data: Scalars['Base64']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export interface IAuth {
  __typename?: 'Auth';
  /** Access token that can be used as authentication */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** Refresh token that can be used to get new access token when expired */
  refreshToken?: Maybe<Scalars['String']['output']>;
}

export interface IAvailableFeatureInterface {
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export enum IAvailableServiceAvailabilityEnum {
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

export interface IAvailableServiceInterface {
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IAvailableSharedCar {
  __typename?: 'AvailableSharedCar';
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  id: Scalars['ID']['output'];
  startsAt: Scalars['DateTime']['output'];
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export interface IAvailableSharedVehicle {
  __typename?: 'AvailableSharedVehicle';
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  icon: IIcon;
  id: Scalars['ID']['output'];
  modality: IModality;
  startsAt: Scalars['DateTime']['output'];
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export interface IAvailableSubscription {
  __typename?: 'AvailableSubscription';
  hasTraject: Scalars['Boolean']['output'];
  humanized: Scalars['String']['output'];
  /** Humanized values for the zones */
  humanizedZones?: Maybe<Array<IZone>>;
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  starValues: Array<IStarValue>;
  trainClassOptions?: Maybe<ITrainClassOptions>;
  value: ISubscriptionEnum;
  /** @deprecated Deprecated due to introduction of humanized_zones */
  zones: Array<Scalars['String']['output']>;
}

export interface IBalance {
  __typename?: 'Balance';
  totalFiscalAmount?: Maybe<Scalars['Float']['output']>;
  totalGrossAmount?: Maybe<Scalars['Float']['output']>;
  totalNetAmount?: Maybe<Scalars['Float']['output']>;
}

export interface IBikeAccess {
  __typename?: 'BikeAccess';
  tokenData?: Maybe<IEkeyLock>;
  tokenType?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validUntil?: Maybe<Scalars['DateTime']['output']>;
}

export interface IBranding {
  __typename?: 'Branding';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface IBudget {
  __typename?: 'Budget';
  /** The humanized values reflecting the valueInCents */
  amount: IMoneyTransfer;
  /** A label to describe what kind of budget this is */
  label: Scalars['String']['output'];
  /**
   * The exact amount as float, can be either positive or negative
   * @deprecated Use valueInCents instead, its more reliable when doing things like calculations
   */
  value: Scalars['Float']['output'];
  /** The exact amount in cents, can be either positive or negative */
  valueInCents: Scalars['Int']['output'];
}

export interface IBudgetGroup {
  __typename?: 'BudgetGroup';
  id: Scalars['ID']['output'];
  reimbursementRules: Array<IReimbursementRule>;
  wkrEnabledBusiness: Scalars['Boolean']['output'];
  wkrEnabledCommute: Scalars['Boolean']['output'];
}

export interface ICalculatedDistance {
  __typename?: 'CalculatedDistance';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** Autogenerated input type of CancelDonkeyRepublicReservation */
export interface ICancelDonkeyRepublicReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CancelDonkeyRepublicReservation. */
export interface ICancelDonkeyRepublicReservationPayload {
  __typename?: 'CancelDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of CancelSharedCarReservation */
export interface ICancelSharedCarReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of CancelSharedCarReservation. */
export interface ICancelSharedCarReservationPayload {
  __typename?: 'CancelSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedCarReservation: ISharedCarReservation;
}

/** Autogenerated input type of CancelSharedVehicleReservation */
export interface ICancelSharedVehicleReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of CancelSharedVehicleReservation. */
export interface ICancelSharedVehicleReservationPayload {
  __typename?: 'CancelSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation: ISharedVehicleReservation;
}

/** Autogenerated input type of CancelTaxiReservation */
export interface ICancelTaxiReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  taxiReservationId: Scalars['ID']['input'];
}

/** Autogenerated return type of CancelTaxiReservation. */
export interface ICancelTaxiReservationPayload {
  __typename?: 'CancelTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation: ITaxiReservation;
}

export interface ICategory {
  __typename?: 'Category';
  id: Scalars['ID']['output'];
  main: Scalars['String']['output'];
  mainCategory: IMainCategory;
  /**
   * The reimbursable category method. Ride, Route or Both
   * @deprecated Use whatOptions reimbursable_category_method
   */
  reimbursableCategoryMethod?: Maybe<IReimbursableCategoryMethod>;
  requiresModality: Scalars['Boolean']['output'];
  sub?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<ISubCategory>;
}

export interface ICo2Emission {
  __typename?: 'Co2Emission';
  humanized: Scalars['String']['output'];
  value: Scalars['Int']['output'];
}

export interface ICo2EmissionPerModality {
  __typename?: 'Co2EmissionPerModality';
  emission: ICo2Emission;
  modality?: Maybe<IModality>;
}

export interface ICo2EmissionSummary {
  __typename?: 'Co2EmissionSummary';
  mobilityEventsPerModality?: Maybe<Array<ICo2EmissionPerModality>>;
  totalEmission: ICo2Emission;
}

export interface IColor {
  __typename?: 'Color';
  /** The color in html HEX value */
  hex?: Maybe<Scalars['String']['output']>;
  /** The color in Hue, Saturation, Lightness and Alpha values */
  hsla?: Maybe<IHsla>;
  /** The color in Red, Green, Blue and Alpha values */
  rgba?: Maybe<IRgba>;
}

export interface ICompensationRideToBeCreated extends ITask {
  __typename?: 'CompensationRideToBeCreated';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  incompleteRideId?: Maybe<Scalars['ID']['output']>;
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  refundId?: Maybe<Scalars['ID']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IConfiguration {
  __typename?: 'Configuration';
  grossLimit?: Maybe<Scalars['Float']['output']>;
  grossPercentage?: Maybe<Scalars['Int']['output']>;
  limitFrom?: Maybe<Scalars['Float']['output']>;
  limitTo?: Maybe<Scalars['Float']['output']>;
  limitToDefaultCommute?: Maybe<Scalars['Boolean']['output']>;
  netLimit?: Maybe<Scalars['Float']['output']>;
  netPercentage?: Maybe<Scalars['Int']['output']>;
  offsets?: Maybe<Array<Array<Scalars['Float']['output']>>>;
  threshold?: Maybe<Scalars['Float']['output']>;
  wkrAllowance?: Maybe<Scalars['Float']['output']>;
}

export interface IConfigurationProfileForHost {
  __typename?: 'ConfigurationProfileForHost';
  /** The customer_label settings for this host */
  customerLabelTheme?: Maybe<ICustomerLabelTheme>;
  /** Customer service details */
  customerService?: Maybe<ICustomerService>;
  /** Whether or not this host uses SSO to login */
  ssoUrl?: Maybe<Scalars['String']['output']>;
  /** The theme settings for this host */
  theme?: Maybe<ITheme>;
}

/** Autogenerated input type of ConfigureMfa */
export interface IConfigureMfaInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account */
  email: Scalars['String']['input'];
  /** Password of the account */
  password: Scalars['String']['input'];
  /** Validation of the otp secret */
  validateOtpSecret?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfigureMfa. */
export interface IConfigureMfaPayload {
  __typename?: 'ConfigureMfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** True when the MFA is correctly configured */
  configurationSuccess?: Maybe<Scalars['Boolean']['output']>;
  /** Base64 encoded otp uri as input for a QR code */
  otpUri?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of ConfirmGreenwheels */
export interface IConfirmGreenwheelsInput {
  /** Whether organizational terms and conditions are accepted */
  acceptOrganizationTerms?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether Greenwheels terms and conditions are accepted */
  acceptTerms: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Country that issues the drivers license */
  driversLicenseCountry: IDriversLicenseCountryEnum;
  /** Number of drivers license */
  driversLicenseNumber: Scalars['String']['input'];
  /** This is only required when the Greenwheels service is confirmed, for the feature it is optional */
  mobilityCardId?: InputMaybe<Scalars['ID']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfirmGreenwheels. */
export interface IConfirmGreenwheelsPayload {
  __typename?: 'ConfirmGreenwheelsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** List of validation errors */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of Confirm */
export interface IConfirmInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
}

/** Autogenerated input type of ConfirmMobilityEvent */
export interface IConfirmMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of ConfirmMobilityEvent. */
export interface IConfirmMobilityEventPayload {
  __typename?: 'ConfirmMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  day: IDayWithMobilityEvents;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
}

/** Autogenerated input type of ConfirmMobilityEvents */
export interface IConfirmMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of ConfirmMobilityEvents. */
export interface IConfirmMobilityEventsPayload {
  __typename?: 'ConfirmMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  confirmedMobilityEvents: Array<IMobilityEvent>;
  days: Array<IDayWithMobilityEvents>;
  result: IResult;
  unconfirmedMobilityEvents: Array<IMobilityEvent>;
}

/** Autogenerated return type of Confirm. */
export interface IConfirmPayload {
  __typename?: 'ConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The confirmed mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of confirming the mobility card order */
  result: IResult;
}

/** Autogenerated input type of ConfirmSharedCarReservation */
export interface IConfirmSharedCarReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfirmSharedCarReservation. */
export interface IConfirmSharedCarReservationPayload {
  __typename?: 'ConfirmSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** @deprecated Use sharedVehicleReservation */
  sharedCarReservation?: Maybe<ISharedCarReservation>;
}

/** Autogenerated input type of ConfirmSharedVehicleReservation */
export interface IConfirmSharedVehicleReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of ConfirmSharedVehicleReservation. */
export interface IConfirmSharedVehicleReservationPayload {
  __typename?: 'ConfirmSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation?: Maybe<ISharedVehicleReservation>;
}

/** Autogenerated input type of ConfirmTaxiReservation */
export interface IConfirmTaxiReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  taxiReservationId: Scalars['ID']['input'];
}

/** Autogenerated return type of ConfirmTaxiReservation. */
export interface IConfirmTaxiReservationPayload {
  __typename?: 'ConfirmTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation: ITaxiReservation;
}

export interface IContractActions {
  __typename?: 'ContractActions';
  revocable: Scalars['Boolean']['output'];
}

export enum IContractPaymentServiceProviderEnum {
  TWIKEY = 'TWIKEY'
}

export interface IContractStatus {
  __typename?: 'ContractStatus';
  humanized: Scalars['String']['output'];
  value: IContractStatusEnum;
}

export enum IContractStatusEnum {
  ACTIVE = 'ACTIVE',
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  INVITED = 'INVITED',
  NEW = 'NEW',
  SUSPENDED = 'SUSPENDED'
}

export interface ICoordinates {
  __typename?: 'Coordinates';
  latitude: Scalars['Coordinate']['output'];
  longitude: Scalars['Coordinate']['output'];
}

export interface ICoordinatesMutation {
  latitude: Scalars['Coordinate']['input'];
  longitude: Scalars['Coordinate']['input'];
}

/** Autogenerated input type of CopyMobilityEventsForDay */
export interface ICopyMobilityEventsForDayInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Which date to copy */
  from: Scalars['Date']['input'];
  /** Dates to copy the manual mobility events on the given from date to */
  to: Array<Scalars['Date']['input']>;
}

/** Autogenerated return type of CopyMobilityEventsForDay. */
export interface ICopyMobilityEventsForDayPayload {
  __typename?: 'CopyMobilityEventsForDayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDay>;
  result: IResult;
}

/** Autogenerated input type of CopyMobilityEvents */
export interface ICopyMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of CopyMobilityEvents. */
export interface ICopyMobilityEventsPayload {
  __typename?: 'CopyMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDayWithMobilityEvents>;
  result: IResult;
}

export interface ICost extends IReimbursementRule {
  __typename?: 'Cost';
  budgetGroupId: Scalars['ID']['output'];
  category: ICategory;
  configuration: IConfiguration;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  main: IMainCategory;
  modality?: Maybe<IModality>;
  reimbursableCategory: IReimbursableCategory;
  sub?: Maybe<ISubCategory>;
  travelPurpose: Scalars['String']['output'];
}

export interface ICountryIsoCode {
  __typename?: 'CountryIsoCode';
  humanized: Scalars['String']['output'];
  value: ICountryIsoCodeEnum;
}

export enum ICountryIsoCodeEnum {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

/** Autogenerated input type of CreateCommuteCompensation */
export interface ICreateCommuteCompensationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date to create the commute compensations for. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Dates to create the commute compensations for. */
  dates?: InputMaybe<Array<Scalars['Date']['input']>>;
}

/** Autogenerated return type of CreateCommuteCompensation. */
export interface ICreateCommuteCompensationPayload {
  __typename?: 'CreateCommuteCompensationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed commute compensations. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  dayWithMobilityEvents: IDayWithMobilityEvents;
  /** returns mobility events for all dates if given mutiple dates */
  daysWithMobilityEvents: Array<IDayWithMobilityEvents>;
  mobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  /** List of all unconfirmed commute compensations. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateCommuteRides */
export interface ICreateCommuteRidesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date to create the commute rides for. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Dates to create the commute rides for. */
  dates?: InputMaybe<Array<Scalars['Date']['input']>>;
}

/** Autogenerated return type of CreateCommuteRides. */
export interface ICreateCommuteRidesPayload {
  __typename?: 'CreateCommuteRidesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed commute rides. A Commute ride can be confirmed by passing confirm: true. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  dayWithMobilityEvents: IDayWithMobilityEvents;
  /** returns mobility events for all dates if given mutiple dates */
  daysWithMobilityEvents: Array<IDayWithMobilityEvents>;
  /** Favorite with a fallback location that cause the mutation to fail */
  favoriteWithFallback?: Maybe<IFavorite>;
  mobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  /** List of all unconfirmed commute rides. A Commute ride can be confirmed by passing confirm: true. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateCompensationMobilityEvent */
export interface ICreateCompensationMobilityEventInput {
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  incompleteRideId: Scalars['Int']['input'];
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  refundId: Scalars['Int']['input'];
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** DEPRECATED. The backend has taken over the responsibility and this field will be faced out in the future. */
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}

/** Autogenerated return type of CreateCompensationMobilityEvent. */
export interface ICreateCompensationMobilityEventPayload {
  __typename?: 'CreateCompensationMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Created mobility event, if multiple mobility events are created it contains the first created mobility event */
  mobilityEvent?: Maybe<IMobilityEvent>;
  /** Result of creating a mobility event */
  result: IResult;
  /** List of validation errors while creating mobility events */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateDirectDebitMandate */
export interface ICreateDirectDebitMandateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateDirectDebitMandate. */
export interface ICreateDirectDebitMandatePayload {
  __typename?: 'CreateDirectDebitMandatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new direct debit mandate */
  directDebitMandate?: Maybe<IDirectDebitMandate>;
  /** Whether the request was a success or not */
  result: IResult;
}

/** Autogenerated input type of CreateDonkeyRepublicReservation */
export interface ICreateDonkeyRepublicReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
}

/** Autogenerated return type of CreateDonkeyRepublicReservation. */
export interface ICreateDonkeyRepublicReservationPayload {
  __typename?: 'CreateDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of CreateFavorite */
export interface ICreateFavoriteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A human friendly name to recognize this favorite, for example 'Reisbalans'. */
  label: Scalars['String']['input'];
  /** The latitude of the location. */
  latitude: Scalars['Coordinate']['input'];
  /** The fill address information as string, for example 'Utrechtseweg 9, 3811NA Amersfoort'. */
  location: Scalars['String']['input'];
  /** An enriched location object with complete address address information from Google Places. This being optional is for backwards compatibility, we really want to make it required. */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** The longitude of the location. */
  longitude: Scalars['Coordinate']['input'];
  /** If this favorite should belong to an organization, then pass along its id here. (Only admins are allowed to set this) */
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  /** The type of favorite this is. */
  type?: InputMaybe<IFavoriteTypeEnum>;
}

/** Autogenerated return type of CreateFavorite. */
export interface ICreateFavoritePayload {
  __typename?: 'CreateFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new favorite record */
  favorite?: Maybe<IFavorite>;
  /** Whether the request was a success or not */
  result: IResult;
  /** If something was not ok, you can find the reason here */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateLeaseBikeOrder */
export interface ICreateLeaseBikeOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Duration of the lease contract in months */
  durationInMonths?: InputMaybe<Scalars['Int']['input']>;
  /** Key that identifies this order in Reisbalans */
  employeeKey: Scalars['String']['input'];
  /** Start date of the lease contract */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Lease price in cents */
  leasePriceInCents?: InputMaybe<Scalars['Int']['input']>;
  /** Order key that identifies this order */
  orderKey: Scalars['String']['input'];
  /** Description of the product in the lease contract */
  productDescription?: InputMaybe<Scalars['String']['input']>;
  /** Image of the product in the lease contract */
  productImage?: InputMaybe<IAttachment>;
  /** Name of the product in the lease contract */
  productName?: InputMaybe<Scalars['String']['input']>;
  /** Start date of the lease contract */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /** Status of the order */
  status?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateLeaseBikeOrder. */
export interface ICreateLeaseBikeOrderPayload {
  __typename?: 'CreateLeaseBikeOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateMileage */
export interface ICreateMileageInput {
  amountInKilometers: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  licensePlate: Scalars['String']['input'];
  recordedAt: Scalars['DateTime']['input'];
}

/** Autogenerated return type of CreateMileage. */
export interface ICreateMileagePayload {
  __typename?: 'CreateMileagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Added mileage */
  mileage?: Maybe<IMileage>;
  /** Result of adding a mileage */
  result: IResult;
  /** List of validation errors while adding a mileage */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateMobilityCardOrder */
export interface ICreateMobilityCardOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateMobilityCardOrder. */
export interface ICreateMobilityCardOrderPayload {
  __typename?: 'CreateMobilityCardOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Created mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of creating a mobility card order */
  result: IResult;
}

/** Autogenerated input type of CreateMobilityEvent */
export interface ICreateMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm the mobility events right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether a retour ride should be created */
  createRetourRide?: InputMaybe<Scalars['Boolean']['input']>;
  /** The specific employment id you want to link this mobility event to */
  employmentId?: InputMaybe<Scalars['ID']['input']>;
  financial?: InputMaybe<IFinancialMutation>;
  fiscality?: InputMaybe<IFiscalityEnum>;
  happenedAt: Scalars['DateTime']['input'];
  /** Whether or not to recalculate the consumption */
  recalculate?: InputMaybe<Scalars['Boolean']['input']>;
  route?: InputMaybe<IRouteMutation>;
  routes?: InputMaybe<Array<IRouteMutation>>;
  /** DEPRECATED. Submit used to be a synonym for confirm, use confirm instead */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  what?: InputMaybe<ICreateWhatMutation>;
  why: IWhyMutation;
}

/** Autogenerated return type of CreateMobilityEvent. */
export interface ICreateMobilityEventPayload {
  __typename?: 'CreateMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed mobility events. A mobility event can be confirmed by passing confirm: true when creating the mobility event. Confirming happens apart from creating the mobility event, meaning it can fail. All successfully confirmed mobility event is in this field. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** Created mobility event, if multiple mobility events are created it contains the first created mobility event */
  mobilityEvent?: Maybe<IMobilityEvent>;
  /** List of all created mobility events */
  mobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** Result of creating a mobility event */
  result: IResult;
  /** List of all unconfirmed mobility events. A mobility event can be confirmed by passing confirm: true when creating the mobility event. Confirming happens apart from creating the mobility event, meaning it can fail. When a confirm should happen but didnt, the mobility event is in this field. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** List of validation errors while creating mobility events */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateParkingOrder */
export interface ICreateParkingOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Motivation for your parking order */
  motivation?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateParkingOrder. */
export interface ICreateParkingOrderPayload {
  __typename?: 'CreateParkingOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  parkingOrder?: Maybe<IParkingOrder>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateTaxiReservation */
export interface ICreateTaxiReservationInput {
  /** The desired time at which the taxi should be waiting for you. */
  at: Scalars['DateTime']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The phone number that the driver can reach you on. */
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The departure location. */
  departure: ILocationMutation;
  /** The destination location. */
  destination: ILocationMutation;
  /** The amount of passengers making use of the taxi. */
  passengers: Scalars['Int']['input'];
  /** Any remarks that you would like to pass on to the taxi driver. */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** The desired type of taxi you would like to order. */
  vehicleType?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of CreateTaxiReservation. */
export interface ICreateTaxiReservationPayload {
  __typename?: 'CreateTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation?: Maybe<ITaxiReservation>;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateTranzerReservation */
export interface ICreateTranzerReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
  /** List of travelers for this booking. Can either be a contactId which should have all the required traveler information, or a list of specific data. */
  travelers: Array<ITravelerMutation>;
}

/** Autogenerated return type of CreateTranzerReservation. */
export interface ICreateTranzerReservationPayload {
  __typename?: 'CreateTranzerReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  tranzerReservation?: Maybe<ITranzerReservation>;
}

/** Autogenerated input type of CreateUserProfile */
export interface ICreateUserProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth of the user */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  /** Preferred purpose of supplier transactions */
  defaultMobilityCardTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Preferred purpose of supplier transactions on weekends */
  defaultMobilityCardWeekendTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Email address of the user. Required for initial authentication together with inviteCode. If using the invite code, email is required */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Employee number of the user, that is mostly used internally. */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Home address of the user */
  homeAddress?: InputMaybe<IUserProfileAddress>;
  /** Infix of the user */
  infix?: InputMaybe<Scalars['String']['input']>;
  /** Initials of the user */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Invite code of the user. Required for authentication for Freddi. Required for initial authentication together with email. */
  inviteCode?: InputMaybe<Scalars['String']['input']>;
  /** Invite token of the user. Required for authentication generated by Devise. Required for initial authentication no email required when using a token. */
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** New password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Confirmation of new password of the user. */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the user. Accepted formats with fake phone numbers: +31612345678 (NL), +3214812345 (BE), +4912345678 (DE), 0612345678, 06-12345678, 0401234567, 040-1234567, 0455123456, 0455-123456, all other European numbers. See employee.rb for specific regex. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** User-preferred purpose of GPS registered rides */
  preferredTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Agreed Terms and conditions */
  termsAndConditionsDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Work address of the user */
  workAddress?: InputMaybe<IUserProfileAddress>;
}

/** Autogenerated return type of CreateUserProfile. */
export interface ICreateUserProfilePayload {
  __typename?: 'CreateUserProfilePayload';
  /** Api-Token to authenticate the employee for successive requests */
  apiToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** JWT to authenticate the employee for successive requests */
  jwt?: Maybe<Scalars['String']['output']>;
  /** Updated employee */
  me?: Maybe<IEmployee>;
  /** Returns whether the mutation was successful */
  result: IResult;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of CreateVehicle */
export interface ICreateVehicleInput {
  brand?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fuelType?: InputMaybe<IVehicleFuelTypeEnum>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  modalityObjectType?: InputMaybe<IModalityKindEnum>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED: Use modality_object_type field */
  objectType?: InputMaybe<IModalityPrivateVehicleEnum>;
  startsOn: Scalars['Date']['input'];
}

/** Autogenerated return type of CreateVehicle. */
export interface ICreateVehiclePayload {
  __typename?: 'CreateVehiclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface ICreateWhatMutation {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  /** Reimbursable category method. If given, properties for the other reimbursable category are nullified in the backend since theyre mutually exclusive. */
  reimbursableCategoryMethodValue?: InputMaybe<IReimbursableCategoryMethodEnum>;
  sharedRidePassengers?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}

/** Autogenerated input type of CreateWorkFromHome */
export interface ICreateWorkFromHomeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date to create the work from hom for. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Dates to create the work from home for. */
  dates?: InputMaybe<Array<Scalars['Date']['input']>>;
}

/** Autogenerated return type of CreateWorkFromHome. */
export interface ICreateWorkFromHomePayload {
  __typename?: 'CreateWorkFromHomePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of all confirmed work from home events. */
  confirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  dayWithMobilityEvents: IDayWithMobilityEvents;
  /** returns mobility events for all dates if given mutiple dates */
  daysWithMobilityEvents: Array<IDayWithMobilityEvents>;
  mobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  /** List of all unconfirmed work from home events. */
  unconfirmedMobilityEvents?: Maybe<Array<IMobilityEvent>>;
  validationErrors: Array<IValidationError>;
}

export enum ICrowdForecastEnum {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  UNKNOWN = 'UNKNOWN'
}

export interface ICustomerLabelTheme {
  __typename?: 'CustomerLabelTheme';
  /** The favicon for this organization */
  favicon?: Maybe<IImage>;
  /** The icon for this organization */
  icon?: Maybe<IImage>;
  /** The light theme logo for this organization */
  logo?: Maybe<IImage>;
  /** The dark theme logo for this organization */
  logoDark?: Maybe<IImage>;
  /** The login poster image that should be used for this organization */
  poster?: Maybe<IImage>;
  /** The primary color of the organization */
  primaryColor?: Maybe<IColor>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<IColor>;
  /** The sidebar color of the organization */
  sidebarColor?: Maybe<IColor>;
  /** The sidebar text color of the organization */
  sidebarTextColor?: Maybe<IColor>;
}


export interface ICustomerLabelThemeFaviconArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemeIconArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemeLogoArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemeLogoDarkArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface ICustomerLabelThemePosterArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ICustomerService {
  __typename?: 'CustomerService';
  /** Customer service email address */
  emailAddress: Scalars['String']['output'];
  /** URL of dedicated customer service website */
  helpUrl?: Maybe<Scalars['String']['output']>;
  /** The alias for the url service desk */
  helpUrlName: Scalars['String']['output'];
  /** URL for a privacy policy */
  privacyPolicyUrl: Scalars['String']['output'];
  /** Customer service telephone number */
  telephone: Scalars['String']['output'];
}

/** Range of dates */
export interface IDateRangeInput {
  /** Minimum value of the range */
  from: Scalars['Date']['input'];
  /** Maximum value of the range */
  to: Scalars['Date']['input'];
}

export enum IDateRangeStringEnum {
  LAST_MONTH = 'last_month',
  LAST_WEEK = 'last_week',
  LAST_YEAR = 'last_year',
  MONTH = 'month',
  TODAY = 'today',
  WEEK = 'week',
  YEAR = 'year',
  YESTERDAY = 'yesterday'
}

/** Range of dates */
export interface IDateRangeStringInput {
  /** Minimum value of the range */
  dateRange: IDateRangeStringEnum;
}

export type IDay = IDayWithMobilityEvents | IDayWithoutEmployment;

export interface IDayWithMobilityEvents {
  __typename?: 'DayWithMobilityEvents';
  commuteCompensationAddable?: Maybe<Scalars['Boolean']['output']>;
  commuteRideAddable: Scalars['Boolean']['output'];
  commuteRidesAddable: Scalars['Boolean']['output'];
  date: Scalars['Date']['output'];
  eventsAddable: Scalars['Boolean']['output'];
  /** Mobility events for this day are confirmable */
  eventsConfirmable: Scalars['Boolean']['output'];
  /** @deprecated Submit used to be a synonym for confirm. We now only use confirm, so use confirmable instead */
  eventsSubmittable: Scalars['Boolean']['output'];
  mobilityEvents: Array<IMobilityEvent>;
  permissions: IPermissions;
  status?: Maybe<Scalars['String']['output']>;
  workFromHomeCompensationAddable: Scalars['Boolean']['output'];
}

export interface IDayWithoutEmployment {
  __typename?: 'DayWithoutEmployment';
  date: Scalars['Date']['output'];
}

export interface IDescription {
  __typename?: 'Description';
  /** Translated description of this service */
  humanized: Scalars['String']['output'];
}

/** Autogenerated input type of DestroyFavorite */
export interface IDestroyFavoriteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the favorite you want to destroy */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of DestroyFavorite. */
export interface IDestroyFavoritePayload {
  __typename?: 'DestroyFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Whether the destruction was a success or not */
  result: IResult;
}

/** Autogenerated input type of DestroyMobilityEvent */
export interface IDestroyMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of DestroyMobilityEvent. */
export interface IDestroyMobilityEventPayload {
  __typename?: 'DestroyMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  day: IDay;
  result: IResult;
}

/** Autogenerated input type of DestroyMobilityEvents */
export interface IDestroyMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of DestroyMobilityEvents. */
export interface IDestroyMobilityEventsPayload {
  __typename?: 'DestroyMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDayWithMobilityEvents>;
  destroyedMobilityEventIds: Array<Scalars['ID']['output']>;
  result: IResult;
  undestroyedMobilityEvents: Array<IMobilityEvent>;
}

export enum IDestroyProfileAddressEnum {
  HOME = 'HOME',
  WORK = 'WORK'
}

/** Autogenerated input type of DestroyProfileAddress */
export interface IDestroyProfileAddressInput {
  /** Which address should be removed from the profile */
  addressType: IDestroyProfileAddressEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of DestroyProfileAddress. */
export interface IDestroyProfileAddressPayload {
  __typename?: 'DestroyProfileAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Returns whether the mutation was successful */
  result: IResult;
}

export interface IDirectDebitInvoice {
  __typename?: 'DirectDebitInvoice';
  /** Amount invoiced */
  amount?: Maybe<IMoney>;
  /** External reference of this invoice */
  externalReference: Scalars['String']['output'];
  /** The database id of the invoice */
  id: Scalars['ID']['output'];
  /** Date of the invoice */
  invoiceDate: Scalars['Date']['output'];
  /** URL of the PDF of this invoice */
  pdfUrl: Scalars['String']['output'];
  /** The settlement that belongs to this invoice */
  settlement?: Maybe<ISettlement>;
  /** Status of this invoice */
  status?: Maybe<IInvoiceStatus>;
}

export interface IDirectDebitMandate {
  __typename?: 'DirectDebitMandate';
  /** External URL for accepting mandate */
  acceptanceUrl?: Maybe<Scalars['String']['output']>;
  /** DateTime at which this mandate was accepted */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  actions: IMandateActions;
  /** Ecurring customer token */
  ecurringCustomerToken?: Maybe<Scalars['String']['output']>;
  /** Ecurring subscription token */
  ecurringSubscriptionToken?: Maybe<Scalars['String']['output']>;
  /** Mandate ID */
  id: Scalars['ID']['output'];
  /** Whether this mandate was accepted */
  isAccepted: Scalars['Boolean']['output'];
  /** Whether this mandate was customer registered */
  isCustomerRegistered: Scalars['Boolean']['output'];
  /** Whether this mandate was revoked */
  isRevoked: Scalars['Boolean']['output'];
  /** Whether this is a valid mandate */
  isValid: Scalars['Boolean']['output'];
  /** DateTime at which this mandate was revoked */
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Status of mandate */
  status?: Maybe<IMandateStatus>;
  /** Mandate UUID */
  uuid?: Maybe<Scalars['String']['output']>;
}

export interface IDirectDebitMandates {
  __typename?: 'DirectDebitMandates';
  create?: Maybe<ICreateDirectDebitMandatePayload>;
  revoke?: Maybe<IRevokeDirectDebitMandatePayload>;
}


export interface IDirectDebitMandatesCreateArgs {
  input: ICreateDirectDebitMandateInput;
}


export interface IDirectDebitMandatesRevokeArgs {
  input: IRevokeDirectDebitMandateInput;
}

export enum IDirectionEnum {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  ZERO = 'ZERO'
}

export interface IDisplay {
  __typename?: 'Display';
  humanized: Scalars['String']['output'];
  value: IDisplayTypeEnum;
}

export enum IDisplayTypeEnum {
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
  VIRGIN = 'VIRGIN'
}

export interface IDistance extends IReimbursementRule {
  __typename?: 'Distance';
  budgetGroupId: Scalars['ID']['output'];
  category: ICategory;
  configuration: IConfiguration;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  main: IMainCategory;
  modality?: Maybe<IModality>;
  reimbursableCategory: IReimbursableCategory;
  sub?: Maybe<ISubCategory>;
  travelPurpose: Scalars['String']['output'];
}

export interface IDistancePerModality {
  __typename?: 'DistancePerModality';
  distance: Scalars['Int']['output'];
  modality?: Maybe<IModality>;
}

export interface IDistanceSummary {
  __typename?: 'DistanceSummary';
  mobilityEventsPerModality?: Maybe<Array<IDistancePerModality>>;
  totalDistance: Scalars['Int']['output'];
}

export interface IDiverted {
  __typename?: 'Diverted';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface IDonkeyLeg {
  __typename?: 'DonkeyLeg';
  bookingId?: Maybe<Scalars['String']['output']>;
  /** A bike pickup/drop-off location. */
  from: IAssetStation;
  /** The identifier of the leg */
  id: Scalars['ID']['output'];
  mode?: Maybe<IDonkeyRepublicModality>;
  /** A list of prices per segment. Segments are following up each other, depending on the length of a ride. The longer the ride, the more segments will be included in the final price. */
  price: Array<ISegmentPrice>;
  quoteId?: Maybe<Scalars['String']['output']>;
  /** The name of the external service that was used to obtain information. */
  service: Scalars['String']['output'];
}

export interface IDonkeyQuote {
  __typename?: 'DonkeyQuote';
  id: Scalars['ID']['output'];
  legs: Array<IDonkeyLeg>;
  validUntil: Scalars['DateTime']['output'];
}

export interface IDonkeyRepublic extends IAvailableFeatureInterface {
  __typename?: 'DonkeyRepublic';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IDonkeyRepublicBooking {
  __typename?: 'DonkeyRepublicBooking';
  cancel?: Maybe<ICancelDonkeyRepublicReservationPayload>;
  create?: Maybe<ICreateDonkeyRepublicReservationPayload>;
  finish?: Maybe<IFinishDonkeyRepublicReservationPayload>;
  lock?: Maybe<ILockDonkeyRepublicAssetPayload>;
  start?: Maybe<IStartDonkeyRepublicReservationPayload>;
  unlock?: Maybe<IUnlockDonkeyRepublicAssetPayload>;
}


export interface IDonkeyRepublicBookingCancelArgs {
  input: ICancelDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingCreateArgs {
  input: ICreateDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingFinishArgs {
  input: IFinishDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingLockArgs {
  input: ILockDonkeyRepublicAssetInput;
}


export interface IDonkeyRepublicBookingStartArgs {
  input: IStartDonkeyRepublicReservationInput;
}


export interface IDonkeyRepublicBookingUnlockArgs {
  input: IUnlockDonkeyRepublicAssetInput;
}

export interface IDonkeyRepublicModality {
  __typename?: 'DonkeyRepublicModality';
  humanized: Scalars['String']['output'];
  value: IDonkeyRepublicModalityEnum;
}

export enum IDonkeyRepublicModalityEnum {
  BIKE = 'bike',
  EBIKE = 'ebike'
}

export interface IDonkeyRepublicReservation {
  __typename?: 'DonkeyRepublicReservation';
  /** Data to access the Donkey Republic bike */
  accessData?: Maybe<IBikeAccess>;
  /** ID of the asset */
  assetId?: Maybe<Scalars['String']['output']>;
  /** User readable name for the asset */
  assetName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** An external id for the Mobidesk platform. */
  externalId: Scalars['ID']['output'];
  /** Full price ex. vat in cents */
  fullPriceExVatInCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  legs: Array<IDonkeyLeg>;
  status: IDonkeyRepublicStatus;
  /** The reason why the booking was unexpectedly put in this status. */
  statusReason?: Maybe<Scalars['String']['output']>;
  /** The reference in Donkey Republic */
  supplierReference: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
}

export interface IDonkeyRepublicResult extends IResultInterface {
  __typename?: 'DonkeyRepublicResult';
  /** Error field containing the error(s) from the DonkeyRepublic API */
  donkeyRepublicError?: Maybe<IError>;
  /** Error message */
  error?: Maybe<Scalars['String']['output']>;
  /** Success message */
  success?: Maybe<Scalars['String']['output']>;
}

export interface IDonkeyRepublicStatus {
  __typename?: 'DonkeyRepublicStatus';
  humanized: Scalars['String']['output'];
  value: IDonkeyRepublicStatusEnum;
}

export enum IDonkeyRepublicStatusEnum {
  ACTIVE = 'active',
  ACTIVE_LOCKED = 'active_locked',
  ACTIVE_PENDING_LOCK = 'active_pending_lock',
  ACTIVE_PENDING_UNLOCK = 'active_pending_unlock',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  CONFIRMED_PENDING_UNLOCK = 'confirmed_pending_unlock',
  FINISHED = 'finished',
  NEW = 'new'
}

export interface IDriver {
  __typename?: 'Driver';
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface IDriversLicenseCountry {
  __typename?: 'DriversLicenseCountry';
  /** Humanized country name that can be used for displaying */
  humanized: Scalars['String']['output'];
  /** Internal country name */
  value: IDriversLicenseCountryEnum;
}

export enum IDriversLicenseCountryEnum {
  AT = 'AT',
  BE = 'BE',
  BG = 'BG',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  EL = 'EL',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IS = 'IS',
  IT = 'IT',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK'
}

export interface IDuration extends IWhen {
  __typename?: 'Duration';
  editable: Scalars['Boolean']['output'];
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface IEkey {
  __typename?: 'Ekey';
  key?: Maybe<Scalars['String']['output']>;
  passkey?: Maybe<Scalars['String']['output']>;
}

export interface IEkeyLock {
  __typename?: 'EkeyLock';
  ekey?: Maybe<IEkey>;
  lock?: Maybe<ILock>;
}

export interface IEmployee {
  __typename?: 'Employee';
  /** @deprecated Use favorites instead */
  addresses?: Maybe<Array<IAddress>>;
  /** Total number of mobility events that needs to be approved by manager */
  approvableMobilityEventsAggregate: Scalars['Int']['output'];
  availableFeature?: Maybe<IAvailableFeatureInterface>;
  availableFeatures?: Maybe<Array<IAvailableFeatureInterface>>;
  /** Return a single service that belong to this employee */
  availableService?: Maybe<IAvailableServiceInterface>;
  /** List of all services on products that belong to this employee */
  availableServices?: Maybe<Array<IAvailableServiceInterface>>;
  /** @deprecated Use availableSharedVehicles */
  availableSharedCars?: Maybe<Array<IAvailableSharedCar>>;
  /** Request a list of available shared vehicles. The location where the vehicles must be nearby can be configured via the from_* arguments, but falls back to the most recent Favorite location with type "shared_vehicles_location". For legacy purposes there is a hard-coded fallback after this as well. */
  availableSharedVehicles?: Maybe<Array<IAvailableSharedVehicle>>;
  /** List of all available subscriptions within the budget group */
  availableSubscriptions?: Maybe<Array<IAvailableSubscription>>;
  /** Returns if the current session is an admin logged in as an employee */
  becameEmployee: Scalars['Boolean']['output'];
  /** The branding of the organization that htis employee is currently active at */
  branding?: Maybe<IBranding>;
  /** Returns the total CO2 emission for a date range, followed by a breakdown of CO2 emission per modality */
  co2EmissionSummary?: Maybe<ICo2EmissionSummary>;
  /** A list of all mobility_events that are copyable for a specific day */
  copyableMobilityEvents: Array<IMobilityEvent>;
  day?: Maybe<IDay>;
  days: Array<IDay>;
  defaultTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** Direct debit invoices for this employee */
  directDebitInvoices: Array<IDirectDebitInvoice>;
  /** All given direct debit mandates for this employee */
  directDebitMandates: Array<IDirectDebitMandate>;
  /** Returns the total recorded distance in meters for a date range, followed by a breakdown of distance in meters per modality */
  distanceSummary?: Maybe<IDistanceSummary>;
  /** Asset availability for Donkey Republic bikes per city */
  donkeyAssetsAvailability?: Maybe<Array<IAssetLocation>>;
  donkeyQuotes?: Maybe<Array<IDonkeyQuote>>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicReservations?: Maybe<Array<IDonkeyRepublicReservation>>;
  /** Whether or not this user needs to give a mandate for split billing */
  eMandateRequired: Scalars['Boolean']['output'];
  /** @deprecated Deprecated due to the obsolete addition of employee as prefix. Use `financialConsequences` instead. */
  employeeFinancialConsequences: IEmployeeFinancialConsequences;
  /** Returns a list of employments for this employee */
  employments?: Maybe<Array<IEmployment>>;
  /** Lists all export files for various datasets for the current employee */
  exportFiles?: Maybe<IExportFiles>;
  /**
   * Lists all export URLs for various datasets for the current employee
   * @deprecated Use exportFiles endpoint to retrieve the exports
   */
  exportUrls: IExportUrls;
  /** endpoint to retrieve the favorite locations of the current employee */
  favorites?: Maybe<Array<IFavorite>>;
  /** @deprecated use availableFeatures instead */
  featureOptions: Array<IFeatureOption>;
  filterOptions?: Maybe<IFilterOptions>;
  financialConsequences: IEmployeeFinancialConsequences;
  /** Fixed commute distance used by default for commute rides if enabled in the budget group. */
  fixedCommuteDistance?: Maybe<Scalars['Float']['output']>;
  greenwheelsFleets?: Maybe<Array<IGreenwheelsFleet>>;
  hasAgreedToGeneralTerms: Scalars['Boolean']['output'];
  hasAgreedToPrivacyStatement: Scalars['Boolean']['output'];
  hasNotAgreedToUpdatedGeneralTerms: Scalars['Boolean']['output'];
  hasNotAgreedToUpdatedPrivacyStatement: Scalars['Boolean']['output'];
  hasSso: Scalars['Boolean']['output'];
  holidayOnDate?: Maybe<Scalars['String']['output']>;
  /** The database id of this employee */
  id: Scalars['ID']['output'];
  /**
   * Invoices for this employee
   * @deprecated Use directDebitInvoices instead
   */
  invoices: Array<IDirectDebitInvoice>;
  journey?: Maybe<IJourney>;
  journeys?: Maybe<Array<IJourney>>;
  /** @deprecated The url is requestable via available features */
  leaseBikeUrl?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<ILicensePlate>;
  /** license plates of the vehicles of this employee */
  licensePlates?: Maybe<Array<ILicensePlate>>;
  /** Preview of multiple mobility events merged into one */
  mergedMobilityEventsPreview: IMergedMobilityEventsPreview;
  messages: Array<IMessage>;
  mileage?: Maybe<IMileage>;
  /** Years of when the Mileages were recorded for the vehicles of this employee */
  mileagesYears?: Maybe<Array<Scalars['String']['output']>>;
  /** The token to use when connecting with Mobidot */
  mobidotUserToken?: Maybe<Scalars['String']['output']>;
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  mobilityCardOrders: Array<IMobilityCardOrder>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  mobilityEventFormValidations?: Maybe<IMobilityEventFormValidations>;
  /** Totals for mobility events within the selection. Amounts are based on the budget transaction. */
  mobilityEventTotals: IMobilityEventTotals;
  mobilityEvents: Array<IMobilityEvent>;
  mobilityEventsAggregate?: Maybe<IMobilityEventAggregate>;
  mobilityEventsByDate: Array<IMobilityEventByDate>;
  multiModalAvailability?: Maybe<Array<IMultiModalAvailability>>;
  organization?: Maybe<IOrganization>;
  organizations?: Maybe<Array<IOrganization>>;
  /** Parking orders for this employee */
  parkingOrders: Array<IParkingOrder>;
  permissions: IPermissions;
  /** Return a single personal means of registration */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** All personal means of registration for this user */
  personalMeansOfRegistrations?: Maybe<Array<IPersonalMeansOfRegistration>>;
  /** Return a single personal vehicle */
  personalVehicle?: Maybe<IMeansOfTransport>;
  /** List of all vehicles that are available for this employee */
  personalVehicles: Array<IMeansOfTransport>;
  /**
   * The phone number of this employee
   * @deprecated Use `profile.phone_number` instead. We did this to group profile-specific data about this employee.
   */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  possibleReplacementManagers?: Maybe<Array<IPossibleReplacementManager>>;
  /** The profile details of this employee, mostly containing his personal details */
  profile: IProfile;
  /** Returns a list of countries Iso Codes */
  profileCountries: Array<ICountryIsoCode>;
  /** QR code that can be used to retrieve an API token for this user */
  qrCodeForApiToken: Scalars['String']['output'];
  refundableIncompleteRides?: Maybe<Array<IMobilityEvent>>;
  replacementAssignments?: Maybe<Array<IReplacementManager>>;
  replacementManager?: Maybe<IReplacementManager>;
  replacementManagers?: Maybe<Array<IReplacementManager>>;
  /** @deprecated The requestable features are now accessible through the available_features endpoint. */
  requestableFeatureOptions?: Maybe<Array<IRequestableFeatureOption>>;
  requiredFields: IRequiredFields;
  /** Lists all reservations across different reservation types for the current employee */
  reservations?: Maybe<Array<IReservation>>;
  routeMetadata: IRouteMetadata;
  /** @deprecated Use sharedVehicleReservation */
  sharedCarReservation?: Maybe<ISharedCarReservation>;
  /** @deprecated Use sharedVehicleReservations */
  sharedCarReservations?: Maybe<Array<ISharedCarReservation>>;
  sharedVehicleReservation?: Maybe<ISharedVehicleReservation>;
  sharedVehicleReservations?: Maybe<Array<ISharedVehicleReservation>>;
  splitBillingContract?: Maybe<ISplitBillingContract>;
  splitBillingContracts?: Maybe<Array<ISplitBillingContract>>;
  /** Split billing invoices for this employee */
  splitBillingInvoices: Array<ISplitBillingInvoice>;
  statistics: IStatistics;
  subordinate?: Maybe<ISubordinate>;
  subordinates?: Maybe<Array<ISubordinate>>;
  tasks: Array<ITask>;
  taxiReservation?: Maybe<ITaxiReservation>;
  taxiReservations: Array<ITaxiReservation>;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  /** The token used for inviting users or when resetting their password */
  token: Scalars['String']['output'];
  tranzerQuotes?: Maybe<Array<IQuote>>;
  tranzerReservation?: Maybe<ITranzerReservation>;
  tranzerReservations?: Maybe<Array<ITranzerReservation>>;
  /** vehicle options for creating mileages */
  vehicleOptions?: Maybe<Array<IVehicleOptions>>;
  whatOptions: Array<IWhatOption>;
  /** all active yellowbrick services of this employee */
  yellowbrickServices?: Maybe<Array<IYellowbrickService>>;
}


export interface IEmployeeAvailableFeatureArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeAvailableFeaturesArgs {
  filterBy?: InputMaybe<Array<IFeatureOptionEnum>>;
  status?: InputMaybe<IMobilityCardOrderStatusEnum>;
}


export interface IEmployeeAvailableServiceArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeAvailableServicesArgs {
  filterBy?: InputMaybe<IPersonalMeansOfRegistrationServiceStatusFilterEnum>;
}


export interface IEmployeeAvailableSharedCarsArgs {
  endsAt: Scalars['DateTime']['input'];
  startsAt: Scalars['DateTime']['input'];
}


export interface IEmployeeAvailableSharedVehiclesArgs {
  endsAt: Scalars['DateTime']['input'];
  fromHumanized?: InputMaybe<Scalars['String']['input']>;
  fromLatitude?: InputMaybe<Scalars['Coordinate']['input']>;
  fromLongitude?: InputMaybe<Scalars['Coordinate']['input']>;
  startsAt: Scalars['DateTime']['input'];
}


export interface IEmployeeCo2EmissionSummaryArgs {
  dateRange?: InputMaybe<IDateRangeInput>;
  dateRangeString?: InputMaybe<IDateRangeStringInput>;
}


export interface IEmployeeCopyableMobilityEventsArgs {
  forDate: Scalars['Date']['input'];
}


export interface IEmployeeDayArgs {
  date: Scalars['Date']['input'];
}


export interface IEmployeeDaysArgs {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
}


export interface IEmployeeDefaultTravelPurposeArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeDirectDebitMandatesArgs {
  filterByStatus?: InputMaybe<IMandateStatusFilterEnum>;
}


export interface IEmployeeDistanceSummaryArgs {
  dateRange?: InputMaybe<IDateRangeInput>;
  dateRangeString?: InputMaybe<IDateRangeStringInput>;
}


export interface IEmployeeDonkeyQuotesArgs {
  cityIdentifier: Scalars['String']['input'];
  stationId: Scalars['ID']['input'];
}


export interface IEmployeeDonkeyRepublicReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeEmploymentsArgs {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  withChildren?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeExportFilesArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
  year?: InputMaybe<Scalars['Int']['input']>;
}


export interface IEmployeeFavoritesArgs {
  filterBy?: InputMaybe<IFavoriteFilterEnum>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
  withFallbackLocation?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeFeatureOptionsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeHolidayOnDateArgs {
  date: Scalars['Date']['input'];
}


export interface IEmployeeJourneyArgs {
  journeyId: Scalars['String']['input'];
}


export interface IEmployeeJourneysArgs {
  arrivalTime?: InputMaybe<Scalars['DateTime']['input']>;
  departureTime?: InputMaybe<Scalars['DateTime']['input']>;
  destination: ILocationInput;
  journeyModes?: InputMaybe<Array<IPreferredJourneyModeEnum>>;
  journeyOperators?: InputMaybe<Array<Scalars['String']['input']>>;
  order?: InputMaybe<IJourneySorter>;
  origin: ILocationInput;
}


export interface IEmployeeLicensePlateArgs {
  licensePlate: Scalars['String']['input'];
}


export interface IEmployeeLicensePlatesArgs {
  order?: InputMaybe<IVehicleStatusEnum>;
}


export interface IEmployeeMergedMobilityEventsPreviewArgs {
  mobilityEventIds: Array<Scalars['ID']['input']>;
}


export interface IEmployeeMessagesArgs {
  withKey?: InputMaybe<IMessageKeyEnum>;
}


export interface IEmployeeMileageArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeMobilityCardOrderArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeMobilityEventArgs {
  id?: InputMaybe<Scalars['Int']['input']>;
  mobilityEventId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IEmployeeMobilityEventFormValidationsArgs {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  fromLocationId?: InputMaybe<Scalars['ID']['input']>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  toLocationId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IEmployeeMobilityEventTotalsArgs {
  happenedBetween?: InputMaybe<IDateRangeInput>;
}


export interface IEmployeeMobilityEventsArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  filterStatus?: InputMaybe<Scalars['String']['input']>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
}


export interface IEmployeeMobilityEventsAggregateArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  includeAll?: InputMaybe<Scalars['Boolean']['input']>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
}


export interface IEmployeeMobilityEventsByDateArgs {
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum>>;
  filterStatus?: InputMaybe<Scalars['String']['input']>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mainCategories?: InputMaybe<Array<IMainCategoryEnum>>;
  modalities?: InputMaybe<Array<IModalityKindEnum>>;
  modalityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  modalityOwnerships?: InputMaybe<Array<IOwnershipEnum>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum>>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum>>;
}


export interface IEmployeeMultiModalAvailabilityArgs {
  lat: Scalars['Coordinate']['input'];
  long: Scalars['Coordinate']['input'];
  modalities?: InputMaybe<Array<IJourneyModeEnum>>;
  operators?: InputMaybe<Array<Scalars['String']['input']>>;
  radius: Scalars['Int']['input'];
}


export interface IEmployeeOrganizationArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeOrganizationsArgs {
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
}


export interface IEmployeePermissionsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeePersonalMeansOfRegistrationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeePersonalMeansOfRegistrationsArgs {
  filterByKindMain?: InputMaybe<IPersonalMeansOfRegistrationKindMainEnum>;
  filterByStatus?: InputMaybe<IPersonalMeansOfRegistrationStatusFilterEnum>;
}


export interface IEmployeePersonalVehicleArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeePersonalVehiclesArgs {
  filterBy?: InputMaybe<IVehicleStatusEnum>;
  order?: InputMaybe<IOrderEnum>;
  ownership?: InputMaybe<IOwnershipEnum>;
}


export interface IEmployeeReplacementManagerArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeRequiredFieldsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IEmployeeReservationsArgs {
  filterBy?: InputMaybe<IReservationStatusEnum>;
  filterByState?: InputMaybe<IReservationStateEnum>;
  kinds?: InputMaybe<Array<IReservationKindEnum>>;
  order?: InputMaybe<IOrderEnum>;
}


export interface IEmployeeRouteMetadataArgs {
  from?: InputMaybe<ICoordinatesMutation>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  on?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<ICoordinatesMutation>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}


export interface IEmployeeSharedCarReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeSharedCarReservationsArgs {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeSharedVehicleReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeSharedVehicleReservationsArgs {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
}


export interface IEmployeeSplitBillingContractArgs {
  contractId: Scalars['String']['input'];
}


export interface IEmployeeSplitBillingContractsArgs {
  filterByStatus?: InputMaybe<IContractStatusEnum>;
}


export interface IEmployeeSubordinateArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeTaxiReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeTermsAndConditionsArgs {
  kind?: InputMaybe<ITermsAndConditionsDocumentKindEnum>;
  kinds?: InputMaybe<Array<ITermsAndConditionsDocumentKindEnum>>;
}


export interface IEmployeeTranzerQuotesArgs {
  classNumber?: InputMaybe<ITrainClassEnum>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  fromLocationName: Scalars['String']['input'];
  passengerCount?: InputMaybe<Scalars['Int']['input']>;
  preferredModalities?: InputMaybe<Array<IPreferredModalityEnum>>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  toLocationName: Scalars['String']['input'];
}


export interface IEmployeeTranzerReservationArgs {
  id: Scalars['ID']['input'];
}


export interface IEmployeeWhatOptionsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}

export interface IEmployeeAddress {
  __typename?: 'EmployeeAddress';
  city?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use employeeCountryIsoCode please */
  countryIsoCode?: Maybe<ICountryIsoCodeEnum>;
  employeeCountryIsoCode?: Maybe<ICountryIsoCode>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  streetNumberSuffix?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
}

export interface IEmployeeAddressMutation {
  city: Scalars['String']['input'];
  countryIsoCode: ICountryIsoCodeEnum;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  streetNumberSuffix?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
}

export interface IEmployeeDetails {
  __typename?: 'EmployeeDetails';
  email?: Maybe<Scalars['String']['output']>;
  employeeUuid?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  infix?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  leaseBicycleCategory?: Maybe<Scalars['String']['output']>;
  organizationUuid?: Maybe<Scalars['ID']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export interface IEmployeeDetailsResponse {
  __typename?: 'EmployeeDetailsResponse';
  details?: Maybe<IEmployeeDetails>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
}

export interface IEmployeeFinancialConsequences {
  __typename?: 'EmployeeFinancialConsequences';
  budgetTransactionRemainder: IBudget;
  /** cumulative gross amount of all settlements */
  cumulativeGrossAmount?: Maybe<Scalars['Float']['output']>;
  /** cumulative net amount of all settlements */
  cumulativeNetAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use last_intermediate_settlement.gross_amount instead */
  grossAmount?: Maybe<IBudget>;
  /** All financial consequences coming from the last intermediate settlement. If no intermediate settlement is present yet, it returns the data of the tentative settlement instead. */
  lastIntermediateSettlement?: Maybe<ISettlement>;
  /** @deprecated Use last_intermediate_settlement.net_amount instead */
  netAmount?: Maybe<IBudget>;
  /** get a specific settlement */
  settlement?: Maybe<ISettlement>;
  settlements?: Maybe<Array<ISettlement>>;
  /** @deprecated Use last_intermediate_settlement.gross_amount instead */
  tentativeGrossAmount: IBudget;
  /** @deprecated Use tentative_settlement.net_amount instead */
  tentativeNetAmount: IBudget;
  /** All financial consequences coming from the tentative settlement. */
  tentativeSettlement: ISettlement;
}


export interface IEmployeeFinancialConsequencesSettlementArgs {
  id: Scalars['ID']['input'];
}

export interface IEmployment {
  __typename?: 'Employment';
  /** The budget group name that this employment belongs to */
  budgetGroupName: Scalars['String']['output'];
  /** The commute monthly km budget */
  commuteMonthlyKmBudget?: Maybe<Scalars['Int']['output']>;
  /** The commute monthly momney budget */
  commuteMonthlyMoneyBudget?: Maybe<Scalars['Int']['output']>;
  /** The cost center that this employment belongs to */
  costCenter?: Maybe<Scalars['String']['output']>;
  /** The department that this employment belongs to */
  department?: Maybe<Scalars['String']['output']>;
  /** The date that this employment ends on */
  endsOn?: Maybe<Scalars['DateTime']['output']>;
  /** The database id of this employment */
  id: Scalars['ID']['output'];
  /** Whether this employment is the main employment */
  main: Scalars['Boolean']['output'];
  /** The date that this employment starts on */
  startsOn: Scalars['DateTime']['output'];
}

export interface IEnecoDongle extends IAvailableFeatureInterface {
  __typename?: 'EnecoDongle';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IError {
  __typename?: 'Error';
  /** Error details */
  details?: Maybe<Array<IError>>;
  /** Error message */
  message?: Maybe<Scalars['String']['output']>;
  /** Error code */
  messageCode?: Maybe<Scalars['String']['output']>;
}

export interface IExplanation {
  __typename?: 'Explanation';
  /** Whether to still show the explanation box even if it is not required */
  allowed: Scalars['Boolean']['output'];
  humanized?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  required: IExplanationRequired;
}

export interface IExplanationRequired {
  __typename?: 'ExplanationRequired';
  business: Scalars['Boolean']['output'];
  businessByAmount: Scalars['Boolean']['output'];
  businessByDistance: Scalars['Boolean']['output'];
  commute: Scalars['Boolean']['output'];
  commuteByAmount: Scalars['Boolean']['output'];
  commuteByDistance: Scalars['Boolean']['output'];
  personal: Scalars['Boolean']['output'];
  personalByAmount: Scalars['Boolean']['output'];
  personalByDistance: Scalars['Boolean']['output'];
}

export interface IExportFiles {
  __typename?: 'ExportFiles';
  mobilityEventsExport?: Maybe<Scalars['String']['output']>;
  ridesExport?: Maybe<Scalars['String']['output']>;
  transactionsExport?: Maybe<Scalars['String']['output']>;
}

export interface IExportUrls {
  __typename?: 'ExportUrls';
  mobilityEventsExportUrl: Scalars['String']['output'];
}

export interface IExternalUrl {
  __typename?: 'ExternalUrl';
  /** Title of the URL */
  title: Scalars['String']['output'];
  /** Actual URL */
  url: Scalars['String']['output'];
}

export interface IExternalUrls {
  __typename?: 'ExternalUrls';
  /** Static URL to the mobility app */
  appStoreUrls: IAppUrls;
  /** Static URL to the English version of the current Privacy Statement */
  englishPrivacyStatementUrl: IExternalUrl;
  /** Static URL to the English version of the current Terms and Conditions */
  englishTermsAndConditionsUrl: IExternalUrl;
  /** URL to request a NS refund */
  nsRefundUrl: IExternalUrl;
  /** URL to the Yellowbrick Forgot Password page */
  yellowbrickForgotPasswordUrl: IExternalUrl;
}

export interface IFavorite {
  __typename?: 'Favorite';
  /** Actions that can be executed on this favorite */
  actions: IFavoriteActions;
  /** Contains the longitude and latitude of the location */
  coordinates: ICoordinates;
  geoLocation?: Maybe<IFavoriteLocation>;
  /** Whether this favorite uses a fallback location */
  hasFallbackLocation: Scalars['Boolean']['output'];
  /** The database id */
  id: Scalars['ID']['output'];
  /** A user friendly/generated label */
  label: Scalars['String']['output'];
  /** The location string as it was provided by google places */
  location: Scalars['String']['output'];
  /** An enriched location object with complete address address information retrieved from google places */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** If provided, this favorite has been supplied by the employee's organization */
  organization?: Maybe<IOrganization>;
  /** If null, this has been created by the employee or their organization, otherwise it is either their home or work location */
  type?: Maybe<IFavoriteTypeEnum>;
}

export interface IFavoriteActions {
  __typename?: 'FavoriteActions';
  deletable: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
}

export enum IFavoriteFilterEnum {
  ORGANIZATION = 'ORGANIZATION',
  PERSONAL = 'PERSONAL'
}

export interface IFavoriteLocation {
  __typename?: 'FavoriteLocation';
  city?: Maybe<Scalars['String']['output']>;
  coordinates: ICoordinates;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  initialSource: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  streetNumberSuffix?: Maybe<Scalars['String']['output']>;
  tomtomPlaceId?: Maybe<Scalars['String']['output']>;
}

export enum IFavoriteTypeEnum {
  HOME = 'HOME',
  SHARED_VEHICLES_LOCATION = 'SHARED_VEHICLES_LOCATION',
  WORK = 'WORK'
}

export interface IFavorites {
  __typename?: 'Favorites';
  create?: Maybe<ICreateFavoritePayload>;
  destroy?: Maybe<IDestroyFavoritePayload>;
  setManualCoordinates?: Maybe<ISetManualFavoriteCoordinatesPayload>;
  update?: Maybe<IUpdateFavoritePayload>;
}


export interface IFavoritesCreateArgs {
  input: ICreateFavoriteInput;
}


export interface IFavoritesDestroyArgs {
  input: IDestroyFavoriteInput;
}


export interface IFavoritesSetManualCoordinatesArgs {
  input: ISetManualFavoriteCoordinatesInput;
}


export interface IFavoritesUpdateArgs {
  input: IUpdateFavoriteInput;
}

export interface IFeatureOption {
  __typename?: 'FeatureOption';
  humanized: Scalars['String']['output'];
  value: IFeatureOptionEnum;
}

export enum IFeatureOptionEnum {
  AMBER = 'amber',
  DONKEY_REPUBLIC = 'donkey_republic',
  ENECO_DONGLE = 'eneco_dongle',
  GPS = 'gps',
  GREENWHEELS = 'greenwheels',
  LEASE_BIKE = 'lease_bike',
  RENTAL_CAR = 'rental_car',
  SHARED_VEHICLE = 'shared_vehicle',
  SHUTTEL_CHECK = 'shuttel_check',
  SHUTTEL_MY_WHEELS = 'shuttel_my_wheels',
  SHUTTEL_SIXT = 'shuttel_sixt',
  SHUTTEL_TAXI = 'shuttel_taxi',
  SHUTTEL_TRANZER_ETICKETS = 'shuttel_tranzer_etickets',
  SHUTTEL_VECORE = 'shuttel_vecore',
  TAXI = 'taxi',
  TRANZER_ETICKETS = 'tranzer_etickets',
  YELLOWBRICK_SP = 'yellowbrick_sp'
}

export interface IFeatureOrderStatus {
  __typename?: 'FeatureOrderStatus';
  /** Humanized and translated status */
  humanized: Scalars['String']['output'];
  /** Raw value of the status */
  value: IFeatureOrderStatusEnum;
}

export enum IFeatureOrderStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ORDERED = 'ORDERED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUEST_FAILED = 'REQUEST_FAILED'
}

export interface IFeatureUrls {
  __typename?: 'FeatureUrls';
  /** Url for Android App */
  android?: Maybe<Scalars['String']['output']>;
  /** Url for iOS App */
  ios?: Maybe<Scalars['String']['output']>;
  /** Url for web */
  web?: Maybe<Scalars['String']['output']>;
}

export interface IFilterOptionItem {
  __typename?: 'FilterOptionItem';
  /** Filter humanized value */
  humanized: Scalars['String']['output'];
  /** Filter value */
  value: Scalars['String']['output'];
}

export interface IFilterOptions {
  __typename?: 'FilterOptions';
  /** Donkey assets availability type filter options */
  donkeyAssetsAvailabilityType?: Maybe<Array<IFilterOptionItem>>;
  /** JourneyServiceProviders filter options */
  journeyServiceOperators?: Maybe<Array<IFilterOptionItem>>;
  /** JourneyModes filter options */
  journeysJourneyModes?: Maybe<Array<IFilterOptionItem>>;
  /** Main categories of MobilityEvents */
  mainCategories?: Maybe<Array<IFilterOptionItem>>;
  /** MobilityEvents Status filter options */
  mobilityEventsFilterByStatus?: Maybe<Array<IFilterOptionItem>>;
  /** MobilityEvents Modality filter options */
  mobilityEventsModality?: Maybe<Array<IFilterOptionItem>>;
  /** MobilityEvents TravelPurpose filter options */
  mobilityEventsTravelPurpose?: Maybe<Array<IFilterOptionItem>>;
  /** MultiModalAvailability modality filter options */
  multiModalAvailabilityModalities?: Maybe<Array<IFilterOptionItem>>;
  /** Reservation kinds */
  reservationsKinds?: Maybe<Array<IFilterOptionItem>>;
  /** Tranzer quotes preferred modalities filter options */
  tranzerQuotesPreferredModalities?: Maybe<Array<IFilterOptionItem>>;
}

export enum IFilterStatusEnum {
  ACCEPTED = 'accepted',
  CONFIRMABLE = 'confirmable',
  CONFIRMED = 'confirmed',
  DRAFT = 'draft',
  REJECTED = 'rejected',
  REQUIRES_ACTION = 'requires_action'
}

export interface IFinancial {
  __typename?: 'Financial';
  consequences: IFinancialConsequences;
  fact?: Maybe<IFinancialFact>;
}

export interface IFinancialComponent {
  __typename?: 'FinancialComponent';
  amount: IMoney;
  entries: Array<IJournalEntry>;
  label: Scalars['String']['output'];
  moneyTransfer: IMoneyTransfer;
}

export interface IFinancialConsequences {
  __typename?: 'FinancialConsequences';
  fiscal?: Maybe<IFinancialComponent>;
  gross?: Maybe<IFinancialComponent>;
  net?: Maybe<IFinancialComponent>;
}

export interface IFinancialFact {
  __typename?: 'FinancialFact';
  direction?: Maybe<IDirectionEnum>;
  exclVat?: Maybe<IMoney>;
  inclVat: IMoney;
  label: Scalars['String']['output'];
  receipt?: Maybe<IReceipt>;
  vatRate?: Maybe<IVatRate>;
}

export interface IFinancialFactMutation {
  inclVat?: InputMaybe<Scalars['BigDecimal']['input']>;
  receipt?: InputMaybe<IAttachment>;
}

export interface IFinancialMutation {
  fact: IFinancialFactMutation;
}

export interface IFinancialSummary {
  __typename?: 'FinancialSummary';
  fiscal?: Maybe<Scalars['Float']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  net?: Maybe<Scalars['Float']['output']>;
}

/** Autogenerated input type of FinishDonkeyRepublicReservation */
export interface IFinishDonkeyRepublicReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Coordinate']['input'];
  longitude: Scalars['Coordinate']['input'];
  userIsNearVehicle: Scalars['Boolean']['input'];
  vehicleIsLocked: Scalars['Boolean']['input'];
}

/** Autogenerated return type of FinishDonkeyRepublicReservation. */
export interface IFinishDonkeyRepublicReservationPayload {
  __typename?: 'FinishDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of FinishSharedCarReservation */
export interface IFinishSharedCarReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of FinishSharedCarReservation. */
export interface IFinishSharedCarReservationPayload {
  __typename?: 'FinishSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** @deprecated Use sharedVehicleReservation */
  sharedCarReservation: ISharedCarReservation;
}

/** Autogenerated input type of FinishSharedVehicleReservation */
export interface IFinishSharedVehicleReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of FinishSharedVehicleReservation. */
export interface IFinishSharedVehicleReservationPayload {
  __typename?: 'FinishSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation: ISharedVehicleReservation;
}

export interface IFirstClassReason {
  __typename?: 'FirstClassReason';
  humanized: Scalars['String']['output'];
  value: IFirstClassReasonEnum;
}

export enum IFirstClassReasonEnum {
  BUSINESS_ONLY = 'BUSINESS_ONLY',
  EMPLOYMENT_CONDITION = 'EMPLOYMENT_CONDITION',
  MEDICAL = 'MEDICAL',
  OWN_ACCOUNT = 'OWN_ACCOUNT'
}

export enum IFiscalityEnum {
  REIMBURSED = 'REIMBURSED',
  SUPPLIED = 'SUPPLIED'
}

export interface IFuel {
  __typename?: 'Fuel';
  humanized: Scalars['String']['output'];
  value: IVehicleFuelTypeEnum;
}

export interface IGeoLocation {
  __typename?: 'GeoLocation';
  coordinates: ICoordinates;
  humanized?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of GetAccessUrl */
export interface IGetAccessUrlInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of GetAccessUrl. */
export interface IGetAccessUrlPayload {
  __typename?: 'GetAccessUrlPayload';
  /** If everything was ok, this contains the url to redirect the user to */
  accessUrl?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The id of the contract as known in the database */
  contract?: Maybe<ISplitBillingContract>;
  /** Whether the request was a success or not */
  result: IResult;
}

/** Suggest locations */
export interface IGooglePlaces {
  __typename?: 'GooglePlaces';
  /** returns the details given a specific place id. */
  details?: Maybe<IGooglePlacesDetailsResult>;
  /** returns a list places that starts with... */
  suggest?: Maybe<Array<IGooglePlacesResult>>;
}


/** Suggest locations */
export interface IGooglePlacesDetailsArgs {
  placeId: Scalars['String']['input'];
}


/** Suggest locations */
export interface IGooglePlacesSuggestArgs {
  startsWith: Scalars['String']['input'];
}

export interface IGooglePlacesDetailsResult {
  __typename?: 'GooglePlacesDetailsResult';
  /** A formatted address of the location. */
  address: Scalars['String']['output'];
  /** City address component */
  city?: Maybe<Scalars['String']['output']>;
  /** Contains the longitude and latitude of the location */
  coordinates: ICoordinates;
  /** Country ISO Code address component */
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  /** Reference to stored location with a cached google details */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The name of the location */
  name: Scalars['String']['output'];
  /** The Google Place ID that you'd like to get the details from. */
  placeId: Scalars['String']['output'];
  /** Postal Code address component */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Street address component */
  street?: Maybe<Scalars['String']['output']>;
  /** Street number address component */
  streetNumber?: Maybe<Scalars['String']['output']>;
  /** When related location cannot be resolved or created because of missing data from google_places, an error message is provided. */
  validationErrors: Array<IValidationError>;
}

export interface IGooglePlacesResult {
  __typename?: 'GooglePlacesResult';
  /** A HTML enriched string containing the name of the location */
  html: Scalars['String']['output'];
  /** The Google Place ID which is needed if you want to retrieve the details if this place */
  placeId: Scalars['String']['output'];
  /** Structured formatting of the suggested text */
  structuredText: IGooglePlacesStructuredText;
  /** A plain text string containing the name of the location */
  text: Scalars['String']['output'];
  /** The location type and icon */
  type?: Maybe<IGooglePlacesTypeValue>;
}

export interface IGooglePlacesStructuredText {
  __typename?: 'GooglePlacesStructuredText';
  /** Main text of the suggestion, usually the name of the place */
  main?: Maybe<Scalars['String']['output']>;
  /** Secondary text of the suggestion, usually the location of the place */
  secondary?: Maybe<Scalars['String']['output']>;
}

/** Google places type */
export interface IGooglePlacesTypeValue {
  __typename?: 'GooglePlacesTypeValue';
  /** Icon name for type */
  icon?: Maybe<IIconEnum>;
  /** The location type */
  value: IGooglePlacesTypesEnum;
}

/** Google places types enum */
export enum IGooglePlacesTypesEnum {
  AIRPORT = 'airport',
  BUS_STATION = 'bus_station',
  GAS_STATION = 'gas_station',
  LIGHT_RAIL_STATION = 'light_rail_station',
  PARKING = 'parking',
  SUBWAY_STATION = 'subway_station',
  TRAIN_STATION = 'train_station',
  UNKNOWN = 'unknown'
}

export interface IGreenwheels extends IAvailableFeatureInterface {
  __typename?: 'Greenwheels';
  /** Whether an employee can activate the greenwheels feature */
  activatable: Scalars['Boolean']['output'];
  /** Activation link for Greenwheels */
  activationLink?: Maybe<Scalars['String']['output']>;
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** List of countries whose driving licenses can be used for Greenwheels */
  driversLicenseCountries: Array<IDriversLicenseCountry>;
  /** URL to the Terms and Conditions document of Greenwheels. This is different then the terms_and_conditions document, which always comes from the organization the employee belongs to. */
  greenwheelsTermsAndConditionsUrl: Scalars['String']['output'];
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Whether an employee can register for greenwheels */
  registerable: Scalars['Boolean']['output'];
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IGreenwheelsFleet {
  __typename?: 'GreenwheelsFleet';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface IHow {
  __typename?: 'How';
  humanized: Scalars['String']['output'];
  icon?: Maybe<IIcon>;
  identity?: Maybe<IIdentity>;
  label: Scalars['String']['output'];
}

export interface IHowMuch {
  __typename?: 'HowMuch';
  /** @deprecated Field renamed to co2_emission */
  co2?: Maybe<IQuantity>;
  co2Emission?: Maybe<IQuantity>;
  consumption: IQuantity;
}

export interface IHsla {
  __typename?: 'Hsla';
  alpha: Scalars['Int']['output'];
  hue: Scalars['Int']['output'];
  lightness: Scalars['Int']['output'];
  saturation: Scalars['Int']['output'];
}

export enum IHttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export interface IHub extends IMultiModalAvailability {
  __typename?: 'Hub';
  /** The city identifier for this hub */
  cityIdentifier?: Maybe<Scalars['String']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the operator for this hub */
  operator: Scalars['String']['output'];
  /** The amount of parking spaces available at this hub */
  parkingAvailable?: Maybe<Scalars['Int']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  /** The hubId for this hub */
  stationId?: Maybe<Scalars['String']['output']>;
  /** The amount of assets available at this hub */
  totalAvailable: Scalars['Int']['output'];
  type: Scalars['String']['output'];
}

export interface IIcon {
  __typename?: 'Icon';
  name: IIconEnum;
}

export enum IIconEnum {
  AIRPORT = 'airport',
  ALL_MODALITIES = 'all_modalities',
  AMBER = 'amber',
  BEHAVIOR = 'behavior',
  BICYCLE = 'bicycle',
  BIKE = 'bike',
  BUDGET = 'budget',
  BUS = 'bus',
  CAR = 'car',
  DONKEY_REPUBLIC = 'donkey_republic',
  ELECTRIC_CAR = 'electric_car',
  ELECTRICITY = 'electricity',
  ENECO_DONGLE = 'eneco_dongle',
  FERRY = 'ferry',
  FUEL = 'fuel',
  LEASE_BIKE = 'lease_bike',
  LEGS = 'legs',
  LIGHT_RAIL_STATION = 'light_rail_station',
  LOCATION_ARROW = 'location_arrow',
  METRO = 'metro',
  MOTORCYCLE = 'motorcycle',
  OTHER_OV = 'other_ov',
  OV_CHIP = 'ov_chip',
  PARKING = 'parking',
  PUBLIC_TRANSPORT = 'public_transport',
  RECEIPT = 'receipt',
  REFUND = 'refund',
  REISBALANS = 'reisbalans',
  RENTAL_CAR = 'rental_car',
  SCOOTER = 'scooter',
  SHARED_VEHICLE = 'shared_vehicle',
  SHED = 'shed',
  SHUTTEL_CHECK = 'shuttel_check',
  SHUTTEL_MY_WHEELS = 'shuttel_my_wheels',
  SHUTTEL_SIXT = 'shuttel_sixt',
  SHUTTEL_TAXI = 'shuttel_taxi',
  SHUTTEL_TRANZER_ETICKETS = 'shuttel_tranzer_etickets',
  SHUTTEL_VECORE = 'shuttel_vecore',
  SUBWAY_STATION = 'subway_station',
  SURCHARGE = 'surcharge',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER = 'transfer',
  TRANZER_ETICKETS = 'tranzer_etickets',
  UNKNOWN = 'unknown',
  WALKING = 'walking',
  YELLOWBRICK_SP = 'yellowbrick_sp'
}

export interface IIdentity {
  __typename?: 'Identity';
  humanized: Scalars['String']['output'];
}

/** image type */
export interface IImage {
  __typename?: 'Image';
  /** The image in BASE64 encoding */
  base64: Scalars['String']['output'];
  /** The content type of the image */
  contentType: Scalars['String']['output'];
  /** The image height in px */
  height: Scalars['Int']['output'];
  /** The content size in bytes */
  size: Scalars['String']['output'];
  /** The URI of the image */
  uri: Scalars['String']['output'];
  /** The image width in px */
  width: Scalars['Int']['output'];
}

export interface IInvoiceStatus {
  __typename?: 'InvoiceStatus';
  humanized: Scalars['String']['output'];
  value: IInvoiceStatusEnum;
}

export enum IInvoiceStatusEnum {
  FAILED = 'FAILED',
  PAID = 'PAID',
  SCHEDULED = 'SCHEDULED'
}

export interface IJournalEntry {
  __typename?: 'JournalEntry';
  amount: IMoney;
  calculation: Scalars['String']['output'];
  label: Scalars['String']['output'];
  moneyTransfer: IMoneyTransfer;
}

export interface IJourney {
  __typename?: 'Journey';
  arrivalTime: Scalars['DateTime']['output'];
  caloriesCost: Scalars['Int']['output'];
  co2Emission: Scalars['Int']['output'];
  departureTime: Scalars['DateTime']['output'];
  destination: IGeoLocation;
  duration?: Maybe<Scalars['Int']['output']>;
  /** Money amount with humanized value */
  estimatedPrice?: Maybe<IMoneyWithCurrency>;
  id: Scalars['ID']['output'];
  inconvenienceScore: Scalars['Int']['output'];
  journeyServiceOperatorIds: Array<Scalars['String']['output']>;
  journeyServiceOperators: Array<IServiceOperator>;
  legs: Array<IJourneyLeg>;
  origin: IGeoLocation;
}

export interface IJourneyLeg {
  __typename?: 'JourneyLeg';
  /** The agency for this specific leg */
  agency?: Maybe<Scalars['String']['output']>;
  /** The logo URL for the agency */
  agencyLogo?: Maybe<Scalars['String']['output']>;
  bookingId?: Maybe<Scalars['ID']['output']>;
  /** The expected crowdiness of this leg */
  crowdForecast?: Maybe<ICrowdForecastEnum>;
  /** The distance for this leg in meters */
  distance?: Maybe<Scalars['Int']['output']>;
  /** The duration for this leg in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The platform at which the means of transport will arrive on the destination */
  endPlatform?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  /** A humanized string for any non-public transport leg */
  humanized?: Maybe<Scalars['String']['output']>;
  icon: IIcon;
  /** The identifier of the leg */
  id: Scalars['ID']['output'];
  /** The destination for this line */
  lineDirection?: Maybe<Scalars['String']['output']>;
  /** The line identifier for this leg */
  lineNumber?: Maybe<Scalars['String']['output']>;
  mode: IJourneyMode;
  /** The polyline for this specific leg, which can be used to draw the route on a map */
  polyline?: Maybe<Scalars['String']['output']>;
  quoteId?: Maybe<Scalars['ID']['output']>;
  /** The departure platform for this leg */
  startPlatform?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  toLocation: IGeoLocation;
  /** Array of waypoints of the decoded polyline */
  waypoints?: Maybe<Array<ICoordinates>>;
}

export interface IJourneyMode {
  __typename?: 'JourneyMode';
  humanized: Scalars['String']['output'];
  value: IJourneyModeEnum;
}

export enum IJourneyModeEnum {
  BIKE = 'bike',
  BUS = 'bus',
  CAR = 'car',
  FERRY = 'ferry',
  METRO = 'metro',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER = 'transfer',
  UNKNOWN = 'unknown',
  WALKING = 'walking'
}

export enum IJourneySortTypeEnum {
  CAL = 'cal',
  CO2 = 'co2',
  DISTANCE = 'distance',
  DURATION = 'duration',
  PRICE = 'price',
  TRANSFER_COUNT = 'transfer_count'
}

export interface IJourneySorter {
  /** The sort order for the specified type (low-high or high-low) */
  direction: IOrderEnum;
  type: IJourneySortTypeEnum;
}

export interface ILabel {
  __typename?: 'Label';
  humanized?: Maybe<Scalars['String']['output']>;
  value?: Maybe<ILabelEnum>;
}

export enum ILabelEnum {
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE',
  PAST = 'PAST'
}

export interface ILeaseBike extends IAvailableFeatureInterface {
  __typename?: 'LeaseBike';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  /**
   * This is only available when the request for this feature has been approved and the employee is allowed to order a lease bike
   * @deprecated Use the urls endpoint instead
   */
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<IFeatureUrls>;
}

export interface ILeaseBikeOrder {
  __typename?: 'LeaseBikeOrder';
  create?: Maybe<ICreateLeaseBikeOrderPayload>;
}


export interface ILeaseBikeOrderCreateArgs {
  input: ICreateLeaseBikeOrderInput;
}

export interface ILeaseCar {
  __typename?: 'LeaseCar';
  /** The amount of kilometers you made for personal purposes during the current month */
  personalKilometers: Scalars['Float']['output'];
  /** The amount of kilometers made for unaccounted purposes */
  totalUnaccountedKilometers: Scalars['Float']['output'];
}

export interface ILeg {
  __typename?: 'Leg';
  quantity: IQuantity;
  travelPurpose: ITravelPurpose;
}

export interface ILicensePlate {
  __typename?: 'LicensePlate';
  budgetChargeTotal?: Maybe<Scalars['Float']['output']>;
  licensePlate: Scalars['String']['output'];
  mileages?: Maybe<Array<IMileage>>;
  remainingKilometersTotal?: Maybe<Scalars['Float']['output']>;
}

export interface ILocation {
  __typename?: 'Location';
  coordinates?: Maybe<ICoordinates>;
  favoriteId?: Maybe<Scalars['ID']['output']>;
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
}

export interface ILocationInput {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  name: Scalars['String']['input'];
}

export interface ILocationMutation {
  coordinates: ICoordinatesMutation;
  favoriteId?: InputMaybe<Scalars['ID']['input']>;
  humanized: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
}

export interface ILock {
  __typename?: 'Lock';
  bdAddress?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
}

/** Autogenerated input type of LockDonkeyRepublicAsset */
export interface ILockDonkeyRepublicAssetInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Coordinate']['input'];
  locationName: Scalars['String']['input'];
  longitude: Scalars['Coordinate']['input'];
}

/** Autogenerated return type of LockDonkeyRepublicAsset. */
export interface ILockDonkeyRepublicAssetPayload {
  __typename?: 'LockDonkeyRepublicAssetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IResult;
}

/** Autogenerated input type of Login */
export interface ILoginInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account */
  email: Scalars['String']['input'];
  /** OTP token */
  otpToken?: InputMaybe<Scalars['String']['input']>;
  /** Password of the account */
  password: Scalars['String']['input'];
}

/** Autogenerated return type of Login. */
export interface ILoginPayload {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Does the user have OTP configured? Use the ConfigureMfa mutation to configure OTP */
  hasOtpConfigured?: Maybe<Scalars['Boolean']['output']>;
  /** The JWT Token that can be used as authentication */
  jwt?: Maybe<Scalars['String']['output']>;
  /** If true, this means that the password validates, but OTP is required and the user can only login with a valid OTP token */
  otpRequired?: Maybe<Scalars['Boolean']['output']>;
  result: IResult;
  /** Token that can be used as authentication */
  token?: Maybe<Scalars['String']['output']>;
  tokens?: Maybe<IAuth>;
}

/** Autogenerated input type of Logout */
export interface ILogoutInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of Logout. */
export interface ILogoutPayload {
  __typename?: 'LogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

export interface IMsaAbstractProperty {
  __typename?: 'MSAAbstractProperty';
  /** Name of the property. */
  key: Scalars['String']['output'];
  /** Value of the property. */
  value: Scalars['String']['output'];
}

export enum IMsaAdminAction {
  FIATING = 'FIATING'
}

export interface IMsaCarrier {
  __typename?: 'MSACarrier';
  /** The available admin actions for the current status the carrier is in. */
  adminActions?: Maybe<Array<Maybe<IMsaAdminAction>>>;
  /** Product group identifier */
  carrierId: Scalars['UUID']['output'];
  /** Product group identifier */
  productGroupId: Scalars['UUID']['output'];
  /** Properties associated with the carrier. */
  properties?: Maybe<Array<Maybe<IMsaAbstractProperty>>>;
  provider: IMsaProviderType;
  status?: Maybe<IMsaExternalStatus>;
  statusCategory: IMsaStatusCategory;
  /** The available traveler actions for the current status the carrier is in. */
  travelerActions?: Maybe<Array<Maybe<IMsaTravelerAction>>>;
  /** The name of the type */
  type: Scalars['String']['output'];
}

/** Create carrier request body */
export interface IMsaCreateCarrierRequestBodyRequestInput {
  /** The type of the carrier */
  carrierType: Scalars['String']['input'];
  /** Product group identifier */
  productGroupId: Scalars['UUID']['input'];
  properties?: InputMaybe<IMsaPropertiesInput>;
}

/** Create product group request body */
export interface IMsaCreateProductGroupRequestBodyRequestInput {
  /** Product group identifier */
  organizationalUnitId: Scalars['UUID']['input'];
}

/** Create product request body */
export interface IMsaCreateProductRequestBodyRequestInput {
  /** Product group identifier */
  productGroupId: Scalars['UUID']['input'];
  /** The type of the product */
  productType: Scalars['String']['input'];
  properties?: InputMaybe<IMsaPropertiesInput>;
}

export enum IMsaExternalStatus {
  AWAITING_FIAT = 'AWAITING_FIAT',
  DEACTIVATED = 'DEACTIVATED',
  INITIAL = 'INITIAL',
  IN_USE = 'IN_USE',
  ORDERING = 'ORDERING'
}

export interface IMsaProduct {
  __typename?: 'MSAProduct';
  /** The available admin actions for the current status the product is in. */
  adminActions?: Maybe<Array<Maybe<IMsaAdminAction>>>;
  /** Product group identifier */
  productGroupId: Scalars['UUID']['output'];
  /** Product group identifier */
  productId: Scalars['UUID']['output'];
  /** Properties associated with the carrier. */
  properties?: Maybe<Array<Maybe<IMsaAbstractProperty>>>;
  provider: IMsaProviderType;
  status?: Maybe<IMsaExternalStatus>;
  statusCategory: IMsaStatusCategory;
  /** The available traveler actions for the current status the product is in. */
  travelerActions?: Maybe<Array<Maybe<IMsaTravelerAction>>>;
  /** The name of the type */
  type?: Maybe<Scalars['String']['output']>;
}

export interface IMsaProductGroup {
  __typename?: 'MSAProductGroup';
  /** Carriers inside this group */
  carriers: Array<Maybe<IMsaCarrier>>;
  /** Product group identifier */
  organizationalUnitId: Scalars['UUID']['output'];
  organizationalUnitUuid: Scalars['JSON']['output'];
  /** Product group identifier */
  productGroupId: Scalars['UUID']['output'];
  /** Products inside this group */
  products: Array<Maybe<IMsaProduct>>;
}

/** The properties for the entity */
export interface IMsaPropertiesInput {
  key?: InputMaybe<Scalars['String']['input']>;
}

/** A binding between a generic PropertyType and a Carrier or Product. */
export interface IMsaPropertyTypeBinding {
  __typename?: 'MSAPropertyTypeBinding';
  constraints: Array<Maybe<IMsaPropertyTypeConstraint>>;
  /** The property type name */
  name: Scalars['String']['output'];
  /** Indicates if a property type is required when requesting a product or carrier. */
  required: Scalars['Boolean']['output'];
}

export interface IMsaPropertyTypeConstraint {
  __typename?: 'MSAPropertyTypeConstraint';
  /** The constraint type, used to identify the type of constaint. One of Symfony's Validation Constraints. */
  constraint: Scalars['String']['output'];
  rules: IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRules;
}

export enum IMsaProviderType {
  AMBER = 'AMBER',
  ENECO = 'ENECO',
  GREENWHEELS = 'GREENWHEELS',
  NS = 'NS',
  OVFIETS = 'OVFIETS',
  TLS = 'TLS',
  TRANSVISION = 'TRANSVISION',
  TRANZER = 'TRANZER',
  TRAVELCARD = 'TRAVELCARD',
  VECORE = 'VECORE',
  YELLOWBRICK = 'YELLOWBRICK'
}

/** A list of rules to define the constraint. */
export interface IMsaQueryCarrierTypePropertiesListItemsConstraintsItemsRules {
  __typename?: 'MSAQueryCarrierTypePropertiesListItemsConstraintsItemsRules';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
}

/** Replace properties on a carrier. */
export interface IMsaReplaceCarrierPropertiesRequestBodyRequestInput {
  properties: IMsaPropertiesInput;
}

/** Replace carrier request body */
export interface IMsaReplaceCarrierRequestBodyRequestInput {
  /** Product group identifier */
  carrierId: Scalars['UUID']['input'];
}

/** Replace properties on a product. */
export interface IMsaReplaceProductPropertiesRequestBodyRequestInput {
  properties: IMsaPropertiesInput;
}

export enum IMsaStatusCategory {
  ACTIVATING = 'ACTIVATING',
  ACTIVE = 'ACTIVE',
  DEACTIVATING = 'DEACTIVATING',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW'
}

/** Returns if product type is supported by product group */
export interface IMsaSupportedCarriersForProductTypeList {
  __typename?: 'MSASupportedCarriersForProductTypeList';
  /** List of compatible carrier types */
  compatibleCarrierTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** List of compatible carrier ids */
  existingCompatibleCarriers?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  /** Status */
  ok?: Maybe<Scalars['Boolean']['output']>;
}

export enum IMsaTravelerAction {
  SET_DEFECTIVE = 'SET_DEFECTIVE',
  SET_STOLEN = 'SET_STOLEN'
}

export interface IMainCategory {
  __typename?: 'MainCategory';
  humanized: Scalars['String']['output'];
  subCategories: Array<ISubCategory>;
  value: Scalars['String']['output'];
}

export enum IMainCategoryEnum {
  CONTRACT = 'contract',
  CORRECTION = 'correction',
  DAY = 'day',
  DEPOSIT = 'deposit',
  E_TICKET = 'e_ticket',
  FINE = 'fine',
  FUEL = 'fuel',
  ISSUANCE = 'issuance',
  MAINTENANCE = 'maintenance',
  OTHER = 'other',
  PARKING = 'parking',
  PUBLIC_TRANSPORT_SUBSCRIPTION = 'public_transport_subscription',
  REFUND = 'refund',
  RELOAD = 'reload',
  RENTAL = 'rental',
  REPLACEMENT = 'replacement',
  RIDE = 'ride',
  SETTLEMENT = 'settlement',
  SURCHARGE = 'surcharge',
  USER_FEE = 'user_fee'
}

export interface IMandateActions {
  __typename?: 'MandateActions';
  acceptable: Scalars['Boolean']['output'];
  revokable: Scalars['Boolean']['output'];
}

export interface IMandateStatus {
  __typename?: 'MandateStatus';
  humanized: Scalars['String']['output'];
  value: IMandateStatusEnum;
}

export enum IMandateStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED',
  REVOKED = 'REVOKED',
  SUBSCRIPTION_ADDED = 'SUBSCRIPTION_ADDED'
}

export enum IMandateStatusFilterEnum {
  ACCEPTED = 'ACCEPTED',
  ACTIVE = 'ACTIVE',
  CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED',
  INACTIVE = 'INACTIVE',
  REVOKED = 'REVOKED',
  SUBSCRIPTION_ADDED = 'SUBSCRIPTION_ADDED'
}

/** Autogenerated input type of MarkMessageAsRead */
export interface IMarkMessageAsReadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
}

/** Autogenerated return type of MarkMessageAsRead. */
export interface IMarkMessageAsReadPayload {
  __typename?: 'MarkMessageAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

export interface IMeanOfTransportFiscality {
  __typename?: 'MeanOfTransportFiscality';
  humanized: Scalars['String']['output'];
  value: IFiscalityEnum;
}

/** MeansOfTransport is used as a type that could return either a vehicle or a modality. Also, it is used internally as a subclass of the VehiclePresenter. */
export interface IMeansOfTransport {
  __typename?: 'MeansOfTransport';
  /** Actions that can be executed on this vehicle */
  actions: IVehicleActions;
  budgetCostPerKilometer?: Maybe<Scalars['Float']['output']>;
  budgetCostPerMonth?: Maybe<Scalars['Float']['output']>;
  budgetGroupFuelConsumptionNorm?: Maybe<Scalars['Float']['output']>;
  co2EmissionPerKilometer: Scalars['Int']['output'];
  /** Date until when this vehicle is / was available to the user */
  endsOn?: Maybe<Scalars['Date']['output']>;
  /**
   * Whether this vehicle is fiscally reimbursed or supplied
   * @deprecated use meansOfTransportFiscality please
   */
  fiscality: IFiscalityEnum;
  /** @deprecated Please interface with the vehicleInformation field for fuelType */
  fuelType?: Maybe<Scalars['String']['output']>;
  /** Humanized description of this vehicle based on the available fields */
  humanized: Scalars['String']['output'];
  /** Icon of this vehicle */
  icon?: Maybe<IIcon>;
  /** Identifier of this vehicle */
  id: Scalars['ID']['output'];
  /**
   * Static label that is a Dutch translation for "Vehicle"
   * @deprecated Frontends should be in control of displaying labels with their translations
   */
  label: Scalars['String']['output'];
  /** License plate of this vehicle */
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** Whether this vehicle is fiscally reimbursed or supplied */
  meansOfTransportFiscality: IMeanOfTransportFiscality;
  /** Modality this vehicle belongs to */
  modality: IModality;
  /** Name for this vehicle as given by the user  */
  name?: Maybe<Scalars['String']['output']>;
  /** Date from when this vehicle is available to the user */
  startsOn: Scalars['Date']['output'];
  title?: Maybe<Scalars['String']['output']>;
  /** Specific information about this vehicle, fetched from RDW */
  vehicleInformation?: Maybe<IVehicleInformation>;
}

export interface IMedia {
  __typename?: 'Media';
  humanized: Scalars['String']['output'];
  value: IMediaTypeEnum;
}

export interface IMediaSide {
  __typename?: 'MediaSide';
  /** The file extension of the side of the graphic. */
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export enum IMediaTypeEnum {
  GRAPHIC = 'GRAPHIC',
  PDF = 'PDF'
}

/** Autogenerated input type of MergeMobilityEvents */
export interface IMergeMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of MergeMobilityEvents. */
export interface IMergeMobilityEventsPayload {
  __typename?: 'MergeMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  day?: Maybe<IDayWithMobilityEvents>;
  mergedMobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
}

export interface IMergedMobilityEventsPreview {
  __typename?: 'MergedMobilityEventsPreview';
  /** Possible error message */
  error?: Maybe<Scalars['String']['output']>;
  /** The merged mobility event */
  mobilityEventPreview?: Maybe<IMobilityEventPreview>;
}

export interface IMessage {
  __typename?: 'Message';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
}

export enum IMessageKeyEnum {
  ALL = 'ALL',
  MOBILITY_CARD_ORDER = 'MOBILITY_CARD_ORDER'
}

export interface IMileage {
  __typename?: 'Mileage';
  amountInKilometers: Scalars['Int']['output'];
  budgetCharge?: Maybe<Scalars['Float']['output']>;
  budgetCostPerKilometer?: Maybe<Scalars['Float']['output']>;
  hasUnaccountedDistance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  licensePlate: Scalars['String']['output'];
  mobilityEventId?: Maybe<Scalars['ID']['output']>;
  recordedAt: Scalars['DateTime']['output'];
  remainingKilometers?: Maybe<Scalars['Float']['output']>;
  supportsUnaccountedDistances: Scalars['Boolean']['output'];
}

export interface IMileages {
  __typename?: 'Mileages';
  create?: Maybe<ICreateMileagePayload>;
  update?: Maybe<IUpdateMileagePayload>;
}


export interface IMileagesCreateArgs {
  input: ICreateMileageInput;
}


export interface IMileagesUpdateArgs {
  input: IUpdateMileageInput;
}

export interface IMobilityCardOrder {
  __typename?: 'MobilityCardOrder';
  /** Actions that can be executed on this mobility card order */
  actions: IMobilityCardOrderActions;
  budgetGroupRequiresPincode?: Maybe<Scalars['Boolean']['output']>;
  /** DateTime at which this mobility card order was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The employment that this mobility card order belongs to */
  employment?: Maybe<IEmployment>;
  firstClassReason?: Maybe<IFirstClassReason>;
  fromStation?: Maybe<Scalars['String']['output']>;
  hasPincodeBeenEntered?: Maybe<Scalars['Boolean']['output']>;
  /** Humanized value for subscription */
  humanizedSubscription?: Maybe<ISubscription>;
  id: Scalars['ID']['output'];
  /** Whether the mobilityCardOrder has been delivered after the order. */
  isDelivered?: Maybe<Scalars['Boolean']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  needsApproval?: Maybe<Scalars['Boolean']['output']>;
  /** The url pointing to the photo that has been uploaded if there is one */
  passportPhoto?: Maybe<Scalars['String']['output']>;
  /** Permissions for if the steps in the wizard can be displayed */
  permission?: Maybe<IMobilityCardOrderPermission>;
  recommendedCard?: Maybe<IRecommendedCard>;
  /** List of all requestable services */
  requestableServices?: Maybe<Array<IRequestableService>>;
  /** List of all requestable subscriptions */
  requestableSubscriptions?: Maybe<Array<IAvailableSubscription>>;
  requiredTermsAndConditionsDocuments?: Maybe<Array<ITermsAndConditionsDocument>>;
  services?: Maybe<Array<IMobilityCardOrderService>>;
  starValue?: Maybe<IStarValue>;
  status?: Maybe<IMobilityCardOrderStatus>;
  /** @deprecated Deprecated due to introduction of humanized_subscription */
  subscription?: Maybe<ISubscriptionEnum>;
  toStation?: Maybe<Scalars['String']['output']>;
  trainClass?: Maybe<ITrainClass>;
  trainClassStatus?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<IZone>;
}

export interface IMobilityCardOrderActions {
  __typename?: 'MobilityCardOrderActions';
  isEditable: Scalars['Boolean']['output'];
}

export interface IMobilityCardOrderPermission {
  __typename?: 'MobilityCardOrderPermission';
  canRequestOptions?: Maybe<Scalars['Boolean']['output']>;
  canRequestPhoto?: Maybe<Scalars['Boolean']['output']>;
}

export interface IMobilityCardOrderService {
  __typename?: 'MobilityCardOrderService';
  /** Humanized and translated service */
  humanized: Scalars['String']['output'];
  /** Raw value of the service */
  value: IPersonalMeansOfRegistrationServiceKindSubEnum;
}

export interface IMobilityCardOrderStatus {
  __typename?: 'MobilityCardOrderStatus';
  /** Humanized and translated status */
  humanized: Scalars['String']['output'];
  /** Raw value of the status */
  value: IMobilityCardOrderStatusEnum;
}

export enum IMobilityCardOrderStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ORDERED = 'ORDERED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUEST_FAILED = 'REQUEST_FAILED'
}

export interface IMobilityCardOrders {
  __typename?: 'MobilityCardOrders';
  confirm?: Maybe<IConfirmPayload>;
  create?: Maybe<ICreateMobilityCardOrderPayload>;
  setOptions?: Maybe<ISetOptionsPayload>;
  setPhoto?: Maybe<ISetPhotoPayload>;
  setProfile?: Maybe<ISetProfilePayload>;
  setTerms?: Maybe<ISetTermsPayload>;
}


export interface IMobilityCardOrdersConfirmArgs {
  input: IConfirmInput;
}


export interface IMobilityCardOrdersCreateArgs {
  input: ICreateMobilityCardOrderInput;
}


export interface IMobilityCardOrdersSetOptionsArgs {
  input: ISetOptionsInput;
}


export interface IMobilityCardOrdersSetPhotoArgs {
  input: ISetPhotoInput;
}


export interface IMobilityCardOrdersSetProfileArgs {
  input: ISetProfileInput;
}


export interface IMobilityCardOrdersSetTermsArgs {
  input: ISetTermsInput;
}

export interface IMobilityEvent {
  __typename?: 'MobilityEvent';
  actions: IActions;
  allowPersonalConsumption: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The employment that this mobility event belongs to */
  employment?: Maybe<IEmployment>;
  financial: IFinancial;
  fiscality?: Maybe<IFiscalityEnum>;
  happenedAt: Scalars['DateTime']['output'];
  happenedInTheFuture: Scalars['Boolean']['output'];
  how: IHow;
  howMuch?: Maybe<IHowMuch>;
  id: Scalars['ID']['output'];
  /** Whether this mobility event is a refund */
  isRefund: Scalars['Boolean']['output'];
  needsApproval: Scalars['Boolean']['output'];
  /** Required fields of mobility event */
  requiredFields: Array<Scalars['String']['output']>;
  signatures?: Maybe<Array<ISignature>>;
  status: IStatus;
  what: IWhat;
  /** @deprecated Remove distinction between expense and ride. This is going to disappear. */
  whatOption?: Maybe<IWhatOption>;
  when: IWhen;
  where?: Maybe<IWhere>;
  who: IEmployee;
  why?: Maybe<IWhy>;
}

export interface IMobilityEventAggregate {
  __typename?: 'MobilityEventAggregate';
  count: Scalars['Int']['output'];
}

export interface IMobilityEventByDate {
  __typename?: 'MobilityEventByDate';
  /** Date on which these mobilityEvents happened */
  date: Scalars['Date']['output'];
  /** MobilityEvents for this date */
  mobilityEvents: Array<IMobilityEvent>;
}

/** Mobility event form validation */
export interface IMobilityEventFormValidations {
  __typename?: 'MobilityEventFormValidations';
  /** Show the warning which says the user has entered more than 5 wfh compensations this week */
  showWarningForOver5WfhThisWeek?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a walk or bike ride of over 40 km */
  showWarningForOver40KmWalkOrBike?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a wfh compensation on a holiday */
  showWarningForWfhOnHoliday?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a wfh compensation on a weekend */
  showWarningForWfhOnWeekend?: Maybe<Scalars['Boolean']['output']>;
  /** Show the warning which says the user has entered a wfh compensation on a weekend or holiday */
  showWarningForWfhOnWeekendOrHoliday?: Maybe<Scalars['Boolean']['output']>;
}

export interface IMobilityEventPreview {
  __typename?: 'MobilityEventPreview';
  actions: IActions;
  allowPersonalConsumption: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The employment that this mobility event belongs to */
  employment?: Maybe<IEmployment>;
  financial: IFinancial;
  fiscality?: Maybe<IFiscalityEnum>;
  happenedAt: Scalars['DateTime']['output'];
  happenedInTheFuture: Scalars['Boolean']['output'];
  how: IHow;
  howMuch?: Maybe<IHowMuch>;
  /** Since a mobility event preview might not have been stored yet, it can be null */
  id?: Maybe<Scalars['ID']['output']>;
  /** Whether this mobility event is a refund */
  isRefund: Scalars['Boolean']['output'];
  needsApproval: Scalars['Boolean']['output'];
  /** Required fields of mobility event */
  requiredFields: Array<Scalars['String']['output']>;
  signatures?: Maybe<Array<ISignature>>;
  status: IStatus;
  what: IWhat;
  /** @deprecated Remove distinction between expense and ride. This is going to disappear. */
  whatOption?: Maybe<IWhatOption>;
  when: IWhen;
  where?: Maybe<IWhere>;
  who: IEmployee;
  why?: Maybe<IWhy>;
}

export interface IMobilityEventTotals {
  __typename?: 'MobilityEventTotals';
  /** All totals */
  all: ITotals;
  /** Business totals */
  business: ITotals;
  /** Commute totals */
  commute: ITotals;
  /** Work from home totals */
  workFromHome: ITotals;
}

export interface IMobilityEventsWeek extends ITask {
  __typename?: 'MobilityEventsWeek';
  date?: Maybe<Scalars['Date']['output']>;
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IMobilityServicesMutation {
  __typename?: 'MobilityServicesMutation';
  carrierCreate?: Maybe<IMsaCarrier>;
  carrierReplace?: Maybe<IMsaCarrier>;
  carrierUpdate?: Maybe<IMsaCarrier>;
  productCreate?: Maybe<IMsaProduct>;
  productGroupCreate?: Maybe<IMsaProductGroup>;
  productUpdate?: Maybe<IMsaProduct>;
}


export interface IMobilityServicesMutationCarrierCreateArgs {
  input?: InputMaybe<IMsaCreateCarrierRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationCarrierReplaceArgs {
  input?: InputMaybe<IMsaReplaceCarrierRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationCarrierUpdateArgs {
  carrierId: Scalars['String']['input'];
  input?: InputMaybe<IMsaReplaceCarrierPropertiesRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationProductCreateArgs {
  input?: InputMaybe<IMsaCreateProductRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationProductGroupCreateArgs {
  input?: InputMaybe<IMsaCreateProductGroupRequestBodyRequestInput>;
}


export interface IMobilityServicesMutationProductUpdateArgs {
  input?: InputMaybe<IMsaReplaceProductPropertiesRequestBodyRequestInput>;
  productId: Scalars['String']['input'];
}

export interface IMobilityServicesQuery {
  __typename?: 'MobilityServicesQuery';
  carrierGet?: Maybe<IMsaCarrier>;
  carrierTypePropertiesList?: Maybe<Array<Maybe<IMsaPropertyTypeBinding>>>;
  productGet?: Maybe<IMsaProduct>;
  productGroupGet?: Maybe<IMsaProductGroup>;
  productTypePropertiesList?: Maybe<Array<Maybe<IMsaPropertyTypeBinding>>>;
  productTypeSupportedCarriers?: Maybe<IMsaSupportedCarriersForProductTypeList>;
}


export interface IMobilityServicesQueryCarrierGetArgs {
  carrierId: Scalars['UUID']['input'];
}


export interface IMobilityServicesQueryCarrierTypePropertiesListArgs {
  carrierType: Scalars['String']['input'];
}


export interface IMobilityServicesQueryProductGetArgs {
  productId: Scalars['UUID']['input'];
}


export interface IMobilityServicesQueryProductGroupGetArgs {
  productGroupId: Scalars['UUID']['input'];
}


export interface IMobilityServicesQueryProductTypePropertiesListArgs {
  productType: Scalars['String']['input'];
}


export interface IMobilityServicesQueryProductTypeSupportedCarriersArgs {
  productGroupId: Scalars['UUID']['input'];
  productType: Scalars['String']['input'];
}

export interface IModality {
  __typename?: 'Modality';
  /** in case of a train this relays if its first or second class, in case of ride_sharing this relays the amount of passengers required */
  clazz?: Maybe<Scalars['String']['output']>;
  /** A translated and humanized version of the modality */
  humanized: Scalars['String']['output'];
  /** A translated and humanized version of the modality starting with a capital */
  humanizedWithCapital: Scalars['String']['output'];
  /** The database id of this record */
  id: Scalars['ID']['output'];
  /** Relays the kind of modality, which might be a car, bike, train and so on */
  kind: IModalityKindEnum;
  /** Who owns the modality, values like personal, employer, ride_sharing, etc */
  modalityOwnership?: Maybe<IModalityOwnership>;
  /**
   * Who owns the modality, values like personal, employer, ride_sharing, etc
   * @deprecated use modalityOwnership please
   */
  ownership?: Maybe<Scalars['String']['output']>;
  /** The required amount of passengers for this modality */
  requiredPassengerCount: Scalars['Int']['output'];
  /** Whether or not this modality requires a vehicle */
  requiresVehicle: Scalars['Boolean']['output'];
  /** The mode of traval, might be something like driving, walking or bicycling */
  travelMode: Scalars['String']['output'];
}

export enum IModalityKindEnum {
  ALL_MODALITIES = 'all_modalities',
  BICYCLE = 'bicycle',
  BUS = 'bus',
  CAR = 'car',
  E_BICYCLE = 'e_bicycle',
  E_SCOOTER = 'e_scooter',
  ELECTRIC_CAR = 'electric_car',
  FERRY = 'ferry',
  INTERNATIONAL_TRAIN = 'international_train',
  LEGS = 'legs',
  METRO = 'metro',
  MOTORCYCLE = 'motorcycle',
  OTHER = 'other',
  PRIVATE_OTHER = 'private_other',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram'
}

export interface IModalityOwnership {
  __typename?: 'ModalityOwnership';
  humanized: Scalars['String']['output'];
  value: IOwnershipEnum;
}

export enum IModalityPrivateVehicleEnum {
  PRIVATE_CAR = 'private_car',
  PRIVATE_ELECTRIC_CAR = 'private_electric_car',
  PRIVATE_MOTORCYCLE = 'private_motorcycle'
}

export interface IMoney {
  __typename?: 'Money';
  humanized: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueInCents: Scalars['Int']['output'];
}

export interface IMoneyTransfer {
  __typename?: 'MoneyTransfer';
  direction: IDirectionEnum;
  humanized: Scalars['String']['output'];
}

export interface IMoneyWithCurrency {
  __typename?: 'MoneyWithCurrency';
  currency: Scalars['String']['output'];
  humanized: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueInCents: Scalars['Int']['output'];
}

export interface IMultiModalAvailability {
  deeplink?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  type: Scalars['String']['output'];
}

export interface IMutation {
  __typename?: 'Mutation';
  MobilityServices: IMobilityServicesMutation;
  acceptMobilityEvents?: Maybe<IAcceptMobilityEventsPayload>;
  activateEnecoDongle?: Maybe<IActivateEnecoDonglePayload>;
  activatePersonalMeansOfRegistration?: Maybe<IActivatePersonalMeansOfRegistrationPayload>;
  addPushNotificationToken?: Maybe<IAddPushNotificationTokenPayload>;
  /** Login admin with email and password. */
  adminLogin?: Maybe<IAdminLoginPayload>;
  assignReplacementManager?: Maybe<IAssignReplacementManagerPayload>;
  /** @deprecated Use cancelSharedVehicleInformation */
  cancelSharedCarReservation?: Maybe<ICancelSharedCarReservationPayload>;
  cancelSharedVehicleReservation?: Maybe<ICancelSharedVehicleReservationPayload>;
  /** @deprecated Use the taxi/cancel endpoint please */
  cancelTaxiReservation?: Maybe<ICancelTaxiReservationPayload>;
  /** Configure 2FA for this user */
  configureMfa?: Maybe<IConfigureMfaPayload>;
  confirmMobilityEvent?: Maybe<IConfirmMobilityEventPayload>;
  confirmMobilityEvents?: Maybe<IConfirmMobilityEventsPayload>;
  /** @deprecated Use confirmSharedVehicleReservatoin */
  confirmSharedCarReservation?: Maybe<IConfirmSharedCarReservationPayload>;
  confirmSharedVehicleReservation?: Maybe<IConfirmSharedVehicleReservationPayload>;
  /** @deprecated Use the taxi/confirm endpoint please */
  confirmTaxiReservation?: Maybe<IConfirmTaxiReservationPayload>;
  copyMobilityEvents?: Maybe<ICopyMobilityEventsPayload>;
  copyMobilityEventsForDay?: Maybe<ICopyMobilityEventsForDayPayload>;
  createCommuteCompensation?: Maybe<ICreateCommuteCompensationPayload>;
  createCommuteRides?: Maybe<ICreateCommuteRidesPayload>;
  /** @deprecated Done automatically by backend after linking fine with the missed trip */
  createCompensationMobilityEvent?: Maybe<ICreateCompensationMobilityEventPayload>;
  createMobilityEvent?: Maybe<ICreateMobilityEventPayload>;
  /** @deprecated Use the taxi/create endpoint please */
  createTaxiReservation?: Maybe<ICreateTaxiReservationPayload>;
  /** DEPRECATED. Only used by Freddi to update employees. Still used by RBW to reset password. */
  createUserProfile?: Maybe<ICreateUserProfilePayload>;
  /** @deprecated Use the vehicle/create endpoint instead */
  createVehicle?: Maybe<ICreateVehiclePayload>;
  createWorkFromHome?: Maybe<ICreateWorkFromHomePayload>;
  destroyMobilityEvent?: Maybe<IDestroyMobilityEventPayload>;
  destroyMobilityEvents?: Maybe<IDestroyMobilityEventsPayload>;
  destroyProfileAddress?: Maybe<IDestroyProfileAddressPayload>;
  directDebitMandates?: Maybe<IDirectDebitMandates>;
  donkeyRepublic?: Maybe<IDonkeyRepublicBooking>;
  favorites?: Maybe<IFavorites>;
  /** @deprecated Use finishSharedVehicleReservation */
  finishSharedCarReservation?: Maybe<IFinishSharedCarReservationPayload>;
  finishSharedVehicleReservation?: Maybe<IFinishSharedVehicleReservationPayload>;
  leaseBikeOrder?: Maybe<ILeaseBikeOrder>;
  /** Login user with email and password. It creates a new session for the user and it optionally returns a token. */
  login?: Maybe<ILoginPayload>;
  /** Logout user. Destroys the session, optionally receives a token that will be revoked */
  logout?: Maybe<ILogoutPayload>;
  markMessageAsRead?: Maybe<IMarkMessageAsReadPayload>;
  mergeMobilityEvents?: Maybe<IMergeMobilityEventsPayload>;
  mileages?: Maybe<IMileages>;
  mobilityCardOrders?: Maybe<IMobilityCardOrders>;
  parking?: Maybe<IParking>;
  reisbalans: IReisbalansMutation;
  rejectMobilityEvents?: Maybe<IRejectMobilityEventsPayload>;
  reportPersonalMeansOfRegistration?: Maybe<IReportPersonalMeansOfRegistrationPayload>;
  /** @deprecated Use requestFeature instead */
  requestDonkeyRepublicFeature?: Maybe<IRequestDonkeyRepublicFeaturePayload>;
  requestFeature?: Maybe<IRequestFeaturePayload>;
  requestTrainClassChange?: Maybe<IRequestTrainClassChangePayload>;
  resetPassword?: Maybe<IResetPasswordPayload>;
  reuploadPhoto?: Maybe<IReuploadPhotoPayload>;
  sendNewInvitationLink?: Maybe<ISendNewInvitationLinkPayload>;
  sendPasswordResetMail?: Maybe<ISendPasswordResetMailPayload>;
  sendPrivacyRequestMail?: Maybe<ISendPrivacyRequestMailPayload>;
  services?: Maybe<IServices>;
  setAgreeTerms?: Maybe<ISetAgreeTermsPayload>;
  splitBillingContracts?: Maybe<ISplitBillingContracts>;
  /** @deprecated Use startSharedVehicleReservation */
  startSharedCarReservation?: Maybe<IStartSharedCarReservationPayload>;
  startSharedVehicleReservation?: Maybe<IStartSharedVehicleReservationPayload>;
  studentTravelProduct?: Maybe<IStudentTravelProduct>;
  taxi?: Maybe<ITaxi>;
  terminateOwnership?: Maybe<ITerminateOwnershipPayload>;
  terminatePersonalMeansOfRegistration?: Maybe<ITerminatePersonalMeansOfRegistrationPayload>;
  terminateReplacementManager?: Maybe<ITerminateReplacementManagerPayload>;
  /** @deprecated Use the vehicle/terminate endpoint instead */
  terminateVehicle?: Maybe<ITerminateVehiclePayload>;
  tranzer?: Maybe<ITranzer>;
  unacceptMobilityEvent?: Maybe<IUnacceptMobilityEventPayload>;
  unacceptMobilityEvents?: Maybe<IUnacceptMobilityEventsPayload>;
  unconfirmMobilityEvent?: Maybe<IUnconfirmMobilityEventPayload>;
  unconfirmMobilityEvents?: Maybe<IUnconfirmMobilityEventsPayload>;
  updateMobilityEvent?: Maybe<IUpdateMobilityEventPayload>;
  updateMobilityEvents?: Maybe<IUpdateMobilityEventsPayload>;
  updateTaxiReservation?: Maybe<IUpdateTaxiReservationPayload>;
  updateUserProfile?: Maybe<IUpdateUserProfilePayload>;
  vehicle?: Maybe<IVehicle>;
}


export interface IMutationAcceptMobilityEventsArgs {
  input: IAcceptMobilityEventsInput;
}


export interface IMutationActivateEnecoDongleArgs {
  input: IActivateEnecoDongleInput;
}


export interface IMutationActivatePersonalMeansOfRegistrationArgs {
  input: IActivatePersonalMeansOfRegistrationInput;
}


export interface IMutationAddPushNotificationTokenArgs {
  input: IAddPushNotificationTokenInput;
}


export interface IMutationAdminLoginArgs {
  input: IAdminLoginInput;
}


export interface IMutationAssignReplacementManagerArgs {
  input: IAssignReplacementManagerInput;
}


export interface IMutationCancelSharedCarReservationArgs {
  input: ICancelSharedCarReservationInput;
}


export interface IMutationCancelSharedVehicleReservationArgs {
  input: ICancelSharedVehicleReservationInput;
}


export interface IMutationCancelTaxiReservationArgs {
  input: ICancelTaxiReservationInput;
}


export interface IMutationConfigureMfaArgs {
  input: IConfigureMfaInput;
}


export interface IMutationConfirmMobilityEventArgs {
  input: IConfirmMobilityEventInput;
}


export interface IMutationConfirmMobilityEventsArgs {
  input: IConfirmMobilityEventsInput;
}


export interface IMutationConfirmSharedCarReservationArgs {
  input: IConfirmSharedCarReservationInput;
}


export interface IMutationConfirmSharedVehicleReservationArgs {
  input: IConfirmSharedVehicleReservationInput;
}


export interface IMutationConfirmTaxiReservationArgs {
  input: IConfirmTaxiReservationInput;
}


export interface IMutationCopyMobilityEventsArgs {
  input: ICopyMobilityEventsInput;
}


export interface IMutationCopyMobilityEventsForDayArgs {
  input: ICopyMobilityEventsForDayInput;
}


export interface IMutationCreateCommuteCompensationArgs {
  input: ICreateCommuteCompensationInput;
}


export interface IMutationCreateCommuteRidesArgs {
  input: ICreateCommuteRidesInput;
}


export interface IMutationCreateCompensationMobilityEventArgs {
  input: ICreateCompensationMobilityEventInput;
}


export interface IMutationCreateMobilityEventArgs {
  input: ICreateMobilityEventInput;
}


export interface IMutationCreateTaxiReservationArgs {
  input: ICreateTaxiReservationInput;
}


export interface IMutationCreateUserProfileArgs {
  input: ICreateUserProfileInput;
}


export interface IMutationCreateVehicleArgs {
  input: ICreateVehicleInput;
}


export interface IMutationCreateWorkFromHomeArgs {
  input: ICreateWorkFromHomeInput;
}


export interface IMutationDestroyMobilityEventArgs {
  input: IDestroyMobilityEventInput;
}


export interface IMutationDestroyMobilityEventsArgs {
  input: IDestroyMobilityEventsInput;
}


export interface IMutationDestroyProfileAddressArgs {
  input: IDestroyProfileAddressInput;
}


export interface IMutationFinishSharedCarReservationArgs {
  input: IFinishSharedCarReservationInput;
}


export interface IMutationFinishSharedVehicleReservationArgs {
  input: IFinishSharedVehicleReservationInput;
}


export interface IMutationLoginArgs {
  input: ILoginInput;
}


export interface IMutationLogoutArgs {
  input: ILogoutInput;
}


export interface IMutationMarkMessageAsReadArgs {
  input: IMarkMessageAsReadInput;
}


export interface IMutationMergeMobilityEventsArgs {
  input: IMergeMobilityEventsInput;
}


export interface IMutationRejectMobilityEventsArgs {
  input: IRejectMobilityEventsInput;
}


export interface IMutationReportPersonalMeansOfRegistrationArgs {
  input: IReportPersonalMeansOfRegistrationInput;
}


export interface IMutationRequestDonkeyRepublicFeatureArgs {
  input: IRequestDonkeyRepublicFeatureInput;
}


export interface IMutationRequestFeatureArgs {
  input: IRequestFeatureInput;
}


export interface IMutationRequestTrainClassChangeArgs {
  input: IRequestTrainClassChangeInput;
}


export interface IMutationResetPasswordArgs {
  input: IResetPasswordInput;
}


export interface IMutationReuploadPhotoArgs {
  input: IReuploadPhotoInput;
}


export interface IMutationSendNewInvitationLinkArgs {
  input: ISendNewInvitationLinkInput;
}


export interface IMutationSendPasswordResetMailArgs {
  input: ISendPasswordResetMailInput;
}


export interface IMutationSendPrivacyRequestMailArgs {
  input: ISendPrivacyRequestMailInput;
}


export interface IMutationSetAgreeTermsArgs {
  input: ISetAgreeTermsInput;
}


export interface IMutationStartSharedCarReservationArgs {
  input: IStartSharedCarReservationInput;
}


export interface IMutationStartSharedVehicleReservationArgs {
  input: IStartSharedVehicleReservationInput;
}


export interface IMutationTerminateOwnershipArgs {
  input: ITerminateOwnershipInput;
}


export interface IMutationTerminatePersonalMeansOfRegistrationArgs {
  input: ITerminatePersonalMeansOfRegistrationInput;
}


export interface IMutationTerminateReplacementManagerArgs {
  input: ITerminateReplacementManagerInput;
}


export interface IMutationTerminateVehicleArgs {
  input: ITerminateVehicleInput;
}


export interface IMutationUnacceptMobilityEventArgs {
  input: IUnacceptMobilityEventInput;
}


export interface IMutationUnacceptMobilityEventsArgs {
  input: IUnacceptMobilityEventsInput;
}


export interface IMutationUnconfirmMobilityEventArgs {
  input: IUnconfirmMobilityEventInput;
}


export interface IMutationUnconfirmMobilityEventsArgs {
  input: IUnconfirmMobilityEventsInput;
}


export interface IMutationUpdateMobilityEventArgs {
  input: IUpdateMobilityEventInput;
}


export interface IMutationUpdateMobilityEventsArgs {
  input: IUpdateMobilityEventsInput;
}


export interface IMutationUpdateTaxiReservationArgs {
  input: IUpdateTaxiReservationInput;
}


export interface IMutationUpdateUserProfileArgs {
  input: IUpdateUserProfileInput;
}

export interface INsRefundUrl {
  __typename?: 'NSRefundUrl';
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface INewProduct extends ITask {
  __typename?: 'NewProduct';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface INonCombined {
  __typename?: 'NonCombined';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface IOpeningHours {
  __typename?: 'OpeningHours';
  dayOfWeek?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
}

export interface IOptionsMutation {
  firstClassReason?: InputMaybe<IFirstClassReasonEnum>;
  fromStation?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  pincode?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<IPersonalMeansOfRegistrationServiceKindSubEnum>>;
  starValue?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<ISubscriptionEnum>;
  toStation?: InputMaybe<Scalars['String']['input']>;
  trainClass?: InputMaybe<ITrainClassEnum>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

export enum IOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface IOrganization {
  __typename?: 'Organization';
  budgetGroup?: Maybe<IBudgetGroup>;
  /** Customer service details for this organization */
  customerService: ICustomerService;
  distancePrecision?: Maybe<Scalars['Int']['output']>;
  /** Optional external client number to be used for Driven */
  externalClientNumber?: Maybe<Scalars['String']['output']>;
  /** Whether a final settlement is running for this organization */
  finalSettlementRunning?: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether there are active projects on the given date */
  hasActiveProjects?: Maybe<Scalars['Boolean']['output']>;
  /** The url (or host-relative path) of the icon for this organization */
  icon?: Maybe<Scalars['String']['output']>;
  /** Unique ID of the Organization */
  id: Scalars['ID']['output'];
  /** The url (or host-relative path) of the logo for this organization */
  logo?: Maybe<Scalars['String']['output']>;
  /** Whether postal codes are mandatory within this organization. */
  mandatoryLocationPostalCode?: Maybe<Scalars['Boolean']['output']>;
  minPasswordLength?: Maybe<Scalars['Int']['output']>;
  /** Name of the Organization */
  name: Scalars['String']['output'];
  /** The url that points to the policy document for this organization */
  policyDocumentUrl?: Maybe<Scalars['String']['output']>;
  /** The primary color of the organization */
  primaryColor?: Maybe<IColor>;
  /** Returns the active projects on the given date (the filter argument is deprecated, use on instead) */
  projects?: Maybe<Array<Maybe<IProject>>>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<IColor>;
  /**
   * The email address of the service desk
   * @deprecated Use fields from customerService instead
   */
  serviceEmailAddress: Scalars['String']['output'];
  /**
   * The url of the service desk
   * @deprecated Use fields from customerService instead
   */
  serviceHelpUrl?: Maybe<Scalars['String']['output']>;
  /**
   * The alias for the url service desk
   * @deprecated Use fields from customerService instead
   */
  serviceHelpUrlName: Scalars['String']['output'];
  /**
   * The phone number of the service desk
   * @deprecated Use fields from customerService instead
   */
  serviceTelephone: Scalars['String']['output'];
  /** The uuid of this organization */
  uuid?: Maybe<Scalars['String']['output']>;
}


export interface IOrganizationBudgetGroupArgs {
  id: Scalars['ID']['input'];
}


export interface IOrganizationHasActiveProjectsArgs {
  on?: InputMaybe<Scalars['Date']['input']>;
}


export interface IOrganizationProjectsArgs {
  filter?: InputMaybe<IProjectScope>;
  on?: InputMaybe<Scalars['Date']['input']>;
  textFilter?: InputMaybe<Scalars['String']['input']>;
}

export interface IOvFietsLocation {
  __typename?: 'OvFietsLocation';
  address?: Maybe<IStreetAddress>;
  availableBikes?: Maybe<Scalars['Int']['output']>;
  coordinates: ICoordinates;
  distance: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  open?: Maybe<Scalars['Boolean']['output']>;
  openingHours?: Maybe<Array<Maybe<IOpeningHours>>>;
  stationCode?: Maybe<Scalars['String']['output']>;
}

export interface IOvPas {
  __typename?: 'OvPas';
  allowedOvPasTypes: Array<IOvPasEnum>;
  /** Check if approval is required for the specified OV-pas type */
  approvalRequired: Scalars['Boolean']['output'];
}


export interface IOvPasApprovalRequiredArgs {
  type: IOvPasEnum;
}

export enum IOvPasEnum {
  OV_PAS_ANONYMOUS = 'OV_PAS_ANONYMOUS',
  OV_PAS_PERSONAL = 'OV_PAS_PERSONAL',
  OV_PAY = 'OV_PAY'
}

export enum IOwnershipEnum {
  COMMUNAL = 'COMMUNAL',
  COMPANY = 'COMPANY',
  EMPLOYER = 'EMPLOYER',
  LEASE = 'LEASE',
  PERSONAL = 'PERSONAL',
  RENTAL = 'RENTAL',
  REPLACEMENT = 'REPLACEMENT',
  RIDE_SHARING = 'RIDE_SHARING',
  SHARED = 'SHARED'
}

export interface IParking {
  __typename?: 'Parking';
  create?: Maybe<ICreateParkingOrderPayload>;
}


export interface IParkingCreateArgs {
  input: ICreateParkingOrderInput;
}

export interface IParkingOrder {
  __typename?: 'ParkingOrder';
  /** Actions that can be executed on this order */
  actions: IParkingOrderActions;
  /** DateTime at which this order was approved */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** DateTime at which this order was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Motivation given when this order was created */
  motivation?: Maybe<Scalars['String']['output']>;
  /** Status of this order */
  status: IParkingOrderStatus;
}

export interface IParkingOrderActions {
  __typename?: 'ParkingOrderActions';
  /** Whether this order is editable */
  editable: Scalars['Boolean']['output'];
}

export interface IParkingOrderStatus {
  __typename?: 'ParkingOrderStatus';
  /** Humanized and translated status */
  humanized: Scalars['String']['output'];
  /** Raw value of the status */
  value: IParkingOrderStatusEnum;
}

export enum IParkingOrderStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ORDERED = 'ORDERED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUEST_FAILED = 'REQUEST_FAILED'
}

export interface IPassword {
  __typename?: 'Password';
  /** Conditions for a valid password. */
  passwordConditions?: Maybe<Array<Scalars['String']['output']>>;
  /** Strength/validity of given password. */
  passwordStrength?: Maybe<IPasswordStrength>;
}


export interface IPasswordPasswordStrengthArgs {
  password: Scalars['String']['input'];
}

export interface IPasswordStrength {
  __typename?: 'PasswordStrength';
  valid?: Maybe<Scalars['Boolean']['output']>;
}

export interface IPendingApproval extends ITask {
  __typename?: 'PendingApproval';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IPermissions {
  __typename?: 'Permissions';
  /** List of addable modalties */
  addableVehicles: Array<IAddableVehiclePermission>;
  /** Whether the employee can login by qr code */
  allowLoginByQrCode: Scalars['Boolean']['output'];
  /**
   * Whether the employee has access to the amber feature
   * @deprecated Amber is no longer available
   */
  amberFeature: Scalars['Boolean']['output'];
  /** Whether the employee can do approvals */
  approvals: Scalars['Boolean']['output'];
  /**
   * Whether the employee can add a private car
   * @deprecated Use addable_vehicles instead
   */
  canAddPrivateCar: Scalars['Boolean']['output'];
  /**
   * Whether the employee can add a private electric car
   * @deprecated Use addable_vehicles instead
   */
  canAddPrivateElectricCar: Scalars['Boolean']['output'];
  /**
   * Whether the employee can add a private motorcycle
   * @deprecated Use addable_vehicles instead
   */
  canAddPrivateMotorcycle: Scalars['Boolean']['output'];
  /** Whether this employee can assign a replacement manager */
  canAssignReplacementManager: Scalars['Boolean']['output'];
  /** Whether the employee can create a new mobility card order */
  canCreateNewMobilityCardOrder: Scalars['Boolean']['output'];
  /** Whether this employee can remove a replacement manager */
  canRemoveReplacementManager: Scalars['Boolean']['output'];
  /**
   * Whether the employee can request the lease bike feature
   * @deprecated You can check this through the availableFeatures endpoint.
   */
  canRequestLeaseBikeFeature: Scalars['Boolean']['output'];
  /** Whether the employee can use GPS tracking */
  canUseGps: Scalars['Boolean']['output'];
  /** Whether the employee can view financial consequences */
  canViewFinancialConsequences: Scalars['Boolean']['output'];
  /** Whether the employee can view the lease bike feature */
  canViewLeaseBikeFeature: Scalars['Boolean']['output'];
  /** Whether the employee can view the mileages page */
  canViewMileages: Scalars['Boolean']['output'];
  /** whether the employee can change a ride from combined to non_combined or vice versa */
  changeCombinedRide: Scalars['Boolean']['output'];
  /** Whether the employee can manage commute compensation events made to him / her */
  manageCommuteCompensation: Scalars['Boolean']['output'];
  /** Whether the employee can add commute rides */
  manageCommuteRides: Scalars['Boolean']['output'];
  /** Whether the employee can manage (view and activate) mobility cards */
  manageMobilityCards: Scalars['Boolean']['output'];
  /** Whether the employee can add mobility events */
  manageMobilityEvents: Scalars['Boolean']['output'];
  /** Whether the employee can manage (view and request new password) parking services */
  manageParking: Scalars['Boolean']['output'];
  /** Whether the employee can manage work from home events made to him / her */
  manageWorkFromHome: Scalars['Boolean']['output'];
  /** Whether the employee can order mobility cards */
  orderMobilityCards: Scalars['Boolean']['output'];
  /** Whether the employee can order parking */
  orderParking: Scalars['Boolean']['output'];
  /** Whether OTP is configured for this employee */
  otpConfigured: Scalars['Boolean']['output'];
  /** Whether OTP configuration is required to login */
  otpRequiredForLogin: Scalars['Boolean']['output'];
  /** Whether the employee can receive reimbursements for business rides */
  reimburseBusinessRide: Scalars['Boolean']['output'];
  /** Whether the employee can receive reimbursements for commute rides */
  reimburseCommuteRide: Scalars['Boolean']['output'];
  /** Whether the employee has access to the rental car feature */
  rentalCarFeature: Scalars['Boolean']['output'];
  /** Whether the employee can request a direct debit mandate */
  requestDirectDebitMandate: Scalars['Boolean']['output'];
  /** Whether the employee can request the shared vehicle reservations feature */
  requestSharedVehicleFeature: Scalars['Boolean']['output'];
  /** Whether the employee can request split billing */
  requestSplitBillingContract: Scalars['Boolean']['output'];
  /** Whether the employee can request the taxi feature */
  requestTaxiFeature: Scalars['Boolean']['output'];
  /** Whether the employee has access to the route planner feature */
  routePlannerFeature: Scalars['Boolean']['output'];
  /** Whether the employee can select an other employment */
  selectEmployment: Scalars['Boolean']['output'];
  /** Whether the employee has access to the shared vehicle reservations feature */
  sharedVehicleFeature: Scalars['Boolean']['output'];
  /** Whether the employee has access to the taxi feature */
  taxiFeature: Scalars['Boolean']['output'];
  /** Whether the employee can unaccept mobility events */
  unacceptMobilityEvents: Scalars['Boolean']['output'];
  /** Whether the employee can unconfirm mobility events */
  unconfirmMobilityEvents: Scalars['Boolean']['output'];
  /** Whether the employee can view direct debit invoices */
  viewDirectDebitInvoices: Scalars['Boolean']['output'];
  /**
   * Whether the employee can view payments made to him / her
   * @deprecated Use view_direct_debit_invoices instead
   */
  viewPayments: Scalars['Boolean']['output'];
  /** Whether the employee can view split billing invoices */
  viewSplitBillingInvoices: Scalars['Boolean']['output'];
}

export interface IPersonalMeansOfRegistration {
  __typename?: 'PersonalMeansOfRegistration';
  /** Abilities that an employee has for this personal mean of registration */
  abilities: IPersonalMeansOfRegistrationAbilities;
  /** All services that are available for this personal mean of registration. Also contains the currently active services */
  availableServices?: Maybe<Array<IAvailableServiceInterface>>;
  /** Date when this personal mean of registration ends */
  endsOn: IPersonalMeansOfRegistrationEndsOn;
  /** Internal identifier for this personal mean of registration */
  id: Scalars['ID']['output'];
  /** Public identifier for this personal mean of registration (for example a OV card number or Yellowbrick username) */
  identity?: Maybe<IPersonalMeansOfRegistrationIdentity>;
  /** Kind of this personal mean of registration */
  kind: IPersonalMeansOfRegistrationKind;
  /** Reasons why photo is rejected, only contains reasons if status is photo_disapproved */
  photoRejectedReasons?: Maybe<Array<IPersonalMeansOfRegistrationPhotoRejectedReason>>;
  /** Is replacement of this card requested */
  replacementRequested?: Maybe<Scalars['Boolean']['output']>;
  /**
   * All services that are currently active for this personal mean of registration
   * @deprecated Use available_services instead, those can be filtered on active or inactive
   */
  services?: Maybe<Array<IPersonalMeansOfRegistrationService>>;
  /** Status of this personal mean of registration */
  status?: Maybe<IPersonalMeansOfRegistrationStatus>;
}


export interface IPersonalMeansOfRegistrationAvailableServicesArgs {
  filterBy?: InputMaybe<IPersonalMeansOfRegistrationServiceStatusFilterEnum>;
}

export interface IPersonalMeansOfRegistrationAbilities {
  __typename?: 'PersonalMeansOfRegistrationAbilities';
  /** Whether the personal mean of registration can be activated */
  activatable: Scalars['Boolean']['output'];
  /** Whether the personal mean of registration can be reported lost or stolen */
  lostOrStolenReportable: Scalars['Boolean']['output'];
  /** Whether the personal mean of registration can be terminated */
  terminatable: Scalars['Boolean']['output'];
}

export interface IPersonalMeansOfRegistrationEndsOn {
  __typename?: 'PersonalMeansOfRegistrationEndsOn';
  humanized: Scalars['String']['output'];
}

export interface IPersonalMeansOfRegistrationIdentity {
  __typename?: 'PersonalMeansOfRegistrationIdentity';
  humanized?: Maybe<Scalars['String']['output']>;
}

export interface IPersonalMeansOfRegistrationKind {
  __typename?: 'PersonalMeansOfRegistrationKind';
  humanized: Scalars['String']['output'];
  main: IPersonalMeansOfRegistrationKindMainEnum;
  sub?: Maybe<IPersonalMeansOfRegistrationKindSubEnum>;
}

export enum IPersonalMeansOfRegistrationKindMainEnum {
  ELECTRIC_CHARGING_DONGLE = 'electric_charging_dongle',
  FUEL_CARD = 'fuel_card',
  GPS = 'gps',
  MANUAL = 'manual',
  MOBILITY_CARD = 'mobility_card',
  REISBALANS = 'reisbalans',
  SEMI_MANUAL = 'semi_manual',
  TAXI_ID = 'taxi_id',
  TELEMATICA = 'telematica'
}

export enum IPersonalMeansOfRegistrationKindSubEnum {
  DRIVEN_ZOVC = 'driven_zovc',
  DRIVEN_ZOVC_DKV = 'driven_zovc_dkv',
  I_TRAVEL_DEELKAART = 'i_travel_deelkaart',
  I_TRAVEL_DEELKAART_LEGACY = 'i_travel_deelkaart_legacy',
  I_TRAVEL_PREPAID_ZOVC = 'i_travel_prepaid_zovc',
  I_TRAVEL_PREPAID_ZOVC_LEGACY = 'i_travel_prepaid_zovc_legacy',
  I_TRAVEL_ZOVC = 'i_travel_zovc',
  I_TRAVEL_ZOVC_DKV = 'i_travel_zovc_dkv',
  I_TRAVEL_ZOVC_LEGACY = 'i_travel_zovc_legacy',
  I_TRAVEL_ZOVC_TANKEN = 'i_travel_zovc_tanken',
  MOBILITEITS_MANAGER_DEELKAART = 'mobiliteits_manager_deelkaart',
  MOBILITEITS_MANAGER_DEELKAART_LEGACY = 'mobiliteits_manager_deelkaart_legacy',
  MOBILITEITS_MANAGER_PREPAID_ZOVC = 'mobiliteits_manager_prepaid_zovc',
  MOBILITEITS_MANAGER_ZOVC = 'mobiliteits_manager_zovc',
  MOBILITEITS_MANAGER_ZOVC_DKV = 'mobiliteits_manager_zovc_dkv',
  MOBILITEITS_MANAGER_ZOVC_DKV_LEGACY = 'mobiliteits_manager_zovc_dkv_legacy',
  MOBILITEITS_MANAGER_ZOVC_LEGACY = 'mobiliteits_manager_zovc_legacy',
  MOBILITEITS_MANAGER_ZOVC_TANKEN = 'mobiliteits_manager_zovc_tanken',
  MOBILITEITS_MANAGER_ZOVC_TANKEN_LEGACY = 'mobiliteits_manager_zovc_tanken_legacy',
  NOC_NSF_ZOVC = 'noc_nsf_zovc',
  NS_BUSINESS_CARD = 'ns_business_card',
  QBUZZ_DEELKAART = 'qbuzz_deelkaart',
  QBUZZ_ZOVC = 'qbuzz_zovc',
  RB_ENECO = 'rb_eneco',
  REISBALANS_ANONYMOUS_ZOVC = 'reisbalans_anonymous_zovc',
  REISBALANS_DEELKAART = 'reisbalans_deelkaart',
  REISBALANS_UNLIMITED = 'reisbalans_unlimited',
  REISBALANS_ZOVC = 'reisbalans_zovc',
  REISBALANS_ZOVC_DKV = 'reisbalans_zovc_dkv',
  REISBALANS_ZOVC_TANKEN = 'reisbalans_zovc_tanken',
  RIJKSOVERHEID_ANONYMOUS_ZOVC = 'rijksoverheid_anonymous_zovc',
  RIJKSOVERHEID_ANONYMOUS_ZOVC_V2 = 'rijksoverheid_anonymous_zovc_v2',
  RIJKSOVERHEID_NSBC = 'rijksoverheid_nsbc',
  RIJKSOVERHEID_V2_ZOVC_HANDMATIGE_VERZENDING = 'rijksoverheid_v2_zovc_handmatige_verzending',
  RIJKSOVERHEID_ZOVC = 'rijksoverheid_zovc',
  RIJKSOVERHEID_ZOVC_HANDMATIGE_VERZENDING = 'rijksoverheid_zovc_handmatige_verzending',
  RIJKSOVERHEID_ZOVC_V2 = 'rijksoverheid_zovc_v2',
  RYDER_ANONYMOUS_ZOVC = 'ryder_anonymous_zovc',
  RYDER_DEELKAART = 'ryder_deelkaart',
  RYDER_ZOVC = 'ryder_zovc',
  SHUTTEL_ZOVC = 'shuttel_zovc',
  SHUTTEL_ZOVC_DKV = 'shuttel_zovc_dkv',
  SHUTTEL_ZOVC_TANKEN = 'shuttel_zovc_tanken',
  UOV_DEELKAART = 'uov_deelkaart',
  UOV_ZOVC = 'uov_zovc',
  UTRECHT_REGION_PASS = 'utrecht_region_pass',
  VOLKSWAGEN_LEASING_COMBIKAART = 'volkswagen_leasing_combikaart',
  VOLKSWAGEN_LEASING_ZAKELIJKE_OV_CHIPKAART = 'volkswagen_leasing_zakelijke_ov_chipkaart',
  WITTEBRUG_ANONYMOUS_ZOVC = 'wittebrug_anonymous_zovc',
  WITTEBRUG_DEELKAART = 'wittebrug_deelkaart',
  WITTEBRUG_ZOVC = 'wittebrug_zovc',
  YELLOWBRICK_PRODUCT = 'yellowbrick_product',
  ZUIDAS_DEELKAART = 'zuidas_deelkaart',
  ZUIDAS_DEELKAART_LEGACY = 'zuidas_deelkaart_legacy',
  ZUIDAS_ZOVC = 'zuidas_zovc'
}

export interface IPersonalMeansOfRegistrationMetaStatus {
  __typename?: 'PersonalMeansOfRegistrationMetaStatus';
  humanized: Scalars['String']['output'];
  value: IPersonalMeansOfRegistrationMetaStatusEnum;
}

export enum IPersonalMeansOfRegistrationMetaStatusEnum {
  IN_USE = 'in_use',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  REQUIRES_ACTION = 'requires_action'
}

export interface IPersonalMeansOfRegistrationPhotoRejectedReason {
  __typename?: 'PersonalMeansOfRegistrationPhotoRejectedReason';
  humanized: Scalars['String']['output'];
  value: IPersonalMeansOfRegistrationPhotoRejectedReasonEnum;
}

export enum IPersonalMeansOfRegistrationPhotoRejectedReasonEnum {
  EYES_NOT_VISIBLE = 'eyes_not_visible',
  FACE_NOT_ENTIRELY_VISIBLE = 'face_not_entirely_visible',
  NOT_ENOUGH_CONTRAST = 'not_enough_contrast',
  NOT_JUST_FACE = 'not_just_face',
  NOT_SHARP_ENOUGH = 'not_sharp_enough',
  REFLECTION = 'reflection',
  TOO_DARK = 'too_dark',
  TOO_LIGHT = 'too_light',
  TOO_SMALL = 'too_small'
}

export enum IPersonalMeansOfRegistrationReport {
  LOST_OR_STOLEN = 'LOST_OR_STOLEN'
}

export interface IPersonalMeansOfRegistrationService {
  __typename?: 'PersonalMeansOfRegistrationService';
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  id: Scalars['ID']['output'];
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  kind: IPersonalMeansOfRegistrationServiceKind;
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  trainClass?: Maybe<ITrainClass>;
}

export interface IPersonalMeansOfRegistrationServiceEndsOn {
  __typename?: 'PersonalMeansOfRegistrationServiceEndsOn';
  humanized: Scalars['String']['output'];
}

export interface IPersonalMeansOfRegistrationServiceIdentity {
  __typename?: 'PersonalMeansOfRegistrationServiceIdentity';
  humanized: Scalars['String']['output'];
}

export interface IPersonalMeansOfRegistrationServiceKind {
  __typename?: 'PersonalMeansOfRegistrationServiceKind';
  humanized: Scalars['String']['output'];
  main: Scalars['String']['output'];
  sub: IPersonalMeansOfRegistrationServiceKindSubEnum;
}

export enum IPersonalMeansOfRegistrationServiceKindSubEnum {
  ALTIJD_VOORDEEL = 'altijd_voordeel',
  DAL = 'dal',
  DAL_VOORDEEL = 'dal_voordeel',
  DAL_VRIJ = 'dal_vrij',
  DKV = 'dkv',
  GREENWHEELS = 'greenwheels',
  GVB_ONLY = 'gvb_only',
  GVB_RANDSTAD_NOORD_ZONE = 'gvb_randstad_noord_zone',
  GVB_ZONE = 'gvb_zone',
  NS_PR = 'ns_pr',
  OV_FIETS = 'ov_fiets',
  OV_VRIJ = 'ov_vrij',
  PR = 'pr',
  QBUZZ_REGIO = 'qbuzz_regio',
  QBUZZ_REGIO_TREIN = 'qbuzz_regio_trein',
  RAC = 'rac',
  RAZ = 'raz',
  REIZEN_OP_REKENING = 'reizen_op_rekening',
  REIZEN_OP_SALDO = 'reizen_op_saldo',
  RET_NET = 'ret_net',
  RET_ONLY = 'ret_only',
  RET_REGIO = 'ret_regio',
  ROS_MOB = 'ros_mob',
  TAXI = 'taxi',
  TRAJECT_OP_MAAT = 'traject_op_maat',
  TRAJECT_VRIJ = 'traject_vrij',
  TRAVEL_CARD = 'travel_card',
  TREIN_VRIJ = 'trein_vrij',
  UOV_GEMAK_REGIO = 'uov_gemak_regio',
  UOV_GEMAK_STAD = 'uov_gemak_stad',
  UOV_STER = 'uov_ster',
  UOV_VRIJ_REGIO = 'uov_vrij_regio',
  UOV_VRIJ_STAD = 'uov_vrij_stad',
  YELLOWBRICK_PR = 'yellowbrick_pr',
  YELLOWBRICK_SP = 'yellowbrick_sp'
}

export interface IPersonalMeansOfRegistrationServiceStatus {
  __typename?: 'PersonalMeansOfRegistrationServiceStatus';
  /** New value field with enum values */
  enumValue: IPersonalMeansOfRegistrationServiceStatusFilterEnum;
  humanized: Scalars['String']['output'];
  /** Metastatus of this status */
  metaStatus?: Maybe<IPersonalMeansOfRegistrationMetaStatus>;
  /** @deprecated Deprecated in favor of EnumValue field */
  value: Scalars['String']['output'];
}

export enum IPersonalMeansOfRegistrationServiceStatusFilterEnum {
  ACTIVATION_EXPIRED = 'activation_expired',
  ACTIVATION_REQUESTED = 'activation_requested',
  ACTIVE = 'active',
  BEING_TERMINATED = 'being_terminated',
  ERROR = 'error',
  IN_USE = 'in_use',
  INACTIVE = 'inactive',
  INITIALIZED = 'initialized',
  MANUFACTURED = 'manufactured',
  NOT_REQUESTED = 'not_requested',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_GREENWHEELS_ACTIVATION = 'pending_greenwheels_activation',
  PENDING_GREENWHEELS_REGISTRATION = 'pending_greenwheels_registration',
  PHOTO_DISAPPROVED = 'photo_disapproved',
  REJECTED = 'rejected',
  REPORTED_STOLEN = 'reported_stolen',
  REQUESTED = 'requested',
  SHIPPED = 'shipped',
  TERMINATED = 'terminated',
  UNCOLLECTED_PRODUCTS = 'uncollected_products'
}

export interface IPersonalMeansOfRegistrationStatus {
  __typename?: 'PersonalMeansOfRegistrationStatus';
  humanized: Scalars['String']['output'];
  value: IPersonalMeansOfRegistrationStatusEnum;
}

export enum IPersonalMeansOfRegistrationStatusEnum {
  ACTIVATION_EXPIRED = 'activation_expired',
  ACTIVATION_REQUESTED = 'activation_requested',
  BEING_TERMINATED = 'being_terminated',
  ERROR = 'error',
  IN_USE = 'in_use',
  INITIALIZED = 'initialized',
  MANUFACTURED = 'manufactured',
  NOT_REQUESTED = 'not_requested',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_GREENWHEELS_ACTIVATION = 'pending_greenwheels_activation',
  PENDING_GREENWHEELS_REGISTRATION = 'pending_greenwheels_registration',
  PHOTO_DISAPPROVED = 'photo_disapproved',
  REJECTED = 'rejected',
  REPORTED_STOLEN = 'reported_stolen',
  REQUESTED = 'requested',
  SHIPPED = 'shipped',
  TERMINATED = 'terminated',
  UNCOLLECTED_PRODUCTS = 'uncollected_products'
}

export enum IPersonalMeansOfRegistrationStatusFilterEnum {
  ACTIVATABLE = 'activatable',
  ACTIVATION_EXPIRED = 'activation_expired',
  ACTIVATION_REQUESTED = 'activation_requested',
  ACTIVE = 'active',
  BEING_TERMINATED = 'being_terminated',
  ERROR = 'error',
  IN_USE = 'in_use',
  INACTIVE = 'inactive',
  INITIALIZED = 'initialized',
  MANUFACTURED = 'manufactured',
  NOT_REQUESTED = 'not_requested',
  PENDING_ACTIVATION = 'pending_activation',
  PENDING_GREENWHEELS_ACTIVATION = 'pending_greenwheels_activation',
  PENDING_GREENWHEELS_REGISTRATION = 'pending_greenwheels_registration',
  PHOTO_DISAPPROVED = 'photo_disapproved',
  REJECTED = 'rejected',
  REPORTED_STOLEN = 'reported_stolen',
  REQUESTED = 'requested',
  SHIPPED = 'shipped',
  TERMINATED = 'terminated',
  UNCOLLECTED_PRODUCTS = 'uncollected_products',
  UNKNOWN = 'unknown'
}

export interface IPhoto {
  avatar: IAttachment;
  cropH?: InputMaybe<Scalars['String']['input']>;
  cropW?: InputMaybe<Scalars['String']['input']>;
  cropX?: InputMaybe<Scalars['String']['input']>;
  cropY?: InputMaybe<Scalars['String']['input']>;
}

/** Suggest locations */
export interface IPlaces {
  __typename?: 'Places';
  /** returns the details given a specific place id */
  details?: Maybe<IPlacesDetailsResult>;
  /** returns a list of places that starts with .. */
  suggest?: Maybe<Array<IPlacesResult>>;
}


/** Suggest locations */
export interface IPlacesDetailsArgs {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  placeId: Scalars['String']['input'];
}


/** Suggest locations */
export interface IPlacesSuggestArgs {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  startsWith: Scalars['String']['input'];
}

export interface IPlacesDetailsResult {
  __typename?: 'PlacesDetailsResult';
  /** A formatted address of the location */
  address: Scalars['String']['output'];
  /** City address component */
  city?: Maybe<Scalars['String']['output']>;
  /** Contains the longitude and latitude of the location */
  coordinates: ICoordinates;
  /** Country ISO Code address component */
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  /** Reference to stored location with a cached google or tomtom details */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The name of the location */
  name: Scalars['String']['output'];
  /** The TomTom Place ID that you'd like to get the details from. */
  placeId: Scalars['String']['output'];
  /** Postal code address component */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Street address component */
  street?: Maybe<Scalars['String']['output']>;
  /** Street number address component */
  streetNumber?: Maybe<Scalars['String']['output']>;
  /** When related location cannot be resolved or created because of missing data from google_places, an error message is provided. */
  validationErrors: Array<IValidationError>;
}

export interface IPlacesResult {
  __typename?: 'PlacesResult';
  /** A HTML enriched string containing the name of the location */
  html: Scalars['String']['output'];
  /** The TomTom Place ID which is needed if you want to retrieve the details if this place */
  placeId: Scalars['String']['output'];
  /** Structured formatting of the suggested text */
  structuredText: IPlacesStructuredText;
  /** A plain text string containing the name of the location */
  text: Scalars['String']['output'];
  /** The location type and icon */
  type?: Maybe<IPlacesTypeValue>;
}

export interface IPlacesStructuredText {
  __typename?: 'PlacesStructuredText';
  /** Main text of the suggestion, usually the name of the place */
  main?: Maybe<Scalars['String']['output']>;
  /** Secondary text of the suggestion, usually the location of the place */
  secondary?: Maybe<Scalars['String']['output']>;
}

/** TomTom places type */
export interface IPlacesTypeValue {
  __typename?: 'PlacesTypeValue';
  /** Icon name for type */
  icon?: Maybe<IIconEnum>;
  /** The location type */
  value: IPlacesTypesEnum;
}

/** TomTom places types enum */
export enum IPlacesTypesEnum {
  AIRPORT = 'airport',
  OPEN_AREA_PARKING = 'open_area_parking',
  PARKING_GARAGE = 'parking_garage',
  PETROL_STATION = 'petrol_station',
  PUBLIC_TRANSPORT_STOP = 'public_transport_stop',
  RAILWAY_STATION = 'railway_station',
  UNKNOWN = 'unknown'
}

export interface IPossibleReplacementManager {
  __typename?: 'PossibleReplacementManager';
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
}

export enum IPreferredJourneyModeEnum {
  BIKE = 'bike',
  BUS = 'bus',
  CAR = 'car',
  FERRY = 'ferry',
  METRO = 'metro',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram'
}

export enum IPreferredModalityEnum {
  BUS = 'bus',
  METRO = 'metro',
  TRAIN = 'train',
  TRAM = 'tram'
}

export interface IPrice {
  __typename?: 'Price';
  amount: IMoney;
  currency: Scalars['String']['output'];
}

export interface IPrivacy {
  __typename?: 'Privacy';
  /** More information about the privacy type */
  description?: Maybe<Scalars['String']['output']>;
  /** Humanized name for the privacy type */
  humanized?: Maybe<Scalars['String']['output']>;
  /** Optional clarifications for the request */
  reasons?: Maybe<Array<IPrivacyRequestReason>>;
  /** Privacy type value */
  value?: Maybe<IPrivacyTypeEnum>;
}

export interface IPrivacyRequestReason {
  __typename?: 'PrivacyRequestReason';
  description: Scalars['String']['output'];
  reason?: Maybe<IPrivacyRequestReasonEnum>;
  title: Scalars['String']['output'];
  /** @deprecated Deprecated in favor of PrivacyRequestReasonEnum reason field */
  value?: Maybe<IRightToBeForgottenReasonEnum>;
}

export enum IPrivacyRequestReasonEnum {
  CONSENT_WITHDRAWN = 'consent_withdrawn',
  LAWFUL_RETENTION_PERIOD_EXPIRED = 'lawful_retention_period_expired',
  NO_LONGER_NECESSARY = 'no_longer_necessary',
  OBJECTS_TO_PROCESSING = 'objects_to_processing',
  POSSIBLY_INCORRECT = 'possibly_incorrect',
  UNLAWFULLY_PROCESSED = 'unlawfully_processed'
}

export enum IPrivacyTypeEnum {
  RIGHT_TO_ACCESS = 'right_to_access',
  RIGHT_TO_BE_FORGOTTEN = 'right_to_be_forgotten',
  RIGHT_TO_DATA_PORTABILITY = 'right_to_data_portability',
  RIGHT_TO_OBJECT = 'right_to_object',
  RIGHT_TO_OBTAIN_HUMAN_INTERVENTION = 'right_to_obtain_human_intervention',
  RIGHT_TO_RECTIFICATION = 'right_to_rectification',
  RIGHT_TO_RESTRICTION_OF_PROCESSING = 'right_to_restriction_of_processing'
}

export interface IProfile {
  __typename?: 'Profile';
  /** The date since the employee has been active */
  activeSince: Scalars['Date']['output'];
  /** The url pointing to the avatar of this employee if they have one */
  avatar?: Maybe<Scalars['String']['output']>;
  /** Date of birth of this employee */
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** Preferred purpose of supplier transactions */
  defaultMobilityCardTransactionTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** Preferred purpose of supplier transactions on weekends */
  defaultMobilityCardWeekendTransactionTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** The email address of this employee */
  email: Scalars['String']['output'];
  /** Employee number used within the organization for this employee */
  employeeNumber?: Maybe<Scalars['String']['output']>;
  /** The first name of this employee */
  firstName: Scalars['String']['output'];
  /** home address of the current employee, with full address details */
  homeAddress?: Maybe<IEmployeeAddress>;
  /** The infix of this employee, like : 'de, van, van der, etc' */
  infix?: Maybe<Scalars['String']['output']>;
  /** The initials of this employee */
  initials?: Maybe<Scalars['String']['output']>;
  /** Flag to determine whether this employee is a manager */
  isManager: Scalars['Boolean']['output'];
  /** The last name of this employee */
  lastName: Scalars['String']['output'];
  /** The phone number of this employee */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Preferred purpose of GPS registered rides */
  preferredTravelPurpose?: Maybe<ITravelPurposeEnum>;
  /** A list of AVG (GDPR) privacy request types */
  privacyTypes?: Maybe<Array<IPrivacy>>;
  /** A list of AVG (GDPR) right to be forgotten request reasons */
  rightToBeForgottenReasons?: Maybe<Array<IRightToBeForgottenReason>>;
  /** Whether or not the employee opted in to use the new interface */
  usesNewInterface?: Maybe<Scalars['Boolean']['output']>;
  /** work address of the current employee, with full address details */
  workAddress?: Maybe<IEmployeeAddress>;
}

export interface IProfileMutation {
  dateOfBirth: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  homeAddress: IEmployeeAddressMutation;
  /** The infix of an employee, like : 'de, van, van der, etc' */
  infix?: InputMaybe<Scalars['String']['input']>;
  initials: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}

export interface IProject {
  __typename?: 'Project';
  active: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  endsOn?: Maybe<Scalars['Date']['output']>;
  humanized: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  startsOn: Scalars['Date']['output'];
}

export enum IProjectScope {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface IPublicTransportStop extends IMultiModalAvailability {
  __typename?: 'PublicTransportStop';
  deeplink?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  /** The services available at this stop (e.g. which kind of trains for a train station or the departing lines for tram or bus stops) */
  services?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
}

export interface IPurpose {
  __typename?: 'Purpose';
  /** A breakdown of the total value */
  details?: Maybe<Array<IStats>>;
  /** The total CO2 emission emitted during the travelled distance */
  totalCo2EmissionInGrams?: Maybe<Scalars['Int']['output']>;
  /** The total distance in meters */
  totalDistanceInMeters?: Maybe<Scalars['Int']['output']>;
}

export interface IQuantity {
  __typename?: 'Quantity';
  amount: Scalars['Int']['output'];
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  unit: IUnitEnum;
}

export interface IQuery {
  __typename?: 'Query';
  MobilityServices: IMobilityServicesQuery;
  /** Convert the Base64 encoded data string, generated by reading the QR code of a user profile from the portal, to a valid apiToken */
  apiTokenForQrCode?: Maybe<IResultWithAccessTokens>;
  configurationProfileForHost?: Maybe<IConfigurationProfileForHost>;
  currentTime: Scalars['DateTime']['output'];
  customerService?: Maybe<ICustomerService>;
  employeeDetails?: Maybe<IEmployeeDetailsResponse>;
  /** All external URLs used throughout the application */
  externalUrls: IExternalUrls;
  /** Fuel types that can be used for vehicle */
  fuelTypes?: Maybe<Array<IFuel>>;
  getUserByInviteCode?: Maybe<IEmployee>;
  googlePlaces?: Maybe<IGooglePlaces>;
  inviteTokenValid?: Maybe<Scalars['Boolean']['output']>;
  /**
   * If you are logged in and supply a httponly cookie, you can request a JWT here.
   * @deprecated Use reauthorize field instead
   */
  jwt?: Maybe<Scalars['String']['output']>;
  me?: Maybe<IEmployee>;
  /**
   * Returns the url where refund can be claimed from NS for a delayed train journey
   * @deprecated Use externalUrls.nsRefundUrl instead
   */
  nsRefundUrl: INsRefundUrl;
  ovFietsLocationsByCoordinates?: Maybe<Array<IOvFietsLocation>>;
  ovFietsLocationsByFavoriteId?: Maybe<Array<IOvFietsLocation>>;
  password?: Maybe<IPassword>;
  places?: Maybe<IPlaces>;
  /** If you are logged in and supply a httponly cookie, you can request a pair of access and refresh tokens here. */
  reauthorize?: Maybe<IAuth>;
  reisbalans: IReisbalansQuery;
  reisbalansV2: IReisbalansV2Query;
  /** Returns wether SSO is enabled or not and an authentication url */
  ssoEnabled?: Maybe<ISsoEnabled>;
  /** All NS Stations */
  stations?: Maybe<Array<IStation>>;
  supportedVersion?: Maybe<ISupportedVersion>;
  /** Terms documents for the current platform and brand. */
  termsAndConditionsDocuments?: Maybe<Array<ITermsAndConditionsDocument>>;
  theme?: Maybe<ITheme>;
  /** @deprecated Only use in the app to migrate. */
  tokens?: Maybe<IAuth>;
  vehicleInformation?: Maybe<IVehicleInformation>;
}


export interface IQueryApiTokenForQrCodeArgs {
  data: Scalars['String']['input'];
  tokenType?: InputMaybe<ITokenTypeEnum>;
}


export interface IQueryConfigurationProfileForHostArgs {
  host: Scalars['String']['input'];
}


export interface IQueryCustomerServiceArgs {
  host?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}


export interface IQueryEmployeeDetailsArgs {
  key: Scalars['String']['input'];
}


export interface IQueryGetUserByInviteCodeArgs {
  email: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
}


export interface IQueryInviteTokenValidArgs {
  inviteToken: Scalars['String']['input'];
}


export interface IQueryJwtArgs {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
}


export interface IQueryOvFietsLocationsByCoordinatesArgs {
  at: ICoordinatesMutation;
  radius: Scalars['Int']['input'];
}


export interface IQueryOvFietsLocationsByFavoriteIdArgs {
  id: Scalars['ID']['input'];
  radius: Scalars['Int']['input'];
}


export interface IQueryReauthorizeArgs {
  refreshToken: Scalars['String']['input'];
}


export interface IQuerySsoEnabledArgs {
  username: Scalars['String']['input'];
}


export interface IQueryThemeArgs {
  uuid: Scalars['String']['input'];
}


export interface IQueryVehicleInformationArgs {
  licensePlate: Scalars['String']['input'];
}

export interface IQuote {
  __typename?: 'Quote';
  endTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  legs: Array<ITranzerLeg>;
  /** Required fields in the booking request in order to book this quote. */
  requiredBookingFields?: Maybe<Array<Scalars['String']['output']>>;
  startTime: Scalars['DateTime']['output'];
  status: IQuoteStatus;
  totalCosts: IMoney;
  /** Total duration in seconds. */
  totalDuration: Scalars['Int']['output'];
  validUntil: Scalars['DateTime']['output'];
}

export interface IQuoteStatus {
  __typename?: 'QuoteStatus';
  humanized: Scalars['String']['output'];
  value: IQuoteStatusEnum;
}

export enum IQuoteStatusEnum {
  PROPOSED = 'PROPOSED',
  UNBOOKABLE = 'UNBOOKABLE'
}

export interface IReceipt {
  __typename?: 'Receipt';
  contentType: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
}

export interface IRecommendedCard {
  __typename?: 'RecommendedCard';
  /** Humanized and translated recommended card */
  humanized: Scalars['String']['output'];
  /** Raw value of the recommended card */
  value: IPersonalMeansOfRegistrationKindSubEnum;
}

/** Autogenerated input type of RefreshTranzerReservation */
export interface IRefreshTranzerReservationInput {
  /** Mobidesk booking ID */
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of RefreshTranzerReservation. */
export interface IRefreshTranzerReservationPayload {
  __typename?: 'RefreshTranzerReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  tranzerReservation?: Maybe<ITranzerReservation>;
}

export interface IRefundToBeLinked extends ITask {
  __typename?: 'RefundToBeLinked';
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  mobilityEventId?: Maybe<Scalars['ID']['output']>;
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export interface IReimbursableCategory {
  __typename?: 'ReimbursableCategory';
  category: ICategory;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modality?: Maybe<IModality>;
}

export interface IReimbursableCategoryMethod {
  __typename?: 'ReimbursableCategoryMethod';
  humanized: Scalars['String']['output'];
  value: IReimbursableCategoryMethodEnum;
}

export enum IReimbursableCategoryMethodEnum {
  BOTH = 'BOTH',
  COST = 'COST',
  ROUTE = 'ROUTE'
}

export interface IReimbursementRule {
  budgetGroupId: Scalars['ID']['output'];
  category: ICategory;
  configuration: IConfiguration;
  fiscality: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  main: IMainCategory;
  modality?: Maybe<IModality>;
  reimbursableCategory: IReimbursableCategory;
  sub?: Maybe<ISubCategory>;
  travelPurpose: Scalars['String']['output'];
}

/** Autogenerated input type of RejectMobilityEvents */
export interface IRejectMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
  rejectReason: Scalars['String']['input'];
}

/** Autogenerated return type of RejectMobilityEvents. */
export interface IRejectMobilityEventsPayload {
  __typename?: 'RejectMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rejectedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  unrejectedMobilityEvents: Array<IMobilityEvent>;
}

/** Autogenerated input type of RemoveFromWhitelist */
export interface IRemoveFromWhitelistInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardId: Scalars['ID']['input'];
}

/** Autogenerated return type of RemoveFromWhitelist. */
export interface IRemoveFromWhitelistPayload {
  __typename?: 'RemoveFromWhitelistPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  studentTravelRequestDates: IRequestDates;
  validationErrors: Array<IValidationError>;
}

export interface IRentalCar extends IAvailableFeatureInterface {
  __typename?: 'RentalCar';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  /**
   * This is only available when the request for this feature has been approved and the employee is allowed to order a rental car
   * @deprecated Use the urls endpoint instead
   */
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IRentalLocations {
  __typename?: 'RentalLocations';
  pickup: ILocation;
  pickupLabel: Scalars['String']['output'];
  return: ILocation;
  returnLabel: Scalars['String']['output'];
}

export interface IReplacementManager {
  __typename?: 'ReplacementManager';
  /** End of the period when this manager can approve */
  approvalPeriodEnd?: Maybe<Scalars['Date']['output']>;
  /** Start of the period when this manager can approve */
  approvalPeriodStart: Scalars['Date']['output'];
  /** Email of the temporary replacement manager */
  email: Scalars['String']['output'];
  /** Email of the manager that is being replaced */
  emailApprovalManager: Scalars['String']['output'];
  /** Name of the temporary replacement manager */
  fullName: Scalars['String']['output'];
  /** Name of the manager that is being replaced */
  fullNameApprovalManager: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Label for showing if manager is active or not */
  label?: Maybe<ILabel>;
  /** ID of the employee who this manager is */
  managerId: Scalars['ID']['output'];
}

/** Autogenerated input type of ReportPersonalMeansOfRegistration */
export interface IReportPersonalMeansOfRegistrationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the personal means of registration (mobility card) */
  id: Scalars['ID']['input'];
  /** Indicates whether a replacement card should be ordered, defaults to true */
  needReplacement?: InputMaybe<Scalars['Boolean']['input']>;
  /** DEPRECATED, this mutation will only be used to report cards as lost/stolen */
  reportType?: InputMaybe<IPersonalMeansOfRegistrationReport>;
}

/** Autogenerated return type of ReportPersonalMeansOfRegistration. */
export interface IReportPersonalMeansOfRegistrationPayload {
  __typename?: 'ReportPersonalMeansOfRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  personalMeansOfRegistration: IPersonalMeansOfRegistration;
  result: IResult;
}

/** Studentenreisproduct is an external managed product provided to NOC*NSF students using a (labeled) NS businesscard */
export interface IRequestDates {
  __typename?: 'RequestDates';
  /** Date of successful removal from studentenreisproduct whitelist */
  studentTravelAddedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date of successful adding to studentenreisproduct whitelist */
  studentTravelRemovedAt?: Maybe<Scalars['DateTime']['output']>;
}

/** Autogenerated input type of RequestDonkeyRepublicFeature */
export interface IRequestDonkeyRepublicFeatureInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  termsAndConditionsDocumentIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of RequestDonkeyRepublicFeature. */
export interface IRequestDonkeyRepublicFeaturePayload {
  __typename?: 'RequestDonkeyRepublicFeaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result?: Maybe<IResult>;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of RequestFeature */
export interface IRequestFeatureInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feature: IFeatureOptionEnum;
  motivation?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsDocumentIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of RequestFeature. */
export interface IRequestFeaturePayload {
  __typename?: 'RequestFeaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result?: Maybe<IResult>;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of RequestService */
export interface IRequestServiceInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of mobility card on which this service is requested */
  mobilityCardId?: InputMaybe<Scalars['ID']['input']>;
  /** Motivation for requesting these services */
  motivation?: InputMaybe<Scalars['String']['input']>;
  /** Services to request */
  services: Array<IPersonalMeansOfRegistrationServiceKindSubEnum>;
  /** List of IDs of agreed upon terms and condition documents */
  terms?: InputMaybe<Array<Scalars['ID']['input']>>;
}

/** Autogenerated return type of RequestService. */
export interface IRequestServicePayload {
  __typename?: 'RequestServicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  /** Change request used for this services request */
  servicesChangeRequest?: Maybe<IServicesChangeRequest>;
  /** List of validation errors */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of RequestTrainClassChange */
export interface IRequestTrainClassChangeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  firstClassReason?: InputMaybe<IFirstClassReasonEnum>;
  motivation?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
}

/** Autogenerated return type of RequestTrainClassChange. */
export interface IRequestTrainClassChangePayload {
  __typename?: 'RequestTrainClassChangePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  trainClassChangeRequest?: Maybe<ITrainClassChangeRequest>;
  validationErrors: Array<IValidationError>;
}

export interface IRequestableDonkeyRepublic extends IAvailableFeatureInterface {
  __typename?: 'RequestableDonkeyRepublic';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Whether the feature requires motivation. */
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  requiresPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IRequestableFeature extends IAvailableFeatureInterface {
  __typename?: 'RequestableFeature';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Whether the feature requires motivation. */
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  /** Whether a phone number is required for this feature. */
  requiresPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  urls?: Maybe<IFeatureUrls>;
}

export interface IRequestableFeatureOption {
  __typename?: 'RequestableFeatureOption';
  name?: Maybe<IFeatureOption>;
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditions?: Maybe<Array<ITermsAndConditions>>;
}

export interface IRequestableService extends IAvailableServiceInterface {
  __typename?: 'RequestableService';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Whether the requestable service may require the user to enter a PIN. */
  requiresPincode?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IRequestableYellowbrickProduct extends IAvailableServiceInterface {
  __typename?: 'RequestableYellowbrickProduct';
  /** When a service is requestable because of rules of the budget group, it can be a required or an optional service. When the service is not requestable by the budget group but on the card when requesting the card, the availability is `null`. */
  budgetGroupAvailability?: Maybe<IAvailableServiceAvailabilityEnum>;
  /** Description of this service */
  description?: Maybe<IDescription>;
  /** Date on which this service is due to be ended (if available) */
  endsOn?: Maybe<IPersonalMeansOfRegistrationServiceEndsOn>;
  /** Icon for the available service, based on the kind */
  icon?: Maybe<IIcon>;
  /** Identity for the service on this card. Can be a username or a unique number (if available) */
  identity?: Maybe<IPersonalMeansOfRegistrationServiceIdentity>;
  /** Kind of service that is available */
  kind: IPersonalMeansOfRegistrationServiceKind;
  /** Mobidesk ownership token for this service */
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  /** Personal Mean of Registration this service belongs to (or will belong to if service has not been requested yet) */
  personalMeansOfRegistration?: Maybe<IPersonalMeansOfRegistration>;
  /** Internal ID used for this service (if available) */
  recordId?: Maybe<Scalars['ID']['output']>;
  /** Whether this service needs a motiviation when requesting it */
  requiresMotivation: Scalars['Boolean']['output'];
  /** Whether the requestable service may require the user to enter a PIN. */
  requiresPincode?: Maybe<Scalars['Boolean']['output']>;
  /** Current status of this service */
  status?: Maybe<IPersonalMeansOfRegistrationServiceStatus>;
  /** The terms and conditions document for this service */
  termsAndConditions?: Maybe<ITermsAndConditions>;
}

export interface IRequiredFields {
  __typename?: 'RequiredFields';
  /** Whether a description is allowed for any MobilityEvent, even if it is not required. */
  descriptionAllowed: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is BUSINESS */
  descriptionForMobilitycardTransactionBusiness: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is COMMUTE */
  descriptionForMobilitycardTransactionCommute: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is PERSONAL */
  descriptionForMobilitycardTransactionPersonal: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the mobilitycard_transaction is in the given array of values */
  descriptionForMobilitycardTransactions: Array<ITravelPurposeEnum>;
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is BUSINESS when creating ride by amount */
  descriptionForRideByAmountBusiness: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is COMMUTE when creating ride by amount */
  descriptionForRideByAmountCommute: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is PERSONAL when creating ride by amount */
  descriptionForRideByAmountPersonal: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is BUSINESS when creating ride by distance */
  descriptionForRideByDistanceBusiness: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is COMMUTE when creating ride by distance */
  descriptionForRideByDistanceCommute: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is PERSONAL when creating ride by distance */
  descriptionForRideByDistancePersonal: Scalars['Boolean']['output'];
  /**
   * Whether a description is required to be given when the travelpurpose of the ride_transaction is BUSINESS
   * @deprecated This settings got split in two. Use descriptionForRideByAmountBusiness or descriptionForRideByDistanceBusiness
   */
  descriptionForRideTransactionBusiness: Scalars['Boolean']['output'];
  /**
   * Whether a description is required to be given when the travelpurpose of the ride_transaction is COMMUTE
   * @deprecated This settings got split in two. Use descriptionForRideByAmountCommute or descriptionForRideByDistanceCommute
   */
  descriptionForRideTransactionCommute: Scalars['Boolean']['output'];
  /**
   * Whether a description is required to be given when the travelpurpose of the ride_transaction is PERSONAL
   * @deprecated This settings got split in two. Use descriptionForRideByAmountPersonal or descriptionForRideByDistancePersonal
   */
  descriptionForRideTransactionPersonal: Scalars['Boolean']['output'];
  /** Whether a description is required to be given when the travelpurpose of the ride_transaction is in the given array of values */
  descriptionForRideTransactions: Array<ITravelPurposeEnum>;
  /** Whether a project is allowed for any MobilityEvent, even if it is not required. */
  projectAllowed: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is BUSINESS */
  projectForTravelpurposeBusiness: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is COMMUTE */
  projectForTravelpurposeCommute: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is PERSONAL */
  projectForTravelpurposePersonal: Scalars['Boolean']['output'];
  /** Whether a project is required to be given when the travelpurpose is in the given array of values */
  projectForTravelpurposes: Array<ITravelPurposeEnum>;
}

export interface IReservation {
  __typename?: 'Reservation';
  /** When the reservation has been cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The end location/destination of this reservation */
  endLocation?: Maybe<IGeoLocation>;
  /** When does the reservation end. Not applicable for Taxi reservations */
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the icon that can be used for this reservation */
  icon?: Maybe<IIcon>;
  /** This id is not the database id but a composed id from the class and record id. If you want the database id refer to the recordId. */
  id: Scalars['String']['output'];
  /** The id of this record as know by the database. */
  recordId: Scalars['ID']['output'];
  /** The original class that this reservation belongs to */
  reservationClass: IReservationClassEnum;
  /** The current status of this reservation, with a value and a humanized. Query the specific object to get precise status info */
  reservationStatus?: Maybe<IReservationStatus>;
  /** The start location/departure of this reservation */
  startLocation?: Maybe<IGeoLocation>;
  /** When does the reservation start */
  startsAt: Scalars['DateTime']['output'];
  /**
   * The current status of this reservation, prone to change, use for displaying data only. Query the specific object to get precise status info
   * @deprecated Use reservationStatus instead
   */
  status?: Maybe<Scalars['String']['output']>;
  /** The title of this reservation. */
  title: Scalars['String']['output'];
  /** If the reservation has a license plate, this will be filled with that value */
  vehicleLicensePlate?: Maybe<Scalars['String']['output']>;
}

export enum IReservationClassEnum {
  DONKEYREPUBLICBOOKING = 'DONKEYREPUBLICBOOKING',
  SHAREDVEHICLERESERVATION = 'SHAREDVEHICLERESERVATION',
  TAXIRESERVATION = 'TAXIRESERVATION',
  TRANZERBOOKING = 'TRANZERBOOKING'
}

export enum IReservationKindEnum {
  BICYCLE = 'bicycle',
  BIKE = 'bike',
  BUS = 'bus',
  CAR = 'car',
  EBIKE = 'ebike',
  METRO = 'metro',
  SCOOTER = 'scooter',
  TAXI = 'taxi',
  TRAIN = 'train',
  TRAM = 'tram'
}

export enum IReservationStateEnum {
  ARCHIVED = 'ARCHIVED',
  CURRENT = 'CURRENT'
}

export interface IReservationStatus {
  __typename?: 'ReservationStatus';
  humanized: Scalars['String']['output'];
  value: IReservationStatusValueEnum;
}

export enum IReservationStatusEnum {
  ARCHIVED = 'ARCHIVED',
  CURRENT = 'CURRENT'
}

export enum IReservationStatusValueEnum {
  ACTIVE = 'ACTIVE',
  ACTIVE_LOCKED = 'ACTIVE_LOCKED',
  ACTIVE_PENDING_LOCK = 'ACTIVE_PENDING_LOCK',
  ACTIVE_PENDING_UNLOCK = 'ACTIVE_PENDING_UNLOCK',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  CANCELLING = 'CANCELLING',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_PENDING_UNLOCK = 'CONFIRMED_PENDING_UNLOCK',
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  FINISHING = 'FINISHING',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED',
  STARTING = 'STARTING',
  UPDATED = 'UPDATED'
}

/** Autogenerated input type of ResetPassword */
export interface IResetPasswordInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** New password of the user */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Confirmation of new password of the user. */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** The password reset token which has been provided in the password reset mail */
  token: Scalars['String']['input'];
}

/** Autogenerated return type of ResetPassword. */
export interface IResetPasswordPayload {
  __typename?: 'ResetPasswordPayload';
  /** Api-Token to authenticate the employee for successive requests */
  apiToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated employee */
  me?: Maybe<IEmployee>;
  /** Returns whether the mutation was successful */
  result: IResult;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

export interface IResult {
  __typename?: 'Result';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['String']['output']>;
}

export interface IResultInterface {
  /** Error message */
  error?: Maybe<Scalars['String']['output']>;
  /** Success message */
  success?: Maybe<Scalars['String']['output']>;
}

export interface IResultWithAccessTokens {
  __typename?: 'ResultWithAccessTokens';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['String']['output']>;
  tokens?: Maybe<IAuth>;
}

/** Autogenerated input type of ReuploadPhoto */
export interface IReuploadPhotoInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardId: Scalars['ID']['input'];
  photo: IPhoto;
}

/** Autogenerated return type of ReuploadPhoto. */
export interface IReuploadPhotoPayload {
  __typename?: 'ReuploadPhotoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Profile with the new photo */
  profile?: Maybe<IProfile>;
  /** Result after reuploading a photo */
  result: IResult;
}

/** Autogenerated input type of RevokeDirectDebitMandate */
export interface IRevokeDirectDebitMandateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Mandate ID that needs to be revoked */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of RevokeDirectDebitMandate. */
export interface IRevokeDirectDebitMandatePayload {
  __typename?: 'RevokeDirectDebitMandatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new direct debit mandate */
  directDebitMandate?: Maybe<IDirectDebitMandate>;
  /** Whether the request was a success or not */
  result: IResult;
}

/** Autogenerated input type of RevokeSplitBillingContract */
export interface IRevokeSplitBillingContractInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  /** SplitBillingContracts IDs that needs to be revoked */
  splitBillingContractIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of RevokeSplitBillingContract. */
export interface IRevokeSplitBillingContractPayload {
  __typename?: 'RevokeSplitBillingContractPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  revokedContracts?: Maybe<Array<ISplitBillingContract>>;
}

export interface IRgba {
  __typename?: 'Rgba';
  alpha: Scalars['Int']['output'];
  blue: Scalars['Int']['output'];
  green: Scalars['Int']['output'];
  red: Scalars['Int']['output'];
}

export interface IRightToBeForgottenReason {
  __typename?: 'RightToBeForgottenReason';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<IRightToBeForgottenReasonEnum>;
}

export enum IRightToBeForgottenReasonEnum {
  CONSENT_WITHDRAWN = 'consent_withdrawn',
  LAWFUL_RETENTION_PERIOD_EXPIRED = 'lawful_retention_period_expired',
  NO_LONGER_NECESSARY = 'no_longer_necessary',
  OBJECTS_TO_PROCESSING = 'objects_to_processing',
  UNLAWFULLY_PROCESSED = 'unlawfully_processed'
}

export interface IRoute {
  __typename?: 'Route';
  diverted?: Maybe<IDiverted>;
  from: ILocation;
  label: Scalars['String']['output'];
  nonCombined?: Maybe<INonCombined>;
  to: ILocation;
  track?: Maybe<Array<ICoordinates>>;
}

export interface IRouteMetadata {
  __typename?: 'RouteMetadata';
  /** Returns the freshly calculated distance for a given from location, to location and modality. Does not round off. */
  calculateDistance: ICalculatedDistance;
  /** Returns the co2 emission for a given from location, to location, modality and/or vehicle */
  co2Emission: ICo2Emission;
  /** Returns the co2 emission for a given distance in km, modality and/or vehicle */
  co2EmissionFromDistance: ICo2Emission;
  /** Returns the saved distance for a given from location, to location and modality */
  distance: ICalculatedDistance;
  /** Returns whether the distance is overridable when creating or editing a mobility event */
  distanceOverridable: Scalars['Boolean']['output'];
  /** Returns whether commute distance is fixed */
  fixedCommuteDistanceEnabled: Scalars['Boolean']['output'];
  /** Returns whether non-combined rides are allowed */
  nonCombinedAllowed: Scalars['Boolean']['output'];
}


export interface IRouteMetadataCalculateDistanceArgs {
  from: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  on: Scalars['Date']['input'];
  to: ICoordinatesMutation;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}


export interface IRouteMetadataCo2EmissionArgs {
  from: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  to: ICoordinatesMutation;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IRouteMetadataCo2EmissionFromDistanceArgs {
  distance: Scalars['Float']['input'];
  modalityId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}


export interface IRouteMetadataDistanceArgs {
  from: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  to: ICoordinatesMutation;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}

export interface IRouteMutation {
  /** Overridden distance in kilometers */
  distance?: InputMaybe<Scalars['Float']['input']>;
  diverted?: InputMaybe<Scalars['Boolean']['input']>;
  from: ILocationMutation;
  nonCombined?: InputMaybe<Scalars['Boolean']['input']>;
  to: ILocationMutation;
}

export interface ISegmentPrice {
  __typename?: 'SegmentPrice';
  amount: IMoney;
  currency: Scalars['String']['output'];
  /** The duration of a segment. */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The start of a segment's pricing plan. Calculated from the moment the bike was unlocked. */
  fromMinutes: Scalars['Int']['output'];
  /** The end of a segment's pricing plan. Calculated from the moment the bike was unlocked. */
  tillMinutes?: Maybe<Scalars['Int']['output']>;
  /** The amount of VAT to be added to the amount field. */
  vatPercentage: Scalars['Float']['output'];
}

/** Autogenerated input type of SendNewInvitationLink */
export interface ISendNewInvitationLinkInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The email of the employee requesting a new invitation link */
  email: Scalars['String']['input'];
}

/** Autogenerated return type of SendNewInvitationLink. */
export interface ISendNewInvitationLinkPayload {
  __typename?: 'SendNewInvitationLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Returns whether the mutation was successful */
  result: IResult;
}

/** Autogenerated input type of SendPasswordResetMail */
export interface ISendPasswordResetMailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Email of the account that requests a password reset */
  email: Scalars['String']['input'];
}

/** Autogenerated return type of SendPasswordResetMail. */
export interface ISendPasswordResetMailPayload {
  __typename?: 'SendPasswordResetMailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of SendPrivacyRequestMail */
export interface ISendPrivacyRequestMailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Additional reason to be requested / emailed */
  reason?: InputMaybe<IPrivacyRequestReasonEnum>;
  /** Privacy type to be requested / emailed */
  requestType: IPrivacyTypeEnum;
}

/** Autogenerated return type of SendPrivacyRequestMail. */
export interface ISendPrivacyRequestMailPayload {
  __typename?: 'SendPrivacyRequestMailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

export interface IServiceOperator {
  __typename?: 'ServiceOperator';
  android?: Maybe<Scalars['String']['output']>;
  humanized?: Maybe<Scalars['String']['output']>;
  ios?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orderPortal?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
}

export interface IServiceProvider {
  __typename?: 'ServiceProvider';
  humanized: Scalars['String']['output'];
  value: IContractPaymentServiceProviderEnum;
}

export interface IServices {
  __typename?: 'Services';
  confirmGreenwheels?: Maybe<IConfirmGreenwheelsPayload>;
  request?: Maybe<IRequestServicePayload>;
}


export interface IServicesConfirmGreenwheelsArgs {
  input: IConfirmGreenwheelsInput;
}


export interface IServicesRequestArgs {
  input: IRequestServiceInput;
}

export interface IServicesChangeRequest {
  __typename?: 'ServicesChangeRequest';
  /** Datetime at which the change request was approved */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Employee that owns this change request */
  employee: IEmployee;
  id: Scalars['ID']['output'];
  /** Motivation given when filing the change request */
  motivation?: Maybe<Scalars['String']['output']>;
  /** Personal Mean of Registration this change request belongs to */
  personalMeansOfRegistration: IPersonalMeansOfRegistration;
  /** Datetime at which the change request was rejected */
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Names of services included in this change request */
  services: Array<IPersonalMeansOfRegistrationServiceKindSubEnum>;
}

/** Autogenerated input type of SetAgreeTerms */
export interface ISetAgreeTermsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED. Mutation supports now multiple ids, use ids instead */
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
}

/** Autogenerated return type of SetAgreeTerms. */
export interface ISetAgreeTermsPayload {
  __typename?: 'SetAgreeTermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of SetManualFavoriteCoordinates */
export interface ISetManualFavoriteCoordinatesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the favorite you'd like to update */
  id: Scalars['ID']['input'];
  /** The latitude of the location. */
  latitude?: InputMaybe<Scalars['Coordinate']['input']>;
  /** The longitude of the location. */
  longitude?: InputMaybe<Scalars['Coordinate']['input']>;
}

/** Autogenerated return type of SetManualFavoriteCoordinates. */
export interface ISetManualFavoriteCoordinatesPayload {
  __typename?: 'SetManualFavoriteCoordinatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new favorite record */
  favorite?: Maybe<IFavorite>;
  /** Whether the request was a success or not */
  result: IResult;
  /** If something was not ok, you can find the reason here */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of SetOptions */
export interface ISetOptionsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  options: IOptionsMutation;
}

/** Autogenerated return type of SetOptions. */
export interface ISetOptionsPayload {
  __typename?: 'SetOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of updating a mobility card order */
  result: IResult;
  /** List of validation errors while setting product options */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of SetPhoto */
export interface ISetPhotoInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  photo: IPhoto;
}

/** Autogenerated return type of SetPhoto. */
export interface ISetPhotoPayload {
  __typename?: 'SetPhotoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Profile with the new photo */
  profile?: Maybe<IProfile>;
  /** Result of updating a mobility card order */
  result: IResult;
}

/** Autogenerated input type of SetProfile */
export interface ISetProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  profile: IProfileMutation;
}

/** Autogenerated return type of SetProfile. */
export interface ISetProfilePayload {
  __typename?: 'SetProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Changed profile */
  profile?: Maybe<IProfile>;
  /** Result of setting a profile */
  result?: Maybe<IResult>;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of SetTerms */
export interface ISetTermsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityCardOrderId: Scalars['ID']['input'];
  termsAndConditionsDocumentIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of SetTerms. */
export interface ISetTermsPayload {
  __typename?: 'SetTermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated mobility card order */
  mobilityCardOrder?: Maybe<IMobilityCardOrder>;
  /** Result of updating a mobility card order */
  result: IResult;
}

export interface ISettlement {
  __typename?: 'Settlement';
  /** Returns the total gross, net and fiscal amount of all mobility events within this settlement */
  balance?: Maybe<IBalance>;
  committedAt?: Maybe<Scalars['DateTime']['output']>;
  financialSummary?: Maybe<Array<IFinancialSummary>>;
  /** The gross amount of money you received or had to pay */
  grossAmount: IBudget;
  happenedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use financial_summary instead. */
  mobilityEvents?: Maybe<Array<IMobilityEvent>>;
  /** The net amount of money you received or had to pay */
  netAmount: IBudget;
  organization?: Maybe<IOrganization>;
  /** Returns the total gross, net and fiscal amount of all mobility events of the previous settlement */
  previousBalance?: Maybe<IBalance>;
  previousSettlement?: Maybe<ISettlement>;
  /** The total gross and net charge of all mobility events within this settlement */
  totalGrossAndNetCharge: IBudget;
  /** The total gross and net reimbursement of all mobility events within this settlement */
  totalGrossAndNetReimbursement: IBudget;
  /** List of financial implications for each travel purpose */
  travelPurposes: Array<ITravelPurposeFinancialConsequences>;
  type?: Maybe<ISettlementTypeFinancialConsequences>;
  withGrossBalance?: Maybe<Scalars['Boolean']['output']>;
}

export interface ISettlementTypeFinancialConsequences {
  __typename?: 'SettlementTypeFinancialConsequences';
  enumValue?: Maybe<ISettlementTypeFinancialConsequencesEnum>;
  humanized?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated in favor of EnumValue field */
  value?: Maybe<Scalars['String']['output']>;
}

export enum ISettlementTypeFinancialConsequencesEnum {
  EMPLOYEEFINALSETTLEMENT = 'EmployeeFinalSettlement',
  EMPLOYEEINTERMEDIATESETTLEMENT = 'EmployeeIntermediateSettlement',
  TENTATIVEEMPLOYEEINTERMEDIATESETTLEMENT = 'TentativeEmployeeIntermediateSettlement'
}

export interface ISharedCarReservation {
  __typename?: 'SharedCarReservation';
  bookingId: Scalars['String']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  id: Scalars['ID']['output'];
  startable: Scalars['Boolean']['output'];
  startsAt: Scalars['DateTime']['output'];
  status: ISharedCarReservationStatus;
  vehicleBrand?: Maybe<Scalars['String']['output']>;
  vehicleKey?: Maybe<Scalars['JSON']['output']>;
  vehicleLicensePlate?: Maybe<Scalars['String']['output']>;
  vehicleModel?: Maybe<Scalars['String']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export interface ISharedCarReservationStatus {
  __typename?: 'SharedCarReservationStatus';
  humanized: Scalars['String']['output'];
  value: ISharedVehicleReservationStatusEnum;
}

export interface ISharedVehicle extends IAvailableFeatureInterface {
  __typename?: 'SharedVehicle';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ISharedVehicleReservation {
  __typename?: 'SharedVehicleReservation';
  bookingId: Scalars['String']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  icon: IIcon;
  id: Scalars['ID']['output'];
  modality: IModality;
  reservationId?: Maybe<Scalars['String']['output']>;
  startable: Scalars['Boolean']['output'];
  startsAt: Scalars['DateTime']['output'];
  status: ISharedCarReservationStatus;
  vehicleBrand?: Maybe<Scalars['String']['output']>;
  vehicleKey?: Maybe<Scalars['JSON']['output']>;
  vehicleLicensePlate?: Maybe<Scalars['String']['output']>;
  vehicleModel?: Maybe<Scalars['String']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
}

export enum ISharedVehicleReservationStatusEnum {
  ACTIVE = 'ACTIVE',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  CANCELLING = 'CANCELLING',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  ERROR = 'ERROR',
  FINISHING = 'FINISHING',
  STARTED = 'STARTED',
  STARTING = 'STARTING',
  UPDATED = 'UPDATED'
}

export interface IShuttelCheck extends IAvailableFeatureInterface {
  __typename?: 'ShuttelCheck';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** @deprecated Deprecated in favor of promocode url. This field will be empty. */
  promocode?: Maybe<Scalars['String']['output']>;
  promocodeUrl?: Maybe<Scalars['String']['output']>;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelMyWheels extends IAvailableFeatureInterface {
  __typename?: 'ShuttelMyWheels';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelSixt extends IAvailableFeatureInterface {
  __typename?: 'ShuttelSixt';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelTaxi extends IAvailableFeatureInterface {
  __typename?: 'ShuttelTaxi';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelTranzerEticket extends IAvailableFeatureInterface {
  __typename?: 'ShuttelTranzerEticket';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IShuttelVecore extends IAvailableFeatureInterface {
  __typename?: 'ShuttelVecore';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ISignature {
  __typename?: 'Signature';
  approvalLevel?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mobilityEvent?: Maybe<IMobilityEvent>;
  reason?: Maybe<Scalars['String']['output']>;
  signer?: Maybe<IEmployee>;
}

export interface ISplitBillingContract {
  __typename?: 'SplitBillingContract';
  /** The url where the client can register at the payment service provider */
  accessUrl?: Maybe<Scalars['String']['output']>;
  actions: IContractActions;
  /** DateTime at which this Contract was active */
  activeAt?: Maybe<Scalars['DateTime']['output']>;
  /** DateTime at which this Contract was assigned */
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  /** To which brand this contract belongs to */
  brand?: Maybe<Scalars['String']['output']>;
  /** DateTime at which this Contract was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The debtor number */
  debtorNumber?: Maybe<Scalars['String']['output']>;
  /** Which email address type has been used */
  emailAddressType: ISplitBillingContractEmailAddress;
  /** Contract ID */
  id: Scalars['ID']['output'];
  /** DateTime at which this Contract was invited */
  invitedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The mobidesk_entity_token */
  mobideskEntityToken?: Maybe<Scalars['String']['output']>;
  /** The parent debtor number */
  parentDebtorNumber?: Maybe<Scalars['String']['output']>;
  /** Which payment service provider has been used */
  paymentServiceProvider: IServiceProvider;
  /** DateTime at which this Contract has been requested */
  requestedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Contract UUID */
  splitBillingContractId?: Maybe<Scalars['String']['output']>;
  /** Status of Contract */
  status: IContractStatus;
  /** Contains the reason of the current status */
  statusReason?: Maybe<Scalars['String']['output']>;
  /** DateTime at which this Contract was suspended */
  suspendedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface ISplitBillingContractEmailAddress {
  __typename?: 'SplitBillingContractEmailAddress';
  humanized: Scalars['String']['output'];
  value: ISplitBillingContractEmailAddressEnum;
}

export enum ISplitBillingContractEmailAddressEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export interface ISplitBillingContracts {
  __typename?: 'SplitBillingContracts';
  getAccessUrl?: Maybe<IGetAccessUrlPayload>;
  revoke?: Maybe<IRevokeSplitBillingContractPayload>;
}


export interface ISplitBillingContractsGetAccessUrlArgs {
  input: IGetAccessUrlInput;
}


export interface ISplitBillingContractsRevokeArgs {
  input: IRevokeSplitBillingContractInput;
}

export interface ISplitBillingInvoice {
  __typename?: 'SplitBillingInvoice';
  /** Amount invoiced */
  amount?: Maybe<IMoney>;
  /** External reference of this invoice */
  externalReference: Scalars['String']['output'];
  /** The database id of the invoice */
  id: Scalars['ID']['output'];
  /** Date of the invoice */
  invoiceDate: Scalars['Date']['output'];
  /** URL of the PDF of this invoice */
  pdfUrl?: Maybe<Scalars['String']['output']>;
  /** Transaction report of this invoice */
  transactionsReport?: Maybe<ITransactionsReport>;
  /** URL of the UBL of this invoice */
  ublUrl?: Maybe<Scalars['String']['output']>;
  /** The UUID of this invoice */
  uuid: Scalars['String']['output'];
}

export interface ISsoEnabled {
  __typename?: 'SsoEnabled';
  ssoEnabled: Scalars['Boolean']['output'];
  ssoHost: Scalars['String']['output'];
}

export interface IStarValue {
  __typename?: 'StarValue';
  humanized: Scalars['String']['output'];
  value: IStarValueEnum;
}

export enum IStarValueEnum {
  FIVE = 'FIVE',
  FOUR = 'FOUR',
  ONE = 'ONE',
  SIX = 'SIX',
  THREE = 'THREE',
  TWO = 'TWO'
}

/** Autogenerated input type of StartDonkeyRepublicReservation */
export interface IStartDonkeyRepublicReservationInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of StartDonkeyRepublicReservation. */
export interface IStartDonkeyRepublicReservationPayload {
  __typename?: 'StartDonkeyRepublicReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of StartSharedCarReservation */
export interface IStartSharedCarReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of StartSharedCarReservation. */
export interface IStartSharedCarReservationPayload {
  __typename?: 'StartSharedCarReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedCarReservation: ISharedCarReservation;
}

/** Autogenerated input type of StartSharedVehicleReservation */
export interface IStartSharedVehicleReservationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of StartSharedVehicleReservation. */
export interface IStartSharedVehicleReservationPayload {
  __typename?: 'StartSharedVehicleReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  sharedVehicleReservation: ISharedVehicleReservation;
}

export interface IStateTransitions {
  __typename?: 'StateTransitions';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  confirmer?: Maybe<Scalars['String']['output']>;
  rejectReason?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface IStation {
  __typename?: 'Station';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface IStatistics {
  __typename?: 'Statistics';
  /**
   * This shows the remainder of your current budget.
   * @deprecated Use me.financialConsequences.budgetTransactionRemainder instead.
   */
  budgetBalance: IBudget;
  /** This is a representation of the kilometers you drove */
  leaseCar: ILeaseCar;
  /**
   * This is a representation of your personal balance
   * @deprecated Use me.financialConsequences.tentativeSettlement instead.
   */
  personalBalance: ISettlement;
  /**
   * This is a representation of your balance from the last settlement, or when missing the current tentative one
   * @deprecated Use me.financialConsequences.lastIntermediateSettlement instead.
   */
  settlement?: Maybe<ISettlement>;
  /** This is a representation of your travel behaviour that is split up into personal, commute and business */
  travelBehaviour: ITravelBehaviour;
}

export interface IStats {
  __typename?: 'Stats';
  /** The total CO2 emission emitted during the travelled distance */
  totalCo2EmissionInGrams?: Maybe<Scalars['Int']['output']>;
  /** The total distance travelled in meters */
  totalDistanceInMeters?: Maybe<Scalars['Int']['output']>;
  /** The kind of transport that was used */
  transportType?: Maybe<Scalars['String']['output']>;
}

export interface IStatsPerModality {
  __typename?: 'StatsPerModality';
  co2PerKm?: Maybe<Scalars['Int']['output']>;
  totalCo2?: Maybe<Scalars['Int']['output']>;
  totalKms?: Maybe<Scalars['Int']['output']>;
}

export interface IStatus {
  __typename?: 'Status';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: IStatusWithConfirmedEnum;
}

export enum IStatusEnum {
  ACCEPTED = 'ACCEPTED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED'
}

export enum IStatusWithConfirmedEnum {
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED'
}

export interface IStreetAddress {
  __typename?: 'StreetAddress';
  city?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
}

/** StudentTravelProduct(Studentenreisproduct) namespace, used to remove or add existing cards to the SRS whitelist */
export interface IStudentTravelProduct {
  __typename?: 'StudentTravelProduct';
  /** Add to the studentenreisproduct whitelist. */
  addToWhitelist?: Maybe<IAddToWhitelistPayload>;
  /** Remove from the studentenreisproduct whitelist. */
  removeFromWhitelist?: Maybe<IRemoveFromWhitelistPayload>;
}


/** StudentTravelProduct(Studentenreisproduct) namespace, used to remove or add existing cards to the SRS whitelist */
export interface IStudentTravelProductAddToWhitelistArgs {
  input: IAddToWhitelistInput;
}


/** StudentTravelProduct(Studentenreisproduct) namespace, used to remove or add existing cards to the SRS whitelist */
export interface IStudentTravelProductRemoveFromWhitelistArgs {
  input: IRemoveFromWhitelistInput;
}

export interface ISubCategory {
  __typename?: 'SubCategory';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export enum ISubCategoryEnum {
  ALTIJD_VOORDEEL = 'altijd_voordeel',
  BREAKFAST = 'breakfast',
  BUDGET = 'budget',
  CAR_WASH = 'car_wash',
  COMMUTE_COMPENSATION = 'commute_compensation',
  DAL = 'dal',
  DAL_VOORDEEL = 'dal_voordeel',
  DAL_VRIJ = 'dal_vrij',
  DAYTIME = 'daytime',
  DIESEL = 'diesel',
  DINNER = 'dinner',
  DURATION = 'duration',
  ELECTRIC = 'electric',
  ELECTRICITY_FUEL_CARD = 'electricity_fuel_card',
  EVENING = 'evening',
  EXTENDED_SUBSCRIPTION = 'extended_subscription',
  FINAL = 'final',
  FLUID = 'fluid',
  FUEL_USAGE = 'fuel_usage',
  GARAGE = 'garage',
  GASOLINE = 'gasoline',
  INTERCITY_DIRECT = 'intercity_direct',
  INTERMEDIATE = 'intermediate',
  LEASE = 'lease',
  LOCATION = 'location',
  LODGING = 'lodging',
  LPG = 'lpg',
  LUNCH = 'lunch',
  MOBILITY_CARD = 'mobility_card',
  OTHER = 'other',
  OV_VRIJ = 'ov_vrij',
  OV_ZAKELIJK = 'ov_zakelijk',
  REISBALANS = 'reisbalans',
  SHARED = 'shared',
  SHED = 'shed',
  STREET = 'street',
  TRAJECT_OP_MAAT = 'traject_op_maat',
  TRAJECT_VRIJ = 'traject_vrij',
  TRANSACTION_COSTS = 'transaction_costs',
  TREIN_VRIJ = 'trein_vrij',
  UNACCOUNTED_DISTANCE = 'unaccounted_distance',
  UNKNOWN = 'unknown',
  WORK_FROM_HOME = 'work_from_home'
}

export interface ISubordinate {
  __typename?: 'Subordinate';
  /** Mobility events of this employee that are waiting a fiat */
  acceptableMobilityEvents: Array<IMobilityEvent>;
  /** Mobility events of this employee that are accepted */
  acceptedMobilityEvents: Array<IMobilityEvent>;
  /** Full name of the subordinate employee */
  fullName: Scalars['String']['output'];
  /** ID of the subordinate employee */
  id: Scalars['ID']['output'];
  /** Total number of mobility events of this employee that are waiting for a fiat */
  numAcceptableMobilityEvents: Scalars['Int']['output'];
}


export interface ISubordinateAcceptableMobilityEventsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  offsetForId?: InputMaybe<Scalars['Int']['input']>;
}


export interface ISubordinateAcceptedMobilityEventsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  offsetForId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ISubscription {
  __typename?: 'Subscription';
  humanized: Scalars['String']['output'];
  value: ISubscriptionEnum;
}

export enum ISubscriptionEnum {
  ALTIJD_VOORDEEL = 'ALTIJD_VOORDEEL',
  DAL = 'DAL',
  DAL_VOORDEEL = 'DAL_VOORDEEL',
  DAL_VRIJ = 'DAL_VRIJ',
  GVB_ONLY = 'GVB_ONLY',
  GVB_RANDSTAD_NOORD_ZONE = 'GVB_RANDSTAD_NOORD_ZONE',
  GVB_ZONE = 'GVB_ZONE',
  OV_VRIJ = 'OV_VRIJ',
  QBUZZ_REGIO = 'QBUZZ_REGIO',
  QBUZZ_REGIO_TREIN = 'QBUZZ_REGIO_TREIN',
  RAC = 'RAC',
  RAZ = 'RAZ',
  REIZEN_OP_REKENING = 'REIZEN_OP_REKENING',
  REIZEN_OP_SALDO = 'REIZEN_OP_SALDO',
  RET_NET = 'RET_NET',
  RET_ONLY = 'RET_ONLY',
  RET_REGIO = 'RET_REGIO',
  ROS_MOB = 'ROS_MOB',
  TRAJECT_OP_MAAT = 'TRAJECT_OP_MAAT',
  TRAJECT_VRIJ = 'TRAJECT_VRIJ',
  TRAVEL_CARD = 'TRAVEL_CARD',
  TREIN_VRIJ = 'TREIN_VRIJ',
  UOV_GEMAK_REGIO = 'UOV_GEMAK_REGIO',
  UOV_GEMAK_STAD = 'UOV_GEMAK_STAD',
  UOV_STER = 'UOV_STER',
  UOV_VRIJ_REGIO = 'UOV_VRIJ_REGIO',
  UOV_VRIJ_STAD = 'UOV_VRIJ_STAD'
}

/** Returns whether version is supported */
export interface ISupportedVersion {
  __typename?: 'SupportedVersion';
  isSupported: Scalars['Boolean']['output'];
}


/** Returns whether version is supported */
export interface ISupportedVersionIsSupportedArgs {
  version: Scalars['String']['input'];
}

export interface ITask {
  /** Humanized value for this task type. */
  humanized: Scalars['String']['output'];
  /** Contains actionable information about the task. */
  message: Scalars['String']['output'];
  /**
   * The path you can use for navigation
   * @deprecated Frontend uses hash params instead of url params, we dont want the backend to keep track of the frontends URLs. Use detailed fields on each task.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** What kind of tasks type we are handling */
  type: ITaskEnum;
}

export enum ITaskEnum {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  ACTIVATABLE_DONGLE = 'ACTIVATABLE_DONGLE',
  COMPENSATION_RIDE_TO_BE_CREATED = 'COMPENSATION_RIDE_TO_BE_CREATED',
  MOBILITY_EVENTS_WEEK = 'MOBILITY_EVENTS_WEEK',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PRODUCT = 'PRODUCT',
  REFUND = 'REFUND'
}

export interface ITaxi {
  __typename?: 'Taxi';
  cancel?: Maybe<ICancelTaxiReservationPayload>;
  confirm?: Maybe<IConfirmTaxiReservationPayload>;
  create?: Maybe<ICreateTaxiReservationPayload>;
  /** @deprecated It is no longer possible to update a reservation... */
  update?: Maybe<IUpdateTaxiReservationPayload>;
}


export interface ITaxiCancelArgs {
  input: ICancelTaxiReservationInput;
}


export interface ITaxiConfirmArgs {
  input: IConfirmTaxiReservationInput;
}


export interface ITaxiCreateArgs {
  input: ICreateTaxiReservationInput;
}


export interface ITaxiUpdateArgs {
  input: IUpdateTaxiReservationInput;
}

export interface ITaxiFeature extends IAvailableFeatureInterface {
  __typename?: 'TaxiFeature';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ITaxiReservation {
  __typename?: 'TaxiReservation';
  cancellable: Scalars['Boolean']['output'];
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  departAt: Scalars['DateTime']['output'];
  departure: ILocation;
  destination: ILocation;
  /** @deprecated We have lost access to this information at the moment */
  driver?: Maybe<IDriver>;
  id: Scalars['ID']['output'];
  passengerCount: Scalars['Int']['output'];
  price?: Maybe<IMoney>;
  remarks?: Maybe<Scalars['String']['output']>;
  requestedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ITaxiStatus>;
  vehicle?: Maybe<ITaxiReservationVehicle>;
  vehicleType?: Maybe<Scalars['String']['output']>;
}

export interface ITaxiReservationVehicle {
  __typename?: 'TaxiReservationVehicle';
  brand?: Maybe<Scalars['String']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ITaxiStatus {
  __typename?: 'TaxiStatus';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

/** Autogenerated input type of TerminateOwnership */
export interface ITerminateOwnershipInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The mobidesk_ownership_token of the ownership (service or feature) */
  mobideskOwnershipToken: Scalars['Int']['input'];
}

/** Autogenerated return type of TerminateOwnership. */
export interface ITerminateOwnershipPayload {
  __typename?: 'TerminateOwnershipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of TerminatePersonalMeansOfRegistration */
export interface ITerminatePersonalMeansOfRegistrationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the personal means of registration (mobility card) */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of TerminatePersonalMeansOfRegistration. */
export interface ITerminatePersonalMeansOfRegistrationPayload {
  __typename?: 'TerminatePersonalMeansOfRegistrationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  personalMeansOfRegistration: IPersonalMeansOfRegistration;
  result: IResult;
}

/** Autogenerated input type of TerminateReplacementManager */
export interface ITerminateReplacementManagerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the replacement manager that should be removed */
  replacementManagerId: Scalars['ID']['input'];
}

/** Autogenerated return type of TerminateReplacementManager. */
export interface ITerminateReplacementManagerPayload {
  __typename?: 'TerminateReplacementManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
}

/** Autogenerated input type of TerminateVehicle */
export interface ITerminateVehicleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Date when to terminate the vehicle */
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  /** ID of the vehicle to terminate */
  id: Scalars['ID']['input'];
}

/** Autogenerated return type of TerminateVehicle. */
export interface ITerminateVehiclePayload {
  __typename?: 'TerminateVehiclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
  vehicle: IMeansOfTransport;
}

export interface ITermsAndConditions {
  __typename?: 'TermsAndConditions';
  /** Whether the employee agreed upon these terms and conditions */
  agreed: Scalars['Boolean']['output'];
  /** When the employee agreed upon these terms and conditions */
  agreedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The terms and conditions document */
  document: ITermsAndConditionsDocument;
  /** Whether a service requires an agreement of the terms and conditions */
  requiresAgreement: Scalars['Boolean']['output'];
}

export interface ITermsAndConditionsDocument {
  __typename?: 'TermsAndConditionsDocument';
  /** Humanized value for the kind of terms and conditions document */
  humanizedKind: ITermsAndConditionsDocumentHumanizedKind;
  /** The identifier of the terms and conditions document */
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated due to introduction of humanized_kind */
  kind: ITermsAndConditionsDocumentKindEnum;
  /** The URL to the terms and conditions document */
  pdfUrl: Scalars['String']['output'];
}

export interface ITermsAndConditionsDocumentHumanizedKind {
  __typename?: 'TermsAndConditionsDocumentHumanizedKind';
  humanized?: Maybe<Scalars['String']['output']>;
  value: ITermsAndConditionsDocumentKindEnum;
}

export enum ITermsAndConditionsDocumentKindEnum {
  ADMIN_GENERAL = 'ADMIN_GENERAL',
  ADMIN_PRIVACY_STATEMENT = 'ADMIN_PRIVACY_STATEMENT',
  DKV = 'DKV',
  DONKEY_REPUBLIC = 'DONKEY_REPUBLIC',
  DRIVEN_ZOVC = 'DRIVEN_ZOVC',
  DRIVEN_ZOVC_DKV = 'DRIVEN_ZOVC_DKV',
  ENECO_DONGLE = 'ENECO_DONGLE',
  FREDDI_USER_FEE = 'FREDDI_USER_FEE',
  GENERAL = 'GENERAL',
  GREENWHEELS = 'GREENWHEELS',
  I_TRAVEL_PREPAID_ZOVC = 'I_TRAVEL_PREPAID_ZOVC',
  I_TRAVEL_ZOVC = 'I_TRAVEL_ZOVC',
  I_TRAVEL_ZOVC_DKV = 'I_TRAVEL_ZOVC_DKV',
  I_TRAVEL_ZOVC_LEGACY = 'I_TRAVEL_ZOVC_LEGACY',
  I_TRAVEL_ZOVC_TANKEN = 'I_TRAVEL_ZOVC_TANKEN',
  LEASE_BIKE = 'LEASE_BIKE',
  MOBILITEITS_MANAGER_PREPAID_ZOVC = 'MOBILITEITS_MANAGER_PREPAID_ZOVC',
  MOBILITEITS_MANAGER_ZOVC = 'MOBILITEITS_MANAGER_ZOVC',
  MOBILITEITS_MANAGER_ZOVC_DKV = 'MOBILITEITS_MANAGER_ZOVC_DKV',
  MOBILITEITS_MANAGER_ZOVC_DKV_LEGACY = 'MOBILITEITS_MANAGER_ZOVC_DKV_LEGACY',
  MOBILITEITS_MANAGER_ZOVC_LEGACY = 'MOBILITEITS_MANAGER_ZOVC_LEGACY',
  MOBILITEITS_MANAGER_ZOVC_TANKEN = 'MOBILITEITS_MANAGER_ZOVC_TANKEN',
  MOBILITEITS_MANAGER_ZOVC_TANKEN_LEGACY = 'MOBILITEITS_MANAGER_ZOVC_TANKEN_LEGACY',
  MOBILITY_CARD = 'MOBILITY_CARD',
  NOC_NSF_ZOVC = 'NOC_NSF_ZOVC',
  NS_BUSINESS_CARD = 'NS_BUSINESS_CARD',
  NS_PR = 'NS_PR',
  ORGANIZATION = 'ORGANIZATION',
  OV_FIETS = 'OV_FIETS',
  PR = 'PR',
  PRIVACY_STATEMENT = 'PRIVACY_STATEMENT',
  QBUZZ_ZOVC = 'QBUZZ_ZOVC',
  REISBALANS_ANONYMOUS_ZOVC = 'REISBALANS_ANONYMOUS_ZOVC',
  REISBALANS_UNLIMITED = 'REISBALANS_UNLIMITED',
  REISBALANS_ZOVC = 'REISBALANS_ZOVC',
  REISBALANS_ZOVC_DKV = 'REISBALANS_ZOVC_DKV',
  REISBALANS_ZOVC_TANKEN = 'REISBALANS_ZOVC_TANKEN',
  RENTAL_CAR = 'RENTAL_CAR',
  RYDER_ANONYMOUS_ZOVC = 'RYDER_ANONYMOUS_ZOVC',
  RYDER_ZOVC = 'RYDER_ZOVC',
  SHARED_VEHICLE = 'SHARED_VEHICLE',
  SHUTTEL_CHECK = 'SHUTTEL_CHECK',
  SHUTTEL_MY_WHEELS = 'SHUTTEL_MY_WHEELS',
  SHUTTEL_SIXT = 'SHUTTEL_SIXT',
  SHUTTEL_TAXI = 'SHUTTEL_TAXI',
  SHUTTEL_TRANZER_ETICKETS = 'SHUTTEL_TRANZER_ETICKETS',
  SHUTTEL_VECORE = 'SHUTTEL_VECORE',
  TAXI = 'TAXI',
  TRANZER_ETICKETS = 'TRANZER_ETICKETS',
  TRAVEL_CARD = 'TRAVEL_CARD',
  UOV_ZOVC = 'UOV_ZOVC',
  USER_FEE = 'USER_FEE',
  WITTEBRUG_ANONYMOUS_ZOVC = 'WITTEBRUG_ANONYMOUS_ZOVC',
  WITTEBRUG_ZOVC = 'WITTEBRUG_ZOVC',
  YELLOWBRICK_PR = 'YELLOWBRICK_PR',
  YELLOWBRICK_SP = 'YELLOWBRICK_SP',
  ZUIDAS_ZOVC = 'ZUIDAS_ZOVC'
}

export interface ITheme {
  __typename?: 'Theme';
  /** The url (or host-relative path) of the icon for this organization */
  icon?: Maybe<Scalars['String']['output']>;
  /** The login image that should be used for this organization */
  loginImage?: Maybe<Scalars['String']['output']>;
  /** The url (or host-relative path) of the logo for this organization */
  logo?: Maybe<Scalars['String']['output']>;
  /** The primary color of the organization */
  primaryColor?: Maybe<IColor>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<IColor>;
}


export interface IThemeIconArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface IThemeLoginImageArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}


export interface IThemeLogoArgs {
  expires?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ITicket {
  __typename?: 'Ticket';
  /** A base64 encoded string of a QR image, barcode or image. */
  barcode?: Maybe<Scalars['String']['output']>;
  /** Brief description of the terms of conditions that apply when purchasing this ticket. */
  conditionsOneliner?: Maybe<Scalars['String']['output']>;
  /** A link to all terms of conditions that apply when purchasing this ticket. */
  conditionsUrl: Scalars['String']['output'];
  /** The corresponding media objects for tickets. */
  media: Array<ITicketMedia>;
  /** The price of the ticket. */
  price: IPrice;
  /** The name of the purchased product */
  productName: Scalars['String']['output'];
  /** The class that applies for this ticket. */
  ticketClass?: Maybe<Scalars['String']['output']>;
  /** The name/title of the ticket. */
  ticketName: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTill: Scalars['DateTime']['output'];
}

export interface ITicketMedia {
  __typename?: 'TicketMedia';
  back?: Maybe<IMediaSide>;
  displayType?: Maybe<IDisplay>;
  front?: Maybe<IMediaSide>;
  type: IMedia;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ITimestamp extends IWhen {
  __typename?: 'Timestamp';
  at: Scalars['DateTime']['output'];
  editable: Scalars['Boolean']['output'];
}

export enum ITokenTypeEnum {
  ACCESS = 'ACCESS',
  JWT = 'JWT',
  LEGACY = 'LEGACY'
}

export interface ITotals {
  __typename?: 'Totals';
  /** Total amount */
  amount: IMoney;
  /** Total count */
  count: Scalars['Int']['output'];
  /** Total distance */
  distance: Scalars['Float']['output'];
}

export interface ITrainClass {
  __typename?: 'TrainClass';
  humanized: Scalars['String']['output'];
  value: ITrainClassEnum;
}

export interface ITrainClassChange {
  __typename?: 'TrainClassChange';
  /** Whether the employee can request a train class change */
  canRequestTrainClassChange?: Maybe<Scalars['Boolean']['output']>;
  /** If an employee can switch train classes, but requires a reason to do so, this is the set of reasons that is available for his budget_group. */
  firstClassReasons?: Maybe<Array<IFirstClassReason>>;
  /** The train class that can be requested (if any). */
  requestableTrainClass?: Maybe<ITrainClass>;
  /** Whether a motivation is required when requesting a train class change */
  requiresTrainClassChangeMotivation?: Maybe<Scalars['Boolean']['output']>;
  /** The status of a train class request */
  trainClassStatus?: Maybe<Scalars['String']['output']>;
}

export interface ITrainClassChangeRequest {
  __typename?: 'TrainClassChangeRequest';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  employee: IEmployee;
  firstClassReason?: Maybe<IFirstClassReason>;
  id: Scalars['ID']['output'];
  motivation?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  subscription: IPersonalMeansOfRegistrationService;
  trainClass: ITrainClass;
}

export enum ITrainClassEnum {
  FIRST = 'FIRST',
  SECOND = 'SECOND'
}

export interface ITrainClassOptions {
  __typename?: 'TrainClassOptions';
  /** If an employee can switch train classes, but requires a reason to do so, this is the set of reasons that is available for his budget_group. */
  firstClassReasons?: Maybe<Array<IFirstClassReason>>;
  /** The train classes that can be requested (if any). */
  requestableTrainClasses?: Maybe<Array<ITrainClass>>;
  /** Whether a motivation is required when requesting a train class change */
  requiresMotivation?: Maybe<Scalars['Boolean']['output']>;
}

export interface ITraject {
  __typename?: 'Traject';
  /** Start-point of the traject */
  from?: Maybe<Scalars['String']['output']>;
  /** End-point of the traject */
  to?: Maybe<Scalars['String']['output']>;
}

export interface ITransactionsReport {
  __typename?: 'TransactionsReport';
  /** PDF URL of transactions report */
  pdfUrl?: Maybe<Scalars['String']['output']>;
  /** XLS URL of transactions report */
  xlsUrl?: Maybe<Scalars['String']['output']>;
}

export interface ITranzer {
  __typename?: 'Tranzer';
  create?: Maybe<ICreateTranzerReservationPayload>;
  refresh?: Maybe<IRefreshTranzerReservationPayload>;
}


export interface ITranzerCreateArgs {
  input: ICreateTranzerReservationInput;
}


export interface ITranzerRefreshArgs {
  input: IRefreshTranzerReservationInput;
}

export interface ITranzerEticket extends IAvailableFeatureInterface {
  __typename?: 'TranzerEticket';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface ITranzerLeg {
  __typename?: 'TranzerLeg';
  /** The agency for this specific leg */
  agency?: Maybe<Scalars['String']['output']>;
  /** The logo URL for the agency */
  agencyLogo?: Maybe<Scalars['String']['output']>;
  /** The departure platform for this leg. Nil if not applicable. */
  arrivalPlatform?: Maybe<Scalars['String']['output']>;
  bookingId?: Maybe<Scalars['ID']['output']>;
  /** The co2 emission for the leg, measured in kilograms */
  co2Emission: Scalars['Float']['output'];
  /** The departure platform for this leg. Nil if not applicable. */
  departurePlatform?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  fromLocation: IGeoLocation;
  icon: IIcon;
  /** The identifier of the leg */
  id: Scalars['ID']['output'];
  modality: ITravelMode;
  /** The polyline for this specific leg, which can be used to draw the route on a map */
  polyline?: Maybe<Scalars['String']['output']>;
  quoteId?: Maybe<Scalars['ID']['output']>;
  /** The name of the external service that was used to obtain information. */
  service: Scalars['String']['output'];
  startTime: Scalars['DateTime']['output'];
  /** The pre-purchased tickets for the leg. */
  tickets: Array<ITicket>;
  toLocation: IGeoLocation;
}

export interface ITranzerReservation {
  __typename?: 'TranzerReservation';
  createdAt: Scalars['DateTime']['output'];
  endTime: Scalars['DateTime']['output'];
  externalId: Scalars['ID']['output'];
  /** The booking id */
  id: Scalars['ID']['output'];
  legs?: Maybe<Array<ITranzerLeg>>;
  startTime: Scalars['DateTime']['output'];
  status?: Maybe<ITranzerStatus>;
  totalCosts: IMoney;
  /** Total duration in seconds. */
  totalDuration: Scalars['Int']['output'];
}

export interface ITranzerStatus {
  __typename?: 'TranzerStatus';
  humanized: Scalars['String']['output'];
  value: ITranzerStatusEnum;
}

export enum ITranzerStatusEnum {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  ERROR = 'error'
}

export interface ITravelBehaviour {
  __typename?: 'TravelBehaviour';
  /** A breakdown of all modalities that you used for business travel purposes */
  business?: Maybe<IPurpose>;
  /** A breakdown of all modalities that you used for commute travel purposes */
  commute?: Maybe<IPurpose>;
  /** A breakdown of all modalities that you used for personal travel purposes */
  personal?: Maybe<IPurpose>;
  /** The total distance and co2 you are allowed to use in a month */
  usageForNorm?: Maybe<IUsage>;
  /** The total distance and co2 you have remaining to use this month */
  usageRemaining?: Maybe<IUsage>;
}

export interface ITravelMode {
  __typename?: 'TravelMode';
  humanized: Scalars['String']['output'];
  value: ITravelModeEnum;
}

export enum ITravelModeEnum {
  BUS = 'bus',
  METRO = 'metro',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER = 'transfer',
  WALKING = 'walking'
}

export interface ITravelPurpose {
  __typename?: 'TravelPurpose';
  humanized: Scalars['String']['output'];
  value?: Maybe<ITravelPurposeEnum>;
}

export enum ITravelPurposeEnum {
  BUSINESS = 'BUSINESS',
  COMMUTE = 'COMMUTE',
  PERSONAL = 'PERSONAL'
}

export interface ITravelPurposeFinancialConsequences {
  __typename?: 'TravelPurposeFinancialConsequences';
  /** The total gross and net charge of all mobility events within this travel purpose */
  totalGrossAndNetCharge: IBudget;
  /** The total gross and net reimbursement of all mobility events within this travel purpose */
  totalGrossAndNetReimbursement: IBudget;
  /** Travel purpose */
  travelPurpose: ITravelPurposeWithUnknown;
}

export interface ITravelPurposeWithUnknown {
  __typename?: 'TravelPurposeWithUnknown';
  humanized: Scalars['String']['output'];
  value?: Maybe<ITravelPurposeWithUnknownEnum>;
}

export enum ITravelPurposeWithUnknownEnum {
  BUSINESS = 'BUSINESS',
  COMMUTE = 'COMMUTE',
  PERSONAL = 'PERSONAL',
  UNKNOWN = 'UNKNOWN'
}

export interface ITravelerMutation {
  firstName?: InputMaybe<Scalars['String']['input']>;
  infix?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
}

export enum ITypeEnum {
  HOME = 'HOME',
  SHARED_VEHICLES_LOCATION = 'SHARED_VEHICLES_LOCATION',
  WORK = 'WORK'
}

/** Autogenerated input type of UnacceptMobilityEvent */
export interface IUnacceptMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventId: Scalars['ID']['input'];
}

/** Autogenerated return type of UnacceptMobilityEvent. */
export interface IUnacceptMobilityEventPayload {
  __typename?: 'UnacceptMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UnacceptMobilityEvents */
export interface IUnacceptMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of UnacceptMobilityEvents. */
export interface IUnacceptMobilityEventsPayload {
  __typename?: 'UnacceptMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notUnacceptedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  unacceptedMobilityEvents: Array<IMobilityEvent>;
}

/** Autogenerated input type of UnconfirmMobilityEvent */
export interface IUnconfirmMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventId: Scalars['ID']['input'];
}

/** Autogenerated return type of UnconfirmMobilityEvent. */
export interface IUnconfirmMobilityEventPayload {
  __typename?: 'UnconfirmMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UnconfirmMobilityEvents */
export interface IUnconfirmMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
}

/** Autogenerated return type of UnconfirmMobilityEvents. */
export interface IUnconfirmMobilityEventsPayload {
  __typename?: 'UnconfirmMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  notUnconfirmedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  unconfirmedMobilityEvents: Array<IMobilityEvent>;
}

export enum IUnitEnum {
  GRAMS = 'GRAMS',
  METERS = 'METERS',
  MILLILITERS = 'MILLILITERS',
  SECONDS = 'SECONDS',
  WATTS_PER_HOUR = 'WATTS_PER_HOUR'
}

/** Autogenerated input type of UnlockDonkeyRepublicAsset */
export interface IUnlockDonkeyRepublicAssetInput {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Coordinate']['input'];
  locationName: Scalars['String']['input'];
  longitude: Scalars['Coordinate']['input'];
}

/** Autogenerated return type of UnlockDonkeyRepublicAsset. */
export interface IUnlockDonkeyRepublicAssetPayload {
  __typename?: 'UnlockDonkeyRepublicAssetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donkeyRepublicReservation?: Maybe<IDonkeyRepublicReservation>;
  donkeyRepublicResult?: Maybe<IDonkeyRepublicResult>;
  result: IDonkeyRepublicResult;
}

/** Autogenerated input type of UpdateFavorite */
export interface IUpdateFavoriteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the favorite you'd like to update */
  id: Scalars['ID']['input'];
  /** A human friendly name to recognize this favorite, for example 'Reisbalans'. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** The latitude of the location. */
  latitude?: InputMaybe<Scalars['Coordinate']['input']>;
  /** The fill address information as string, for example 'Utrechtseweg 9, 3811NA Amersfoort'. */
  location?: InputMaybe<Scalars['String']['input']>;
  /** An enriched location object with complete address address informat from goolge places */
  locationId?: InputMaybe<Scalars['ID']['input']>;
  /** The longitude of the location. */
  longitude?: InputMaybe<Scalars['Coordinate']['input']>;
  /** The type of favorite this is. */
  type?: InputMaybe<IFavoriteTypeEnum>;
}

/** Autogenerated return type of UpdateFavorite. */
export interface IUpdateFavoritePayload {
  __typename?: 'UpdateFavoritePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** If everything was ok, this contains your new favorite record */
  favorite?: Maybe<IFavorite>;
  /** Whether the request was a success or not */
  result: IResult;
  /** If something was not ok, you can find the reason here */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateMileage */
export interface IUpdateMileageInput {
  amountInKilometers?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the mileage you'd like to update */
  id: Scalars['ID']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  recordedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

/** Autogenerated return type of UpdateMileage. */
export interface IUpdateMileagePayload {
  __typename?: 'UpdateMileagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Added mileage */
  mileage?: Maybe<IMileage>;
  /** Result of adding a mileage */
  result: IResult;
  /** List of validation errors while adding a mileage */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateMobilityEvent */
export interface IUpdateMobilityEventInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the mobility event can be confirmed right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the employment that you want to link this mobility event to */
  employmentId?: InputMaybe<Scalars['ID']['input']>;
  financial?: InputMaybe<IFinancialMutation>;
  happenedAt?: InputMaybe<Scalars['DateTime']['input']>;
  mobilityEventId: Scalars['ID']['input'];
  /** Whether or not to recalculate the consumption */
  recalculate?: InputMaybe<Scalars['Boolean']['input']>;
  route?: InputMaybe<IUpdateRouteMutation>;
  /** DEPRECATED. Submit used to be a synonym for confirm, use confirm instead */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  what?: InputMaybe<IUpdateWhatMutation>;
  why?: InputMaybe<IUpdateWhyMutation>;
}

/** Autogenerated return type of UpdateMobilityEvent. */
export interface IUpdateMobilityEventPayload {
  __typename?: 'UpdateMobilityEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mobilityEvent?: Maybe<IMobilityEvent>;
  result: IResult;
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateMobilityEvents */
export interface IUpdateMobilityEventsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not to confirm the mobility events right away */
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the employment that you want to link this mobility event to */
  employmentId?: InputMaybe<Scalars['ID']['input']>;
  mobilityEventIds: Array<Scalars['ID']['input']>;
  /** DEPRECATED. Submit used to be a synonym for confirm, use confirm instead */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  what?: InputMaybe<IUpdateWhatMutation>;
  why?: InputMaybe<IUpdateWhyMutation>;
}

/** Autogenerated return type of UpdateMobilityEvents. */
export interface IUpdateMobilityEventsPayload {
  __typename?: 'UpdateMobilityEventsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  days: Array<IDayWithMobilityEvents>;
  notUpdatedMobilityEvents: Array<IMobilityEvent>;
  result: IResult;
  updatedMobilityEvents: Array<IMobilityEvent>;
}

export interface IUpdateRouteMutation {
  /** Overridden distance in kilometers */
  distance?: InputMaybe<Scalars['Float']['input']>;
  diverted?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<ILocationMutation>;
  nonCombined?: InputMaybe<Scalars['Boolean']['input']>;
  to?: InputMaybe<ILocationMutation>;
}

/** Autogenerated input type of UpdateTaxiReservation */
export interface IUpdateTaxiReservationInput {
  at?: InputMaybe<Scalars['DateTime']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  departure?: InputMaybe<ILocationMutation>;
  destination?: InputMaybe<ILocationMutation>;
  passengers?: InputMaybe<Scalars['Int']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  taxiReservationId?: InputMaybe<Scalars['ID']['input']>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
}

/** Autogenerated return type of UpdateTaxiReservation. */
export interface IUpdateTaxiReservationPayload {
  __typename?: 'UpdateTaxiReservationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  taxiReservation?: Maybe<ITaxiReservation>;
}

/** Autogenerated input type of UpdateUserProfile */
export interface IUpdateUserProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Current password of the user. Required for a password change once the password is set, if left empty password is not changed. */
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth of the user */
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  /** Preferred purpose of supplier transactions */
  defaultMobilityCardTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Preferred purpose of supplier transactions on weekends */
  defaultMobilityCardWeekendTransactionTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Email address of the user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Employee number of the user, that is mostly used internally. */
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Fixed commute distance used by default for commute rides if enabled in the budget group. */
  fixedCommuteDistance?: InputMaybe<Scalars['Float']['input']>;
  /** Home address of the user */
  homeAddress?: InputMaybe<IUserProfileAddress>;
  /** Infix of the user */
  infix?: InputMaybe<Scalars['String']['input']>;
  /** Initials of the user */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** New password of the user. Required for a password change, if left empty password is not changed. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Confirmation of new password of the user. Required for a password change, if left empty password is not changed. */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the user. Accepted formats with fake phone numbers: +31612345678 (NL), +3214812345 (BE), +4912345678 (DE), 0612345678, 06-12345678, 0401234567, 040-1234567, 0455123456, 0455-123456, all other European numbers. See employee.rb for specific regex. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** User-preferred purpose of GPS registered rides */
  preferredTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
  /** Agreed Terms and conditions */
  termsAndConditionsDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Whether the users opted in to use the new interface or not. */
  usesNewInterface?: InputMaybe<Scalars['Boolean']['input']>;
  /** Work address of the user */
  workAddress?: InputMaybe<IUserProfileAddress>;
}

/** Autogenerated return type of UpdateUserProfile. */
export interface IUpdateUserProfilePayload {
  __typename?: 'UpdateUserProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Updated employee */
  me?: Maybe<IEmployee>;
  /** Returns whether the mutation was successful */
  result: IResult;
  /** Returns which provided attribute(s) didnt apply to the validation rules */
  validationErrors: Array<IValidationError>;
}

/** Autogenerated input type of UpdateVehicle */
export interface IUpdateVehicleInput {
  brand?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endsOn?: InputMaybe<Scalars['Date']['input']>;
  fuelType?: InputMaybe<IVehicleFuelTypeEnum>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startsOn: Scalars['Date']['input'];
  vehicleId: Scalars['ID']['input'];
}

/** Autogenerated return type of UpdateVehicle. */
export interface IUpdateVehiclePayload {
  __typename?: 'UpdateVehiclePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: IResult;
  validationErrors: Array<IValidationError>;
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface IUpdateWhatMutation {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  /** Reimbursable category method. If given, properties for the other reimbursable category are nullified in the backend since theyre mutually exclusive. */
  reimbursableCategoryMethodValue?: InputMaybe<IReimbursableCategoryMethodEnum>;
  sharedRidePassengers?: InputMaybe<Array<Scalars['String']['input']>>;
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}

export interface IUpdateWhyMutation {
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Consumption amount in km */
  personalDistance?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  refundedMobilityEventId?: InputMaybe<Scalars['ID']['input']>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}

export interface IUsage {
  __typename?: 'Usage';
  /** The total CO2 emission emitted during the travelled distance */
  co2EmissionInGrams?: Maybe<Scalars['Int']['output']>;
  /** The total distance in meters */
  distanceInMeters?: Maybe<Scalars['Int']['output']>;
}

export interface IUserProfileAddress {
  city?: InputMaybe<Scalars['String']['input']>;
  countryIsoCode?: InputMaybe<ICountryIsoCodeEnum>;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  streetNumberSuffix?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
}

/** Information on budget groups */
export interface IV2BudgetGroup {
  __typename?: 'V2BudgetGroup';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** Information on organization */
export interface IV2Organization {
  __typename?: 'V2Organization';
  /** Published budget groups */
  budgetGroups?: Maybe<Array<IV2BudgetGroup>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface IValidationError {
  __typename?: 'ValidationError';
  /** Attribute with errors */
  attribute: Scalars['String']['output'];
  /** Full error as a sentence */
  fullMessages: Array<Scalars['String']['output']>;
  /** Individual error messages for the attribute */
  messages: Array<Scalars['String']['output']>;
}

export interface IVatRate {
  __typename?: 'VatRate';
  humanized: Scalars['String']['output'];
}

export interface IVehicle {
  __typename?: 'Vehicle';
  create?: Maybe<ICreateVehiclePayload>;
  terminate?: Maybe<ITerminateVehiclePayload>;
  update?: Maybe<IUpdateVehiclePayload>;
}


export interface IVehicleCreateArgs {
  input: ICreateVehicleInput;
}


export interface IVehicleTerminateArgs {
  input: ITerminateVehicleInput;
}


export interface IVehicleUpdateArgs {
  input: IUpdateVehicleInput;
}

export interface IVehicleActions {
  __typename?: 'VehicleActions';
  editable: Scalars['Boolean']['output'];
  terminatable: Scalars['Boolean']['output'];
}

export interface IVehicleAsset extends IMultiModalAvailability {
  __typename?: 'VehicleAsset';
  /** Estimated battery level of this vehicle as a percentage, ranging from 0-100 */
  batteryLevel?: Maybe<Scalars['Int']['output']>;
  deeplink?: Maybe<Scalars['String']['output']>;
  /** Optional details about the vehicle */
  description?: Maybe<Scalars['String']['output']>;
  geoLocation: ICoordinates;
  icon: IIcon;
  /** Unique identifier for this particular vehicle */
  id: Scalars['String']['output'];
  modality: IJourneyMode;
  /** Human readable name for this asset */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the operator for this vehicle */
  operator: Scalars['String']['output'];
  /** Estimated range for this vehicle without needing to refuel, in meters */
  range?: Maybe<Scalars['Int']['output']>;
  serviceOperator?: Maybe<IServiceOperator>;
  type: Scalars['String']['output'];
  /** Name of the type of this vehicle */
  vehicleTypeName?: Maybe<Scalars['String']['output']>;
}

export enum IVehicleFuelTypeEnum {
  BIO_ETHANOL = 'BIO_ETHANOL',
  CNG = 'CNG',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  HYBRID = 'HYBRID',
  HYBRID_DIESEL = 'HYBRID_DIESEL',
  HYBRID_PETROL = 'HYBRID_PETROL',
  HYBRID_PLUG_IN_PETROL = 'HYBRID_PLUG_IN_PETROL',
  HYDROGEN = 'HYDROGEN',
  LPG = 'LPG',
  OTHER = 'OTHER',
  PETROL = 'PETROL'
}

export interface IVehicleInformation {
  __typename?: 'VehicleInformation';
  brand?: Maybe<Scalars['String']['output']>;
  co2EmissionPerKilometer?: Maybe<Scalars['Int']['output']>;
  energyLabel?: Maybe<Scalars['String']['output']>;
  fuelType?: Maybe<IFuel>;
  model?: Maybe<Scalars['String']['output']>;
}

export interface IVehicleModalityKind {
  __typename?: 'VehicleModalityKind';
  humanized: Scalars['String']['output'];
  value: IModalityKindEnum;
}

export interface IVehicleOptions {
  __typename?: 'VehicleOptions';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export enum IVehicleStatusEnum {
  ARCHIVED = 'ARCHIVED',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE'
}

export interface IWhat {
  __typename?: 'What';
  /** Category of this mobility event. Examples: Ride, Parking costs, other, etc. */
  category: ICategory;
  /** Indicates whether the what of this mobility event is editable */
  editable: Scalars['Boolean']['output'];
  /** Humanized string, made up of category and modality */
  humanized: Scalars['String']['output'];
  /** Icon that corresponds to the category of the mobility event */
  icon: IIcon;
  /** @deprecated This field can be either a vehicle (if present) or a modality, you should use the vehicle and / or modality fields instead. Be aware, this field can only be used with fields that exist on both a vehicle and a modality. */
  meansOfTransport?: Maybe<IMeansOfTransport>;
  /** In case of the mobility event category being a ride, the modality field returns the way this ride was made. Examples: Public transport, car, bike, etc. */
  modality?: Maybe<IModality>;
  /** The reimbursable category method. Ride, Route or Both */
  reimbursableCategoryMethod: IReimbursableCategoryMethod;
  /** If this was a shared ride, this field returns the list of names of the passengers that shared the ride with you */
  sharedRidePassengers?: Maybe<Array<Scalars['String']['output']>>;
  /** In case of the mobility event category being a ride, the vehicle field returns the specific vehicle that was used for this ride */
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface IWhatOption {
  __typename?: 'WhatOption';
  category: ICategory;
  icon: IIcon;
  modality?: Maybe<IModality>;
  /** Which reimbursable category method can be chosen */
  reimbursableCategoryMethod?: Maybe<IReimbursableCategoryMethod>;
  vehicle?: Maybe<IMeansOfTransport>;
}

export interface IWhen {
  editable: Scalars['Boolean']['output'];
}

export type IWhere = ILocation | IRentalLocations | IRoute;

export interface IWhy {
  __typename?: 'Why';
  /** @deprecated Use diverted on Route instead of Why */
  diverted?: Maybe<IDiverted>;
  /** Indicates whether the why of this mobility event is editable */
  editable: Scalars['Boolean']['output'];
  explanation?: Maybe<IExplanation>;
  /** Humanized value for ride that was refunded by this refund */
  humanizedRefundedMobilityEvent?: Maybe<Scalars['String']['output']>;
  legs?: Maybe<Array<ILeg>>;
  /** Consumption amount in kilometers when transaction is distance based */
  personalDistance?: Maybe<Scalars['Int']['output']>;
  project?: Maybe<IProject>;
  /** Ride that was refunded by this refund */
  refundedMobilityEventId?: Maybe<Scalars['Int']['output']>;
  stateTransitions: IStateTransitions;
  /** @deprecated Deprecated because of move from submitted -> confirmed. Status can now be retrieved directly on the mobilityEvent via mobilityEvent.status. */
  status: IWhyStatus;
  travelPurpose: ITravelPurpose;
}

export interface IWhyMutation {
  explanation?: InputMaybe<Scalars['String']['input']>;
  /** Personal consumption amount in kilometers */
  personalDistance?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  travelPurpose: ITravelPurposeEnum;
}

export interface IWhyStatus {
  __typename?: 'WhyStatus';
  humanized: Scalars['String']['output'];
  label: Scalars['String']['output'];
  /** Mobility event status with submitted instead of confirmed */
  value: IStatusEnum;
}

export interface IWrpm {
  __typename?: 'Wrpm';
  modality?: Maybe<IWrpmModality>;
  /** Total stats for this modality for business and commute travel purposes */
  statsPerModality?: Maybe<IStatsPerModality>;
  /** Total stats for this modality for travel purpose business */
  statsPerModalityBusiness?: Maybe<IStatsPerModality>;
  /** Total stats for this modality for travel purpose commute */
  statsPerModalityCommute?: Maybe<IStatsPerModality>;
}

export interface IWrpmModality {
  __typename?: 'WrpmModality';
  humanized?: Maybe<Scalars['String']['output']>;
  value?: Maybe<IWrpmModalityEnum>;
}

export enum IWrpmModalityEnum {
  CAR_DIESEL = 'CAR_DIESEL',
  CAR_ELECTRIC = 'CAR_ELECTRIC',
  CAR_HYBRID = 'CAR_HYBRID',
  CAR_OTHER = 'CAR_OTHER',
  CAR_PETROL = 'CAR_PETROL',
  LEGS_OR_BICYCLE = 'LEGS_OR_BICYCLE',
  MOTORCYCLE_ELECTRIC = 'MOTORCYCLE_ELECTRIC',
  MOTORCYCLE_PETROL = 'MOTORCYCLE_PETROL',
  OV = 'OV',
  SCOOTER_ELECTRIC = 'SCOOTER_ELECTRIC',
  SCOOTER_PETROL = 'SCOOTER_PETROL'
}

export interface IYellowbrickService {
  __typename?: 'YellowbrickService';
  identity?: Maybe<IPersonalMeansOfRegistrationIdentity>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface IYellowbrickSp extends IAvailableFeatureInterface {
  __typename?: 'YellowbrickSp';
  /** Whether this feature requires activation */
  activatable: Scalars['Boolean']['output'];
  /** The agreed terms of this feature */
  agreedTermsAndConditions?: Maybe<Array<ITermsAndConditions>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of this service */
  description?: Maybe<Scalars['String']['output']>;
  /** Icon for the available feature, based on the type */
  icon: IIcon;
  /** Internal ID used for this feature */
  id?: Maybe<Scalars['ID']['output']>;
  mobideskOwnershipToken?: Maybe<Scalars['Int']['output']>;
  motivation?: Maybe<Scalars['String']['output']>;
  /** Name of this feature */
  name: IFeatureOption;
  /** Current status of this feature */
  status: IFeatureOrderStatus;
  urls?: Maybe<IFeatureUrls>;
}

export interface IZone {
  __typename?: 'Zone';
  humanized: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface IReisbalansMutation {
  __typename?: 'reisbalansMutation';
  acceptMobilityEvents?: Maybe<IAcceptMobilityEventsPayload>;
  activateEnecoDongle?: Maybe<IActivateEnecoDonglePayload>;
  activatePersonalMeansOfRegistration?: Maybe<IActivatePersonalMeansOfRegistrationPayload>;
  addPushNotificationToken?: Maybe<IAddPushNotificationTokenPayload>;
  /** Login admin with email and password. */
  adminLogin?: Maybe<IAdminLoginPayload>;
  assignReplacementManager?: Maybe<IAssignReplacementManagerPayload>;
  /** @deprecated Use cancelSharedVehicleInformation */
  cancelSharedCarReservation?: Maybe<ICancelSharedCarReservationPayload>;
  cancelSharedVehicleReservation?: Maybe<ICancelSharedVehicleReservationPayload>;
  /** @deprecated Use the taxi/cancel endpoint please */
  cancelTaxiReservation?: Maybe<ICancelTaxiReservationPayload>;
  /** Configure 2FA for this user */
  configureMfa?: Maybe<IConfigureMfaPayload>;
  confirmMobilityEvent?: Maybe<IConfirmMobilityEventPayload>;
  confirmMobilityEvents?: Maybe<IConfirmMobilityEventsPayload>;
  /** @deprecated Use confirmSharedVehicleReservatoin */
  confirmSharedCarReservation?: Maybe<IConfirmSharedCarReservationPayload>;
  confirmSharedVehicleReservation?: Maybe<IConfirmSharedVehicleReservationPayload>;
  /** @deprecated Use the taxi/confirm endpoint please */
  confirmTaxiReservation?: Maybe<IConfirmTaxiReservationPayload>;
  copyMobilityEvents?: Maybe<ICopyMobilityEventsPayload>;
  copyMobilityEventsForDay?: Maybe<ICopyMobilityEventsForDayPayload>;
  createCommuteCompensation?: Maybe<ICreateCommuteCompensationPayload>;
  createCommuteRides?: Maybe<ICreateCommuteRidesPayload>;
  /** @deprecated Done automatically by backend after linking fine with the missed trip */
  createCompensationMobilityEvent?: Maybe<ICreateCompensationMobilityEventPayload>;
  createMobilityEvent?: Maybe<ICreateMobilityEventPayload>;
  /** @deprecated Use the taxi/create endpoint please */
  createTaxiReservation?: Maybe<ICreateTaxiReservationPayload>;
  /** DEPRECATED. Only used by Freddi to update employees. Still used by RBW to reset password. */
  createUserProfile?: Maybe<ICreateUserProfilePayload>;
  /** @deprecated Use the vehicle/create endpoint instead */
  createVehicle?: Maybe<ICreateVehiclePayload>;
  createWorkFromHome?: Maybe<ICreateWorkFromHomePayload>;
  destroyMobilityEvent?: Maybe<IDestroyMobilityEventPayload>;
  destroyMobilityEvents?: Maybe<IDestroyMobilityEventsPayload>;
  destroyProfileAddress?: Maybe<IDestroyProfileAddressPayload>;
  directDebitMandates?: Maybe<IDirectDebitMandates>;
  donkeyRepublic?: Maybe<IDonkeyRepublicBooking>;
  favorites?: Maybe<IFavorites>;
  /** @deprecated Use finishSharedVehicleReservation */
  finishSharedCarReservation?: Maybe<IFinishSharedCarReservationPayload>;
  finishSharedVehicleReservation?: Maybe<IFinishSharedVehicleReservationPayload>;
  leaseBikeOrder?: Maybe<ILeaseBikeOrder>;
  /** Login user with email and password. It creates a new session for the user and it optionally returns a token. */
  login?: Maybe<ILoginPayload>;
  /** Logout user. Destroys the session, optionally receives a token that will be revoked */
  logout?: Maybe<ILogoutPayload>;
  markMessageAsRead?: Maybe<IMarkMessageAsReadPayload>;
  mergeMobilityEvents?: Maybe<IMergeMobilityEventsPayload>;
  mileages?: Maybe<IMileages>;
  mobilityCardOrders?: Maybe<IMobilityCardOrders>;
  parking?: Maybe<IParking>;
  rejectMobilityEvents?: Maybe<IRejectMobilityEventsPayload>;
  reportPersonalMeansOfRegistration?: Maybe<IReportPersonalMeansOfRegistrationPayload>;
  /** @deprecated Use requestFeature instead */
  requestDonkeyRepublicFeature?: Maybe<IRequestDonkeyRepublicFeaturePayload>;
  requestFeature?: Maybe<IRequestFeaturePayload>;
  requestTrainClassChange?: Maybe<IRequestTrainClassChangePayload>;
  resetPassword?: Maybe<IResetPasswordPayload>;
  reuploadPhoto?: Maybe<IReuploadPhotoPayload>;
  sendNewInvitationLink?: Maybe<ISendNewInvitationLinkPayload>;
  sendPasswordResetMail?: Maybe<ISendPasswordResetMailPayload>;
  sendPrivacyRequestMail?: Maybe<ISendPrivacyRequestMailPayload>;
  services?: Maybe<IServices>;
  setAgreeTerms?: Maybe<ISetAgreeTermsPayload>;
  splitBillingContracts?: Maybe<ISplitBillingContracts>;
  /** @deprecated Use startSharedVehicleReservation */
  startSharedCarReservation?: Maybe<IStartSharedCarReservationPayload>;
  startSharedVehicleReservation?: Maybe<IStartSharedVehicleReservationPayload>;
  studentTravelProduct?: Maybe<IStudentTravelProduct>;
  taxi?: Maybe<ITaxi>;
  terminateOwnership?: Maybe<ITerminateOwnershipPayload>;
  terminatePersonalMeansOfRegistration?: Maybe<ITerminatePersonalMeansOfRegistrationPayload>;
  terminateReplacementManager?: Maybe<ITerminateReplacementManagerPayload>;
  /** @deprecated Use the vehicle/terminate endpoint instead */
  terminateVehicle?: Maybe<ITerminateVehiclePayload>;
  tranzer?: Maybe<ITranzer>;
  unacceptMobilityEvent?: Maybe<IUnacceptMobilityEventPayload>;
  unacceptMobilityEvents?: Maybe<IUnacceptMobilityEventsPayload>;
  unconfirmMobilityEvent?: Maybe<IUnconfirmMobilityEventPayload>;
  unconfirmMobilityEvents?: Maybe<IUnconfirmMobilityEventsPayload>;
  updateMobilityEvent?: Maybe<IUpdateMobilityEventPayload>;
  updateMobilityEvents?: Maybe<IUpdateMobilityEventsPayload>;
  updateTaxiReservation?: Maybe<IUpdateTaxiReservationPayload>;
  updateUserProfile?: Maybe<IUpdateUserProfilePayload>;
  vehicle?: Maybe<IVehicle>;
}


export interface IReisbalansMutationAcceptMobilityEventsArgs {
  input: IAcceptMobilityEventsInput;
}


export interface IReisbalansMutationActivateEnecoDongleArgs {
  input: IActivateEnecoDongleInput;
}


export interface IReisbalansMutationActivatePersonalMeansOfRegistrationArgs {
  input: IActivatePersonalMeansOfRegistrationInput;
}


export interface IReisbalansMutationAddPushNotificationTokenArgs {
  input: IAddPushNotificationTokenInput;
}


export interface IReisbalansMutationAdminLoginArgs {
  input: IAdminLoginInput;
}


export interface IReisbalansMutationAssignReplacementManagerArgs {
  input: IAssignReplacementManagerInput;
}


export interface IReisbalansMutationCancelSharedCarReservationArgs {
  input: ICancelSharedCarReservationInput;
}


export interface IReisbalansMutationCancelSharedVehicleReservationArgs {
  input: ICancelSharedVehicleReservationInput;
}


export interface IReisbalansMutationCancelTaxiReservationArgs {
  input: ICancelTaxiReservationInput;
}


export interface IReisbalansMutationConfigureMfaArgs {
  input: IConfigureMfaInput;
}


export interface IReisbalansMutationConfirmMobilityEventArgs {
  input: IConfirmMobilityEventInput;
}


export interface IReisbalansMutationConfirmMobilityEventsArgs {
  input: IConfirmMobilityEventsInput;
}


export interface IReisbalansMutationConfirmSharedCarReservationArgs {
  input: IConfirmSharedCarReservationInput;
}


export interface IReisbalansMutationConfirmSharedVehicleReservationArgs {
  input: IConfirmSharedVehicleReservationInput;
}


export interface IReisbalansMutationConfirmTaxiReservationArgs {
  input: IConfirmTaxiReservationInput;
}


export interface IReisbalansMutationCopyMobilityEventsArgs {
  input: ICopyMobilityEventsInput;
}


export interface IReisbalansMutationCopyMobilityEventsForDayArgs {
  input: ICopyMobilityEventsForDayInput;
}


export interface IReisbalansMutationCreateCommuteCompensationArgs {
  input: ICreateCommuteCompensationInput;
}


export interface IReisbalansMutationCreateCommuteRidesArgs {
  input: ICreateCommuteRidesInput;
}


export interface IReisbalansMutationCreateCompensationMobilityEventArgs {
  input: ICreateCompensationMobilityEventInput;
}


export interface IReisbalansMutationCreateMobilityEventArgs {
  input: ICreateMobilityEventInput;
}


export interface IReisbalansMutationCreateTaxiReservationArgs {
  input: ICreateTaxiReservationInput;
}


export interface IReisbalansMutationCreateUserProfileArgs {
  input: ICreateUserProfileInput;
}


export interface IReisbalansMutationCreateVehicleArgs {
  input: ICreateVehicleInput;
}


export interface IReisbalansMutationCreateWorkFromHomeArgs {
  input: ICreateWorkFromHomeInput;
}


export interface IReisbalansMutationDestroyMobilityEventArgs {
  input: IDestroyMobilityEventInput;
}


export interface IReisbalansMutationDestroyMobilityEventsArgs {
  input: IDestroyMobilityEventsInput;
}


export interface IReisbalansMutationDestroyProfileAddressArgs {
  input: IDestroyProfileAddressInput;
}


export interface IReisbalansMutationFinishSharedCarReservationArgs {
  input: IFinishSharedCarReservationInput;
}


export interface IReisbalansMutationFinishSharedVehicleReservationArgs {
  input: IFinishSharedVehicleReservationInput;
}


export interface IReisbalansMutationLoginArgs {
  input: ILoginInput;
}


export interface IReisbalansMutationLogoutArgs {
  input: ILogoutInput;
}


export interface IReisbalansMutationMarkMessageAsReadArgs {
  input: IMarkMessageAsReadInput;
}


export interface IReisbalansMutationMergeMobilityEventsArgs {
  input: IMergeMobilityEventsInput;
}


export interface IReisbalansMutationRejectMobilityEventsArgs {
  input: IRejectMobilityEventsInput;
}


export interface IReisbalansMutationReportPersonalMeansOfRegistrationArgs {
  input: IReportPersonalMeansOfRegistrationInput;
}


export interface IReisbalansMutationRequestDonkeyRepublicFeatureArgs {
  input: IRequestDonkeyRepublicFeatureInput;
}


export interface IReisbalansMutationRequestFeatureArgs {
  input: IRequestFeatureInput;
}


export interface IReisbalansMutationRequestTrainClassChangeArgs {
  input: IRequestTrainClassChangeInput;
}


export interface IReisbalansMutationResetPasswordArgs {
  input: IResetPasswordInput;
}


export interface IReisbalansMutationReuploadPhotoArgs {
  input: IReuploadPhotoInput;
}


export interface IReisbalansMutationSendNewInvitationLinkArgs {
  input: ISendNewInvitationLinkInput;
}


export interface IReisbalansMutationSendPasswordResetMailArgs {
  input: ISendPasswordResetMailInput;
}


export interface IReisbalansMutationSendPrivacyRequestMailArgs {
  input: ISendPrivacyRequestMailInput;
}


export interface IReisbalansMutationSetAgreeTermsArgs {
  input: ISetAgreeTermsInput;
}


export interface IReisbalansMutationStartSharedCarReservationArgs {
  input: IStartSharedCarReservationInput;
}


export interface IReisbalansMutationStartSharedVehicleReservationArgs {
  input: IStartSharedVehicleReservationInput;
}


export interface IReisbalansMutationTerminateOwnershipArgs {
  input: ITerminateOwnershipInput;
}


export interface IReisbalansMutationTerminatePersonalMeansOfRegistrationArgs {
  input: ITerminatePersonalMeansOfRegistrationInput;
}


export interface IReisbalansMutationTerminateReplacementManagerArgs {
  input: ITerminateReplacementManagerInput;
}


export interface IReisbalansMutationTerminateVehicleArgs {
  input: ITerminateVehicleInput;
}


export interface IReisbalansMutationUnacceptMobilityEventArgs {
  input: IUnacceptMobilityEventInput;
}


export interface IReisbalansMutationUnacceptMobilityEventsArgs {
  input: IUnacceptMobilityEventsInput;
}


export interface IReisbalansMutationUnconfirmMobilityEventArgs {
  input: IUnconfirmMobilityEventInput;
}


export interface IReisbalansMutationUnconfirmMobilityEventsArgs {
  input: IUnconfirmMobilityEventsInput;
}


export interface IReisbalansMutationUpdateMobilityEventArgs {
  input: IUpdateMobilityEventInput;
}


export interface IReisbalansMutationUpdateMobilityEventsArgs {
  input: IUpdateMobilityEventsInput;
}


export interface IReisbalansMutationUpdateTaxiReservationArgs {
  input: IUpdateTaxiReservationInput;
}


export interface IReisbalansMutationUpdateUserProfileArgs {
  input: IUpdateUserProfileInput;
}

export interface IReisbalansQuery {
  __typename?: 'reisbalansQuery';
  /** Convert the Base64 encoded data string, generated by reading the QR code of a user profile from the portal, to a valid apiToken */
  apiTokenForQrCode?: Maybe<IResultWithAccessTokens>;
  configurationProfileForHost?: Maybe<IConfigurationProfileForHost>;
  currentTime: Scalars['DateTime']['output'];
  customerService?: Maybe<ICustomerService>;
  employeeDetails?: Maybe<IEmployeeDetailsResponse>;
  /** All external URLs used throughout the application */
  externalUrls: IExternalUrls;
  /** Fuel types that can be used for vehicle */
  fuelTypes?: Maybe<Array<IFuel>>;
  getUserByInviteCode?: Maybe<IEmployee>;
  googlePlaces?: Maybe<IGooglePlaces>;
  inviteTokenValid?: Maybe<Scalars['Boolean']['output']>;
  /**
   * If you are logged in and supply a httponly cookie, you can request a JWT here.
   * @deprecated Use reauthorize field instead
   */
  jwt?: Maybe<Scalars['String']['output']>;
  me?: Maybe<IEmployee>;
  /**
   * Returns the url where refund can be claimed from NS for a delayed train journey
   * @deprecated Use externalUrls.nsRefundUrl instead
   */
  nsRefundUrl: INsRefundUrl;
  ovFietsLocationsByCoordinates?: Maybe<Array<IOvFietsLocation>>;
  ovFietsLocationsByFavoriteId?: Maybe<Array<IOvFietsLocation>>;
  password?: Maybe<IPassword>;
  places?: Maybe<IPlaces>;
  /** If you are logged in and supply a httponly cookie, you can request a pair of access and refresh tokens here. */
  reauthorize?: Maybe<IAuth>;
  /** Returns wether SSO is enabled or not and an authentication url */
  ssoEnabled?: Maybe<ISsoEnabled>;
  /** All NS Stations */
  stations?: Maybe<Array<IStation>>;
  supportedVersion?: Maybe<ISupportedVersion>;
  /** Terms documents for the current platform and brand. */
  termsAndConditionsDocuments?: Maybe<Array<ITermsAndConditionsDocument>>;
  theme?: Maybe<ITheme>;
  /** @deprecated Only use in the app to migrate. */
  tokens?: Maybe<IAuth>;
  vehicleInformation?: Maybe<IVehicleInformation>;
}


export interface IReisbalansQueryApiTokenForQrCodeArgs {
  data: Scalars['String']['input'];
  tokenType?: InputMaybe<ITokenTypeEnum>;
}


export interface IReisbalansQueryConfigurationProfileForHostArgs {
  host: Scalars['String']['input'];
}


export interface IReisbalansQueryCustomerServiceArgs {
  host?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
}


export interface IReisbalansQueryEmployeeDetailsArgs {
  key: Scalars['String']['input'];
}


export interface IReisbalansQueryGetUserByInviteCodeArgs {
  email: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
}


export interface IReisbalansQueryInviteTokenValidArgs {
  inviteToken: Scalars['String']['input'];
}


export interface IReisbalansQueryJwtArgs {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
}


export interface IReisbalansQueryOvFietsLocationsByCoordinatesArgs {
  at: ICoordinatesMutation;
  radius: Scalars['Int']['input'];
}


export interface IReisbalansQueryOvFietsLocationsByFavoriteIdArgs {
  id: Scalars['ID']['input'];
  radius: Scalars['Int']['input'];
}


export interface IReisbalansQueryReauthorizeArgs {
  refreshToken: Scalars['String']['input'];
}


export interface IReisbalansQuerySsoEnabledArgs {
  username: Scalars['String']['input'];
}


export interface IReisbalansQueryThemeArgs {
  uuid: Scalars['String']['input'];
}


export interface IReisbalansQueryVehicleInformationArgs {
  licensePlate: Scalars['String']['input'];
}

export interface IReisbalansV2Query {
  __typename?: 'reisbalansV2Query';
  /** Get all OV-pas details */
  ovPas: IOvPas;
  /** Get information on a given organization */
  v2Organization: IV2Organization;
  /** Get all WRPM information */
  wrpm?: Maybe<Array<IWrpm>>;
}


export interface IReisbalansV2QueryV2OrganizationArgs {
  organizationId: Scalars['ID']['input'];
}


export interface IReisbalansV2QueryWrpmArgs {
  forBudgetGroup: Scalars['Boolean']['input'];
  polymorphicId: Scalars['ID']['input'];
}

export type IGetFavoritesWithFallbackQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFavoritesWithFallbackQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'becameEmployee'>
    & { favorites?: Maybe<Array<(
      { __typename?: 'Favorite' }
      & Pick<IFavorite, 'id' | 'type'>
    )>> }
  )> }
);

export type ISetManualFavoriteCoordinatesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  latitude: Scalars['Coordinate']['input'];
  longitude: Scalars['Coordinate']['input'];
}>;


export type ISetManualFavoriteCoordinatesMutation = (
  { __typename?: 'Mutation' }
  & { favorites?: Maybe<(
    { __typename?: 'Favorites' }
    & { setManualCoordinates?: Maybe<(
      { __typename?: 'SetManualFavoriteCoordinatesPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )> }
    )> }
  )> }
);

export type IGetFavoriteLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFavoriteLocationsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { favorites?: Maybe<Array<(
      { __typename?: 'Favorite' }
      & Pick<IFavorite, 'label' | 'location' | 'locationId' | 'id'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      ) }
    )>> }
  )> }
);

export type IGetPlaceDetailsQueryVariables = Exact<{
  placeId: Scalars['String']['input'];
}>;


export type IGetPlaceDetailsQuery = (
  { __typename?: 'Query' }
  & { googlePlaces?: Maybe<(
    { __typename?: 'GooglePlaces' }
    & { details?: Maybe<(
      { __typename?: 'GooglePlacesDetailsResult' }
      & Pick<IGooglePlacesDetailsResult, 'address' | 'name' | 'placeId' | 'locationId'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )> }
    )> }
  )>, places?: Maybe<(
    { __typename?: 'Places' }
    & { details?: Maybe<(
      { __typename?: 'PlacesDetailsResult' }
      & Pick<IPlacesDetailsResult, 'address' | 'name' | 'placeId' | 'locationId'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )> }
    )> }
  )> }
);

export type IGetPlaceSuggestionsQueryVariables = Exact<{
  startsWith: Scalars['String']['input'];
}>;


export type IGetPlaceSuggestionsQuery = (
  { __typename?: 'Query' }
  & { places?: Maybe<(
    { __typename?: 'Places' }
    & { suggest?: Maybe<Array<(
      { __typename?: 'PlacesResult' }
      & Pick<IPlacesResult, 'placeId' | 'html' | 'text'>
    )>> }
  )>, me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { favorites?: Maybe<Array<(
      { __typename?: 'Favorite' }
      & Pick<IFavorite, 'id' | 'label' | 'location' | 'locationId'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      ) }
    )>> }
  )> }
);

export type IGetFuelTypeByLicensePlateQueryVariables = Exact<{
  licensePlate: Scalars['String']['input'];
}>;


export type IGetFuelTypeByLicensePlateQuery = (
  { __typename?: 'Query' }
  & { vehicleInformation?: Maybe<(
    { __typename?: 'VehicleInformation' }
    & { fuelType?: Maybe<(
      { __typename?: 'Fuel' }
      & Pick<IFuel, 'value'>
    )> }
  )> }
);

export type IGetActiveAndFutureVehiclesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetActiveAndFutureVehiclesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'becameEmployee' | 'id'>
    & { current: Array<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
      & { vehicleInformation?: Maybe<(
        { __typename?: 'VehicleInformation' }
        & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
        & { fuelType?: Maybe<(
          { __typename?: 'Fuel' }
          & Pick<IFuel, 'humanized' | 'value'>
        )> }
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, actions: (
        { __typename?: 'VehicleActions' }
        & Pick<IVehicleActions, 'editable' | 'terminatable'>
      ), modality: (
        { __typename?: 'Modality' }
        & { modalityOwnership?: Maybe<(
          { __typename?: 'ModalityOwnership' }
          & Pick<IModalityOwnership, 'value'>
        )> }
      ) }
    )>, future: Array<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
      & { vehicleInformation?: Maybe<(
        { __typename?: 'VehicleInformation' }
        & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
        & { fuelType?: Maybe<(
          { __typename?: 'Fuel' }
          & Pick<IFuel, 'humanized' | 'value'>
        )> }
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, actions: (
        { __typename?: 'VehicleActions' }
        & Pick<IVehicleActions, 'editable' | 'terminatable'>
      ), modality: (
        { __typename?: 'Modality' }
        & { modalityOwnership?: Maybe<(
          { __typename?: 'ModalityOwnership' }
          & Pick<IModalityOwnership, 'value'>
        )> }
      ) }
    )> }
  )> }
);

export type IRevertBetaInterfaceOptInMutationVariables = Exact<{ [key: string]: never; }>;


export type IRevertBetaInterfaceOptInMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile?: Maybe<(
    { __typename?: 'UpdateUserProfilePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IGetTaskListQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetTaskListQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEventsAggregate?: Maybe<(
      { __typename?: 'MobilityEventAggregate' }
      & Pick<IMobilityEventAggregate, 'count'>
    )>, tasks: Array<(
      { __typename: 'ActionRequired' }
      & Pick<IActionRequired, 'humanized' | 'message' | 'type'>
    ) | (
      { __typename: 'ActivatableDongle' }
      & Pick<IActivatableDongle, 'humanized' | 'message' | 'type'>
    ) | (
      { __typename: 'CompensationRideToBeCreated' }
      & Pick<ICompensationRideToBeCreated, 'incompleteRideId' | 'refundId' | 'humanized' | 'message' | 'type'>
    ) | (
      { __typename: 'MobilityEventsWeek' }
      & Pick<IMobilityEventsWeek, 'humanized' | 'message' | 'type'>
    ) | (
      { __typename: 'NewProduct' }
      & Pick<INewProduct, 'humanized' | 'message' | 'type'>
    ) | (
      { __typename: 'PendingApproval' }
      & Pick<IPendingApproval, 'humanized' | 'message' | 'type'>
    ) | (
      { __typename: 'RefundToBeLinked' }
      & Pick<IRefundToBeLinked, 'mobilityEventId' | 'humanized' | 'message' | 'type'>
    )> }
  )> }
);

export type ISetAgreeTermsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type ISetAgreeTermsMutation = (
  { __typename?: 'Mutation' }
  & { setAgreeTerms?: Maybe<(
    { __typename?: 'SetAgreeTermsPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ) }
  )> }
);

export type ICo2EmissionSummaryFragment = (
  { __typename?: 'Co2EmissionSummary' }
  & { mobilityEventsPerModality?: Maybe<Array<(
    { __typename?: 'Co2EmissionPerModality' }
    & { emission: (
      { __typename?: 'Co2Emission' }
      & Pick<ICo2Emission, 'humanized' | 'value'>
    ), modality?: Maybe<(
      { __typename?: 'Modality' }
      & Pick<IModality, 'humanized' | 'id' | 'kind' | 'clazz'>
    )> }
  )>>, totalEmission: (
    { __typename?: 'Co2Emission' }
    & Pick<ICo2Emission, 'humanized' | 'value'>
  ) }
);

export type IDirectDebitInvoiceFragment = (
  { __typename?: 'DirectDebitInvoice' }
  & Pick<IDirectDebitInvoice, 'id' | 'externalReference' | 'invoiceDate' | 'pdfUrl'>
  & { settlement?: Maybe<(
    { __typename?: 'Settlement' }
    & Pick<ISettlement, 'id'>
  )>, status?: Maybe<(
    { __typename?: 'InvoiceStatus' }
    & Pick<IInvoiceStatus, 'humanized'>
  )> }
);

export type IDirectDebitMandateFragment = (
  { __typename?: 'DirectDebitMandate' }
  & Pick<IDirectDebitMandate, 'id' | 'uuid' | 'acceptanceUrl' | 'acceptedAt' | 'ecurringCustomerToken' | 'ecurringSubscriptionToken' | 'isAccepted' | 'isCustomerRegistered' | 'isRevoked' | 'isValid' | 'revokedAt'>
  & { status?: Maybe<(
    { __typename?: 'MandateStatus' }
    & Pick<IMandateStatus, 'humanized' | 'value'>
  )>, actions: (
    { __typename?: 'MandateActions' }
    & Pick<IMandateActions, 'acceptable' | 'revokable'>
  ) }
);

export type IDistanceSummaryFragment = (
  { __typename?: 'DistanceSummary' }
  & Pick<IDistanceSummary, 'totalDistance'>
  & { mobilityEventsPerModality?: Maybe<Array<(
    { __typename?: 'DistancePerModality' }
    & Pick<IDistancePerModality, 'distance'>
    & { modality?: Maybe<(
      { __typename?: 'Modality' }
      & Pick<IModality, 'humanized' | 'id' | 'kind' | 'clazz'>
    )> }
  )>> }
);

export type IEmployeeTasksFragment = (
  { __typename?: 'Employee' }
  & { tasks: Array<(
    { __typename: 'ActionRequired' }
    & Pick<IActionRequired, 'humanized' | 'message' | 'type'>
  ) | (
    { __typename: 'ActivatableDongle' }
    & Pick<IActivatableDongle, 'humanized' | 'message' | 'type'>
  ) | (
    { __typename: 'CompensationRideToBeCreated' }
    & Pick<ICompensationRideToBeCreated, 'incompleteRideId' | 'refundId' | 'humanized' | 'message' | 'type'>
  ) | (
    { __typename: 'MobilityEventsWeek' }
    & Pick<IMobilityEventsWeek, 'humanized' | 'message' | 'type'>
  ) | (
    { __typename: 'NewProduct' }
    & Pick<INewProduct, 'humanized' | 'message' | 'type'>
  ) | (
    { __typename: 'PendingApproval' }
    & Pick<IPendingApproval, 'humanized' | 'message' | 'type'>
  ) | (
    { __typename: 'RefundToBeLinked' }
    & Pick<IRefundToBeLinked, 'mobilityEventId' | 'humanized' | 'message' | 'type'>
  )> }
);

export type IFavoriteFragment = (
  { __typename?: 'Favorite' }
  & Pick<IFavorite, 'id' | 'label' | 'location' | 'locationId' | 'type'>
  & { coordinates: (
    { __typename?: 'Coordinates' }
    & Pick<ICoordinates, 'latitude' | 'longitude'>
  ), organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<IOrganization, 'id' | 'name'>
  )>, actions: (
    { __typename?: 'FavoriteActions' }
    & Pick<IFavoriteActions, 'deletable' | 'editable'>
  ) }
);

export type IFinancialComponentsFragment = (
  { __typename?: 'FinancialComponent' }
  & Pick<IFinancialComponent, 'label'>
  & { moneyTransfer: (
    { __typename?: 'MoneyTransfer' }
    & Pick<IMoneyTransfer, 'humanized' | 'direction'>
  ), amount: (
    { __typename?: 'Money' }
    & Pick<IMoney, 'humanized' | 'valueInCents'>
  ), entries: Array<(
    { __typename?: 'JournalEntry' }
    & Pick<IJournalEntry, 'label' | 'calculation'>
    & { moneyTransfer: (
      { __typename?: 'MoneyTransfer' }
      & Pick<IMoneyTransfer, 'direction' | 'humanized'>
    ), amount: (
      { __typename?: 'Money' }
      & Pick<IMoney, 'humanized' | 'valueInCents'>
    ) }
  )> }
);

export type IIndexMobilityEventFragment = (
  { __typename?: 'MobilityEvent' }
  & Pick<IMobilityEvent, 'id' | 'happenedAt' | 'happenedInTheFuture' | 'allowPersonalConsumption'>
  & { status: (
    { __typename?: 'Status' }
    & Pick<IStatus, 'value' | 'humanized' | 'label'>
  ), employment?: Maybe<(
    { __typename?: 'Employment' }
    & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
  )>, actions: (
    { __typename?: 'Actions' }
    & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'mergeable'>
  ), what: (
    { __typename?: 'What' }
    & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<ICategory, 'main' | 'requiresModality'>
    ), modality?: Maybe<(
      { __typename?: 'Modality' }
      & Pick<IModality, 'id' | 'clazz' | 'kind' | 'requiresVehicle'>
      & { modalityOwnership?: Maybe<(
        { __typename?: 'ModalityOwnership' }
        & Pick<IModalityOwnership, 'humanized' | 'value'>
      )> }
    )>, icon: (
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    ) }
  ), how: (
    { __typename?: 'How' }
    & Pick<IHow, 'humanized'>
    & { icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )> }
  ), why?: Maybe<(
    { __typename?: 'Why' }
    & { travelPurpose: (
      { __typename?: 'TravelPurpose' }
      & Pick<ITravelPurpose, 'humanized' | 'value'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<IProject, 'id' | 'code' | 'humanized'>
    )>, explanation?: Maybe<(
      { __typename?: 'Explanation' }
      & Pick<IExplanation, 'humanized'>
      & { required: (
        { __typename?: 'ExplanationRequired' }
        & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
      ) }
    )> }
  )>, howMuch?: Maybe<(
    { __typename?: 'HowMuch' }
    & { consumption: (
      { __typename?: 'Quantity' }
      & Pick<IQuantity, 'amount' | 'humanized'>
    ) }
  )>, where?: Maybe<(
    { __typename?: 'Location' }
    & Pick<ILocation, 'humanized'>
  ) | (
    { __typename?: 'RentalLocations' }
    & { pickup: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
    ), return: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
    ) }
  ) | (
    { __typename?: 'Route' }
    & { from: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
    ), to: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
    ) }
  )>, financial: (
    { __typename?: 'Financial' }
    & { consequences: (
      { __typename?: 'FinancialConsequences' }
      & { gross?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'direction' | 'humanized'>
        ) }
      )>, net?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'direction' | 'humanized'>
        ) }
      )> }
    ), fact?: Maybe<(
      { __typename?: 'FinancialFact' }
      & { receipt?: Maybe<(
        { __typename?: 'Receipt' }
        & Pick<IReceipt, 'contentType' | 'url'>
      )> }
    )> }
  ) }
);

export type ILicensePlateFragment = (
  { __typename?: 'LicensePlate' }
  & Pick<ILicensePlate, 'budgetChargeTotal' | 'licensePlate' | 'remainingKilometersTotal'>
  & { mileages?: Maybe<Array<(
    { __typename?: 'Mileage' }
    & Pick<IMileage, 'id' | 'licensePlate' | 'recordedAt' | 'remainingKilometers' | 'supportsUnaccountedDistances' | 'hasUnaccountedDistance' | 'budgetCostPerKilometer' | 'budgetCharge' | 'amountInKilometers' | 'mobilityEventId'>
  )>> }
);

export type IMsaCarrierFragment = (
  { __typename?: 'MSACarrier' }
  & Pick<IMsaCarrier, 'carrierId' | 'status' | 'statusCategory'>
);

export type IMileageFragment = (
  { __typename?: 'Mileage' }
  & Pick<IMileage, 'id' | 'licensePlate' | 'recordedAt' | 'remainingKilometers' | 'supportsUnaccountedDistances' | 'hasUnaccountedDistance' | 'budgetCostPerKilometer' | 'budgetCharge' | 'amountInKilometers' | 'mobilityEventId'>
);

export type IMobilityCardOrderFragment = (
  { __typename?: 'MobilityCardOrder' }
  & Pick<IMobilityCardOrder, 'id' | 'fromStation' | 'toStation' | 'passportPhoto' | 'motivation' | 'needsApproval' | 'hasPincodeBeenEntered' | 'completedAt'>
  & { humanizedSubscription?: Maybe<(
    { __typename?: 'Subscription' }
    & Pick<ISubscription, 'value'>
  )>, requiredTermsAndConditionsDocuments?: Maybe<Array<(
    { __typename?: 'TermsAndConditionsDocument' }
    & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
    & { humanizedKind: (
      { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
      & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
    ) }
  )>>, services?: Maybe<Array<(
    { __typename?: 'MobilityCardOrderService' }
    & Pick<IMobilityCardOrderService, 'humanized' | 'value'>
  )>>, recommendedCard?: Maybe<(
    { __typename?: 'RecommendedCard' }
    & Pick<IRecommendedCard, 'humanized'>
  )>, firstClassReason?: Maybe<(
    { __typename?: 'FirstClassReason' }
    & Pick<IFirstClassReason, 'humanized' | 'value'>
  )>, permission?: Maybe<(
    { __typename?: 'MobilityCardOrderPermission' }
    & Pick<IMobilityCardOrderPermission, 'canRequestOptions' | 'canRequestPhoto'>
  )>, starValue?: Maybe<(
    { __typename?: 'StarValue' }
    & Pick<IStarValue, 'humanized' | 'value'>
  )>, zone?: Maybe<(
    { __typename?: 'Zone' }
    & Pick<IZone, 'humanized' | 'value'>
  )>, trainClass?: Maybe<(
    { __typename?: 'TrainClass' }
    & Pick<ITrainClass, 'humanized' | 'value'>
  )>, actions: (
    { __typename?: 'MobilityCardOrderActions' }
    & Pick<IMobilityCardOrderActions, 'isEditable'>
  ), requestableSubscriptions?: Maybe<Array<(
    { __typename?: 'AvailableSubscription' }
    & Pick<IAvailableSubscription, 'hasTraject' | 'value' | 'humanized' | 'requiresMotivation'>
    & { starValues: Array<(
      { __typename?: 'StarValue' }
      & Pick<IStarValue, 'humanized' | 'value'>
    )>, humanizedZones?: Maybe<Array<(
      { __typename?: 'Zone' }
      & Pick<IZone, 'humanized' | 'value'>
    )>>, trainClassOptions?: Maybe<(
      { __typename?: 'TrainClassOptions' }
      & Pick<ITrainClassOptions, 'requiresMotivation'>
      & { firstClassReasons?: Maybe<Array<(
        { __typename?: 'FirstClassReason' }
        & Pick<IFirstClassReason, 'humanized' | 'value'>
      )>>, requestableTrainClasses?: Maybe<Array<(
        { __typename?: 'TrainClass' }
        & Pick<ITrainClass, 'humanized' | 'value'>
      )>> }
    )> }
  )>>, requestableServices?: Maybe<Array<(
    { __typename?: 'RequestableService' }
    & Pick<IRequestableService, 'budgetGroupAvailability' | 'requiresMotivation' | 'requiresPincode'>
    & { kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'sub'>
    ) }
  )>>, status?: Maybe<(
    { __typename?: 'MobilityCardOrderStatus' }
    & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
  )> }
);

export type IMobilityEventDetailsFragment = (
  { __typename?: 'MobilityEvent' }
  & Pick<IMobilityEvent, 'id' | 'happenedAt'>
  & { what: (
    { __typename?: 'What' }
    & Pick<IWhat, 'humanized' | 'editable'>
    & { icon: (
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    ), vehicle?: Maybe<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'humanized'>
    )>, modality?: Maybe<(
      { __typename?: 'Modality' }
      & Pick<IModality, 'humanized'>
    )> }
  ), when: (
    { __typename?: 'Duration' }
    & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
  ) | (
    { __typename?: 'Timestamp' }
    & Pick<ITimestamp, 'at' | 'editable'>
  ), where?: Maybe<(
    { __typename?: 'Location' }
    & Pick<ILocation, 'humanized' | 'label'>
    & { coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'latitude' | 'longitude'>
    )> }
  ) | (
    { __typename?: 'RentalLocations' }
    & { pickup: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
    ), return: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
    ) }
  ) | (
    { __typename?: 'Route' }
    & { from: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
      & { coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      )> }
    ), to: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
      & { coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      )> }
    ), track?: Maybe<Array<(
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'latitude' | 'longitude'>
    )>>, nonCombined?: Maybe<(
      { __typename?: 'NonCombined' }
      & Pick<INonCombined, 'humanized'>
    )>, diverted?: Maybe<(
      { __typename?: 'Diverted' }
      & Pick<IDiverted, 'humanized'>
    )> }
  )>, why?: Maybe<(
    { __typename?: 'Why' }
    & Pick<IWhy, 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
    & { legs?: Maybe<Array<(
      { __typename?: 'Leg' }
      & { quantity: (
        { __typename?: 'Quantity' }
        & Pick<IQuantity, 'humanized'>
      ) }
    )>>, travelPurpose: (
      { __typename?: 'TravelPurpose' }
      & Pick<ITravelPurpose, 'humanized' | 'value'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<IProject, 'code' | 'humanized'>
    )>, explanation?: Maybe<(
      { __typename?: 'Explanation' }
      & Pick<IExplanation, 'humanized'>
    )> }
  )>, financial: (
    { __typename?: 'Financial' }
    & { fact?: Maybe<(
      { __typename?: 'FinancialFact' }
      & Pick<IFinancialFact, 'label'>
      & { receipt?: Maybe<(
        { __typename?: 'Receipt' }
        & Pick<IReceipt, 'contentType' | 'url'>
      )>, exclVat?: Maybe<(
        { __typename?: 'Money' }
        & Pick<IMoney, 'humanized'>
      )>, inclVat: (
        { __typename?: 'Money' }
        & Pick<IMoney, 'humanized'>
      ), vatRate?: Maybe<(
        { __typename?: 'VatRate' }
        & Pick<IVatRate, 'humanized'>
      )> }
    )>, consequences: (
      { __typename?: 'FinancialConsequences' }
      & { gross?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<IMoney, 'valueInCents'>
        ), moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'direction'>
        ) }
      )>, fiscal?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized'>
        ) }
      )>, net?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<IMoney, 'valueInCents'>
        ), moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'direction'>
        ) }
      )> }
    ) }
  ), actions: (
    { __typename?: 'Actions' }
    & Pick<IActions, 'deletable' | 'editable' | 'confirmable' | 'unacceptable'>
  ), how: (
    { __typename?: 'How' }
    & Pick<IHow, 'humanized'>
  ), howMuch?: Maybe<(
    { __typename?: 'HowMuch' }
    & { co2Emission?: Maybe<(
      { __typename?: 'Quantity' }
      & Pick<IQuantity, 'humanized'>
    )>, consumption: (
      { __typename?: 'Quantity' }
      & Pick<IQuantity, 'humanized'>
    ) }
  )>, status: (
    { __typename?: 'Status' }
    & Pick<IStatus, 'humanized' | 'value'>
  ) }
);

export type IPersonalMeansOfRegistrationFragment = (
  { __typename?: 'PersonalMeansOfRegistration' }
  & Pick<IPersonalMeansOfRegistration, 'id'>
  & { photoRejectedReasons?: Maybe<Array<(
    { __typename?: 'PersonalMeansOfRegistrationPhotoRejectedReason' }
    & Pick<IPersonalMeansOfRegistrationPhotoRejectedReason, 'humanized'>
  )>>, kind: (
    { __typename?: 'PersonalMeansOfRegistrationKind' }
    & Pick<IPersonalMeansOfRegistrationKind, 'humanized' | 'main' | 'sub'>
  ), status?: Maybe<(
    { __typename?: 'PersonalMeansOfRegistrationStatus' }
    & Pick<IPersonalMeansOfRegistrationStatus, 'humanized' | 'value'>
  )>, endsOn: (
    { __typename?: 'PersonalMeansOfRegistrationEndsOn' }
    & Pick<IPersonalMeansOfRegistrationEndsOn, 'humanized'>
  ), identity?: Maybe<(
    { __typename?: 'PersonalMeansOfRegistrationIdentity' }
    & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
  )>, abilities: (
    { __typename?: 'PersonalMeansOfRegistrationAbilities' }
    & Pick<IPersonalMeansOfRegistrationAbilities, 'activatable' | 'lostOrStolenReportable' | 'terminatable'>
  ), availableServices?: Maybe<Array<(
    { __typename?: 'ActiveAdditional' }
    & Pick<IActiveAdditional, 'requiresMotivation' | 'recordId'>
    & { termsAndConditions?: Maybe<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'requiresAgreement'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
        ) }
      ) }
    )>, description?: Maybe<(
      { __typename?: 'Description' }
      & Pick<IDescription, 'humanized'>
    )>, icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )>, identity?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
      & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
    )>, kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
    ), status?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
      & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
      & { metaStatus?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
        & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
      )> }
    )>, personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )> }
    )> }
  ) | (
    { __typename?: 'ActiveGreenwheels' }
    & Pick<IActiveGreenwheels, 'requiresMotivation' | 'recordId'>
    & { termsAndConditions?: Maybe<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'requiresAgreement'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
        ) }
      ) }
    )>, description?: Maybe<(
      { __typename?: 'Description' }
      & Pick<IDescription, 'humanized'>
    )>, icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )>, identity?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
      & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
    )>, kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
    ), status?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
      & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
      & { metaStatus?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
        & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
      )> }
    )>, personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )> }
    )> }
  ) | (
    { __typename?: 'ActiveSubscription' }
    & Pick<IActiveSubscription, 'requiresMotivation' | 'recordId'>
    & { termsAndConditions?: Maybe<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'requiresAgreement'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
        ) }
      ) }
    )>, description?: Maybe<(
      { __typename?: 'Description' }
      & Pick<IDescription, 'humanized'>
    )>, icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )>, identity?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
      & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
    )>, kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
    ), status?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
      & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
      & { metaStatus?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
        & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
      )> }
    )>, personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )> }
    )> }
  ) | (
    { __typename?: 'ActiveYellowbrickSp' }
    & Pick<IActiveYellowbrickSp, 'requiresMotivation' | 'recordId'>
    & { termsAndConditions?: Maybe<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'requiresAgreement'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
        ) }
      ) }
    )>, description?: Maybe<(
      { __typename?: 'Description' }
      & Pick<IDescription, 'humanized'>
    )>, icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )>, identity?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
      & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
    )>, kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
    ), status?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
      & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
      & { metaStatus?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
        & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
      )> }
    )>, personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )> }
    )> }
  ) | (
    { __typename?: 'RequestableService' }
    & Pick<IRequestableService, 'requiresMotivation' | 'recordId'>
    & { termsAndConditions?: Maybe<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'requiresAgreement'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
        ) }
      ) }
    )>, description?: Maybe<(
      { __typename?: 'Description' }
      & Pick<IDescription, 'humanized'>
    )>, icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )>, identity?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
      & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
    )>, kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
    ), status?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
      & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
      & { metaStatus?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
        & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
      )> }
    )>, personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )> }
    )> }
  ) | (
    { __typename?: 'RequestableYellowbrickProduct' }
    & Pick<IRequestableYellowbrickProduct, 'requiresMotivation' | 'recordId'>
    & { termsAndConditions?: Maybe<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'requiresAgreement'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
        ) }
      ) }
    )>, description?: Maybe<(
      { __typename?: 'Description' }
      & Pick<IDescription, 'humanized'>
    )>, icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )>, identity?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
      & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
    )>, kind: (
      { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
      & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
    ), status?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
      & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
      & { metaStatus?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
        & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
      )> }
    )>, personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )> }
    )> }
  )>> }
);

export type IPrivacyReasonFragment = (
  { __typename?: 'PrivacyRequestReason' }
  & Pick<IPrivacyRequestReason, 'description' | 'title' | 'reason'>
);

export type IPrivacyTypeFragment = (
  { __typename?: 'Privacy' }
  & Pick<IPrivacy, 'description' | 'humanized' | 'value'>
  & { reasons?: Maybe<Array<(
    { __typename?: 'PrivacyRequestReason' }
    & Pick<IPrivacyRequestReason, 'description' | 'title' | 'reason'>
  )>> }
);

export type IReservationFragment = (
  { __typename?: 'Reservation' }
  & Pick<IReservation, 'id' | 'title' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'recordId' | 'reservationClass'>
  & { icon?: Maybe<(
    { __typename?: 'Icon' }
    & Pick<IIcon, 'name'>
  )>, reservationStatus?: Maybe<(
    { __typename?: 'ReservationStatus' }
    & Pick<IReservationStatus, 'humanized' | 'value'>
  )>, startLocation?: Maybe<(
    { __typename?: 'GeoLocation' }
    & Pick<IGeoLocation, 'humanized'>
    & { coordinates: (
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'latitude' | 'longitude'>
    ) }
  )>, endLocation?: Maybe<(
    { __typename?: 'GeoLocation' }
    & Pick<IGeoLocation, 'humanized'>
    & { coordinates: (
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'latitude' | 'longitude'>
    ) }
  )> }
);

export type ISharedVehicleReservationFragment = (
  { __typename?: 'SharedVehicleReservation' }
  & Pick<ISharedVehicleReservation, 'id' | 'reservationId' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'vehicleName' | 'vehicleBrand' | 'vehicleModel' | 'vehicleLicensePlate'>
  & { fromLocation: (
    { __typename?: 'GeoLocation' }
    & Pick<IGeoLocation, 'humanized'>
    & { coordinates: (
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'latitude' | 'longitude'>
    ) }
  ), status: (
    { __typename?: 'SharedCarReservationStatus' }
    & Pick<ISharedCarReservationStatus, 'value'>
  ), icon: (
    { __typename?: 'Icon' }
    & Pick<IIcon, 'name'>
  ) }
);

export type IShowFragmentFragment = (
  { __typename?: 'MobilityEvent' }
  & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
  & { status: (
    { __typename?: 'Status' }
    & Pick<IStatus, 'value' | 'humanized' | 'label'>
  ), employment?: Maybe<(
    { __typename?: 'Employment' }
    & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
  )>, actions: (
    { __typename?: 'Actions' }
    & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
  ), financial: (
    { __typename?: 'Financial' }
    & { fact?: Maybe<(
      { __typename?: 'FinancialFact' }
      & Pick<IFinancialFact, 'direction' | 'label'>
      & { exclVat?: Maybe<(
        { __typename?: 'Money' }
        & Pick<IMoney, 'humanized'>
      )>, inclVat: (
        { __typename?: 'Money' }
        & Pick<IMoney, 'humanized' | 'value'>
      ), receipt?: Maybe<(
        { __typename?: 'Receipt' }
        & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
      )>, vatRate?: Maybe<(
        { __typename?: 'VatRate' }
        & Pick<IVatRate, 'humanized'>
      )> }
    )>, consequences: (
      { __typename?: 'FinancialConsequences' }
      & { gross?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'humanized' | 'direction'>
        ), amount: (
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized' | 'valueInCents'>
        ), entries: Array<(
          { __typename?: 'JournalEntry' }
          & Pick<IJournalEntry, 'label' | 'calculation'>
          & { moneyTransfer: (
            { __typename?: 'MoneyTransfer' }
            & Pick<IMoneyTransfer, 'direction' | 'humanized'>
          ), amount: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'valueInCents'>
          ) }
        )> }
      )>, net?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'humanized' | 'direction'>
        ), amount: (
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized' | 'valueInCents'>
        ), entries: Array<(
          { __typename?: 'JournalEntry' }
          & Pick<IJournalEntry, 'label' | 'calculation'>
          & { moneyTransfer: (
            { __typename?: 'MoneyTransfer' }
            & Pick<IMoneyTransfer, 'direction' | 'humanized'>
          ), amount: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'valueInCents'>
          ) }
        )> }
      )>, fiscal?: Maybe<(
        { __typename?: 'FinancialComponent' }
        & Pick<IFinancialComponent, 'label'>
        & { moneyTransfer: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'humanized' | 'direction'>
        ), amount: (
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized' | 'valueInCents'>
        ), entries: Array<(
          { __typename?: 'JournalEntry' }
          & Pick<IJournalEntry, 'label' | 'calculation'>
          & { moneyTransfer: (
            { __typename?: 'MoneyTransfer' }
            & Pick<IMoneyTransfer, 'direction' | 'humanized'>
          ), amount: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'valueInCents'>
          ) }
        )> }
      )> }
    ) }
  ), howMuch?: Maybe<(
    { __typename?: 'HowMuch' }
    & { consumption: (
      { __typename?: 'Quantity' }
      & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
    ), co2Emission?: Maybe<(
      { __typename?: 'Quantity' }
      & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
    )> }
  )>, what: (
    { __typename?: 'What' }
    & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
    & { modality?: Maybe<(
      { __typename?: 'Modality' }
      & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
      & { modalityOwnership?: Maybe<(
        { __typename?: 'ModalityOwnership' }
        & Pick<IModalityOwnership, 'humanized' | 'value'>
      )> }
    )>, category: (
      { __typename?: 'Category' }
      & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
      & { mainCategory: (
        { __typename?: 'MainCategory' }
        & Pick<IMainCategory, 'value' | 'humanized'>
      ), subCategory?: Maybe<(
        { __typename?: 'SubCategory' }
        & Pick<ISubCategory, 'value' | 'humanized'>
      )> }
    ), vehicle?: Maybe<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'id' | 'humanized'>
    )>, reimbursableCategoryMethod: (
      { __typename?: 'ReimbursableCategoryMethod' }
      & Pick<IReimbursableCategoryMethod, 'value'>
    ), icon: (
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    ) }
  ), where?: Maybe<(
    { __typename?: 'Location' }
    & Pick<ILocation, 'humanized' | 'label'>
    & { coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'latitude' | 'longitude'>
    )> }
  ) | (
    { __typename?: 'RentalLocations' }
    & { pickup: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
      & { coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      )> }
    ), return: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized'>
      & { coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      )> }
    ) }
  ) | (
    { __typename?: 'Route' }
    & { diverted?: Maybe<(
      { __typename?: 'Diverted' }
      & Pick<IDiverted, 'label' | 'humanized' | 'value'>
    )>, nonCombined?: Maybe<(
      { __typename?: 'NonCombined' }
      & Pick<INonCombined, 'label' | 'humanized' | 'value'>
    )>, from: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
      & { coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      )> }
    ), to: (
      { __typename?: 'Location' }
      & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
      & { coordinates?: Maybe<(
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      )> }
    ), track?: Maybe<Array<(
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'longitude' | 'latitude'>
    )>> }
  )>, why?: Maybe<(
    { __typename?: 'Why' }
    & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
    & { legs?: Maybe<Array<(
      { __typename?: 'Leg' }
      & { travelPurpose: (
        { __typename?: 'TravelPurpose' }
        & Pick<ITravelPurpose, 'humanized'>
      ), quantity: (
        { __typename?: 'Quantity' }
        & Pick<IQuantity, 'amount' | 'humanized'>
      ) }
    )>>, travelPurpose: (
      { __typename?: 'TravelPurpose' }
      & Pick<ITravelPurpose, 'humanized' | 'value'>
    ), project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<IProject, 'id' | 'code' | 'humanized'>
    )>, explanation?: Maybe<(
      { __typename?: 'Explanation' }
      & Pick<IExplanation, 'humanized'>
      & { required: (
        { __typename?: 'ExplanationRequired' }
        & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
      ) }
    )>, stateTransitions: (
      { __typename?: 'StateTransitions' }
      & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
    ) }
  )>, when: (
    { __typename?: 'Duration' }
    & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
  ) | (
    { __typename?: 'Timestamp' }
    & Pick<ITimestamp, 'at' | 'editable'>
  ), how: (
    { __typename?: 'How' }
    & Pick<IHow, 'humanized'>
    & { icon?: Maybe<(
      { __typename?: 'Icon' }
      & Pick<IIcon, 'name'>
    )> }
  ), signatures?: Maybe<Array<(
    { __typename?: 'Signature' }
    & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
    & { signer?: Maybe<(
      { __typename?: 'Employee' }
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
      ) }
    )> }
  )>> }
);

export type ISplitBillingContractFragment = (
  { __typename?: 'SplitBillingContract' }
  & Pick<ISplitBillingContract, 'id' | 'splitBillingContractId' | 'invitedAt' | 'cancelledAt'>
  & { status: (
    { __typename?: 'ContractStatus' }
    & Pick<IContractStatus, 'humanized'>
  ), actions: (
    { __typename?: 'ContractActions' }
    & Pick<IContractActions, 'revocable'>
  ) }
);

export type ISplitBillingInvoiceFragment = (
  { __typename?: 'SplitBillingInvoice' }
  & Pick<ISplitBillingInvoice, 'id' | 'invoiceDate' | 'externalReference' | 'pdfUrl'>
  & { transactionsReport?: Maybe<(
    { __typename?: 'TransactionsReport' }
    & Pick<ITransactionsReport, 'xlsUrl'>
  )> }
);

export type ITaxiReservationFragment = (
  { __typename?: 'TaxiReservation' }
  & Pick<ITaxiReservation, 'id' | 'contactPhoneNumber' | 'vehicleType' | 'passengerCount' | 'departAt' | 'cancellable' | 'cancelledAt' | 'remarks'>
  & { price?: Maybe<(
    { __typename?: 'Money' }
    & Pick<IMoney, 'humanized'>
  )>, departure: (
    { __typename?: 'Location' }
    & Pick<ILocation, 'humanized'>
    & { coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'longitude' | 'latitude'>
    )> }
  ), destination: (
    { __typename?: 'Location' }
    & Pick<ILocation, 'humanized'>
    & { coordinates?: Maybe<(
      { __typename?: 'Coordinates' }
      & Pick<ICoordinates, 'longitude' | 'latitude'>
    )> }
  ), status?: Maybe<(
    { __typename?: 'TaxiStatus' }
    & Pick<ITaxiStatus, 'humanized' | 'value'>
  )>, vehicle?: Maybe<(
    { __typename?: 'TaxiReservationVehicle' }
    & Pick<ITaxiReservationVehicle, 'brand' | 'licensePlate' | 'type'>
  )> }
);

export type IUserProfileInformationFragment = (
  { __typename?: 'Employee' }
  & Pick<IEmployee, 'id' | 'token'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<IProfile, 'avatar' | 'employeeNumber' | 'phoneNumber' | 'firstName' | 'infix' | 'initials' | 'lastName' | 'email' | 'dateOfBirth' | 'defaultMobilityCardWeekendTransactionTravelPurpose' | 'defaultMobilityCardTransactionTravelPurpose'>
    & { homeAddress?: Maybe<(
      { __typename?: 'EmployeeAddress' }
      & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
      & { employeeCountryIsoCode?: Maybe<(
        { __typename?: 'CountryIsoCode' }
        & Pick<ICountryIsoCode, 'humanized' | 'value'>
      )> }
    )> }
  ) }
);

export type IUserWorkInformationFragment = (
  { __typename?: 'Employee' }
  & Pick<IEmployee, 'id'>
  & { profile: (
    { __typename?: 'Profile' }
    & Pick<IProfile, 'employeeNumber'>
    & { workAddress?: Maybe<(
      { __typename?: 'EmployeeAddress' }
      & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
      & { employeeCountryIsoCode?: Maybe<(
        { __typename?: 'CountryIsoCode' }
        & Pick<ICountryIsoCode, 'value' | 'humanized'>
      )> }
    )> }
  ) }
);

export type IValidationErrorFragment = (
  { __typename?: 'ValidationError' }
  & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
);

export type IMobilityEventSignatureFragment = (
  { __typename?: 'Signature' }
  & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
  & { signer?: Maybe<(
    { __typename?: 'Employee' }
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type IPersonalVehicleFragment = (
  { __typename?: 'MeansOfTransport' }
  & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
  & { vehicleInformation?: Maybe<(
    { __typename?: 'VehicleInformation' }
    & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
    & { fuelType?: Maybe<(
      { __typename?: 'Fuel' }
      & Pick<IFuel, 'humanized' | 'value'>
    )> }
  )>, icon?: Maybe<(
    { __typename?: 'Icon' }
    & Pick<IIcon, 'name'>
  )>, actions: (
    { __typename?: 'VehicleActions' }
    & Pick<IVehicleActions, 'editable' | 'terminatable'>
  ), modality: (
    { __typename?: 'Modality' }
    & { modalityOwnership?: Maybe<(
      { __typename?: 'ModalityOwnership' }
      & Pick<IModalityOwnership, 'value'>
    )> }
  ) }
);

export type IGetAvailableFeaturesQueryVariables = Exact<{
  status?: InputMaybe<IMobilityCardOrderStatusEnum>;
}>;


export type IGetAvailableFeaturesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { availableFeatures?: Maybe<Array<(
      { __typename?: 'Amber' }
      & Pick<IAmber, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'DonkeyRepublic' }
      & Pick<IDonkeyRepublic, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'EnecoDongle' }
      & Pick<IEnecoDongle, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'Greenwheels' }
      & Pick<IGreenwheels, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'LeaseBike' }
      & Pick<ILeaseBike, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RentalCar' }
      & Pick<IRentalCar, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RequestableDonkeyRepublic' }
      & Pick<IRequestableDonkeyRepublic, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RequestableFeature' }
      & Pick<IRequestableFeature, 'requiresMotivation' | 'id' | 'motivation' | 'completedAt'>
      & { termsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>>, urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'SharedVehicle' }
      & Pick<ISharedVehicle, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelCheck' }
      & Pick<IShuttelCheck, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelMyWheels' }
      & Pick<IShuttelMyWheels, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelSixt' }
      & Pick<IShuttelSixt, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelTaxi' }
      & Pick<IShuttelTaxi, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelTranzerEticket' }
      & Pick<IShuttelTranzerEticket, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelVecore' }
      & Pick<IShuttelVecore, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'TaxiFeature' }
      & Pick<ITaxiFeature, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'TranzerEticket' }
      & Pick<ITranzerEticket, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'YellowbrickSp' }
      & Pick<IYellowbrickSp, 'id' | 'motivation' | 'completedAt'>
      & { urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    )>> }
  )> }
);

export type IGetHelpUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetHelpUrlQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'id'>
      & { customerService: (
        { __typename?: 'CustomerService' }
        & Pick<ICustomerService, 'helpUrl'>
      ) }
    )> }
  )> }
);

export type IGetMinPasswordLengthQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMinPasswordLengthQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'id' | 'minPasswordLength'>
    )> }
  )> }
);

export type IGetPermissionPerNavigationItemQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetPermissionPerNavigationItemQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'approvals' | 'manageMobilityCards' | 'manageWorkFromHome' | 'orderMobilityCards' | 'requestSharedVehicleFeature' | 'requestTaxiFeature' | 'sharedVehicleFeature' | 'taxiFeature' | 'viewDirectDebitInvoices' | 'viewSplitBillingInvoices' | 'canViewMileages' | 'canViewLeaseBikeFeature' | 'rentalCarFeature' | 'allowLoginByQrCode' | 'canRemoveReplacementManager' | 'canAssignReplacementManager'>
    ) }
  )> }
);

export type IGetRequiredFieldsTransactionFormQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetRequiredFieldsTransactionFormQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { requiredFields: (
      { __typename?: 'RequiredFields' }
      & Pick<IRequiredFields, 'descriptionForRideTransactions' | 'descriptionAllowed' | 'projectForTravelpurposes' | 'projectAllowed' | 'descriptionForRideByAmountPersonal' | 'descriptionForRideByAmountCommute' | 'descriptionForRideByAmountBusiness' | 'descriptionForRideByDistancePersonal' | 'descriptionForRideByDistanceCommute' | 'descriptionForRideByDistanceBusiness'>
    ) }
  )> }
);

export type IGetTermsAndConditionsQueryVariables = Exact<{
  kinds?: InputMaybe<Array<ITermsAndConditionsDocumentKindEnum> | ITermsAndConditionsDocumentKindEnum>;
}>;


export type IGetTermsAndConditionsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { termsAndConditions?: Maybe<Array<(
      { __typename?: 'TermsAndConditions' }
      & Pick<ITermsAndConditions, 'agreed' | 'agreedAt'>
      & { document: (
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
        ) }
      ) }
    )>> }
  )> }
);

export type IGetFeaturePermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFeaturePermissionsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'sharedVehicleFeature' | 'taxiFeature' | 'rentalCarFeature'>
    ) }
  )> }
);

export type IGetTermsStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetTermsStatusQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'becameEmployee' | 'hasNotAgreedToUpdatedGeneralTerms' | 'hasNotAgreedToUpdatedPrivacyStatement' | 'hasAgreedToGeneralTerms' | 'hasAgreedToPrivacyStatement'>
  )> }
);

export type IAcceptMobilityEventsMutationVariables = Exact<{
  input: IAcceptMobilityEventsInput;
}>;


export type IAcceptMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { acceptMobilityEvents?: Maybe<(
    { __typename?: 'AcceptMobilityEventsPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), acceptedMobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IRejectMobilityEventsMutationVariables = Exact<{
  input: IRejectMobilityEventsInput;
}>;


export type IRejectMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { rejectMobilityEvents?: Maybe<(
    { __typename?: 'RejectMobilityEventsPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), rejectedMobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetSubordinateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetSubordinateQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { subordinate?: Maybe<(
      { __typename?: 'Subordinate' }
      & Pick<ISubordinate, 'id' | 'fullName' | 'numAcceptableMobilityEvents'>
      & { acceptableMobilityEvents: Array<(
        { __typename?: 'MobilityEvent' }
        & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
        & { status: (
          { __typename?: 'Status' }
          & Pick<IStatus, 'value' | 'humanized' | 'label'>
        ), employment?: Maybe<(
          { __typename?: 'Employment' }
          & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
        )>, actions: (
          { __typename?: 'Actions' }
          & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
        ), financial: (
          { __typename?: 'Financial' }
          & { fact?: Maybe<(
            { __typename?: 'FinancialFact' }
            & Pick<IFinancialFact, 'direction' | 'label'>
            & { exclVat?: Maybe<(
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized'>
            )>, inclVat: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'value'>
            ), receipt?: Maybe<(
              { __typename?: 'Receipt' }
              & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
            )>, vatRate?: Maybe<(
              { __typename?: 'VatRate' }
              & Pick<IVatRate, 'humanized'>
            )> }
          )>, consequences: (
            { __typename?: 'FinancialConsequences' }
            & { gross?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'humanized' | 'direction'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ), entries: Array<(
                { __typename?: 'JournalEntry' }
                & Pick<IJournalEntry, 'label' | 'calculation'>
                & { moneyTransfer: (
                  { __typename?: 'MoneyTransfer' }
                  & Pick<IMoneyTransfer, 'direction' | 'humanized'>
                ), amount: (
                  { __typename?: 'Money' }
                  & Pick<IMoney, 'humanized' | 'valueInCents'>
                ) }
              )> }
            )>, net?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'humanized' | 'direction'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ), entries: Array<(
                { __typename?: 'JournalEntry' }
                & Pick<IJournalEntry, 'label' | 'calculation'>
                & { moneyTransfer: (
                  { __typename?: 'MoneyTransfer' }
                  & Pick<IMoneyTransfer, 'direction' | 'humanized'>
                ), amount: (
                  { __typename?: 'Money' }
                  & Pick<IMoney, 'humanized' | 'valueInCents'>
                ) }
              )> }
            )>, fiscal?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'humanized' | 'direction'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ), entries: Array<(
                { __typename?: 'JournalEntry' }
                & Pick<IJournalEntry, 'label' | 'calculation'>
                & { moneyTransfer: (
                  { __typename?: 'MoneyTransfer' }
                  & Pick<IMoneyTransfer, 'direction' | 'humanized'>
                ), amount: (
                  { __typename?: 'Money' }
                  & Pick<IMoney, 'humanized' | 'valueInCents'>
                ) }
              )> }
            )> }
          ) }
        ), howMuch?: Maybe<(
          { __typename?: 'HowMuch' }
          & { consumption: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
          ), co2Emission?: Maybe<(
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
          )> }
        )>, what: (
          { __typename?: 'What' }
          & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
          & { modality?: Maybe<(
            { __typename?: 'Modality' }
            & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
            & { modalityOwnership?: Maybe<(
              { __typename?: 'ModalityOwnership' }
              & Pick<IModalityOwnership, 'humanized' | 'value'>
            )> }
          )>, category: (
            { __typename?: 'Category' }
            & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
            & { mainCategory: (
              { __typename?: 'MainCategory' }
              & Pick<IMainCategory, 'value' | 'humanized'>
            ), subCategory?: Maybe<(
              { __typename?: 'SubCategory' }
              & Pick<ISubCategory, 'value' | 'humanized'>
            )> }
          ), vehicle?: Maybe<(
            { __typename?: 'MeansOfTransport' }
            & Pick<IMeansOfTransport, 'id' | 'humanized'>
          )>, reimbursableCategoryMethod: (
            { __typename?: 'ReimbursableCategoryMethod' }
            & Pick<IReimbursableCategoryMethod, 'value'>
          ), icon: (
            { __typename?: 'Icon' }
            & Pick<IIcon, 'name'>
          ) }
        ), where?: Maybe<(
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'label'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) | (
          { __typename?: 'RentalLocations' }
          & { pickup: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ), return: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ) }
        ) | (
          { __typename?: 'Route' }
          & { diverted?: Maybe<(
            { __typename?: 'Diverted' }
            & Pick<IDiverted, 'label' | 'humanized' | 'value'>
          )>, nonCombined?: Maybe<(
            { __typename?: 'NonCombined' }
            & Pick<INonCombined, 'label' | 'humanized' | 'value'>
          )>, from: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ), to: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ), track?: Maybe<Array<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )>> }
        )>, why?: Maybe<(
          { __typename?: 'Why' }
          & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
          & { legs?: Maybe<Array<(
            { __typename?: 'Leg' }
            & { travelPurpose: (
              { __typename?: 'TravelPurpose' }
              & Pick<ITravelPurpose, 'humanized'>
            ), quantity: (
              { __typename?: 'Quantity' }
              & Pick<IQuantity, 'amount' | 'humanized'>
            ) }
          )>>, travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized' | 'value'>
          ), project?: Maybe<(
            { __typename?: 'Project' }
            & Pick<IProject, 'id' | 'code' | 'humanized'>
          )>, explanation?: Maybe<(
            { __typename?: 'Explanation' }
            & Pick<IExplanation, 'humanized'>
            & { required: (
              { __typename?: 'ExplanationRequired' }
              & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
            ) }
          )>, stateTransitions: (
            { __typename?: 'StateTransitions' }
            & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
          ) }
        )>, when: (
          { __typename?: 'Duration' }
          & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
        ) | (
          { __typename?: 'Timestamp' }
          & Pick<ITimestamp, 'at' | 'editable'>
        ), how: (
          { __typename?: 'How' }
          & Pick<IHow, 'humanized'>
          & { icon?: Maybe<(
            { __typename?: 'Icon' }
            & Pick<IIcon, 'name'>
          )> }
        ), signatures?: Maybe<Array<(
          { __typename?: 'Signature' }
          & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
          & { signer?: Maybe<(
            { __typename?: 'Employee' }
            & { profile: (
              { __typename?: 'Profile' }
              & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
            ) }
          )> }
        )>> }
      )> }
    )> }
  )> }
);

export type IGetSubordinatesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetSubordinatesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { subordinates?: Maybe<Array<(
      { __typename?: 'Subordinate' }
      & Pick<ISubordinate, 'id' | 'fullName' | 'numAcceptableMobilityEvents'>
    )>> }
  )> }
);

export type IRevokeDirectDebitMandateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IRevokeDirectDebitMandateMutation = (
  { __typename?: 'Mutation' }
  & { directDebitMandates?: Maybe<(
    { __typename?: 'DirectDebitMandates' }
    & { revoke?: Maybe<(
      { __typename?: 'RevokeDirectDebitMandatePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), directDebitMandate?: Maybe<(
        { __typename?: 'DirectDebitMandate' }
        & Pick<IDirectDebitMandate, 'id' | 'uuid' | 'acceptanceUrl' | 'acceptedAt' | 'ecurringCustomerToken' | 'ecurringSubscriptionToken' | 'isAccepted' | 'isCustomerRegistered' | 'isRevoked' | 'isValid' | 'revokedAt'>
        & { status?: Maybe<(
          { __typename?: 'MandateStatus' }
          & Pick<IMandateStatus, 'humanized' | 'value'>
        )>, actions: (
          { __typename?: 'MandateActions' }
          & Pick<IMandateActions, 'acceptable' | 'revokable'>
        ) }
      )> }
    )> }
  )> }
);

export type IRevokeSplitBillingContractsMutationVariables = Exact<{
  splitBillingContractIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
}>;


export type IRevokeSplitBillingContractsMutation = (
  { __typename?: 'Mutation' }
  & { splitBillingContracts?: Maybe<(
    { __typename?: 'SplitBillingContracts' }
    & { revoke?: Maybe<(
      { __typename?: 'RevokeSplitBillingContractPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), revokedContracts?: Maybe<Array<(
        { __typename?: 'SplitBillingContract' }
        & Pick<ISplitBillingContract, 'id' | 'splitBillingContractId' | 'invitedAt' | 'cancelledAt'>
        & { status: (
          { __typename?: 'ContractStatus' }
          & Pick<IContractStatus, 'humanized'>
        ), actions: (
          { __typename?: 'ContractActions' }
          & Pick<IContractActions, 'revocable'>
        ) }
      )>> }
    )> }
  )> }
);

export type ICreateDirectDebitMandateMutationVariables = Exact<{ [key: string]: never; }>;


export type ICreateDirectDebitMandateMutation = (
  { __typename?: 'Mutation' }
  & { directDebitMandates?: Maybe<(
    { __typename?: 'DirectDebitMandates' }
    & { create?: Maybe<(
      { __typename?: 'CreateDirectDebitMandatePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), directDebitMandate?: Maybe<(
        { __typename?: 'DirectDebitMandate' }
        & Pick<IDirectDebitMandate, 'id' | 'uuid' | 'acceptanceUrl' | 'acceptedAt' | 'ecurringCustomerToken' | 'ecurringSubscriptionToken' | 'isAccepted' | 'isCustomerRegistered' | 'isRevoked' | 'isValid' | 'revokedAt'>
        & { status?: Maybe<(
          { __typename?: 'MandateStatus' }
          & Pick<IMandateStatus, 'humanized' | 'value'>
        )>, actions: (
          { __typename?: 'MandateActions' }
          & Pick<IMandateActions, 'acceptable' | 'revokable'>
        ) }
      )> }
    )> }
  )> }
);

export type IGetDirectDebitMandatePermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetDirectDebitMandatePermissionQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'requestDirectDebitMandate'>
    ) }
  )> }
);

export type IGetInvoicesQueryVariables = Exact<{
  isSplitBilling: Scalars['Boolean']['input'];
}>;


export type IGetInvoicesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { eCurringInvoices?: Array<(
      { __typename?: 'DirectDebitInvoice' }
      & Pick<IDirectDebitInvoice, 'id' | 'externalReference' | 'invoiceDate' | 'pdfUrl'>
      & { settlement?: Maybe<(
        { __typename?: 'Settlement' }
        & Pick<ISettlement, 'id'>
      )>, status?: Maybe<(
        { __typename?: 'InvoiceStatus' }
        & Pick<IInvoiceStatus, 'humanized'>
      )> }
    )>, twikeyInvoices?: Array<(
      { __typename?: 'SplitBillingInvoice' }
      & Pick<ISplitBillingInvoice, 'id' | 'invoiceDate' | 'externalReference' | 'pdfUrl'>
      & { transactionsReport?: Maybe<(
        { __typename?: 'TransactionsReport' }
        & Pick<ITransactionsReport, 'xlsUrl'>
      )> }
    )> }
  )> }
);

export type IGetMandatesQueryVariables = Exact<{
  isSplitBilling: Scalars['Boolean']['input'];
}>;


export type IGetMandatesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { eCurringMandates?: Array<(
      { __typename?: 'DirectDebitMandate' }
      & Pick<IDirectDebitMandate, 'id' | 'uuid' | 'acceptanceUrl' | 'acceptedAt' | 'ecurringCustomerToken' | 'ecurringSubscriptionToken' | 'isAccepted' | 'isCustomerRegistered' | 'isRevoked' | 'isValid' | 'revokedAt'>
      & { status?: Maybe<(
        { __typename?: 'MandateStatus' }
        & Pick<IMandateStatus, 'humanized' | 'value'>
      )>, actions: (
        { __typename?: 'MandateActions' }
        & Pick<IMandateActions, 'acceptable' | 'revokable'>
      ) }
    )>, twikeyContracts?: Maybe<Array<(
      { __typename?: 'SplitBillingContract' }
      & Pick<ISplitBillingContract, 'id' | 'splitBillingContractId' | 'invitedAt' | 'cancelledAt'>
      & { status: (
        { __typename?: 'ContractStatus' }
        & Pick<IContractStatus, 'humanized'>
      ), actions: (
        { __typename?: 'ContractActions' }
        & Pick<IContractActions, 'revocable'>
      ) }
    )>> }
  )> }
);

export type IGetAccessUrlMutationVariables = Exact<{
  input: IGetAccessUrlInput;
}>;


export type IGetAccessUrlMutation = (
  { __typename?: 'Mutation' }
  & { splitBillingContracts?: Maybe<(
    { __typename?: 'SplitBillingContracts' }
    & { getAccessUrl?: Maybe<(
      { __typename?: 'GetAccessUrlPayload' }
      & Pick<IGetAccessUrlPayload, 'accessUrl'>
      & { contract?: Maybe<(
        { __typename?: 'SplitBillingContract' }
        & Pick<ISplitBillingContract, 'splitBillingContractId'>
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ) }
    )> }
  )> }
);

export type IGetSplitBillingContractQueryVariables = Exact<{
  contractId: Scalars['String']['input'];
}>;


export type IGetSplitBillingContractQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { splitBillingContract?: Maybe<(
      { __typename?: 'SplitBillingContract' }
      & Pick<ISplitBillingContract, 'id' | 'splitBillingContractId' | 'accessUrl' | 'statusReason'>
      & { status: (
        { __typename?: 'ContractStatus' }
        & Pick<IContractStatus, 'humanized' | 'value'>
      ) }
    )> }
  )> }
);

export type IGetBillingDataQueryVariables = Exact<{
  isSplitBilling: Scalars['Boolean']['input'];
}>;


export type IGetBillingDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & MakeOptional<Pick<IEmployee, 'id' | 'eMandateRequired'>, 'eMandateRequired'>
    & { financialConsequences?: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { tentativeSettlement: (
        { __typename?: 'Settlement' }
        & { totalGrossAndNetCharge: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'valueInCents'>
        ), totalGrossAndNetReimbursement: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'valueInCents'>
        ) }
      ) }
    ), eCurringInvoices?: Array<(
      { __typename?: 'DirectDebitInvoice' }
      & Pick<IDirectDebitInvoice, 'id' | 'externalReference' | 'invoiceDate' | 'pdfUrl'>
      & { settlement?: Maybe<(
        { __typename?: 'Settlement' }
        & Pick<ISettlement, 'id'>
      )>, status?: Maybe<(
        { __typename?: 'InvoiceStatus' }
        & Pick<IInvoiceStatus, 'humanized'>
      )> }
    )>, eCurringMandates?: Array<(
      { __typename?: 'DirectDebitMandate' }
      & Pick<IDirectDebitMandate, 'id' | 'uuid' | 'acceptanceUrl' | 'acceptedAt' | 'ecurringCustomerToken' | 'ecurringSubscriptionToken' | 'isAccepted' | 'isCustomerRegistered' | 'isRevoked' | 'isValid' | 'revokedAt'>
      & { status?: Maybe<(
        { __typename?: 'MandateStatus' }
        & Pick<IMandateStatus, 'humanized' | 'value'>
      )>, actions: (
        { __typename?: 'MandateActions' }
        & Pick<IMandateActions, 'acceptable' | 'revokable'>
      ) }
    )>, permissions: (
      { __typename?: 'Permissions' }
      & MakeOptional<Pick<IPermissions, 'requestSplitBillingContract' | 'requestDirectDebitMandate'>, 'requestSplitBillingContract' | 'requestDirectDebitMandate'>
    ), twikeyInvoices?: Array<(
      { __typename?: 'SplitBillingInvoice' }
      & Pick<ISplitBillingInvoice, 'id' | 'invoiceDate' | 'externalReference' | 'pdfUrl'>
      & { transactionsReport?: Maybe<(
        { __typename?: 'TransactionsReport' }
        & Pick<ITransactionsReport, 'xlsUrl'>
      )> }
    )>, twikeyContracts?: Maybe<Array<(
      { __typename?: 'SplitBillingContract' }
      & Pick<ISplitBillingContract, 'id' | 'splitBillingContractId' | 'invitedAt' | 'cancelledAt'>
      & { status: (
        { __typename?: 'ContractStatus' }
        & Pick<IContractStatus, 'humanized'>
      ), actions: (
        { __typename?: 'ContractActions' }
        & Pick<IContractActions, 'revocable'>
      ) }
    )>> }
  )> }
);

export type ICarrierCreateMutationVariables = Exact<{
  input: IMsaCreateCarrierRequestBodyRequestInput;
}>;


export type ICarrierCreateMutation = (
  { __typename?: 'Mutation' }
  & { MobilityServices: (
    { __typename?: 'MobilityServicesMutation' }
    & { carrierCreate?: Maybe<(
      { __typename?: 'MSACarrier' }
      & Pick<IMsaCarrier, 'carrierId' | 'status' | 'statusCategory'>
    )> }
  ) }
);

export type IProductGroupGetQueryVariables = Exact<{
  productGroupId: Scalars['UUID']['input'];
}>;


export type IProductGroupGetQuery = (
  { __typename?: 'Query' }
  & { MobilityServices: (
    { __typename?: 'MobilityServicesQuery' }
    & { productGroupGet?: Maybe<(
      { __typename?: 'MSAProductGroup' }
      & { carriers: Array<Maybe<(
        { __typename?: 'MSACarrier' }
        & Pick<IMsaCarrier, 'carrierId' | 'status' | 'statusCategory'>
      )>> }
    )> }
  ) }
);

export type IGetYellowbricksUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetYellowbricksUrlQuery = (
  { __typename?: 'Query' }
  & { externalUrls: (
    { __typename?: 'ExternalUrls' }
    & { yellowbrickForgotPasswordUrl: (
      { __typename?: 'ExternalUrl' }
      & Pick<IExternalUrl, 'title' | 'url'>
    ) }
  ) }
);

export type ISetMobilityCardProfileMutationVariables = Exact<{
  mobilityCardOrderId: Scalars['ID']['input'];
  homeAddress: IEmployeeAddressMutation;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  infix?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['Date']['input'];
  initials: Scalars['String']['input'];
}>;


export type ISetMobilityCardProfileMutation = (
  { __typename?: 'Mutation' }
  & { mobilityCardOrders?: Maybe<(
    { __typename?: 'MobilityCardOrders' }
    & { setProfile?: Maybe<(
      { __typename?: 'SetProfilePayload' }
      & { result?: Maybe<(
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      )>, profile?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<IProfile, 'employeeNumber' | 'firstName' | 'lastName' | 'infix' | 'dateOfBirth' | 'initials' | 'phoneNumber'>
        & { homeAddress?: Maybe<(
          { __typename?: 'EmployeeAddress' }
          & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
          & { employeeCountryIsoCode?: Maybe<(
            { __typename?: 'CountryIsoCode' }
            & Pick<ICountryIsoCode, 'humanized' | 'value'>
          )> }
        )> }
      )>, validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )> }
    )> }
  )> }
);

export type IGetStationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetStationsQuery = (
  { __typename?: 'Query' }
  & { stations?: Maybe<Array<(
    { __typename?: 'Station' }
    & Pick<IStation, 'humanized' | 'value'>
  )>> }
);

export type ISetOptionsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  options: IOptionsMutation;
}>;


export type ISetOptionsMutation = (
  { __typename?: 'Mutation' }
  & { mobilityCardOrders?: Maybe<(
    { __typename?: 'MobilityCardOrders' }
    & { setOptions?: Maybe<(
      { __typename?: 'SetOptionsPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'messages' | 'attribute' | 'fullMessages'>
      )>, mobilityCardOrder?: Maybe<(
        { __typename?: 'MobilityCardOrder' }
        & Pick<IMobilityCardOrder, 'id' | 'fromStation' | 'toStation' | 'passportPhoto' | 'motivation' | 'needsApproval' | 'hasPincodeBeenEntered' | 'completedAt'>
        & { humanizedSubscription?: Maybe<(
          { __typename?: 'Subscription' }
          & Pick<ISubscription, 'value'>
        )>, requiredTermsAndConditionsDocuments?: Maybe<Array<(
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
          ) }
        )>>, services?: Maybe<Array<(
          { __typename?: 'MobilityCardOrderService' }
          & Pick<IMobilityCardOrderService, 'humanized' | 'value'>
        )>>, recommendedCard?: Maybe<(
          { __typename?: 'RecommendedCard' }
          & Pick<IRecommendedCard, 'humanized'>
        )>, firstClassReason?: Maybe<(
          { __typename?: 'FirstClassReason' }
          & Pick<IFirstClassReason, 'humanized' | 'value'>
        )>, permission?: Maybe<(
          { __typename?: 'MobilityCardOrderPermission' }
          & Pick<IMobilityCardOrderPermission, 'canRequestOptions' | 'canRequestPhoto'>
        )>, starValue?: Maybe<(
          { __typename?: 'StarValue' }
          & Pick<IStarValue, 'humanized' | 'value'>
        )>, zone?: Maybe<(
          { __typename?: 'Zone' }
          & Pick<IZone, 'humanized' | 'value'>
        )>, trainClass?: Maybe<(
          { __typename?: 'TrainClass' }
          & Pick<ITrainClass, 'humanized' | 'value'>
        )>, actions: (
          { __typename?: 'MobilityCardOrderActions' }
          & Pick<IMobilityCardOrderActions, 'isEditable'>
        ), requestableSubscriptions?: Maybe<Array<(
          { __typename?: 'AvailableSubscription' }
          & Pick<IAvailableSubscription, 'hasTraject' | 'value' | 'humanized' | 'requiresMotivation'>
          & { starValues: Array<(
            { __typename?: 'StarValue' }
            & Pick<IStarValue, 'humanized' | 'value'>
          )>, humanizedZones?: Maybe<Array<(
            { __typename?: 'Zone' }
            & Pick<IZone, 'humanized' | 'value'>
          )>>, trainClassOptions?: Maybe<(
            { __typename?: 'TrainClassOptions' }
            & Pick<ITrainClassOptions, 'requiresMotivation'>
            & { firstClassReasons?: Maybe<Array<(
              { __typename?: 'FirstClassReason' }
              & Pick<IFirstClassReason, 'humanized' | 'value'>
            )>>, requestableTrainClasses?: Maybe<Array<(
              { __typename?: 'TrainClass' }
              & Pick<ITrainClass, 'humanized' | 'value'>
            )>> }
          )> }
        )>>, requestableServices?: Maybe<Array<(
          { __typename?: 'RequestableService' }
          & Pick<IRequestableService, 'budgetGroupAvailability' | 'requiresMotivation' | 'requiresPincode'>
          & { kind: (
            { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
            & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'sub'>
          ) }
        )>>, status?: Maybe<(
          { __typename?: 'MobilityCardOrderStatus' }
          & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
        )> }
      )> }
    )> }
  )> }
);

export type IUploadAvatarMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  photo: IPhoto;
}>;


export type IUploadAvatarMutation = (
  { __typename?: 'Mutation' }
  & { mobilityCardOrders?: Maybe<(
    { __typename?: 'MobilityCardOrders' }
    & { setPhoto?: Maybe<(
      { __typename?: 'SetPhotoPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), mobilityCardOrder?: Maybe<(
        { __typename?: 'MobilityCardOrder' }
        & Pick<IMobilityCardOrder, 'id' | 'fromStation' | 'toStation' | 'passportPhoto' | 'motivation' | 'needsApproval' | 'hasPincodeBeenEntered' | 'completedAt'>
        & { humanizedSubscription?: Maybe<(
          { __typename?: 'Subscription' }
          & Pick<ISubscription, 'value'>
        )>, requiredTermsAndConditionsDocuments?: Maybe<Array<(
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
          ) }
        )>>, services?: Maybe<Array<(
          { __typename?: 'MobilityCardOrderService' }
          & Pick<IMobilityCardOrderService, 'humanized' | 'value'>
        )>>, recommendedCard?: Maybe<(
          { __typename?: 'RecommendedCard' }
          & Pick<IRecommendedCard, 'humanized'>
        )>, firstClassReason?: Maybe<(
          { __typename?: 'FirstClassReason' }
          & Pick<IFirstClassReason, 'humanized' | 'value'>
        )>, permission?: Maybe<(
          { __typename?: 'MobilityCardOrderPermission' }
          & Pick<IMobilityCardOrderPermission, 'canRequestOptions' | 'canRequestPhoto'>
        )>, starValue?: Maybe<(
          { __typename?: 'StarValue' }
          & Pick<IStarValue, 'humanized' | 'value'>
        )>, zone?: Maybe<(
          { __typename?: 'Zone' }
          & Pick<IZone, 'humanized' | 'value'>
        )>, trainClass?: Maybe<(
          { __typename?: 'TrainClass' }
          & Pick<ITrainClass, 'humanized' | 'value'>
        )>, actions: (
          { __typename?: 'MobilityCardOrderActions' }
          & Pick<IMobilityCardOrderActions, 'isEditable'>
        ), requestableSubscriptions?: Maybe<Array<(
          { __typename?: 'AvailableSubscription' }
          & Pick<IAvailableSubscription, 'hasTraject' | 'value' | 'humanized' | 'requiresMotivation'>
          & { starValues: Array<(
            { __typename?: 'StarValue' }
            & Pick<IStarValue, 'humanized' | 'value'>
          )>, humanizedZones?: Maybe<Array<(
            { __typename?: 'Zone' }
            & Pick<IZone, 'humanized' | 'value'>
          )>>, trainClassOptions?: Maybe<(
            { __typename?: 'TrainClassOptions' }
            & Pick<ITrainClassOptions, 'requiresMotivation'>
            & { firstClassReasons?: Maybe<Array<(
              { __typename?: 'FirstClassReason' }
              & Pick<IFirstClassReason, 'humanized' | 'value'>
            )>>, requestableTrainClasses?: Maybe<Array<(
              { __typename?: 'TrainClass' }
              & Pick<ITrainClass, 'humanized' | 'value'>
            )>> }
          )> }
        )>>, requestableServices?: Maybe<Array<(
          { __typename?: 'RequestableService' }
          & Pick<IRequestableService, 'budgetGroupAvailability' | 'requiresMotivation' | 'requiresPincode'>
          & { kind: (
            { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
            & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'sub'>
          ) }
        )>>, status?: Maybe<(
          { __typename?: 'MobilityCardOrderStatus' }
          & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
        )> }
      )> }
    )> }
  )> }
);

export type IConfirmMobilityCardOrderMutationVariables = Exact<{
  mobilityCardOrderId: Scalars['ID']['input'];
}>;


export type IConfirmMobilityCardOrderMutation = (
  { __typename?: 'Mutation' }
  & { mobilityCardOrders?: Maybe<(
    { __typename?: 'MobilityCardOrders' }
    & { confirm?: Maybe<(
      { __typename?: 'ConfirmPayload' }
      & { mobilityCardOrder?: Maybe<(
        { __typename?: 'MobilityCardOrder' }
        & Pick<IMobilityCardOrder, 'id' | 'fromStation' | 'toStation' | 'passportPhoto' | 'motivation' | 'needsApproval' | 'hasPincodeBeenEntered' | 'completedAt'>
        & { humanizedSubscription?: Maybe<(
          { __typename?: 'Subscription' }
          & Pick<ISubscription, 'value'>
        )>, requiredTermsAndConditionsDocuments?: Maybe<Array<(
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
          ) }
        )>>, services?: Maybe<Array<(
          { __typename?: 'MobilityCardOrderService' }
          & Pick<IMobilityCardOrderService, 'humanized' | 'value'>
        )>>, recommendedCard?: Maybe<(
          { __typename?: 'RecommendedCard' }
          & Pick<IRecommendedCard, 'humanized'>
        )>, firstClassReason?: Maybe<(
          { __typename?: 'FirstClassReason' }
          & Pick<IFirstClassReason, 'humanized' | 'value'>
        )>, permission?: Maybe<(
          { __typename?: 'MobilityCardOrderPermission' }
          & Pick<IMobilityCardOrderPermission, 'canRequestOptions' | 'canRequestPhoto'>
        )>, starValue?: Maybe<(
          { __typename?: 'StarValue' }
          & Pick<IStarValue, 'humanized' | 'value'>
        )>, zone?: Maybe<(
          { __typename?: 'Zone' }
          & Pick<IZone, 'humanized' | 'value'>
        )>, trainClass?: Maybe<(
          { __typename?: 'TrainClass' }
          & Pick<ITrainClass, 'humanized' | 'value'>
        )>, actions: (
          { __typename?: 'MobilityCardOrderActions' }
          & Pick<IMobilityCardOrderActions, 'isEditable'>
        ), requestableSubscriptions?: Maybe<Array<(
          { __typename?: 'AvailableSubscription' }
          & Pick<IAvailableSubscription, 'hasTraject' | 'value' | 'humanized' | 'requiresMotivation'>
          & { starValues: Array<(
            { __typename?: 'StarValue' }
            & Pick<IStarValue, 'humanized' | 'value'>
          )>, humanizedZones?: Maybe<Array<(
            { __typename?: 'Zone' }
            & Pick<IZone, 'humanized' | 'value'>
          )>>, trainClassOptions?: Maybe<(
            { __typename?: 'TrainClassOptions' }
            & Pick<ITrainClassOptions, 'requiresMotivation'>
            & { firstClassReasons?: Maybe<Array<(
              { __typename?: 'FirstClassReason' }
              & Pick<IFirstClassReason, 'humanized' | 'value'>
            )>>, requestableTrainClasses?: Maybe<Array<(
              { __typename?: 'TrainClass' }
              & Pick<ITrainClass, 'humanized' | 'value'>
            )>> }
          )> }
        )>>, requestableServices?: Maybe<Array<(
          { __typename?: 'RequestableService' }
          & Pick<IRequestableService, 'budgetGroupAvailability' | 'requiresMotivation' | 'requiresPincode'>
          & { kind: (
            { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
            & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'sub'>
          ) }
        )>>, status?: Maybe<(
          { __typename?: 'MobilityCardOrderStatus' }
          & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
        )> }
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ) }
    )> }
  )> }
);

export type ISetTermsMutationVariables = Exact<{
  documentIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  mobilityCardOrderId: Scalars['ID']['input'];
}>;


export type ISetTermsMutation = (
  { __typename?: 'Mutation' }
  & { mobilityCardOrders?: Maybe<(
    { __typename?: 'MobilityCardOrders' }
    & { setTerms?: Maybe<(
      { __typename?: 'SetTermsPayload' }
      & { mobilityCardOrder?: Maybe<(
        { __typename?: 'MobilityCardOrder' }
        & Pick<IMobilityCardOrder, 'id' | 'fromStation' | 'toStation' | 'passportPhoto' | 'motivation' | 'needsApproval' | 'hasPincodeBeenEntered' | 'completedAt'>
        & { humanizedSubscription?: Maybe<(
          { __typename?: 'Subscription' }
          & Pick<ISubscription, 'value'>
        )>, requiredTermsAndConditionsDocuments?: Maybe<Array<(
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
          ) }
        )>>, services?: Maybe<Array<(
          { __typename?: 'MobilityCardOrderService' }
          & Pick<IMobilityCardOrderService, 'humanized' | 'value'>
        )>>, recommendedCard?: Maybe<(
          { __typename?: 'RecommendedCard' }
          & Pick<IRecommendedCard, 'humanized'>
        )>, firstClassReason?: Maybe<(
          { __typename?: 'FirstClassReason' }
          & Pick<IFirstClassReason, 'humanized' | 'value'>
        )>, permission?: Maybe<(
          { __typename?: 'MobilityCardOrderPermission' }
          & Pick<IMobilityCardOrderPermission, 'canRequestOptions' | 'canRequestPhoto'>
        )>, starValue?: Maybe<(
          { __typename?: 'StarValue' }
          & Pick<IStarValue, 'humanized' | 'value'>
        )>, zone?: Maybe<(
          { __typename?: 'Zone' }
          & Pick<IZone, 'humanized' | 'value'>
        )>, trainClass?: Maybe<(
          { __typename?: 'TrainClass' }
          & Pick<ITrainClass, 'humanized' | 'value'>
        )>, actions: (
          { __typename?: 'MobilityCardOrderActions' }
          & Pick<IMobilityCardOrderActions, 'isEditable'>
        ), requestableSubscriptions?: Maybe<Array<(
          { __typename?: 'AvailableSubscription' }
          & Pick<IAvailableSubscription, 'hasTraject' | 'value' | 'humanized' | 'requiresMotivation'>
          & { starValues: Array<(
            { __typename?: 'StarValue' }
            & Pick<IStarValue, 'humanized' | 'value'>
          )>, humanizedZones?: Maybe<Array<(
            { __typename?: 'Zone' }
            & Pick<IZone, 'humanized' | 'value'>
          )>>, trainClassOptions?: Maybe<(
            { __typename?: 'TrainClassOptions' }
            & Pick<ITrainClassOptions, 'requiresMotivation'>
            & { firstClassReasons?: Maybe<Array<(
              { __typename?: 'FirstClassReason' }
              & Pick<IFirstClassReason, 'humanized' | 'value'>
            )>>, requestableTrainClasses?: Maybe<Array<(
              { __typename?: 'TrainClass' }
              & Pick<ITrainClass, 'humanized' | 'value'>
            )>> }
          )> }
        )>>, requestableServices?: Maybe<Array<(
          { __typename?: 'RequestableService' }
          & Pick<IRequestableService, 'budgetGroupAvailability' | 'requiresMotivation' | 'requiresPincode'>
          & { kind: (
            { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
            & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'sub'>
          ) }
        )>>, status?: Maybe<(
          { __typename?: 'MobilityCardOrderStatus' }
          & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
        )> }
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ) }
    )> }
  )> }
);

export type IGetMobilityCardOrderQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetMobilityCardOrderQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityCardOrder?: Maybe<(
      { __typename?: 'MobilityCardOrder' }
      & Pick<IMobilityCardOrder, 'id' | 'fromStation' | 'toStation' | 'passportPhoto' | 'motivation' | 'needsApproval' | 'hasPincodeBeenEntered' | 'completedAt'>
      & { humanizedSubscription?: Maybe<(
        { __typename?: 'Subscription' }
        & Pick<ISubscription, 'value'>
      )>, requiredTermsAndConditionsDocuments?: Maybe<Array<(
        { __typename?: 'TermsAndConditionsDocument' }
        & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
        & { humanizedKind: (
          { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
          & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized' | 'value'>
        ) }
      )>>, services?: Maybe<Array<(
        { __typename?: 'MobilityCardOrderService' }
        & Pick<IMobilityCardOrderService, 'humanized' | 'value'>
      )>>, recommendedCard?: Maybe<(
        { __typename?: 'RecommendedCard' }
        & Pick<IRecommendedCard, 'humanized'>
      )>, firstClassReason?: Maybe<(
        { __typename?: 'FirstClassReason' }
        & Pick<IFirstClassReason, 'humanized' | 'value'>
      )>, permission?: Maybe<(
        { __typename?: 'MobilityCardOrderPermission' }
        & Pick<IMobilityCardOrderPermission, 'canRequestOptions' | 'canRequestPhoto'>
      )>, starValue?: Maybe<(
        { __typename?: 'StarValue' }
        & Pick<IStarValue, 'humanized' | 'value'>
      )>, zone?: Maybe<(
        { __typename?: 'Zone' }
        & Pick<IZone, 'humanized' | 'value'>
      )>, trainClass?: Maybe<(
        { __typename?: 'TrainClass' }
        & Pick<ITrainClass, 'humanized' | 'value'>
      )>, actions: (
        { __typename?: 'MobilityCardOrderActions' }
        & Pick<IMobilityCardOrderActions, 'isEditable'>
      ), requestableSubscriptions?: Maybe<Array<(
        { __typename?: 'AvailableSubscription' }
        & Pick<IAvailableSubscription, 'hasTraject' | 'value' | 'humanized' | 'requiresMotivation'>
        & { starValues: Array<(
          { __typename?: 'StarValue' }
          & Pick<IStarValue, 'humanized' | 'value'>
        )>, humanizedZones?: Maybe<Array<(
          { __typename?: 'Zone' }
          & Pick<IZone, 'humanized' | 'value'>
        )>>, trainClassOptions?: Maybe<(
          { __typename?: 'TrainClassOptions' }
          & Pick<ITrainClassOptions, 'requiresMotivation'>
          & { firstClassReasons?: Maybe<Array<(
            { __typename?: 'FirstClassReason' }
            & Pick<IFirstClassReason, 'humanized' | 'value'>
          )>>, requestableTrainClasses?: Maybe<Array<(
            { __typename?: 'TrainClass' }
            & Pick<ITrainClass, 'humanized' | 'value'>
          )>> }
        )> }
      )>>, requestableServices?: Maybe<Array<(
        { __typename?: 'RequestableService' }
        & Pick<IRequestableService, 'budgetGroupAvailability' | 'requiresMotivation' | 'requiresPincode'>
        & { kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'sub'>
        ) }
      )>>, status?: Maybe<(
        { __typename?: 'MobilityCardOrderStatus' }
        & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
      )> }
    )> }
  )> }
);

export type IActivateCardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IActivateCardMutation = (
  { __typename?: 'Mutation' }
  & { activatePersonalMeansOfRegistration?: Maybe<(
    { __typename?: 'ActivatePersonalMeansOfRegistrationPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type ITerminateCardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ITerminateCardMutation = (
  { __typename?: 'Mutation' }
  & { terminatePersonalMeansOfRegistration?: Maybe<(
    { __typename?: 'TerminatePersonalMeansOfRegistrationPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IReuploadPhotoMutationVariables = Exact<{
  input: IReuploadPhotoInput;
}>;


export type IReuploadPhotoMutation = (
  { __typename?: 'Mutation' }
  & { reuploadPhoto?: Maybe<(
    { __typename?: 'ReuploadPhotoPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), profile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<IProfile, 'avatar' | 'employeeNumber'>
    )> }
  )> }
);

export type IGetCardInformationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetCardInformationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { personalMeansOfRegistration?: Maybe<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { photoRejectedReasons?: Maybe<Array<(
        { __typename?: 'PersonalMeansOfRegistrationPhotoRejectedReason' }
        & Pick<IPersonalMeansOfRegistrationPhotoRejectedReason, 'humanized'>
      )>>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationKind' }
        & Pick<IPersonalMeansOfRegistrationKind, 'humanized' | 'main' | 'sub'>
      ), status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationStatus' }
        & Pick<IPersonalMeansOfRegistrationStatus, 'humanized' | 'value'>
      )>, endsOn: (
        { __typename?: 'PersonalMeansOfRegistrationEndsOn' }
        & Pick<IPersonalMeansOfRegistrationEndsOn, 'humanized'>
      ), identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )>, abilities: (
        { __typename?: 'PersonalMeansOfRegistrationAbilities' }
        & Pick<IPersonalMeansOfRegistrationAbilities, 'activatable' | 'lostOrStolenReportable' | 'terminatable'>
      ), availableServices?: Maybe<Array<(
        { __typename?: 'ActiveAdditional' }
        & Pick<IActiveAdditional, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'ActiveGreenwheels' }
        & Pick<IActiveGreenwheels, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'ActiveSubscription' }
        & Pick<IActiveSubscription, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'ActiveYellowbrickSp' }
        & Pick<IActiveYellowbrickSp, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'RequestableService' }
        & Pick<IRequestableService, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'RequestableYellowbrickProduct' }
        & Pick<IRequestableYellowbrickProduct, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type IReportCardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  needReplacement?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type IReportCardMutation = (
  { __typename?: 'Mutation' }
  & { reportPersonalMeansOfRegistration?: Maybe<(
    { __typename?: 'ReportPersonalMeansOfRegistrationPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), personalMeansOfRegistration: (
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { photoRejectedReasons?: Maybe<Array<(
        { __typename?: 'PersonalMeansOfRegistrationPhotoRejectedReason' }
        & Pick<IPersonalMeansOfRegistrationPhotoRejectedReason, 'humanized'>
      )>>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationKind' }
        & Pick<IPersonalMeansOfRegistrationKind, 'humanized' | 'main' | 'sub'>
      ), status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationStatus' }
        & Pick<IPersonalMeansOfRegistrationStatus, 'humanized' | 'value'>
      )>, endsOn: (
        { __typename?: 'PersonalMeansOfRegistrationEndsOn' }
        & Pick<IPersonalMeansOfRegistrationEndsOn, 'humanized'>
      ), identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )>, abilities: (
        { __typename?: 'PersonalMeansOfRegistrationAbilities' }
        & Pick<IPersonalMeansOfRegistrationAbilities, 'activatable' | 'lostOrStolenReportable' | 'terminatable'>
      ), availableServices?: Maybe<Array<(
        { __typename?: 'ActiveAdditional' }
        & Pick<IActiveAdditional, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'ActiveGreenwheels' }
        & Pick<IActiveGreenwheels, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'ActiveSubscription' }
        & Pick<IActiveSubscription, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'ActiveYellowbrickSp' }
        & Pick<IActiveYellowbrickSp, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'RequestableService' }
        & Pick<IRequestableService, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      ) | (
        { __typename?: 'RequestableYellowbrickProduct' }
        & Pick<IRequestableYellowbrickProduct, 'requiresMotivation' | 'recordId'>
        & { termsAndConditions?: Maybe<(
          { __typename?: 'TermsAndConditions' }
          & Pick<ITermsAndConditions, 'requiresAgreement'>
          & { document: (
            { __typename?: 'TermsAndConditionsDocument' }
            & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
            & { humanizedKind: (
              { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
              & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
            ) }
          ) }
        )>, description?: Maybe<(
          { __typename?: 'Description' }
          & Pick<IDescription, 'humanized'>
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
          & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
        )>, kind: (
          { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
          & Pick<IPersonalMeansOfRegistrationServiceKind, 'humanized' | 'main' | 'sub'>
        ), status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
          & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
          & { metaStatus?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
            & Pick<IPersonalMeansOfRegistrationMetaStatus, 'humanized' | 'value'>
          )> }
        )>, personalMeansOfRegistration?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistration' }
          & Pick<IPersonalMeansOfRegistration, 'id'>
          & { identity?: Maybe<(
            { __typename?: 'PersonalMeansOfRegistrationIdentity' }
            & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
          )> }
        )> }
      )>> }
    ) }
  )> }
);

export type IActivateEnecoDongleMutationVariables = Exact<{
  input: IActivateEnecoDongleInput;
}>;


export type IActivateEnecoDongleMutation = (
  { __typename?: 'Mutation' }
  & { activateEnecoDongle?: Maybe<(
    { __typename?: 'ActivateEnecoDonglePayload' }
    & { enecoDongle?: Maybe<(
      { __typename?: 'EnecoDongle' }
      & Pick<IEnecoDongle, 'id' | 'activatable' | 'mobideskOwnershipToken' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    )>, result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ) }
  )> }
);

export type IGetAvailableFeatureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetAvailableFeatureQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { availableFeature?: Maybe<(
      { __typename?: 'Amber' }
      & Pick<IAmber, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'DonkeyRepublic' }
      & Pick<IDonkeyRepublic, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'EnecoDongle' }
      & Pick<IEnecoDongle, 'activatable' | 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'Greenwheels' }
      & Pick<IGreenwheels, 'activatable' | 'activationLink' | 'registerable' | 'greenwheelsTermsAndConditionsUrl' | 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { driversLicenseCountries: Array<(
        { __typename?: 'DriversLicenseCountry' }
        & Pick<IDriversLicenseCountry, 'humanized' | 'value'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'LeaseBike' }
      & Pick<ILeaseBike, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RentalCar' }
      & Pick<IRentalCar, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RequestableDonkeyRepublic' }
      & Pick<IRequestableDonkeyRepublic, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RequestableFeature' }
      & Pick<IRequestableFeature, 'requiresMotivation' | 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { termsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'SharedVehicle' }
      & Pick<ISharedVehicle, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelCheck' }
      & Pick<IShuttelCheck, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelMyWheels' }
      & Pick<IShuttelMyWheels, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelSixt' }
      & Pick<IShuttelSixt, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelTaxi' }
      & Pick<IShuttelTaxi, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelTranzerEticket' }
      & Pick<IShuttelTranzerEticket, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelVecore' }
      & Pick<IShuttelVecore, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'TaxiFeature' }
      & Pick<ITaxiFeature, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'TranzerEticket' }
      & Pick<ITranzerEticket, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'YellowbrickSp' }
      & Pick<IYellowbrickSp, 'id' | 'mobideskOwnershipToken' | 'motivation' | 'description' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    )> }
  )> }
);

export type IUpdateTrainSubscriptionMutationVariables = Exact<{
  input: IRequestTrainClassChangeInput;
}>;


export type IUpdateTrainSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { requestTrainClassChange?: Maybe<(
    { __typename?: 'RequestTrainClassChangePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )> }
  )> }
);

export type IGetGreenwheelsFleetsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetGreenwheelsFleetsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { greenwheelsFleets?: Maybe<Array<(
      { __typename?: 'GreenwheelsFleet' }
      & Pick<IGreenwheelsFleet, 'id' | 'name'>
    )>> }
  )> }
);

export type IConfirmGreenwheelsMutationVariables = Exact<{
  input: IConfirmGreenwheelsInput;
}>;


export type IConfirmGreenwheelsMutation = (
  { __typename?: 'Mutation' }
  & { services?: Maybe<(
    { __typename?: 'Services' }
    & { confirmGreenwheels?: Maybe<(
      { __typename?: 'ConfirmGreenwheelsPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )> }
    )> }
  )> }
);

export type ITerminateOwnershipMutationVariables = Exact<{
  input: ITerminateOwnershipInput;
}>;


export type ITerminateOwnershipMutation = (
  { __typename?: 'Mutation' }
  & { terminateOwnership?: Maybe<(
    { __typename?: 'TerminateOwnershipPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ) }
  )> }
);

export type IGetAvailableServiceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetAvailableServiceQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { availableService?: Maybe<(
      { __typename?: 'ActiveAdditional' }
      & Pick<IActiveAdditional, 'mobideskOwnershipToken' | 'recordId'>
      & { endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value' | 'humanized'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'value' | 'humanized'>
        )> }
      )> }
    ) | (
      { __typename?: 'ActiveGreenwheels' }
      & Pick<IActiveGreenwheels, 'activatable' | 'activationLink' | 'registerable' | 'greenwheelsTermsAndConditionsUrl' | 'mobideskOwnershipToken' | 'recordId'>
      & { driversLicenseCountries: Array<(
        { __typename?: 'DriversLicenseCountry' }
        & Pick<IDriversLicenseCountry, 'humanized' | 'value'>
      )>, termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value' | 'humanized'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'value' | 'humanized'>
        )> }
      )> }
    ) | (
      { __typename?: 'ActiveSubscription' }
      & Pick<IActiveSubscription, 'mobideskOwnershipToken' | 'recordId'>
      & { trainClassChange: (
        { __typename?: 'TrainClassChange' }
        & Pick<ITrainClassChange, 'trainClassStatus' | 'canRequestTrainClassChange' | 'requiresTrainClassChangeMotivation'>
        & { requestableTrainClass?: Maybe<(
          { __typename?: 'TrainClass' }
          & Pick<ITrainClass, 'humanized' | 'value'>
        )>, firstClassReasons?: Maybe<Array<(
          { __typename?: 'FirstClassReason' }
          & Pick<IFirstClassReason, 'humanized' | 'value'>
        )>> }
      ), trainClass?: Maybe<(
        { __typename?: 'TrainClass' }
        & Pick<ITrainClass, 'humanized' | 'value'>
      )>, traject?: Maybe<(
        { __typename?: 'Traject' }
        & Pick<ITraject, 'from' | 'to'>
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value' | 'humanized'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'value' | 'humanized'>
        )> }
      )> }
    ) | (
      { __typename?: 'ActiveYellowbrickSp' }
      & Pick<IActiveYellowbrickSp, 'mobideskOwnershipToken' | 'recordId'>
      & { endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value' | 'humanized'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'value' | 'humanized'>
        )> }
      )> }
    ) | (
      { __typename?: 'RequestableService' }
      & Pick<IRequestableService, 'mobideskOwnershipToken' | 'recordId'>
      & { termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value' | 'humanized'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'value' | 'humanized'>
        )> }
      )> }
    ) | (
      { __typename?: 'RequestableYellowbrickProduct' }
      & Pick<IRequestableYellowbrickProduct, 'mobideskOwnershipToken' | 'recordId'>
      & { endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value' | 'humanized'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'value' | 'humanized'>
        )> }
      )> }
    )> }
  )> }
);

export type IRequestFeatureMutationVariables = Exact<{
  input: IRequestFeatureInput;
}>;


export type IRequestFeatureMutation = (
  { __typename?: 'Mutation' }
  & { requestFeature?: Maybe<(
    { __typename?: 'RequestFeaturePayload' }
    & { result?: Maybe<(
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    )>, validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )> }
  )> }
);

export type ICreateParkingOrderMutationVariables = Exact<{
  input: ICreateParkingOrderInput;
}>;


export type ICreateParkingOrderMutation = (
  { __typename?: 'Mutation' }
  & { parking?: Maybe<(
    { __typename?: 'Parking' }
    & { create?: Maybe<(
      { __typename?: 'CreateParkingOrderPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'messages' | 'fullMessages'>
      )> }
    )> }
  )> }
);

export type IRequestServiceMutationVariables = Exact<{
  input: IRequestServiceInput;
}>;


export type IRequestServiceMutation = (
  { __typename?: 'Mutation' }
  & { services?: Maybe<(
    { __typename?: 'Services' }
    & { request?: Maybe<(
      { __typename?: 'RequestServicePayload' }
      & { validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'messages' | 'fullMessages'>
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ) }
    )> }
  )> }
);

export type IAllowedOvPasTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type IAllowedOvPasTypesQuery = (
  { __typename?: 'Query' }
  & { reisbalansV2: (
    { __typename?: 'reisbalansV2Query' }
    & { ovPas: (
      { __typename?: 'OvPas' }
      & Pick<IOvPas, 'allowedOvPasTypes'>
    ) }
  ) }
);

export type ICreateMobilityCardOrderMutationVariables = Exact<{ [key: string]: never; }>;


export type ICreateMobilityCardOrderMutation = (
  { __typename?: 'Mutation' }
  & { mobilityCardOrders?: Maybe<(
    { __typename?: 'MobilityCardOrders' }
    & { create?: Maybe<(
      { __typename?: 'CreateMobilityCardOrderPayload' }
      & { mobilityCardOrder?: Maybe<(
        { __typename?: 'MobilityCardOrder' }
        & Pick<IMobilityCardOrder, 'id'>
        & { actions: (
          { __typename?: 'MobilityCardOrderActions' }
          & Pick<IMobilityCardOrderActions, 'isEditable'>
        ), status?: Maybe<(
          { __typename?: 'MobilityCardOrderStatus' }
          & Pick<IMobilityCardOrderStatus, 'value'>
        )> }
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ) }
    )> }
  )> }
);

export type IGetCardsAndServicesDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetCardsAndServicesDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'canCreateNewMobilityCardOrder'>
    ), mobilityCardOrders: Array<(
      { __typename?: 'MobilityCardOrder' }
      & Pick<IMobilityCardOrder, 'id'>
      & { actions: (
        { __typename?: 'MobilityCardOrderActions' }
        & Pick<IMobilityCardOrderActions, 'isEditable'>
      ), status?: Maybe<(
        { __typename?: 'MobilityCardOrderStatus' }
        & Pick<IMobilityCardOrderStatus, 'value'>
      )> }
    )>, personalMeansOfRegistrations?: Maybe<Array<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationKind' }
        & Pick<IPersonalMeansOfRegistrationKind, 'humanized' | 'sub'>
      ), status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationStatus' }
        & Pick<IPersonalMeansOfRegistrationStatus, 'value'>
      )> }
    )>>, availableServices?: Maybe<Array<(
      { __typename: 'ActiveAdditional' }
      & Pick<IActiveAdditional, 'recordId'>
      & { endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized' | 'enumValue'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'humanized'>
        )> }
      )> }
    ) | (
      { __typename: 'ActiveGreenwheels' }
      & Pick<IActiveGreenwheels, 'activatable' | 'activationLink' | 'registerable' | 'greenwheelsTermsAndConditionsUrl' | 'recordId'>
      & { driversLicenseCountries: Array<(
        { __typename?: 'DriversLicenseCountry' }
        & Pick<IDriversLicenseCountry, 'humanized' | 'value'>
      )>, termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized' | 'enumValue'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'humanized'>
        )> }
      )> }
    ) | (
      { __typename: 'ActiveSubscription' }
      & Pick<IActiveSubscription, 'recordId'>
      & { trainClassChange: (
        { __typename?: 'TrainClassChange' }
        & Pick<ITrainClassChange, 'canRequestTrainClassChange' | 'requiresTrainClassChangeMotivation'>
        & { firstClassReasons?: Maybe<Array<(
          { __typename?: 'FirstClassReason' }
          & Pick<IFirstClassReason, 'humanized' | 'value'>
        )>> }
      ), trainClass?: Maybe<(
        { __typename?: 'TrainClass' }
        & Pick<ITrainClass, 'humanized' | 'value'>
      )>, traject?: Maybe<(
        { __typename?: 'Traject' }
        & Pick<ITraject, 'from' | 'to'>
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized' | 'enumValue'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'humanized'>
        )> }
      )> }
    ) | (
      { __typename: 'ActiveYellowbrickSp' }
      & Pick<IActiveYellowbrickSp, 'recordId'>
      & { endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized' | 'enumValue'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'humanized'>
        )> }
      )> }
    ) | (
      { __typename: 'RequestableService' }
      & Pick<IRequestableService, 'requiresMotivation' | 'recordId'>
      & { termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized' | 'enumValue'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'humanized'>
        )> }
      )> }
    ) | (
      { __typename: 'RequestableYellowbrickProduct' }
      & Pick<IRequestableYellowbrickProduct, 'requiresMotivation' | 'recordId'>
      & { termsAndConditions?: Maybe<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement' | 'agreed'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>, endsOn?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceEndsOn' }
        & Pick<IPersonalMeansOfRegistrationServiceEndsOn, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationServiceKind' }
        & Pick<IPersonalMeansOfRegistrationServiceKind, 'sub' | 'humanized'>
      ), description?: Maybe<(
        { __typename?: 'Description' }
        & Pick<IDescription, 'humanized'>
      )>, identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceIdentity' }
        & Pick<IPersonalMeansOfRegistrationServiceIdentity, 'humanized'>
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationServiceStatus' }
        & Pick<IPersonalMeansOfRegistrationServiceStatus, 'humanized' | 'enumValue'>
        & { metaStatus?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationMetaStatus' }
          & Pick<IPersonalMeansOfRegistrationMetaStatus, 'value'>
        )> }
      )>, personalMeansOfRegistration?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistration' }
        & Pick<IPersonalMeansOfRegistration, 'id'>
        & { identity?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationIdentity' }
          & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
        )>, status?: Maybe<(
          { __typename?: 'PersonalMeansOfRegistrationStatus' }
          & Pick<IPersonalMeansOfRegistrationStatus, 'humanized'>
        )> }
      )> }
    )>>, availableFeatures?: Maybe<Array<(
      { __typename?: 'Amber' }
      & Pick<IAmber, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'DonkeyRepublic' }
      & Pick<IDonkeyRepublic, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'EnecoDongle' }
      & Pick<IEnecoDongle, 'activatable' | 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'Greenwheels' }
      & Pick<IGreenwheels, 'activatable' | 'registerable' | 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'LeaseBike' }
      & Pick<ILeaseBike, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RentalCar' }
      & Pick<IRentalCar, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RequestableDonkeyRepublic' }
      & Pick<IRequestableDonkeyRepublic, 'requiresMotivation' | 'requiresPhoneNumber' | 'id' | 'motivation' | 'completedAt'>
      & { termsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'RequestableFeature' }
      & Pick<IRequestableFeature, 'requiresMotivation' | 'requiresPhoneNumber' | 'id' | 'motivation' | 'completedAt'>
      & { termsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'SharedVehicle' }
      & Pick<ISharedVehicle, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelCheck' }
      & Pick<IShuttelCheck, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelMyWheels' }
      & Pick<IShuttelMyWheels, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelSixt' }
      & Pick<IShuttelSixt, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelTaxi' }
      & Pick<IShuttelTaxi, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelTranzerEticket' }
      & Pick<IShuttelTranzerEticket, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'ShuttelVecore' }
      & Pick<IShuttelVecore, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'TaxiFeature' }
      & Pick<ITaxiFeature, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'TranzerEticket' }
      & Pick<ITranzerEticket, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    ) | (
      { __typename?: 'YellowbrickSp' }
      & Pick<IYellowbrickSp, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )>, status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'value' | 'humanized'>
      ), agreedTermsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>> }
    )>> }
  )> }
);

export type ICreateWorkFromHomeAllowanceMutationVariables = Exact<{
  date: Scalars['Date']['input'];
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ICreateWorkFromHomeAllowanceMutation = (
  { __typename?: 'Mutation' }
  & { createWorkFromHome?: Maybe<(
    { __typename?: 'CreateWorkFromHomePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )>, mobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetDayQueryVariables = Exact<{
  date: Scalars['Date']['input'];
}>;


export type IGetDayQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'manageWorkFromHome' | 'manageCommuteCompensation' | 'manageMobilityEvents' | 'manageCommuteRides'>
    ), day?: Maybe<(
      { __typename?: 'DayWithMobilityEvents' }
      & Pick<IDayWithMobilityEvents, 'date' | 'commuteCompensationAddable' | 'eventsAddable' | 'eventsConfirmable' | 'commuteRidesAddable' | 'commuteRideAddable' | 'workFromHomeCompensationAddable'>
    ) | { __typename?: 'DayWithoutEmployment' }> }
  )> }
);

export type IGetApprovableMobilityEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetApprovableMobilityEventsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'approvableMobilityEventsAggregate'>
  )> }
);

export type IGetCo2EmissionSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetCo2EmissionSummaryQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { co2EmissionSummary?: Maybe<(
      { __typename?: 'Co2EmissionSummary' }
      & { totalEmission: (
        { __typename?: 'Co2Emission' }
        & Pick<ICo2Emission, 'humanized'>
      ) }
    )> }
  )> }
);

export type IGetFinancialConsequencesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFinancialConsequencesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { tentativeSettlement: (
        { __typename?: 'Settlement' }
        & { totalGrossAndNetCharge: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'valueInCents'>
        ), totalGrossAndNetReimbursement: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'valueInCents'>
        ) }
      ) }
    ) }
  )> }
);

export type IGetMobilityCardPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMobilityCardPermissionQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'canCreateNewMobilityCardOrder'>
    ) }
  )> }
);

export type IGetMobilityCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMobilityCardsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { personalMeansOfRegistrations?: Maybe<Array<(
      { __typename?: 'PersonalMeansOfRegistration' }
      & Pick<IPersonalMeansOfRegistration, 'id'>
      & { endsOn: (
        { __typename?: 'PersonalMeansOfRegistrationEndsOn' }
        & Pick<IPersonalMeansOfRegistrationEndsOn, 'humanized'>
      ), abilities: (
        { __typename?: 'PersonalMeansOfRegistrationAbilities' }
        & Pick<IPersonalMeansOfRegistrationAbilities, 'terminatable' | 'activatable' | 'lostOrStolenReportable'>
      ), identity?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationIdentity' }
        & Pick<IPersonalMeansOfRegistrationIdentity, 'humanized'>
      )>, kind: (
        { __typename?: 'PersonalMeansOfRegistrationKind' }
        & Pick<IPersonalMeansOfRegistrationKind, 'humanized' | 'sub'>
      ), status?: Maybe<(
        { __typename?: 'PersonalMeansOfRegistrationStatus' }
        & Pick<IPersonalMeansOfRegistrationStatus, 'humanized' | 'value'>
      )> }
    )>>, permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'canCreateNewMobilityCardOrder'>
    ), mobilityCardOrders: Array<(
      { __typename?: 'MobilityCardOrder' }
      & Pick<IMobilityCardOrder, 'id'>
      & { actions: (
        { __typename?: 'MobilityCardOrderActions' }
        & Pick<IMobilityCardOrderActions, 'isEditable'>
      ), status?: Maybe<(
        { __typename?: 'MobilityCardOrderStatus' }
        & Pick<IMobilityCardOrderStatus, 'humanized' | 'value'>
      )> }
    )> }
  )> }
);

export type IMobilityEventsAggregateQueryVariables = Exact<{ [key: string]: never; }>;


export type IMobilityEventsAggregateQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEventsAggregate?: Maybe<(
      { __typename?: 'MobilityEventAggregate' }
      & Pick<IMobilityEventAggregate, 'count'>
    )>, rejectedEvents?: Maybe<(
      { __typename?: 'MobilityEventAggregate' }
      & Pick<IMobilityEventAggregate, 'count'>
    )>, draftEvents?: Maybe<(
      { __typename?: 'MobilityEventAggregate' }
      & Pick<IMobilityEventAggregate, 'count'>
    )> }
  )> }
);

export type IGetDashboardReservationDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetDashboardReservationDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { reservations?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<IReservation, 'id' | 'title' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'recordId' | 'reservationClass'>
      & { icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, reservationStatus?: Maybe<(
        { __typename?: 'ReservationStatus' }
        & Pick<IReservationStatus, 'humanized' | 'value'>
      )>, startLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      )>, endLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      )> }
    )>>, availableFeatures?: Maybe<Array<(
      { __typename?: 'Amber' }
      & Pick<IAmber, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'DonkeyRepublic' }
      & Pick<IDonkeyRepublic, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'EnecoDongle' }
      & Pick<IEnecoDongle, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'Greenwheels' }
      & Pick<IGreenwheels, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'LeaseBike' }
      & Pick<ILeaseBike, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'RentalCar' }
      & Pick<IRentalCar, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'RequestableDonkeyRepublic' }
      & Pick<IRequestableDonkeyRepublic, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'RequestableFeature' }
      & Pick<IRequestableFeature, 'requiresMotivation' | 'id' | 'motivation' | 'completedAt'>
      & { termsAndConditions?: Maybe<Array<(
        { __typename?: 'TermsAndConditions' }
        & Pick<ITermsAndConditions, 'requiresAgreement'>
        & { document: (
          { __typename?: 'TermsAndConditionsDocument' }
          & Pick<ITermsAndConditionsDocument, 'id' | 'pdfUrl'>
          & { humanizedKind: (
            { __typename?: 'TermsAndConditionsDocumentHumanizedKind' }
            & Pick<ITermsAndConditionsDocumentHumanizedKind, 'humanized'>
          ) }
        ) }
      )>>, icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'SharedVehicle' }
      & Pick<ISharedVehicle, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'ShuttelCheck' }
      & Pick<IShuttelCheck, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'ShuttelMyWheels' }
      & Pick<IShuttelMyWheels, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'ShuttelSixt' }
      & Pick<IShuttelSixt, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'ShuttelTaxi' }
      & Pick<IShuttelTaxi, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'ShuttelTranzerEticket' }
      & Pick<IShuttelTranzerEticket, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'ShuttelVecore' }
      & Pick<IShuttelVecore, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'TaxiFeature' }
      & Pick<ITaxiFeature, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'TranzerEticket' }
      & Pick<ITranzerEticket, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    ) | (
      { __typename?: 'YellowbrickSp' }
      & Pick<IYellowbrickSp, 'id' | 'motivation' | 'completedAt'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), name: (
        { __typename?: 'FeatureOption' }
        & Pick<IFeatureOption, 'humanized' | 'value'>
      ), status: (
        { __typename?: 'FeatureOrderStatus' }
        & Pick<IFeatureOrderStatus, 'humanized' | 'value'>
      ), urls?: Maybe<(
        { __typename?: 'FeatureUrls' }
        & Pick<IFeatureUrls, 'web'>
      )> }
    )>> }
  )> }
);

export type IGetDashboardDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetDashboardDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { orderMessages: Array<(
      { __typename?: 'Message' }
      & Pick<IMessage, 'id' | 'title' | 'content'>
    )>, allMessages: Array<(
      { __typename?: 'Message' }
      & Pick<IMessage, 'id' | 'title' | 'content'>
    )>, permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'canCreateNewMobilityCardOrder' | 'approvals'>
    ) }
  )> }
);

export type IMarkMessageAsReadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IMarkMessageAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markMessageAsRead?: Maybe<(
    { __typename?: 'MarkMessageAsReadPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IGetCo2EmissionDataQueryVariables = Exact<{
  dateRangeInput?: InputMaybe<IDateRangeStringInput>;
}>;


export type IGetCo2EmissionDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { co2EmissionSummary?: Maybe<(
      { __typename?: 'Co2EmissionSummary' }
      & { mobilityEventsPerModality?: Maybe<Array<(
        { __typename?: 'Co2EmissionPerModality' }
        & { emission: (
          { __typename?: 'Co2Emission' }
          & Pick<ICo2Emission, 'humanized' | 'value'>
        ), modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'humanized' | 'id' | 'kind' | 'clazz'>
        )> }
      )>>, totalEmission: (
        { __typename?: 'Co2Emission' }
        & Pick<ICo2Emission, 'humanized' | 'value'>
      ) }
    )>, distanceSummary?: Maybe<(
      { __typename?: 'DistanceSummary' }
      & Pick<IDistanceSummary, 'totalDistance'>
      & { mobilityEventsPerModality?: Maybe<Array<(
        { __typename?: 'DistancePerModality' }
        & Pick<IDistancePerModality, 'distance'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'humanized' | 'id' | 'kind' | 'clazz'>
        )> }
      )>> }
    )> }
  )> }
);

export type IUpdateMobilityEventsMutationVariables = Exact<{
  input: IUpdateMobilityEventsInput;
}>;


export type IUpdateMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { updateMobilityEvents?: Maybe<(
    { __typename?: 'UpdateMobilityEventsPayload' }
    & { days: Array<(
      { __typename?: 'DayWithMobilityEvents' }
      & Pick<IDayWithMobilityEvents, 'date' | 'eventsConfirmable' | 'eventsAddable'>
      & { mobilityEvents: Array<(
        { __typename?: 'MobilityEvent' }
        & Pick<IMobilityEvent, 'id' | 'happenedAt' | 'happenedInTheFuture' | 'allowPersonalConsumption'>
        & { status: (
          { __typename?: 'Status' }
          & Pick<IStatus, 'value' | 'humanized' | 'label'>
        ), employment?: Maybe<(
          { __typename?: 'Employment' }
          & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
        )>, actions: (
          { __typename?: 'Actions' }
          & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'mergeable'>
        ), what: (
          { __typename?: 'What' }
          & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
          & { category: (
            { __typename?: 'Category' }
            & Pick<ICategory, 'main' | 'requiresModality'>
          ), modality?: Maybe<(
            { __typename?: 'Modality' }
            & Pick<IModality, 'id' | 'clazz' | 'kind' | 'requiresVehicle'>
            & { modalityOwnership?: Maybe<(
              { __typename?: 'ModalityOwnership' }
              & Pick<IModalityOwnership, 'humanized' | 'value'>
            )> }
          )>, icon: (
            { __typename?: 'Icon' }
            & Pick<IIcon, 'name'>
          ) }
        ), how: (
          { __typename?: 'How' }
          & Pick<IHow, 'humanized'>
          & { icon?: Maybe<(
            { __typename?: 'Icon' }
            & Pick<IIcon, 'name'>
          )> }
        ), why?: Maybe<(
          { __typename?: 'Why' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized' | 'value'>
          ), project?: Maybe<(
            { __typename?: 'Project' }
            & Pick<IProject, 'id' | 'code' | 'humanized'>
          )>, explanation?: Maybe<(
            { __typename?: 'Explanation' }
            & Pick<IExplanation, 'humanized'>
            & { required: (
              { __typename?: 'ExplanationRequired' }
              & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
            ) }
          )> }
        )>, howMuch?: Maybe<(
          { __typename?: 'HowMuch' }
          & { consumption: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>, where?: Maybe<(
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
        ) | (
          { __typename?: 'RentalLocations' }
          & { pickup: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
          ), return: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
          ) }
        ) | (
          { __typename?: 'Route' }
          & { from: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
          ), to: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
          ) }
        )>, financial: (
          { __typename?: 'Financial' }
          & { consequences: (
            { __typename?: 'FinancialConsequences' }
            & { gross?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ) }
            )>, net?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ) }
            )> }
          ), fact?: Maybe<(
            { __typename?: 'FinancialFact' }
            & { receipt?: Maybe<(
              { __typename?: 'Receipt' }
              & Pick<IReceipt, 'contentType' | 'url'>
            )> }
          )> }
        ) }
      )>, permissions: (
        { __typename?: 'Permissions' }
        & Pick<IPermissions, 'manageWorkFromHome' | 'manageCommuteCompensation'>
      ) }
    )>, updatedMobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )>, result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ) }
  )> }
);

export type ICreateMobilityEventMutationVariables = Exact<{
  input: ICreateMobilityEventInput;
}>;


export type ICreateMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { createMobilityEvent?: Maybe<(
    { __typename?: 'CreateMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'messages' | 'fullMessages'>
    )>, mobilityEvents?: Maybe<Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )>> }
  )> }
);

export type IUpdateMobilityEventMutationVariables = Exact<{
  input: IUpdateMobilityEventInput;
}>;


export type IUpdateMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { updateMobilityEvent?: Maybe<(
    { __typename?: 'UpdateMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'messages' | 'fullMessages'>
    )>, mobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetMultipleEmploymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMultipleEmploymentsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'selectEmployment'>
    ), employments?: Maybe<Array<(
      { __typename?: 'Employment' }
      & Pick<IEmployment, 'commuteMonthlyMoneyBudget' | 'commuteMonthlyKmBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'startsOn' | 'main' | 'budgetGroupName'>
    )>> }
  )> }
);

export type ILoadAvailableProjectsQueryVariables = Exact<{
  on: Scalars['Date']['input'];
  input?: InputMaybe<Scalars['String']['input']>;
}>;


export type ILoadAvailableProjectsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'id'>
      & { projects?: Maybe<Array<Maybe<(
        { __typename?: 'Project' }
        & Pick<IProject, 'id' | 'code' | 'humanized'>
      )>>> }
    )> }
  )> }
);

export type IHasProjectsQueryVariables = Exact<{
  on: Scalars['Date']['input'];
}>;


export type IHasProjectsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'id' | 'hasActiveProjects'>
    )> }
  )> }
);

export type IGetWarningForOver40KmWalkOrBikeQueryVariables = Exact<{
  modalityId?: InputMaybe<Scalars['ID']['input']>;
  fromLocationId?: InputMaybe<Scalars['ID']['input']>;
  toLocationId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IGetWarningForOver40KmWalkOrBikeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEventFormValidations?: Maybe<(
      { __typename?: 'MobilityEventFormValidations' }
      & Pick<IMobilityEventFormValidations, 'showWarningForOver40KmWalkOrBike'>
    )> }
  )> }
);

export type IDistanceAndCo2QueryVariables = Exact<{
  on: Scalars['Date']['input'];
  from: ICoordinatesMutation;
  to: ICoordinatesMutation;
  modalityId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
  travelPurpose?: InputMaybe<ITravelPurposeEnum>;
}>;


export type IDistanceAndCo2Query = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { routeMetadata: (
      { __typename?: 'RouteMetadata' }
      & { calculateDistance: (
        { __typename?: 'CalculatedDistance' }
        & Pick<ICalculatedDistance, 'value'>
      ), co2Emission: (
        { __typename?: 'Co2Emission' }
        & Pick<ICo2Emission, 'humanized'>
      ) }
    ) }
  )> }
);

export type ICo2ByDistanceQueryVariables = Exact<{
  on: Scalars['Date']['input'];
  distance: Scalars['Float']['input'];
  modalityId: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ICo2ByDistanceQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { routeMetadata: (
      { __typename?: 'RouteMetadata' }
      & { co2EmissionFromDistance: (
        { __typename?: 'Co2Emission' }
        & Pick<ICo2Emission, 'humanized'>
      ) }
    ) }
  )> }
);

export type IDistanceEditableAndNonCombinedQueryVariables = Exact<{
  on: Scalars['Date']['input'];
}>;


export type IDistanceEditableAndNonCombinedQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { routeMetadata: (
      { __typename?: 'RouteMetadata' }
      & Pick<IRouteMetadata, 'distanceOverridable' | 'nonCombinedAllowed'>
    ) }
  )> }
);

export type IGetMandatoryLocationPostalCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMandatoryLocationPostalCodeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'id' | 'mandatoryLocationPostalCode'>
    )> }
  )> }
);

export type IGetRefundableIncompleteRidesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetRefundableIncompleteRidesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { refundableIncompleteRides?: Maybe<Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedAt'>
      & { what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'humanized'>
      ) }
    )>> }
  )> }
);

export type IGetDefaultTravelPurposeQueryVariables = Exact<{
  date?: InputMaybe<Scalars['Date']['input']>;
}>;


export type IGetDefaultTravelPurposeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'defaultTravelPurpose'>
  )> }
);

export type ILoadEditMobilityEventQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ILoadEditMobilityEventQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'isRefund' | 'happenedAt'>
      & { employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'sharedRidePassengers'>
        & { reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'kind' | 'humanizedWithCapital' | 'requiredPassengerCount' | 'requiresVehicle' | 'travelMode' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )> }
      ), why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'refundedMobilityEventId' | 'editable'>
        & { travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'value'>
        ), explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )> }
      )>, howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'humanized'>
        )> }
      )>, where?: Maybe<{ __typename?: 'Location' } | { __typename?: 'RentalLocations' } | (
        { __typename?: 'Route' }
        & { from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>>, diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'value'>
        )> }
      )>, financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & { inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'contentType' | 'filename' | 'url'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type ITerminateMobilityEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ITerminateMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { destroyMobilityEvent?: Maybe<(
    { __typename?: 'DestroyMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IResetMobilityEventMutationVariables = Exact<{
  mobilityEventId: Scalars['ID']['input'];
}>;


export type IResetMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { unacceptMobilityEvent?: Maybe<(
    { __typename?: 'UnacceptMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )>, mobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type INonCombinedAllowedQueryVariables = Exact<{
  on: Scalars['Date']['input'];
}>;


export type INonCombinedAllowedQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { routeMetadata: (
      { __typename?: 'RouteMetadata' }
      & Pick<IRouteMetadata, 'nonCombinedAllowed'>
    ) }
  )> }
);

export type ILoadMobilityEventQueryVariables = Exact<{
  mobilityEventId: Scalars['ID']['input'];
}>;


export type ILoadMobilityEventQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetMobilityEventFormValidationsQueryVariables = Exact<{
  date: Scalars['Date']['input'];
}>;


export type IGetMobilityEventFormValidationsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEventFormValidations?: Maybe<(
      { __typename?: 'MobilityEventFormValidations' }
      & Pick<IMobilityEventFormValidations, 'showWarningForWfhOnHoliday' | 'showWarningForWfhOnWeekend'>
    )> }
  )> }
);

export type IGetDaysQueryVariables = Exact<{
  from: Scalars['Date']['input'];
  to: Scalars['Date']['input'];
  includeHomeWorkRides: Scalars['Boolean']['input'];
  includeWorkHomeAllowance: Scalars['Boolean']['input'];
  includeDailyAllowanceWorkHome: Scalars['Boolean']['input'];
}>;


export type IGetDaysQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { days: Array<(
      { __typename?: 'DayWithMobilityEvents' }
      & MakeOptional<Pick<IDayWithMobilityEvents, 'date' | 'commuteRideAddable' | 'workFromHomeCompensationAddable' | 'commuteCompensationAddable'>, 'commuteRideAddable' | 'workFromHomeCompensationAddable' | 'commuteCompensationAddable'>
    ) | { __typename?: 'DayWithoutEmployment' }> }
  )> }
);

export type IGetMobilityEventsCalendarListQueryVariables = Exact<{
  happenedBetween?: InputMaybe<IDateRangeInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type IGetMobilityEventsCalendarListQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetMobilityEventsFilteredListQueryVariables = Exact<{
  filterByStatus?: InputMaybe<IFilterStatusEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  modalities?: InputMaybe<Array<IModalityKindEnum> | IModalityKindEnum>;
  subCategories?: InputMaybe<Array<ISubCategoryEnum> | ISubCategoryEnum>;
  travelPurposes?: InputMaybe<Array<ITravelPurposeEnum> | ITravelPurposeEnum>;
  filterByStatuses?: InputMaybe<Array<IFilterStatusEnum> | IFilterStatusEnum>;
  happenedBetween?: InputMaybe<IDateRangeInput>;
}>;


export type IGetMobilityEventsFilteredListQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IDestroyMobilityEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IDestroyMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { destroyMobilityEvent?: Maybe<(
    { __typename?: 'DestroyMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IUnacceptMobilityEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IUnacceptMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { unacceptMobilityEvent?: Maybe<(
    { __typename?: 'UnacceptMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), mobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IConfirmSingleMobilityEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IConfirmSingleMobilityEventMutation = (
  { __typename?: 'Mutation' }
  & { confirmMobilityEvent?: Maybe<(
    { __typename?: 'ConfirmMobilityEventPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), mobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetConfirmationModalPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetConfirmationModalPermissionQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'unacceptMobilityEvents'>
    ) }
  )> }
);

export type IConfirmMobilityEventsMutationVariables = Exact<{
  mobilityEventIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IConfirmMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { confirmMobilityEvents?: Maybe<(
    { __typename?: 'ConfirmMobilityEventsPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), confirmedMobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type ICreateCopiedMobilityEventsMutationVariables = Exact<{
  from: Scalars['Date']['input'];
  to: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
}>;


export type ICreateCopiedMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { copyMobilityEventsForDay?: Maybe<(
    { __typename?: 'CopyMobilityEventsForDayPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), days: Array<(
      { __typename?: 'DayWithMobilityEvents' }
      & Pick<IDayWithMobilityEvents, 'date'>
      & { mobilityEvents: Array<(
        { __typename?: 'MobilityEvent' }
        & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
        & { status: (
          { __typename?: 'Status' }
          & Pick<IStatus, 'value' | 'humanized' | 'label'>
        ), employment?: Maybe<(
          { __typename?: 'Employment' }
          & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
        )>, actions: (
          { __typename?: 'Actions' }
          & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
        ), financial: (
          { __typename?: 'Financial' }
          & { fact?: Maybe<(
            { __typename?: 'FinancialFact' }
            & Pick<IFinancialFact, 'direction' | 'label'>
            & { exclVat?: Maybe<(
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized'>
            )>, inclVat: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'value'>
            ), receipt?: Maybe<(
              { __typename?: 'Receipt' }
              & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
            )>, vatRate?: Maybe<(
              { __typename?: 'VatRate' }
              & Pick<IVatRate, 'humanized'>
            )> }
          )>, consequences: (
            { __typename?: 'FinancialConsequences' }
            & { gross?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'humanized' | 'direction'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ), entries: Array<(
                { __typename?: 'JournalEntry' }
                & Pick<IJournalEntry, 'label' | 'calculation'>
                & { moneyTransfer: (
                  { __typename?: 'MoneyTransfer' }
                  & Pick<IMoneyTransfer, 'direction' | 'humanized'>
                ), amount: (
                  { __typename?: 'Money' }
                  & Pick<IMoney, 'humanized' | 'valueInCents'>
                ) }
              )> }
            )>, net?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'humanized' | 'direction'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ), entries: Array<(
                { __typename?: 'JournalEntry' }
                & Pick<IJournalEntry, 'label' | 'calculation'>
                & { moneyTransfer: (
                  { __typename?: 'MoneyTransfer' }
                  & Pick<IMoneyTransfer, 'direction' | 'humanized'>
                ), amount: (
                  { __typename?: 'Money' }
                  & Pick<IMoney, 'humanized' | 'valueInCents'>
                ) }
              )> }
            )>, fiscal?: Maybe<(
              { __typename?: 'FinancialComponent' }
              & Pick<IFinancialComponent, 'label'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'humanized' | 'direction'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ), entries: Array<(
                { __typename?: 'JournalEntry' }
                & Pick<IJournalEntry, 'label' | 'calculation'>
                & { moneyTransfer: (
                  { __typename?: 'MoneyTransfer' }
                  & Pick<IMoneyTransfer, 'direction' | 'humanized'>
                ), amount: (
                  { __typename?: 'Money' }
                  & Pick<IMoney, 'humanized' | 'valueInCents'>
                ) }
              )> }
            )> }
          ) }
        ), howMuch?: Maybe<(
          { __typename?: 'HowMuch' }
          & { consumption: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
          ), co2Emission?: Maybe<(
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
          )> }
        )>, what: (
          { __typename?: 'What' }
          & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
          & { modality?: Maybe<(
            { __typename?: 'Modality' }
            & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
            & { modalityOwnership?: Maybe<(
              { __typename?: 'ModalityOwnership' }
              & Pick<IModalityOwnership, 'humanized' | 'value'>
            )> }
          )>, category: (
            { __typename?: 'Category' }
            & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
            & { mainCategory: (
              { __typename?: 'MainCategory' }
              & Pick<IMainCategory, 'value' | 'humanized'>
            ), subCategory?: Maybe<(
              { __typename?: 'SubCategory' }
              & Pick<ISubCategory, 'value' | 'humanized'>
            )> }
          ), vehicle?: Maybe<(
            { __typename?: 'MeansOfTransport' }
            & Pick<IMeansOfTransport, 'id' | 'humanized'>
          )>, reimbursableCategoryMethod: (
            { __typename?: 'ReimbursableCategoryMethod' }
            & Pick<IReimbursableCategoryMethod, 'value'>
          ), icon: (
            { __typename?: 'Icon' }
            & Pick<IIcon, 'name'>
          ) }
        ), where?: Maybe<(
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'label'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) | (
          { __typename?: 'RentalLocations' }
          & { pickup: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ), return: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ) }
        ) | (
          { __typename?: 'Route' }
          & { diverted?: Maybe<(
            { __typename?: 'Diverted' }
            & Pick<IDiverted, 'label' | 'humanized' | 'value'>
          )>, nonCombined?: Maybe<(
            { __typename?: 'NonCombined' }
            & Pick<INonCombined, 'label' | 'humanized' | 'value'>
          )>, from: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ), to: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
            & { coordinates?: Maybe<(
              { __typename?: 'Coordinates' }
              & Pick<ICoordinates, 'latitude' | 'longitude'>
            )> }
          ), track?: Maybe<Array<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )>> }
        )>, why?: Maybe<(
          { __typename?: 'Why' }
          & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
          & { legs?: Maybe<Array<(
            { __typename?: 'Leg' }
            & { travelPurpose: (
              { __typename?: 'TravelPurpose' }
              & Pick<ITravelPurpose, 'humanized'>
            ), quantity: (
              { __typename?: 'Quantity' }
              & Pick<IQuantity, 'amount' | 'humanized'>
            ) }
          )>>, travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized' | 'value'>
          ), project?: Maybe<(
            { __typename?: 'Project' }
            & Pick<IProject, 'id' | 'code' | 'humanized'>
          )>, explanation?: Maybe<(
            { __typename?: 'Explanation' }
            & Pick<IExplanation, 'humanized'>
            & { required: (
              { __typename?: 'ExplanationRequired' }
              & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
            ) }
          )>, stateTransitions: (
            { __typename?: 'StateTransitions' }
            & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
          ) }
        )>, when: (
          { __typename?: 'Duration' }
          & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
        ) | (
          { __typename?: 'Timestamp' }
          & Pick<ITimestamp, 'at' | 'editable'>
        ), how: (
          { __typename?: 'How' }
          & Pick<IHow, 'humanized'>
          & { icon?: Maybe<(
            { __typename?: 'Icon' }
            & Pick<IIcon, 'name'>
          )> }
        ), signatures?: Maybe<Array<(
          { __typename?: 'Signature' }
          & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
          & { signer?: Maybe<(
            { __typename?: 'Employee' }
            & { profile: (
              { __typename?: 'Profile' }
              & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
            ) }
          )> }
        )>> }
      )> }
    ) | { __typename?: 'DayWithoutEmployment' }> }
  )> }
);

export type ICreateCommuteCompensationMutationVariables = Exact<{
  input: ICreateCommuteCompensationInput;
}>;


export type ICreateCommuteCompensationMutation = (
  { __typename?: 'Mutation' }
  & { createCommuteCompensation?: Maybe<(
    { __typename?: 'CreateCommuteCompensationPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )>, mobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type ICreateCommuteRidesMutationVariables = Exact<{
  dates?: InputMaybe<Array<Scalars['Date']['input']> | Scalars['Date']['input']>;
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ICreateCommuteRidesMutation = (
  { __typename?: 'Mutation' }
  & { createCommuteRides?: Maybe<(
    { __typename?: 'CreateCommuteRidesPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )>, mobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type ICreateWorkFromHomeMutationVariables = Exact<{
  dates?: InputMaybe<Array<Scalars['Date']['input']> | Scalars['Date']['input']>;
  confirm?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ICreateWorkFromHomeMutation = (
  { __typename?: 'Mutation' }
  & { createWorkFromHome?: Maybe<(
    { __typename?: 'CreateWorkFromHomePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )>, mobilityEvents: Array<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IGetMobilityEventMenuInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMobilityEventMenuInformationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'activeSince' | 'employeeNumber'>
    ), permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'manageWorkFromHome' | 'manageCommuteCompensation' | 'manageMobilityEvents' | 'manageCommuteRides'>
    ) }
  )> }
);

export type IGetActiveSinceQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetActiveSinceQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'activeSince' | 'employeeNumber'>
    ) }
  )> }
);

export type IGetMobilityEventsAggregateQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMobilityEventsAggregateQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mobilityEventsAggregate?: Maybe<(
      { __typename?: 'MobilityEventAggregate' }
      & Pick<IMobilityEventAggregate, 'count'>
    )> }
  )> }
);

export type IGetMobilityEventsExportQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetMobilityEventsExportQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { exportFiles?: Maybe<(
      { __typename?: 'ExportFiles' }
      & Pick<IExportFiles, 'transactionsExport'>
    )> }
  )> }
);

export type IMergeMobilityEventsMutationVariables = Exact<{
  mobilityEventIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IMergeMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { mergeMobilityEvents?: Maybe<(
    { __typename?: 'MergeMobilityEventsPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), mergedMobilityEvent?: Maybe<(
      { __typename?: 'MobilityEvent' }
      & Pick<IMobilityEvent, 'id' | 'happenedInTheFuture' | 'happenedAt' | 'allowPersonalConsumption' | 'isRefund'>
      & { status: (
        { __typename?: 'Status' }
        & Pick<IStatus, 'value' | 'humanized' | 'label'>
      ), employment?: Maybe<(
        { __typename?: 'Employment' }
        & Pick<IEmployment, 'budgetGroupName' | 'commuteMonthlyKmBudget' | 'commuteMonthlyMoneyBudget' | 'costCenter' | 'department' | 'endsOn' | 'id' | 'main' | 'startsOn'>
      )>, actions: (
        { __typename?: 'Actions' }
        & Pick<IActions, 'editable' | 'deletable' | 'copyable' | 'confirmable' | 'unacceptable' | 'missingCheckout' | 'mergeable' | 'viewDetails'>
      ), financial: (
        { __typename?: 'Financial' }
        & { fact?: Maybe<(
          { __typename?: 'FinancialFact' }
          & Pick<IFinancialFact, 'direction' | 'label'>
          & { exclVat?: Maybe<(
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          )>, inclVat: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized' | 'value'>
          ), receipt?: Maybe<(
            { __typename?: 'Receipt' }
            & Pick<IReceipt, 'filename' | 'contentType' | 'url'>
          )>, vatRate?: Maybe<(
            { __typename?: 'VatRate' }
            & Pick<IVatRate, 'humanized'>
          )> }
        )>, consequences: (
          { __typename?: 'FinancialConsequences' }
          & { gross?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, net?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )>, fiscal?: Maybe<(
            { __typename?: 'FinancialComponent' }
            & Pick<IFinancialComponent, 'label'>
            & { moneyTransfer: (
              { __typename?: 'MoneyTransfer' }
              & Pick<IMoneyTransfer, 'humanized' | 'direction'>
            ), amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ), entries: Array<(
              { __typename?: 'JournalEntry' }
              & Pick<IJournalEntry, 'label' | 'calculation'>
              & { moneyTransfer: (
                { __typename?: 'MoneyTransfer' }
                & Pick<IMoneyTransfer, 'direction' | 'humanized'>
              ), amount: (
                { __typename?: 'Money' }
                & Pick<IMoney, 'humanized' | 'valueInCents'>
              ) }
            )> }
          )> }
        ) }
      ), howMuch?: Maybe<(
        { __typename?: 'HowMuch' }
        & { consumption: (
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        ), co2Emission?: Maybe<(
          { __typename?: 'Quantity' }
          & Pick<IQuantity, 'label' | 'humanized' | 'amount'>
        )> }
      )>, what: (
        { __typename?: 'What' }
        & Pick<IWhat, 'editable' | 'humanized' | 'sharedRidePassengers'>
        & { modality?: Maybe<(
          { __typename?: 'Modality' }
          & Pick<IModality, 'id' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'kind' | 'requiredPassengerCount' | 'clazz'>
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'humanized' | 'value'>
          )> }
        )>, category: (
          { __typename?: 'Category' }
          & Pick<ICategory, 'id' | 'main' | 'requiresModality'>
          & { mainCategory: (
            { __typename?: 'MainCategory' }
            & Pick<IMainCategory, 'value' | 'humanized'>
          ), subCategory?: Maybe<(
            { __typename?: 'SubCategory' }
            & Pick<ISubCategory, 'value' | 'humanized'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'MeansOfTransport' }
          & Pick<IMeansOfTransport, 'id' | 'humanized'>
        )>, reimbursableCategoryMethod: (
          { __typename?: 'ReimbursableCategoryMethod' }
          & Pick<IReimbursableCategoryMethod, 'value'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      ), where?: Maybe<(
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized' | 'label'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        )> }
      ) | (
        { __typename?: 'RentalLocations' }
        & { pickup: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), return: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ) }
      ) | (
        { __typename?: 'Route' }
        & { diverted?: Maybe<(
          { __typename?: 'Diverted' }
          & Pick<IDiverted, 'label' | 'humanized' | 'value'>
        )>, nonCombined?: Maybe<(
          { __typename?: 'NonCombined' }
          & Pick<INonCombined, 'label' | 'humanized' | 'value'>
        )>, from: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), to: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized' | 'favoriteId' | 'locationId'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), track?: Maybe<Array<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )>> }
      )>, why?: Maybe<(
        { __typename?: 'Why' }
        & Pick<IWhy, 'editable' | 'personalDistance' | 'refundedMobilityEventId' | 'humanizedRefundedMobilityEvent'>
        & { legs?: Maybe<Array<(
          { __typename?: 'Leg' }
          & { travelPurpose: (
            { __typename?: 'TravelPurpose' }
            & Pick<ITravelPurpose, 'humanized'>
          ), quantity: (
            { __typename?: 'Quantity' }
            & Pick<IQuantity, 'amount' | 'humanized'>
          ) }
        )>>, travelPurpose: (
          { __typename?: 'TravelPurpose' }
          & Pick<ITravelPurpose, 'humanized' | 'value'>
        ), project?: Maybe<(
          { __typename?: 'Project' }
          & Pick<IProject, 'id' | 'code' | 'humanized'>
        )>, explanation?: Maybe<(
          { __typename?: 'Explanation' }
          & Pick<IExplanation, 'humanized'>
          & { required: (
            { __typename?: 'ExplanationRequired' }
            & Pick<IExplanationRequired, 'business' | 'commute' | 'personal'>
          ) }
        )>, stateTransitions: (
          { __typename?: 'StateTransitions' }
          & Pick<IStateTransitions, 'rejectedAt' | 'rejectReason' | 'confirmer'>
        ) }
      )>, when: (
        { __typename?: 'Duration' }
        & Pick<IDuration, 'startsAt' | 'endsAt' | 'editable'>
      ) | (
        { __typename?: 'Timestamp' }
        & Pick<ITimestamp, 'at' | 'editable'>
      ), how: (
        { __typename?: 'How' }
        & Pick<IHow, 'humanized'>
        & { icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )> }
      ), signatures?: Maybe<Array<(
        { __typename?: 'Signature' }
        & Pick<ISignature, 'createdAt' | 'id' | 'reason'>
        & { signer?: Maybe<(
          { __typename?: 'Employee' }
          & { profile: (
            { __typename?: 'Profile' }
            & Pick<IProfile, 'email' | 'employeeNumber' | 'firstName' | 'lastName'>
          ) }
        )> }
      )>> }
    )> }
  )> }
);

export type IMergedMobilityEventsPreviewQueryVariables = Exact<{
  mobilityEventIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IMergedMobilityEventsPreviewQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { mergedMobilityEventsPreview: (
      { __typename?: 'MergedMobilityEventsPreview' }
      & { mobilityEventPreview?: Maybe<(
        { __typename?: 'MobilityEventPreview' }
        & Pick<IMobilityEventPreview, 'id' | 'happenedAt'>
        & { when: (
          { __typename?: 'Duration' }
          & Pick<IDuration, 'startsAt' | 'endsAt'>
        ) | (
          { __typename?: 'Timestamp' }
          & Pick<ITimestamp, 'at'>
        ), where?: Maybe<{ __typename?: 'Location' } | { __typename?: 'RentalLocations' } | (
          { __typename?: 'Route' }
          & { from: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
          ), to: (
            { __typename?: 'Location' }
            & Pick<ILocation, 'humanized'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type IDeleteMobilityEventsMutationVariables = Exact<{
  mobilityEventIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IDeleteMobilityEventsMutation = (
  { __typename?: 'Mutation' }
  & { destroyMobilityEvents?: Maybe<(
    { __typename?: 'DestroyMobilityEventsPayload' }
    & Pick<IDestroyMobilityEventsPayload, 'destroyedMobilityEventIds'>
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IGetFilterOptionsMobilityEventsModalityQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFilterOptionsMobilityEventsModalityQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { filterOptions?: Maybe<(
      { __typename?: 'FilterOptions' }
      & { mobilityEventsModality?: Maybe<Array<(
        { __typename?: 'FilterOptionItem' }
        & Pick<IFilterOptionItem, 'humanized' | 'value'>
      )>> }
    )> }
  )> }
);

export type IGetManageMobilityEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetManageMobilityEventsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & Pick<IPermissions, 'manageMobilityEvents' | 'manageCommuteCompensation' | 'manageCommuteRides' | 'manageWorkFromHome'>
    ) }
  )> }
);

export type ICreateFavoriteMutationVariables = Exact<{
  input: ICreateFavoriteInput;
}>;


export type ICreateFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { favorites?: Maybe<(
    { __typename?: 'Favorites' }
    & { create?: Maybe<(
      { __typename?: 'CreateFavoritePayload' }
      & { favorite?: Maybe<(
        { __typename?: 'Favorite' }
        & Pick<IFavorite, 'id' | 'label' | 'location' | 'locationId' | 'type'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ), organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<IOrganization, 'id' | 'name'>
        )>, actions: (
          { __typename?: 'FavoriteActions' }
          & Pick<IFavoriteActions, 'deletable' | 'editable'>
        ) }
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )> }
    )> }
  )> }
);

export type IDestroyFavoriteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IDestroyFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { favorites?: Maybe<(
    { __typename?: 'Favorites' }
    & { destroy?: Maybe<(
      { __typename?: 'DestroyFavoritePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ) }
    )> }
  )> }
);

export type IUpdateFavoriteMutationVariables = Exact<{
  input: IUpdateFavoriteInput;
}>;


export type IUpdateFavoriteMutation = (
  { __typename?: 'Mutation' }
  & { favorites?: Maybe<(
    { __typename?: 'Favorites' }
    & { update?: Maybe<(
      { __typename?: 'UpdateFavoritePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )>, favorite?: Maybe<(
        { __typename?: 'Favorite' }
        & Pick<IFavorite, 'id' | 'label' | 'location' | 'locationId' | 'type'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ), organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<IOrganization, 'id' | 'name'>
        )>, actions: (
          { __typename?: 'FavoriteActions' }
          & Pick<IFavoriteActions, 'deletable' | 'editable'>
        ) }
      )> }
    )> }
  )> }
);

export type IGetFavoritesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFavoritesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { favorites?: Maybe<Array<(
      { __typename?: 'Favorite' }
      & Pick<IFavorite, 'id' | 'label' | 'location' | 'locationId' | 'type'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<ICoordinates, 'latitude' | 'longitude'>
      ), organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<IOrganization, 'id' | 'name'>
      )>, actions: (
        { __typename?: 'FavoriteActions' }
        & Pick<IFavoriteActions, 'deletable' | 'editable'>
      ) }
    )>> }
  )> }
);

export type IGetFinancialTentativeSettlementsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFinancialTentativeSettlementsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { tentativeSettlement: (
        { __typename?: 'Settlement' }
        & { totalGrossAndNetCharge: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'valueInCents' | 'label'>
        ), totalGrossAndNetReimbursement: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'valueInCents' | 'label'>
        ), travelPurposes: Array<(
          { __typename?: 'TravelPurposeFinancialConsequences' }
          & { totalGrossAndNetCharge: (
            { __typename?: 'Budget' }
            & Pick<IBudget, 'valueInCents'>
          ), travelPurpose: (
            { __typename?: 'TravelPurposeWithUnknown' }
            & Pick<ITravelPurposeWithUnknown, 'humanized' | 'value'>
          ), totalGrossAndNetReimbursement: (
            { __typename?: 'Budget' }
            & Pick<IBudget, 'valueInCents'>
          ) }
        )> }
      ) }
    ) }
  )> }
);

export type IGetRemainingBudgetQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetRemainingBudgetQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { budgetTransactionRemainder: (
        { __typename?: 'Budget' }
        & Pick<IBudget, 'valueInCents'>
      ) }
    ) }
  )> }
);

export type IUpdatePasswordSendInformationMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type IUpdatePasswordSendInformationMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordResetMail?: Maybe<(
    { __typename?: 'SendPasswordResetMailPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IGetAuthorizedHelpDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetAuthorizedHelpDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'policyDocumentUrl'>
      & { customerService: (
        { __typename?: 'CustomerService' }
        & Pick<ICustomerService, 'emailAddress' | 'helpUrlName' | 'helpUrl' | 'telephone' | 'privacyPolicyUrl'>
      ) }
    )> }
  )>, externalUrls: (
    { __typename?: 'ExternalUrls' }
    & { nsRefundUrl: (
      { __typename?: 'ExternalUrl' }
      & Pick<IExternalUrl, 'title' | 'url'>
    ) }
  ) }
);

export type IGetUnauthorizedHelpDataQueryVariables = Exact<{
  hostname: Scalars['String']['input'];
}>;


export type IGetUnauthorizedHelpDataQuery = (
  { __typename?: 'Query' }
  & { customerService?: Maybe<(
    { __typename?: 'CustomerService' }
    & Pick<ICustomerService, 'telephone' | 'emailAddress' | 'helpUrlName' | 'privacyPolicyUrl'>
  )>, externalUrls: (
    { __typename?: 'ExternalUrls' }
    & { nsRefundUrl: (
      { __typename?: 'ExternalUrl' }
      & Pick<IExternalUrl, 'title' | 'url'>
    ) }
  ) }
);

export type ICreateMileageMutationVariables = Exact<{
  licensePlate: Scalars['String']['input'];
  amountInKilometers: Scalars['Int']['input'];
  recordedAt: Scalars['DateTime']['input'];
}>;


export type ICreateMileageMutation = (
  { __typename?: 'Mutation' }
  & { mileages?: Maybe<(
    { __typename?: 'Mileages' }
    & { create?: Maybe<(
      { __typename?: 'CreateMileagePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )>, mileage?: Maybe<(
        { __typename?: 'Mileage' }
        & Pick<IMileage, 'id' | 'licensePlate' | 'recordedAt' | 'remainingKilometers' | 'supportsUnaccountedDistances' | 'hasUnaccountedDistance' | 'budgetCostPerKilometer' | 'budgetCharge' | 'amountInKilometers' | 'mobilityEventId'>
      )> }
    )> }
  )> }
);

export type IUpdateMileageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  amountInKilometers?: InputMaybe<Scalars['Int']['input']>;
  recordedAt?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type IUpdateMileageMutation = (
  { __typename?: 'Mutation' }
  & { mileages?: Maybe<(
    { __typename?: 'Mileages' }
    & { update?: Maybe<(
      { __typename?: 'UpdateMileagePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )>, mileage?: Maybe<(
        { __typename?: 'Mileage' }
        & Pick<IMileage, 'id' | 'licensePlate' | 'recordedAt' | 'remainingKilometers' | 'supportsUnaccountedDistances' | 'hasUnaccountedDistance' | 'budgetCostPerKilometer' | 'budgetCharge' | 'amountInKilometers' | 'mobilityEventId'>
      )> }
    )> }
  )> }
);

export type ITerminatePersonalVehicleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  endsOn?: InputMaybe<Scalars['Date']['input']>;
}>;


export type ITerminatePersonalVehicleMutation = (
  { __typename?: 'Mutation' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & { terminate?: Maybe<(
      { __typename?: 'TerminateVehiclePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )>, vehicle: (
        { __typename?: 'MeansOfTransport' }
        & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
        & { vehicleInformation?: Maybe<(
          { __typename?: 'VehicleInformation' }
          & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
          & { fuelType?: Maybe<(
            { __typename?: 'Fuel' }
            & Pick<IFuel, 'humanized' | 'value'>
          )> }
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, actions: (
          { __typename?: 'VehicleActions' }
          & Pick<IVehicleActions, 'editable' | 'terminatable'>
        ), modality: (
          { __typename?: 'Modality' }
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'value'>
          )> }
        ) }
      ) }
    )> }
  )> }
);

export type IGetLicensePlateQueryVariables = Exact<{
  licensePlate: Scalars['String']['input'];
}>;


export type IGetLicensePlateQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { licensePlate?: Maybe<(
      { __typename?: 'LicensePlate' }
      & Pick<ILicensePlate, 'budgetChargeTotal' | 'licensePlate' | 'remainingKilometersTotal'>
      & { mileages?: Maybe<Array<(
        { __typename?: 'Mileage' }
        & Pick<IMileage, 'id' | 'licensePlate' | 'recordedAt' | 'remainingKilometers' | 'supportsUnaccountedDistances' | 'hasUnaccountedDistance' | 'budgetCostPerKilometer' | 'budgetCharge' | 'amountInKilometers' | 'mobilityEventId'>
      )>> }
    )> }
  )> }
);

export type IGetPersonalVehicleQueryVariables = Exact<{
  personalVehicleId: Scalars['ID']['input'];
}>;


export type IGetPersonalVehicleQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { personalVehicle?: Maybe<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
      & { vehicleInformation?: Maybe<(
        { __typename?: 'VehicleInformation' }
        & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
        & { fuelType?: Maybe<(
          { __typename?: 'Fuel' }
          & Pick<IFuel, 'humanized' | 'value'>
        )> }
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, actions: (
        { __typename?: 'VehicleActions' }
        & Pick<IVehicleActions, 'editable' | 'terminatable'>
      ), modality: (
        { __typename?: 'Modality' }
        & { modalityOwnership?: Maybe<(
          { __typename?: 'ModalityOwnership' }
          & Pick<IModalityOwnership, 'value'>
        )> }
      ) }
    )> }
  )> }
);

export type ICreatePersonalVehicleMutationVariables = Exact<{
  input: ICreateVehicleInput;
}>;


export type ICreatePersonalVehicleMutation = (
  { __typename?: 'Mutation' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & { create?: Maybe<(
      { __typename?: 'CreateVehiclePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )>, vehicle?: Maybe<(
        { __typename?: 'MeansOfTransport' }
        & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
        & { vehicleInformation?: Maybe<(
          { __typename?: 'VehicleInformation' }
          & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
          & { fuelType?: Maybe<(
            { __typename?: 'Fuel' }
            & Pick<IFuel, 'humanized' | 'value'>
          )> }
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, actions: (
          { __typename?: 'VehicleActions' }
          & Pick<IVehicleActions, 'editable' | 'terminatable'>
        ), modality: (
          { __typename?: 'Modality' }
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'value'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type IGetFuelTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFuelTypesQuery = (
  { __typename?: 'Query' }
  & { fuelTypes?: Maybe<Array<(
    { __typename?: 'Fuel' }
    & Pick<IFuel, 'value' | 'humanized'>
  )>> }
);

export type IGetPersonalVehicleFormPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetPersonalVehicleFormPermissionsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { permissions: (
      { __typename?: 'Permissions' }
      & { addableVehicles: Array<(
        { __typename?: 'AddableVehiclePermission' }
        & { name: (
          { __typename?: 'VehicleModalityKind' }
          & Pick<IVehicleModalityKind, 'value' | 'humanized'>
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ), allowedFuelTypes: Array<(
          { __typename?: 'Fuel' }
          & Pick<IFuel, 'value' | 'humanized'>
        )> }
      )> }
    ) }
  )> }
);

export type IGetVehicleInformationQueryVariables = Exact<{
  licensePlate: Scalars['String']['input'];
}>;


export type IGetVehicleInformationQuery = (
  { __typename?: 'Query' }
  & { vehicleInformation?: Maybe<(
    { __typename?: 'VehicleInformation' }
    & Pick<IVehicleInformation, 'brand' | 'co2EmissionPerKilometer' | 'energyLabel' | 'model'>
    & { fuelType?: Maybe<(
      { __typename?: 'Fuel' }
      & Pick<IFuel, 'humanized' | 'value'>
    )> }
  )> }
);

export type IUpdatePersonalVehicleMutationVariables = Exact<{
  input: IUpdateVehicleInput;
}>;


export type IUpdatePersonalVehicleMutation = (
  { __typename?: 'Mutation' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & { update?: Maybe<(
      { __typename?: 'UpdateVehiclePayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'error' | 'success'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
      )>, vehicle?: Maybe<(
        { __typename?: 'MeansOfTransport' }
        & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
        & { vehicleInformation?: Maybe<(
          { __typename?: 'VehicleInformation' }
          & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
          & { fuelType?: Maybe<(
            { __typename?: 'Fuel' }
            & Pick<IFuel, 'humanized' | 'value'>
          )> }
        )>, icon?: Maybe<(
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        )>, actions: (
          { __typename?: 'VehicleActions' }
          & Pick<IVehicleActions, 'editable' | 'terminatable'>
        ), modality: (
          { __typename?: 'Modality' }
          & { modalityOwnership?: Maybe<(
            { __typename?: 'ModalityOwnership' }
            & Pick<IModalityOwnership, 'value'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type IGetRidesExportQueryVariables = Exact<{
  year?: InputMaybe<Scalars['Int']['input']>;
}>;


export type IGetRidesExportQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { exportFiles?: Maybe<(
      { __typename?: 'ExportFiles' }
      & Pick<IExportFiles, 'ridesExport'>
    )> }
  )> }
);

export type IGetModalitiesDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetModalitiesDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'mileagesYears'>
    & { current: Array<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
      & { vehicleInformation?: Maybe<(
        { __typename?: 'VehicleInformation' }
        & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
        & { fuelType?: Maybe<(
          { __typename?: 'Fuel' }
          & Pick<IFuel, 'humanized' | 'value'>
        )> }
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, actions: (
        { __typename?: 'VehicleActions' }
        & Pick<IVehicleActions, 'editable' | 'terminatable'>
      ), modality: (
        { __typename?: 'Modality' }
        & { modalityOwnership?: Maybe<(
          { __typename?: 'ModalityOwnership' }
          & Pick<IModalityOwnership, 'value'>
        )> }
      ) }
    )>, archived: Array<(
      { __typename?: 'MeansOfTransport' }
      & Pick<IMeansOfTransport, 'id' | 'humanized' | 'name' | 'startsOn' | 'endsOn' | 'licensePlate'>
      & { vehicleInformation?: Maybe<(
        { __typename?: 'VehicleInformation' }
        & Pick<IVehicleInformation, 'brand' | 'model' | 'co2EmissionPerKilometer' | 'energyLabel'>
        & { fuelType?: Maybe<(
          { __typename?: 'Fuel' }
          & Pick<IFuel, 'humanized' | 'value'>
        )> }
      )>, icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, actions: (
        { __typename?: 'VehicleActions' }
        & Pick<IVehicleActions, 'editable' | 'terminatable'>
      ), modality: (
        { __typename?: 'Modality' }
        & { modalityOwnership?: Maybe<(
          { __typename?: 'ModalityOwnership' }
          & Pick<IModalityOwnership, 'value'>
        )> }
      ) }
    )>, licensePlates?: Maybe<Array<(
      { __typename?: 'LicensePlate' }
      & Pick<ILicensePlate, 'budgetChargeTotal' | 'licensePlate' | 'remainingKilometersTotal'>
      & { mileages?: Maybe<Array<(
        { __typename?: 'Mileage' }
        & Pick<IMileage, 'id' | 'licensePlate' | 'recordedAt' | 'remainingKilometers' | 'supportsUnaccountedDistances' | 'hasUnaccountedDistance' | 'budgetCostPerKilometer' | 'budgetCharge' | 'amountInKilometers' | 'mobilityEventId'>
      )>> }
    )>>, permissions: (
      { __typename?: 'Permissions' }
      & { addableVehicles: Array<(
        { __typename?: 'AddableVehiclePermission' }
        & { icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ), name: (
          { __typename?: 'VehicleModalityKind' }
          & Pick<IVehicleModalityKind, 'value' | 'humanized'>
        ) }
      )> }
    ) }
  )> }
);

export type ISendPrivacyRequestMailMutationVariables = Exact<{
  input: ISendPrivacyRequestMailInput;
}>;


export type ISendPrivacyRequestMailMutation = (
  { __typename?: 'Mutation' }
  & { sendPrivacyRequestMail?: Maybe<(
    { __typename?: 'SendPrivacyRequestMailPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IGetPrivacyOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetPrivacyOptionsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'employeeNumber'>
      & { privacyTypes?: Maybe<Array<(
        { __typename?: 'Privacy' }
        & Pick<IPrivacy, 'description' | 'humanized' | 'value'>
        & { reasons?: Maybe<Array<(
          { __typename?: 'PrivacyRequestReason' }
          & Pick<IPrivacyRequestReason, 'description' | 'title' | 'reason'>
        )>> }
      )>> }
    ) }
  )> }
);

export type IDestroyProfileAddressMutationVariables = Exact<{
  addressType: IDestroyProfileAddressEnum;
}>;


export type IDestroyProfileAddressMutation = (
  { __typename?: 'Mutation' }
  & { destroyProfileAddress?: Maybe<(
    { __typename?: 'DestroyProfileAddressPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ) }
  )> }
);

export type IGetQrCodeForLoginQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetQrCodeForLoginQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'qrCodeForApiToken'>
  )> }
);

export type IGetGpsInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetGpsInformationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'preferredTravelPurpose' | 'employeeNumber'>
    ) }
  )> }
);

export type IUpdateGpsInformationMutationVariables = Exact<{
  preferredTravelPurpose?: InputMaybe<ITravelPurposeEnum>;
}>;


export type IUpdateGpsInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile?: Maybe<(
    { __typename?: 'UpdateUserProfilePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )> }
  )> }
);

export type IResetPasswordMutationVariables = Exact<{
  input: IResetPasswordInput;
}>;


export type IResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & { me?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<IEmployee, 'id'>
    )>, validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'messages' | 'fullMessages'>
    )> }
  )> }
);

export type IGetTranzerQuotesQueryVariables = Exact<{
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  startsAt: Scalars['DateTime']['input'];
  classNumber?: InputMaybe<ITrainClassEnum>;
}>;


export type IGetTranzerQuotesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { tranzerQuotes?: Maybe<Array<(
      { __typename?: 'Quote' }
      & Pick<IQuote, 'id' | 'validUntil'>
      & { legs: Array<(
        { __typename?: 'TranzerLeg' }
        & Pick<ITranzerLeg, 'agency' | 'agencyLogo' | 'co2Emission' | 'description' | 'endTime' | 'id' | 'polyline' | 'service' | 'startTime'>
        & { modality: (
          { __typename?: 'TravelMode' }
          & Pick<ITravelMode, 'humanized' | 'value'>
        ), fromLocation: (
          { __typename?: 'GeoLocation' }
          & Pick<IGeoLocation, 'humanized'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          ) }
        ), toLocation: (
          { __typename?: 'GeoLocation' }
          & Pick<IGeoLocation, 'humanized'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          ) }
        ), tickets: Array<(
          { __typename?: 'Ticket' }
          & Pick<ITicket, 'ticketName' | 'conditionsOneliner'>
          & { price: (
            { __typename?: 'Price' }
            & { amount: (
              { __typename?: 'Money' }
              & Pick<IMoney, 'humanized' | 'valueInCents'>
            ) }
          ) }
        )> }
      )>, status: (
        { __typename?: 'QuoteStatus' }
        & Pick<IQuoteStatus, 'humanized' | 'value'>
      ) }
    )>> }
  )> }
);

export type IGetAllSettlementsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetAllSettlementsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { settlements?: Maybe<Array<(
        { __typename?: 'Settlement' }
        & Pick<ISettlement, 'id' | 'happenedAt'>
        & { type?: Maybe<(
          { __typename?: 'SettlementTypeFinancialConsequences' }
          & Pick<ISettlementTypeFinancialConsequences, 'enumValue'>
        )> }
      )>> }
    ) }
  )> }
);

export type IGetSettlementQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetSettlementQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { settlement?: Maybe<(
        { __typename?: 'Settlement' }
        & Pick<ISettlement, 'id'>
        & { balance?: Maybe<(
          { __typename?: 'Balance' }
          & Pick<IBalance, 'totalFiscalAmount' | 'totalGrossAmount' | 'totalNetAmount'>
        )>, grossAmount: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'label' | 'valueInCents'>
        ), netAmount: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'label' | 'valueInCents'>
        ), financialSummary?: Maybe<Array<(
          { __typename?: 'FinancialSummary' }
          & Pick<IFinancialSummary, 'fiscal' | 'gross' | 'net'>
          & { mobilityEvent?: Maybe<(
            { __typename?: 'MobilityEvent' }
            & Pick<IMobilityEvent, 'id' | 'happenedAt'>
            & { what: (
              { __typename?: 'What' }
              & Pick<IWhat, 'humanized'>
              & { icon: (
                { __typename?: 'Icon' }
                & Pick<IIcon, 'name'>
              ) }
            ) }
          )> }
        )>>, previousBalance?: Maybe<(
          { __typename?: 'Balance' }
          & Pick<IBalance, 'totalFiscalAmount' | 'totalGrossAmount' | 'totalNetAmount'>
        )> }
      )> }
    ) }
  )> }
);

export type IGetTentativeSettlementQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetTentativeSettlementQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { tentativeSettlement: (
        { __typename?: 'Settlement' }
        & { balance?: Maybe<(
          { __typename?: 'Balance' }
          & Pick<IBalance, 'totalFiscalAmount' | 'totalGrossAmount' | 'totalNetAmount'>
        )>, grossAmount: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'label' | 'valueInCents'>
        ), netAmount: (
          { __typename?: 'Budget' }
          & Pick<IBudget, 'label' | 'valueInCents'>
        ), financialSummary?: Maybe<Array<(
          { __typename?: 'FinancialSummary' }
          & Pick<IFinancialSummary, 'fiscal' | 'gross' | 'net'>
          & { mobilityEvent?: Maybe<(
            { __typename?: 'MobilityEvent' }
            & Pick<IMobilityEvent, 'id' | 'happenedAt'>
            & { what: (
              { __typename?: 'What' }
              & Pick<IWhat, 'humanized'>
              & { icon: (
                { __typename?: 'Icon' }
                & Pick<IIcon, 'name'>
              ) }
            ) }
          )> }
        )>>, previousBalance?: Maybe<(
          { __typename?: 'Balance' }
          & Pick<IBalance, 'totalFiscalAmount' | 'totalGrossAmount' | 'totalNetAmount'>
        )> }
      ) }
    ) }
  )> }
);

export type IGetSettlementsDataQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetSettlementsDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { financialConsequences: (
      { __typename?: 'EmployeeFinancialConsequences' }
      & { budgetTransactionRemainder: (
        { __typename?: 'Budget' }
        & { amount: (
          { __typename?: 'MoneyTransfer' }
          & Pick<IMoneyTransfer, 'humanized'>
        ) }
      ) }
    ) }
  )> }
);

export type IGetReplacementManagersQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetReplacementManagersQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { replacementManagers?: Maybe<Array<(
      { __typename?: 'ReplacementManager' }
      & Pick<IReplacementManager, 'fullName' | 'approvalPeriodStart' | 'approvalPeriodEnd' | 'id' | 'managerId'>
      & { label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<ILabel, 'humanized' | 'value'>
      )> }
    )>> }
  )> }
);

export type ISearchAvailableSharedVehiclesQueryVariables = Exact<{
  startsAt: Scalars['DateTime']['input'];
  endsAt: Scalars['DateTime']['input'];
}>;


export type ISearchAvailableSharedVehiclesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { availableSharedVehicles?: Maybe<Array<(
      { __typename?: 'AvailableSharedVehicle' }
      & Pick<IAvailableSharedVehicle, 'id' | 'startsAt' | 'endsAt' | 'vehicleName'>
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), fromLocation: (
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      ) }
    )>> }
  )> }
);

export type ICreateTaxiReservationMutationVariables = Exact<{
  input: ICreateTaxiReservationInput;
}>;


export type ICreateTaxiReservationMutation = (
  { __typename?: 'Mutation' }
  & { taxi?: Maybe<(
    { __typename?: 'Taxi' }
    & { create?: Maybe<(
      { __typename?: 'CreateTaxiReservationPayload' }
      & { taxiReservation?: Maybe<(
        { __typename?: 'TaxiReservation' }
        & Pick<ITaxiReservation, 'id' | 'contactPhoneNumber' | 'vehicleType' | 'passengerCount' | 'departAt' | 'cancellable' | 'cancelledAt' | 'remarks'>
        & { price?: Maybe<(
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized'>
        )>, departure: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )> }
        ), destination: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )> }
        ), status?: Maybe<(
          { __typename?: 'TaxiStatus' }
          & Pick<ITaxiStatus, 'humanized' | 'value'>
        )>, vehicle?: Maybe<(
          { __typename?: 'TaxiReservationVehicle' }
          & Pick<ITaxiReservationVehicle, 'brand' | 'licensePlate' | 'type'>
        )> }
      )>, result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ), validationErrors: Array<(
        { __typename?: 'ValidationError' }
        & Pick<IValidationError, 'attribute' | 'messages' | 'fullMessages'>
      )> }
    )> }
  )> }
);

export type IGetPhoneNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetPhoneNumberQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'employeeNumber' | 'phoneNumber'>
    ) }
  )> }
);

export type IGetDonkeyRepublicReservationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetDonkeyRepublicReservationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { donkeyRepublicReservation?: Maybe<(
      { __typename?: 'DonkeyRepublicReservation' }
      & Pick<IDonkeyRepublicReservation, 'id' | 'assetName'>
      & { status: (
        { __typename?: 'DonkeyRepublicStatus' }
        & Pick<IDonkeyRepublicStatus, 'value'>
      ), legs: Array<(
        { __typename?: 'DonkeyLeg' }
        & { from: (
          { __typename?: 'AssetStation' }
          & Pick<IAssetStation, 'id' | 'name'>
          & { geoLocation?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          )> }
        ), mode?: Maybe<(
          { __typename?: 'DonkeyRepublicModality' }
          & Pick<IDonkeyRepublicModality, 'humanized' | 'value'>
        )>, price: Array<(
          { __typename?: 'SegmentPrice' }
          & Pick<ISegmentPrice, 'fromMinutes' | 'tillMinutes' | 'duration'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<IMoney, 'humanized'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type ICancelSharedVehicleReservationMutationVariables = Exact<{
  input: ICancelSharedVehicleReservationInput;
}>;


export type ICancelSharedVehicleReservationMutation = (
  { __typename?: 'Mutation' }
  & { cancelSharedVehicleReservation?: Maybe<(
    { __typename?: 'CancelSharedVehicleReservationPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), sharedVehicleReservation: (
      { __typename?: 'SharedVehicleReservation' }
      & Pick<ISharedVehicleReservation, 'id' | 'reservationId' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'vehicleName' | 'vehicleBrand' | 'vehicleModel' | 'vehicleLicensePlate'>
      & { fromLocation: (
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      ), status: (
        { __typename?: 'SharedCarReservationStatus' }
        & Pick<ISharedCarReservationStatus, 'value'>
      ), icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ) }
    ) }
  )> }
);

export type IGetSharedVehicleReservationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetSharedVehicleReservationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { sharedVehicleReservation?: Maybe<(
      { __typename?: 'SharedVehicleReservation' }
      & Pick<ISharedVehicleReservation, 'id' | 'reservationId' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'vehicleName' | 'vehicleBrand' | 'vehicleModel' | 'vehicleLicensePlate'>
      & { fromLocation: (
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      ), status: (
        { __typename?: 'SharedCarReservationStatus' }
        & Pick<ISharedCarReservationStatus, 'value'>
      ), icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ) }
    )> }
  )> }
);

export type IConfirmSharedVehicleReservationMutationVariables = Exact<{
  input: IConfirmSharedVehicleReservationInput;
}>;


export type IConfirmSharedVehicleReservationMutation = (
  { __typename?: 'Mutation' }
  & { confirmSharedVehicleReservation?: Maybe<(
    { __typename?: 'ConfirmSharedVehicleReservationPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), sharedVehicleReservation?: Maybe<(
      { __typename?: 'SharedVehicleReservation' }
      & Pick<ISharedVehicleReservation, 'id' | 'reservationId' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'vehicleName' | 'vehicleBrand' | 'vehicleModel' | 'vehicleLicensePlate'>
      & { fromLocation: (
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      ), status: (
        { __typename?: 'SharedCarReservationStatus' }
        & Pick<ISharedCarReservationStatus, 'value'>
      ), icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ) }
    )> }
  )> }
);

export type IConfirmTaxiReservationMutationVariables = Exact<{
  input: IConfirmTaxiReservationInput;
}>;


export type IConfirmTaxiReservationMutation = (
  { __typename?: 'Mutation' }
  & { taxi?: Maybe<(
    { __typename?: 'Taxi' }
    & { confirm?: Maybe<(
      { __typename?: 'ConfirmTaxiReservationPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ), taxiReservation: (
        { __typename?: 'TaxiReservation' }
        & Pick<ITaxiReservation, 'id' | 'contactPhoneNumber' | 'vehicleType' | 'passengerCount' | 'departAt' | 'cancellable' | 'cancelledAt' | 'remarks'>
        & { price?: Maybe<(
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized'>
        )>, departure: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )> }
        ), destination: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )> }
        ), status?: Maybe<(
          { __typename?: 'TaxiStatus' }
          & Pick<ITaxiStatus, 'humanized' | 'value'>
        )>, vehicle?: Maybe<(
          { __typename?: 'TaxiReservationVehicle' }
          & Pick<ITaxiReservationVehicle, 'brand' | 'licensePlate' | 'type'>
        )> }
      ) }
    )> }
  )> }
);

export type ICancelTaxiReservationMutationVariables = Exact<{
  input: ICancelTaxiReservationInput;
}>;


export type ICancelTaxiReservationMutation = (
  { __typename?: 'Mutation' }
  & { taxi?: Maybe<(
    { __typename?: 'Taxi' }
    & { cancel?: Maybe<(
      { __typename?: 'CancelTaxiReservationPayload' }
      & { result: (
        { __typename?: 'Result' }
        & Pick<IResult, 'success' | 'error'>
      ), taxiReservation: (
        { __typename?: 'TaxiReservation' }
        & Pick<ITaxiReservation, 'id' | 'contactPhoneNumber' | 'vehicleType' | 'passengerCount' | 'departAt' | 'cancellable' | 'cancelledAt' | 'remarks'>
        & { price?: Maybe<(
          { __typename?: 'Money' }
          & Pick<IMoney, 'humanized'>
        )>, departure: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )> }
        ), destination: (
          { __typename?: 'Location' }
          & Pick<ILocation, 'humanized'>
          & { coordinates?: Maybe<(
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'longitude' | 'latitude'>
          )> }
        ), status?: Maybe<(
          { __typename?: 'TaxiStatus' }
          & Pick<ITaxiStatus, 'humanized' | 'value'>
        )>, vehicle?: Maybe<(
          { __typename?: 'TaxiReservationVehicle' }
          & Pick<ITaxiReservationVehicle, 'brand' | 'licensePlate' | 'type'>
        )> }
      ) }
    )> }
  )> }
);

export type ILoadTaxiReservationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ILoadTaxiReservationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { taxiReservation?: Maybe<(
      { __typename?: 'TaxiReservation' }
      & Pick<ITaxiReservation, 'id' | 'contactPhoneNumber' | 'vehicleType' | 'passengerCount' | 'departAt' | 'cancellable' | 'cancelledAt' | 'remarks'>
      & { price?: Maybe<(
        { __typename?: 'Money' }
        & Pick<IMoney, 'humanized'>
      )>, departure: (
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )> }
      ), destination: (
        { __typename?: 'Location' }
        & Pick<ILocation, 'humanized'>
        & { coordinates?: Maybe<(
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'longitude' | 'latitude'>
        )> }
      ), status?: Maybe<(
        { __typename?: 'TaxiStatus' }
        & Pick<ITaxiStatus, 'humanized' | 'value'>
      )>, vehicle?: Maybe<(
        { __typename?: 'TaxiReservationVehicle' }
        & Pick<ITaxiReservationVehicle, 'brand' | 'licensePlate' | 'type'>
      )> }
    )> }
  )> }
);

export type IGetTranzerReservationDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IGetTranzerReservationDetailsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { tranzerReservation?: Maybe<(
      { __typename?: 'TranzerReservation' }
      & Pick<ITranzerReservation, 'startTime' | 'endTime' | 'id'>
      & { legs?: Maybe<Array<(
        { __typename?: 'TranzerLeg' }
        & Pick<ITranzerLeg, 'id'>
        & { fromLocation: (
          { __typename?: 'GeoLocation' }
          & Pick<IGeoLocation, 'humanized'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          ) }
        ), toLocation: (
          { __typename?: 'GeoLocation' }
          & Pick<IGeoLocation, 'humanized'>
          & { coordinates: (
            { __typename?: 'Coordinates' }
            & Pick<ICoordinates, 'latitude' | 'longitude'>
          ) }
        ), icon: (
          { __typename?: 'Icon' }
          & Pick<IIcon, 'name'>
        ) }
      )>>, status?: Maybe<(
        { __typename?: 'TranzerStatus' }
        & Pick<ITranzerStatus, 'humanized' | 'value'>
      )> }
    )> }
  )> }
);

export type IGetReservationsQueryVariables = Exact<{
  filterByState: IReservationStateEnum;
  order: IOrderEnum;
}>;


export type IGetReservationsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { reservations?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<IReservation, 'id' | 'title' | 'startsAt' | 'endsAt' | 'cancelledAt' | 'recordId' | 'reservationClass'>
      & { icon?: Maybe<(
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      )>, reservationStatus?: Maybe<(
        { __typename?: 'ReservationStatus' }
        & Pick<IReservationStatus, 'humanized' | 'value'>
      )>, startLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      )>, endLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<IGeoLocation, 'humanized'>
        & { coordinates: (
          { __typename?: 'Coordinates' }
          & Pick<ICoordinates, 'latitude' | 'longitude'>
        ) }
      )> }
    )>> }
  )> }
);

export type IGetOrganisationUrlsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetOrganisationUrlsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<IOrganization, 'policyDocumentUrl'>
      & { customerService: (
        { __typename?: 'CustomerService' }
        & Pick<ICustomerService, 'helpUrl'>
      ) }
    )> }
  )> }
);

export type IGetUserProfileInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserProfileInformationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'token'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'avatar' | 'employeeNumber' | 'phoneNumber' | 'firstName' | 'infix' | 'initials' | 'lastName' | 'email' | 'dateOfBirth' | 'defaultMobilityCardWeekendTransactionTravelPurpose' | 'defaultMobilityCardTransactionTravelPurpose'>
      & { homeAddress?: Maybe<(
        { __typename?: 'EmployeeAddress' }
        & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
        & { employeeCountryIsoCode?: Maybe<(
          { __typename?: 'CountryIsoCode' }
          & Pick<ICountryIsoCode, 'humanized' | 'value'>
        )> }
      )> }
    ) }
  )> }
);

export type IUpdateUserProfileInformationMutationVariables = Exact<{
  input: IUpdateUserProfileInput;
}>;


export type IUpdateUserProfileInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile?: Maybe<(
    { __typename?: 'UpdateUserProfilePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'fullMessages' | 'attribute' | 'messages'>
    )>, me?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<IEmployee, 'id' | 'token'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<IProfile, 'avatar' | 'employeeNumber' | 'phoneNumber' | 'firstName' | 'infix' | 'initials' | 'lastName' | 'email' | 'dateOfBirth' | 'defaultMobilityCardWeekendTransactionTravelPurpose' | 'defaultMobilityCardTransactionTravelPurpose'>
        & { homeAddress?: Maybe<(
          { __typename?: 'EmployeeAddress' }
          & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
          & { employeeCountryIsoCode?: Maybe<(
            { __typename?: 'CountryIsoCode' }
            & Pick<ICountryIsoCode, 'humanized' | 'value'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type IGetEnglishDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetEnglishDocumentsQuery = (
  { __typename?: 'Query' }
  & { externalUrls: (
    { __typename?: 'ExternalUrls' }
    & { englishTermsAndConditionsUrl: (
      { __typename?: 'ExternalUrl' }
      & Pick<IExternalUrl, 'title' | 'url'>
    ), englishPrivacyStatementUrl: (
      { __typename?: 'ExternalUrl' }
      & Pick<IExternalUrl, 'title' | 'url'>
    ) }
  ) }
);

export type ICreateUserProfileMutationVariables = Exact<{
  input: ICreateUserProfileInput;
}>;


export type ICreateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { createUserProfile?: Maybe<(
    { __typename?: 'CreateUserProfilePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'attribute' | 'fullMessages' | 'messages'>
    )>, me?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<IEmployee, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<IProfile, 'employeeNumber' | 'email'>
      ) }
    )> }
  )> }
);

export type ISendNewInvitationLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ISendNewInvitationLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendNewInvitationLink?: Maybe<(
    { __typename?: 'SendNewInvitationLinkPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ) }
  )> }
);

export type IGetTokenValidityQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type IGetTokenValidityQuery = (
  { __typename?: 'Query' }
  & Pick<IQuery, 'inviteTokenValid'>
);

export type IGetUserWorkInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserWorkInformationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'employeeNumber'>
      & { workAddress?: Maybe<(
        { __typename?: 'EmployeeAddress' }
        & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
        & { employeeCountryIsoCode?: Maybe<(
          { __typename?: 'CountryIsoCode' }
          & Pick<ICountryIsoCode, 'value' | 'humanized'>
        )> }
      )> }
    ) }
  )> }
);

export type IUpdateUserWorkProfileInformationMutationVariables = Exact<{
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  streetNumberSuffix?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryIsoCode?: InputMaybe<ICountryIsoCodeEnum>;
}>;


export type IUpdateUserWorkProfileInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile?: Maybe<(
    { __typename?: 'UpdateUserProfilePayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), validationErrors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<IValidationError, 'fullMessages' | 'attribute' | 'messages'>
    )>, me?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<IEmployee, 'id'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<IProfile, 'employeeNumber'>
        & { workAddress?: Maybe<(
          { __typename?: 'EmployeeAddress' }
          & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
          & { employeeCountryIsoCode?: Maybe<(
            { __typename?: 'CountryIsoCode' }
            & Pick<ICountryIsoCode, 'value' | 'humanized'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type IConfigureMfaMutationVariables = Exact<{
  input: IConfigureMfaInput;
}>;


export type IConfigureMfaMutation = (
  { __typename?: 'Mutation' }
  & { configureMfa?: Maybe<(
    { __typename?: 'ConfigureMfaPayload' }
    & Pick<IConfigureMfaPayload, 'otpUri' | 'configurationSuccess'>
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type ILoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  otpToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type ILoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<ILoginPayload, 'hasOtpConfigured' | 'otpRequired'>
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'success' | 'error'>
    ), tokens?: Maybe<(
      { __typename?: 'Auth' }
      & Pick<IAuth, 'accessToken' | 'refreshToken'>
    )> }
  )> }
);

export type ILogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type ILogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutPayload' }
    & { result: (
      { __typename?: 'Result' }
      & Pick<IResult, 'error' | 'success'>
    ) }
  )> }
);

export type IReauthorizeQueryVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type IReauthorizeQuery = (
  { __typename?: 'Query' }
  & { reauthorize?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<IAuth, 'accessToken' | 'refreshToken'>
  )> }
);

export type IGetUserInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserInformationQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id' | 'becameEmployee'>
    & { profile: (
      { __typename?: 'Profile' }
      & Pick<IProfile, 'avatar' | 'employeeNumber' | 'firstName' | 'infix' | 'initials' | 'lastName' | 'email' | 'phoneNumber' | 'usesNewInterface' | 'dateOfBirth' | 'preferredTravelPurpose' | 'defaultMobilityCardTransactionTravelPurpose' | 'defaultMobilityCardWeekendTransactionTravelPurpose'>
      & { homeAddress?: Maybe<(
        { __typename?: 'EmployeeAddress' }
        & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
        & { employeeCountryIsoCode?: Maybe<(
          { __typename?: 'CountryIsoCode' }
          & Pick<ICountryIsoCode, 'humanized' | 'value'>
        )> }
      )>, workAddress?: Maybe<(
        { __typename?: 'EmployeeAddress' }
        & Pick<IEmployeeAddress, 'city' | 'street' | 'streetNumber' | 'streetNumberSuffix' | 'zipcode'>
        & { employeeCountryIsoCode?: Maybe<(
          { __typename?: 'CountryIsoCode' }
          & Pick<ICountryIsoCode, 'humanized' | 'value'>
        )> }
      )> }
    ) }
  )> }
);

export type ILoadWhatOptionsQueryVariables = Exact<{
  on: Scalars['Date']['input'];
}>;


export type ILoadWhatOptionsQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { whatOptions: Array<(
      { __typename?: 'WhatOption' }
      & { icon: (
        { __typename?: 'Icon' }
        & Pick<IIcon, 'name'>
      ), category: (
        { __typename?: 'Category' }
        & Pick<ICategory, 'id' | 'requiresModality'>
        & { mainCategory: (
          { __typename?: 'MainCategory' }
          & Pick<IMainCategory, 'value' | 'humanized'>
        ), subCategory?: Maybe<(
          { __typename?: 'SubCategory' }
          & Pick<ISubCategory, 'value' | 'humanized'>
        )> }
      ), modality?: Maybe<(
        { __typename?: 'Modality' }
        & Pick<IModality, 'id' | 'clazz' | 'kind' | 'humanizedWithCapital' | 'requiresVehicle' | 'travelMode' | 'requiredPassengerCount'>
        & { modalityOwnership?: Maybe<(
          { __typename?: 'ModalityOwnership' }
          & Pick<IModalityOwnership, 'humanized' | 'value'>
        )> }
      )>, vehicle?: Maybe<(
        { __typename?: 'MeansOfTransport' }
        & Pick<IMeansOfTransport, 'id' | 'humanized'>
      )>, reimbursableCategoryMethod?: Maybe<(
        { __typename?: 'ReimbursableCategoryMethod' }
        & Pick<IReimbursableCategoryMethod, 'value'>
      )> }
    )> }
  )> }
);

export type IGetEmployeeDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetEmployeeDatesQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<IEmployee, 'id'>
    & { employments?: Maybe<Array<(
      { __typename?: 'Employment' }
      & Pick<IEmployment, 'startsOn' | 'endsOn'>
    )>> }
  )> }
);

export type IGetThemeQueryVariables = Exact<{
  hostname: Scalars['String']['input'];
}>;


export type IGetThemeQuery = (
  { __typename: 'Query' }
  & { configurationProfileForHost?: Maybe<(
    { __typename?: 'ConfigurationProfileForHost' }
    & { customerLabelTheme?: Maybe<(
      { __typename?: 'CustomerLabelTheme' }
      & { primaryColor?: Maybe<(
        { __typename?: 'Color' }
        & { hsla?: Maybe<(
          { __typename?: 'Hsla' }
          & Pick<IHsla, 'alpha' | 'hue' | 'lightness' | 'saturation'>
        )>, rgba?: Maybe<(
          { __typename?: 'Rgba' }
          & Pick<IRgba, 'blue' | 'alpha' | 'green' | 'red'>
        )> }
      )>, secondaryColor?: Maybe<(
        { __typename?: 'Color' }
        & { hsla?: Maybe<(
          { __typename?: 'Hsla' }
          & Pick<IHsla, 'hue' | 'alpha' | 'lightness' | 'saturation'>
        )>, rgba?: Maybe<(
          { __typename?: 'Rgba' }
          & Pick<IRgba, 'alpha' | 'blue' | 'green' | 'red'>
        )> }
      )>, poster?: Maybe<(
        { __typename?: 'Image' }
        & Pick<IImage, 'uri' | 'base64' | 'width' | 'height'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<IImage, 'uri' | 'base64' | 'height' | 'width'>
      )>, icon?: Maybe<(
        { __typename?: 'Image' }
        & Pick<IImage, 'uri' | 'base64' | 'height' | 'width'>
      )>, favicon?: Maybe<(
        { __typename?: 'Image' }
        & Pick<IImage, 'uri' | 'base64'>
      )> }
    )> }
  )> }
);

export const Co2EmissionSummaryFragmentDoc = gql`
    fragment co2EmissionSummary on Co2EmissionSummary {
  mobilityEventsPerModality {
    emission {
      humanized
      value
    }
    modality {
      humanized
      id
      kind
      clazz
    }
  }
  totalEmission {
    humanized
    value
  }
}
    `;
export const DirectDebitInvoiceFragmentDoc = gql`
    fragment DirectDebitInvoice on DirectDebitInvoice {
  id
  externalReference
  invoiceDate
  pdfUrl
  settlement {
    id
  }
  status {
    humanized
  }
}
    `;
export const DirectDebitMandateFragmentDoc = gql`
    fragment DirectDebitMandate on DirectDebitMandate {
  id
  uuid
  acceptanceUrl
  acceptedAt
  ecurringCustomerToken
  ecurringSubscriptionToken
  isAccepted
  isCustomerRegistered
  isRevoked
  isValid
  revokedAt
  status {
    humanized
    value
  }
  actions {
    acceptable
    revokable
  }
}
    `;
export const DistanceSummaryFragmentDoc = gql`
    fragment distanceSummary on DistanceSummary {
  mobilityEventsPerModality {
    modality {
      humanized
      id
      kind
      clazz
    }
    distance
  }
  totalDistance
}
    `;
export const EmployeeTasksFragmentDoc = gql`
    fragment EmployeeTasks on Employee {
  tasks {
    humanized
    message
    type
    ... on RefundToBeLinked {
      mobilityEventId
    }
    ... on CompensationRideToBeCreated {
      incompleteRideId
      refundId
    }
    __typename
  }
}
    `;
export const FavoriteFragmentDoc = gql`
    fragment Favorite on Favorite {
  id
  label
  location
  locationId
  type
  coordinates {
    latitude
    longitude
  }
  organization {
    id
    name
  }
  actions {
    deletable
    editable
  }
}
    `;
export const IndexMobilityEventFragmentDoc = gql`
    fragment IndexMobilityEvent on MobilityEvent {
  id
  status {
    value
    humanized
    label
  }
  employment {
    budgetGroupName
    commuteMonthlyKmBudget
    commuteMonthlyMoneyBudget
    costCenter
    department
    endsOn
    id
    main
    startsOn
  }
  happenedAt
  happenedInTheFuture
  allowPersonalConsumption
  actions {
    editable
    deletable
    copyable
    confirmable
    unacceptable
    mergeable
  }
  what {
    editable
    category {
      main
      requiresModality
    }
    modality {
      id
      clazz
      kind
      modalityOwnership {
        humanized
        value
      }
      requiresVehicle
    }
    humanized
    icon {
      name
    }
    sharedRidePassengers
  }
  how {
    humanized
    icon {
      name
    }
  }
  why {
    travelPurpose {
      humanized
      value
    }
    project {
      id
      code
      humanized
    }
    explanation {
      humanized
      required {
        business
        commute
        personal
      }
    }
  }
  howMuch {
    consumption {
      amount
      humanized
    }
  }
  where {
    ... on Route {
      from {
        humanized
      }
      to {
        humanized
      }
    }
    ... on Location {
      humanized
    }
    ... on RentalLocations {
      pickup {
        humanized
      }
      return {
        humanized
      }
    }
  }
  financial {
    consequences {
      gross {
        label
        moneyTransfer {
          direction
          humanized
        }
      }
      net {
        label
        moneyTransfer {
          direction
          humanized
        }
      }
    }
    fact {
      receipt {
        contentType
        url
      }
    }
  }
}
    `;
export const MileageFragmentDoc = gql`
    fragment Mileage on Mileage {
  id
  licensePlate
  recordedAt
  remainingKilometers
  supportsUnaccountedDistances
  hasUnaccountedDistance
  budgetCostPerKilometer
  budgetCharge
  amountInKilometers
  mobilityEventId
}
    `;
export const LicensePlateFragmentDoc = gql`
    fragment LicensePlate on LicensePlate {
  budgetChargeTotal
  licensePlate
  remainingKilometersTotal
  mileages {
    ...Mileage
  }
}
    ${MileageFragmentDoc}`;
export const MsaCarrierFragmentDoc = gql`
    fragment MSACarrier on MSACarrier {
  carrierId
  status
  statusCategory
}
    `;
export const MobilityCardOrderFragmentDoc = gql`
    fragment MobilityCardOrder on MobilityCardOrder {
  id
  humanizedSubscription {
    value
  }
  fromStation
  toStation
  passportPhoto
  motivation
  needsApproval
  hasPincodeBeenEntered
  requiredTermsAndConditionsDocuments {
    id
    humanizedKind {
      humanized
      value
    }
    pdfUrl
  }
  services {
    humanized
    value
  }
  recommendedCard {
    humanized
  }
  firstClassReason {
    humanized
    value
  }
  permission {
    canRequestOptions
    canRequestPhoto
  }
  starValue {
    humanized
    value
  }
  zone {
    humanized
    value
  }
  trainClass {
    humanized
    value
  }
  actions {
    isEditable
  }
  requestableSubscriptions {
    hasTraject
    value
    humanized
    requiresMotivation
    starValues {
      humanized
      value
    }
    humanizedZones {
      humanized
      value
    }
    trainClassOptions {
      firstClassReasons {
        humanized
        value
      }
      requestableTrainClasses {
        humanized
        value
      }
      requiresMotivation
    }
  }
  requestableServices {
    budgetGroupAvailability
    requiresMotivation
    requiresPincode
    kind {
      humanized
      sub
    }
  }
  status {
    humanized
    value
  }
  completedAt
}
    `;
export const MobilityEventDetailsFragmentDoc = gql`
    fragment MobilityEventDetails on MobilityEvent {
  what {
    humanized
    icon {
      name
    }
    editable
    vehicle {
      humanized
    }
    modality {
      humanized
    }
  }
  id
  when {
    ... on Duration {
      startsAt
      endsAt
      editable
    }
    ... on Timestamp {
      at
      editable
    }
    editable
  }
  where {
    ... on Location {
      humanized
      label
      coordinates {
        latitude
        longitude
      }
    }
    ... on Route {
      from {
        coordinates {
          latitude
          longitude
        }
        humanized
      }
      to {
        coordinates {
          latitude
          longitude
        }
        humanized
      }
      track {
        latitude
        longitude
      }
      nonCombined {
        humanized
      }
      diverted {
        humanized
      }
    }
    ... on RentalLocations {
      pickup {
        humanized
      }
      return {
        humanized
      }
    }
  }
  why {
    legs {
      quantity {
        humanized
      }
    }
    travelPurpose {
      humanized
      value
    }
    project {
      code
      humanized
    }
    explanation {
      humanized
    }
    refundedMobilityEventId
    humanizedRefundedMobilityEvent
  }
  happenedAt
  financial {
    fact {
      receipt {
        contentType
        url
      }
    }
    consequences {
      gross {
        amount {
          valueInCents
        }
        moneyTransfer {
          direction
        }
        label
      }
      fiscal {
        amount {
          humanized
        }
        label
      }
      net {
        amount {
          valueInCents
        }
        moneyTransfer {
          direction
        }
        label
      }
    }
    fact {
      exclVat {
        humanized
      }
      inclVat {
        humanized
      }
      label
      vatRate {
        humanized
      }
    }
  }
  actions {
    deletable
    editable
    confirmable
    unacceptable
  }
  how {
    humanized
  }
  howMuch {
    co2Emission {
      humanized
    }
    consumption {
      humanized
    }
  }
  status {
    humanized
    value
  }
}
    `;
export const PersonalMeansOfRegistrationFragmentDoc = gql`
    fragment PersonalMeansOfRegistration on PersonalMeansOfRegistration {
  id
  photoRejectedReasons {
    humanized
  }
  kind {
    humanized
    main
    sub
  }
  status {
    humanized
    value
  }
  endsOn {
    humanized
  }
  identity {
    humanized
  }
  abilities {
    activatable
    lostOrStolenReportable
    terminatable
  }
  availableServices {
    requiresMotivation
    termsAndConditions {
      requiresAgreement
      document {
        id
        pdfUrl
        humanizedKind {
          humanized
        }
      }
    }
    description {
      humanized
    }
    icon {
      name
    }
    identity {
      humanized
    }
    kind {
      humanized
      main
      sub
    }
    recordId
    status {
      humanized
      metaStatus {
        humanized
        value
      }
    }
    personalMeansOfRegistration {
      id
      identity {
        humanized
      }
    }
  }
}
    `;
export const PrivacyReasonFragmentDoc = gql`
    fragment privacyReason on PrivacyRequestReason {
  description
  title
  reason
}
    `;
export const PrivacyTypeFragmentDoc = gql`
    fragment privacyType on Privacy {
  description
  humanized
  reasons {
    ...privacyReason
  }
  value
}
    ${PrivacyReasonFragmentDoc}`;
export const ReservationFragmentDoc = gql`
    fragment Reservation on Reservation {
  id
  title
  startsAt
  endsAt
  cancelledAt
  recordId
  reservationClass
  icon {
    name
  }
  reservationStatus {
    humanized
    value
  }
  startLocation {
    coordinates {
      latitude
      longitude
    }
    humanized
  }
  endLocation {
    coordinates {
      latitude
      longitude
    }
    humanized
  }
}
    `;
export const SharedVehicleReservationFragmentDoc = gql`
    fragment SharedVehicleReservation on SharedVehicleReservation {
  id
  reservationId
  fromLocation {
    humanized
    coordinates {
      latitude
      longitude
    }
  }
  startsAt
  endsAt
  cancelledAt
  vehicleName
  vehicleBrand
  vehicleModel
  vehicleLicensePlate
  status {
    value
  }
  icon {
    name
  }
}
    `;
export const FinancialComponentsFragmentDoc = gql`
    fragment FinancialComponents on FinancialComponent {
  label
  moneyTransfer {
    humanized
    direction
  }
  amount {
    humanized
    valueInCents
  }
  entries {
    label
    calculation
    moneyTransfer {
      direction
      humanized
    }
    amount {
      humanized
      valueInCents
    }
  }
}
    `;
export const MobilityEventSignatureFragmentDoc = gql`
    fragment mobilityEventSignature on Signature {
  createdAt
  id
  reason
  signer {
    profile {
      email
      employeeNumber
      firstName
      lastName
    }
  }
}
    `;
export const ShowFragmentFragmentDoc = gql`
    fragment ShowFragment on MobilityEvent {
  id
  happenedInTheFuture
  status {
    value
    humanized
    label
  }
  employment {
    budgetGroupName
    commuteMonthlyKmBudget
    commuteMonthlyMoneyBudget
    costCenter
    department
    endsOn
    id
    main
    startsOn
  }
  happenedAt
  allowPersonalConsumption
  actions {
    editable
    deletable
    copyable
    confirmable
    unacceptable
    missingCheckout
    mergeable
    viewDetails
  }
  financial {
    fact {
      exclVat {
        humanized
      }
      inclVat {
        humanized
        value
      }
      receipt {
        filename
        contentType
        url
      }
      direction
      label
      vatRate {
        humanized
      }
    }
    consequences {
      gross {
        ...FinancialComponents
      }
      net {
        ...FinancialComponents
      }
      fiscal {
        ...FinancialComponents
      }
    }
  }
  howMuch {
    consumption {
      label
      humanized
      amount
    }
    co2Emission {
      label
      humanized
      amount
    }
  }
  what {
    editable
    humanized
    modality {
      id
      humanizedWithCapital
      requiresVehicle
      travelMode
      kind
      modalityOwnership {
        humanized
        value
      }
      requiredPassengerCount
      clazz
    }
    category {
      id
      main
      requiresModality
      mainCategory {
        value
        humanized
      }
      subCategory {
        value
        humanized
      }
    }
    vehicle {
      id
      humanized
    }
    sharedRidePassengers
    reimbursableCategoryMethod {
      value
    }
    icon {
      name
    }
  }
  where {
    ... on Route {
      diverted {
        label
        humanized
        value
      }
      nonCombined {
        label
        humanized
        value
      }
      from {
        humanized
        favoriteId
        locationId
        coordinates {
          latitude
          longitude
        }
      }
      to {
        humanized
        favoriteId
        locationId
        coordinates {
          latitude
          longitude
        }
      }
      track {
        longitude
        latitude
      }
    }
    ... on RentalLocations {
      pickup {
        humanized
        coordinates {
          latitude
          longitude
        }
      }
      return {
        humanized
        coordinates {
          latitude
          longitude
        }
      }
    }
    ... on Location {
      humanized
      label
      coordinates {
        latitude
        longitude
      }
    }
  }
  why {
    editable
    legs {
      travelPurpose {
        humanized
      }
      quantity {
        amount
        humanized
      }
    }
    travelPurpose {
      humanized
      value
    }
    personalDistance
    project {
      id
      code
      humanized
    }
    explanation {
      humanized
      required {
        business
        commute
        personal
      }
    }
    stateTransitions {
      rejectedAt
      rejectReason
      confirmer
    }
    refundedMobilityEventId
    humanizedRefundedMobilityEvent
  }
  when {
    ... on Timestamp {
      at
      editable
    }
    ... on Duration {
      startsAt
      endsAt
      editable
    }
  }
  how {
    humanized
    icon {
      name
    }
  }
  isRefund
  signatures {
    ...mobilityEventSignature
  }
}
    ${FinancialComponentsFragmentDoc}
${MobilityEventSignatureFragmentDoc}`;
export const SplitBillingContractFragmentDoc = gql`
    fragment SplitBillingContract on SplitBillingContract {
  id
  splitBillingContractId
  status {
    humanized
  }
  invitedAt
  cancelledAt
  actions {
    revocable
  }
}
    `;
export const SplitBillingInvoiceFragmentDoc = gql`
    fragment SplitBillingInvoice on SplitBillingInvoice {
  id
  invoiceDate
  externalReference
  pdfUrl
  transactionsReport {
    xlsUrl
  }
}
    `;
export const TaxiReservationFragmentDoc = gql`
    fragment TaxiReservation on TaxiReservation {
  id
  contactPhoneNumber
  price {
    humanized
  }
  departure {
    humanized
    coordinates {
      longitude
      latitude
    }
  }
  destination {
    humanized
    coordinates {
      longitude
      latitude
    }
  }
  vehicleType
  passengerCount
  departAt
  cancellable
  cancelledAt
  remarks
  status {
    humanized
    value
  }
  vehicle {
    brand
    licensePlate
    type
  }
}
    `;
export const UserProfileInformationFragmentDoc = gql`
    fragment UserProfileInformation on Employee {
  id
  token
  profile {
    avatar
    employeeNumber
    phoneNumber
    firstName
    infix
    initials
    lastName
    email
    dateOfBirth
    defaultMobilityCardWeekendTransactionTravelPurpose
    defaultMobilityCardTransactionTravelPurpose
    homeAddress {
      city
      street
      streetNumber
      streetNumberSuffix
      zipcode
      employeeCountryIsoCode {
        humanized
        value
      }
    }
  }
}
    `;
export const UserWorkInformationFragmentDoc = gql`
    fragment UserWorkInformation on Employee {
  id
  profile {
    employeeNumber
    workAddress {
      city
      street
      streetNumber
      employeeCountryIsoCode {
        value
        humanized
      }
      streetNumberSuffix
      zipcode
    }
  }
}
    `;
export const ValidationErrorFragmentDoc = gql`
    fragment ValidationError on ValidationError {
  attribute
  fullMessages
  messages
}
    `;
export const PersonalVehicleFragmentDoc = gql`
    fragment PersonalVehicle on MeansOfTransport {
  id
  humanized
  name
  vehicleInformation {
    brand
    model
    co2EmissionPerKilometer
    energyLabel
    fuelType {
      humanized
      value
    }
  }
  startsOn
  endsOn
  licensePlate
  icon {
    name
  }
  actions {
    editable
    terminatable
  }
  modality {
    modalityOwnership {
      value
    }
  }
}
    `;
export const GetFavoritesWithFallbackDocument = gql`
    query getFavoritesWithFallback {
  me {
    id
    becameEmployee
    favorites(withFallbackLocation: true) {
      id
      type
    }
  }
}
    `;

/**
 * __useGetFavoritesWithFallbackQuery__
 *
 * To run a query within a React component, call `useGetFavoritesWithFallbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesWithFallbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesWithFallbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFavoritesWithFallbackQuery(baseOptions?: Apollo.QueryHookOptions<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>(GetFavoritesWithFallbackDocument, options);
      }
export function useGetFavoritesWithFallbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>(GetFavoritesWithFallbackDocument, options);
        }
export function useGetFavoritesWithFallbackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>(GetFavoritesWithFallbackDocument, options);
        }
export type GetFavoritesWithFallbackQueryHookResult = ReturnType<typeof useGetFavoritesWithFallbackQuery>;
export type GetFavoritesWithFallbackLazyQueryHookResult = ReturnType<typeof useGetFavoritesWithFallbackLazyQuery>;
export type GetFavoritesWithFallbackSuspenseQueryHookResult = ReturnType<typeof useGetFavoritesWithFallbackSuspenseQuery>;
export type GetFavoritesWithFallbackQueryResult = Apollo.QueryResult<IGetFavoritesWithFallbackQuery, IGetFavoritesWithFallbackQueryVariables>;
export const SetManualFavoriteCoordinatesDocument = gql`
    mutation setManualFavoriteCoordinates($id: ID!, $latitude: Coordinate!, $longitude: Coordinate!) {
  favorites {
    setManualCoordinates(
      input: {id: $id, latitude: $latitude, longitude: $longitude}
    ) {
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
    }
  }
}
    `;

/**
 * __useSetManualFavoriteCoordinatesMutation__
 *
 * To run a mutation, you first call `useSetManualFavoriteCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManualFavoriteCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManualFavoriteCoordinatesMutation, { data, loading, error }] = useSetManualFavoriteCoordinatesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useSetManualFavoriteCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<ISetManualFavoriteCoordinatesMutation, ISetManualFavoriteCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISetManualFavoriteCoordinatesMutation, ISetManualFavoriteCoordinatesMutationVariables>(SetManualFavoriteCoordinatesDocument, options);
      }
export type SetManualFavoriteCoordinatesMutationHookResult = ReturnType<typeof useSetManualFavoriteCoordinatesMutation>;
export type SetManualFavoriteCoordinatesMutationResult = Apollo.MutationResult<ISetManualFavoriteCoordinatesMutation>;
export type SetManualFavoriteCoordinatesMutationOptions = Apollo.BaseMutationOptions<ISetManualFavoriteCoordinatesMutation, ISetManualFavoriteCoordinatesMutationVariables>;
export const GetFavoriteLocationsDocument = gql`
    query getFavoriteLocations {
  me {
    id
    favorites {
      label
      location
      locationId
      id
      coordinates {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __useGetFavoriteLocationsQuery__
 *
 * To run a query within a React component, call `useGetFavoriteLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFavoriteLocationsQuery(baseOptions?: Apollo.QueryHookOptions<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>(GetFavoriteLocationsDocument, options);
      }
export function useGetFavoriteLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>(GetFavoriteLocationsDocument, options);
        }
export function useGetFavoriteLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>(GetFavoriteLocationsDocument, options);
        }
export type GetFavoriteLocationsQueryHookResult = ReturnType<typeof useGetFavoriteLocationsQuery>;
export type GetFavoriteLocationsLazyQueryHookResult = ReturnType<typeof useGetFavoriteLocationsLazyQuery>;
export type GetFavoriteLocationsSuspenseQueryHookResult = ReturnType<typeof useGetFavoriteLocationsSuspenseQuery>;
export type GetFavoriteLocationsQueryResult = Apollo.QueryResult<IGetFavoriteLocationsQuery, IGetFavoriteLocationsQueryVariables>;
export const GetPlaceDetailsDocument = gql`
    query getPlaceDetails($placeId: String!) {
  googlePlaces {
    details(placeId: $placeId) {
      address
      coordinates {
        latitude
        longitude
      }
      name
      placeId
      locationId
      validationErrors {
        attribute
        fullMessages
        messages
      }
    }
  }
  places {
    details(placeId: $placeId) {
      address
      coordinates {
        latitude
        longitude
      }
      name
      placeId
      locationId
      validationErrors {
        attribute
        fullMessages
        messages
      }
    }
  }
}
    `;

/**
 * __useGetPlaceDetailsQuery__
 *
 * To run a query within a React component, call `useGetPlaceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceDetailsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceDetailsQuery(baseOptions: Apollo.QueryHookOptions<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables> & ({ variables: IGetPlaceDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables>(GetPlaceDetailsDocument, options);
      }
export function useGetPlaceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables>(GetPlaceDetailsDocument, options);
        }
export function useGetPlaceDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables>(GetPlaceDetailsDocument, options);
        }
export type GetPlaceDetailsQueryHookResult = ReturnType<typeof useGetPlaceDetailsQuery>;
export type GetPlaceDetailsLazyQueryHookResult = ReturnType<typeof useGetPlaceDetailsLazyQuery>;
export type GetPlaceDetailsSuspenseQueryHookResult = ReturnType<typeof useGetPlaceDetailsSuspenseQuery>;
export type GetPlaceDetailsQueryResult = Apollo.QueryResult<IGetPlaceDetailsQuery, IGetPlaceDetailsQueryVariables>;
export const GetPlaceSuggestionsDocument = gql`
    query getPlaceSuggestions($startsWith: String!) {
  places {
    suggest(startsWith: $startsWith) {
      placeId
      html
      text
    }
  }
  me {
    id
    favorites(startsWith: $startsWith) {
      id
      label
      location
      locationId
      coordinates {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __useGetPlaceSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetPlaceSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceSuggestionsQuery({
 *   variables: {
 *      startsWith: // value for 'startsWith'
 *   },
 * });
 */
export function useGetPlaceSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables> & ({ variables: IGetPlaceSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables>(GetPlaceSuggestionsDocument, options);
      }
export function useGetPlaceSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables>(GetPlaceSuggestionsDocument, options);
        }
export function useGetPlaceSuggestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables>(GetPlaceSuggestionsDocument, options);
        }
export type GetPlaceSuggestionsQueryHookResult = ReturnType<typeof useGetPlaceSuggestionsQuery>;
export type GetPlaceSuggestionsLazyQueryHookResult = ReturnType<typeof useGetPlaceSuggestionsLazyQuery>;
export type GetPlaceSuggestionsSuspenseQueryHookResult = ReturnType<typeof useGetPlaceSuggestionsSuspenseQuery>;
export type GetPlaceSuggestionsQueryResult = Apollo.QueryResult<IGetPlaceSuggestionsQuery, IGetPlaceSuggestionsQueryVariables>;
export const GetFuelTypeByLicensePlateDocument = gql`
    query getFuelTypeByLicensePlate($licensePlate: String!) {
  vehicleInformation(licensePlate: $licensePlate) {
    fuelType {
      value
    }
  }
}
    `;

/**
 * __useGetFuelTypeByLicensePlateQuery__
 *
 * To run a query within a React component, call `useGetFuelTypeByLicensePlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelTypeByLicensePlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelTypeByLicensePlateQuery({
 *   variables: {
 *      licensePlate: // value for 'licensePlate'
 *   },
 * });
 */
export function useGetFuelTypeByLicensePlateQuery(baseOptions: Apollo.QueryHookOptions<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables> & ({ variables: IGetFuelTypeByLicensePlateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables>(GetFuelTypeByLicensePlateDocument, options);
      }
export function useGetFuelTypeByLicensePlateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables>(GetFuelTypeByLicensePlateDocument, options);
        }
export function useGetFuelTypeByLicensePlateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables>(GetFuelTypeByLicensePlateDocument, options);
        }
export type GetFuelTypeByLicensePlateQueryHookResult = ReturnType<typeof useGetFuelTypeByLicensePlateQuery>;
export type GetFuelTypeByLicensePlateLazyQueryHookResult = ReturnType<typeof useGetFuelTypeByLicensePlateLazyQuery>;
export type GetFuelTypeByLicensePlateSuspenseQueryHookResult = ReturnType<typeof useGetFuelTypeByLicensePlateSuspenseQuery>;
export type GetFuelTypeByLicensePlateQueryResult = Apollo.QueryResult<IGetFuelTypeByLicensePlateQuery, IGetFuelTypeByLicensePlateQueryVariables>;
export const GetActiveAndFutureVehiclesDocument = gql`
    query getActiveAndFutureVehicles {
  me {
    becameEmployee
    id
    current: personalVehicles(filterBy: CURRENT, order: ASC) {
      ...PersonalVehicle
    }
    future: personalVehicles(filterBy: FUTURE, order: ASC) {
      ...PersonalVehicle
    }
  }
}
    ${PersonalVehicleFragmentDoc}`;

/**
 * __useGetActiveAndFutureVehiclesQuery__
 *
 * To run a query within a React component, call `useGetActiveAndFutureVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAndFutureVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAndFutureVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveAndFutureVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>(GetActiveAndFutureVehiclesDocument, options);
      }
export function useGetActiveAndFutureVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>(GetActiveAndFutureVehiclesDocument, options);
        }
export function useGetActiveAndFutureVehiclesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>(GetActiveAndFutureVehiclesDocument, options);
        }
export type GetActiveAndFutureVehiclesQueryHookResult = ReturnType<typeof useGetActiveAndFutureVehiclesQuery>;
export type GetActiveAndFutureVehiclesLazyQueryHookResult = ReturnType<typeof useGetActiveAndFutureVehiclesLazyQuery>;
export type GetActiveAndFutureVehiclesSuspenseQueryHookResult = ReturnType<typeof useGetActiveAndFutureVehiclesSuspenseQuery>;
export type GetActiveAndFutureVehiclesQueryResult = Apollo.QueryResult<IGetActiveAndFutureVehiclesQuery, IGetActiveAndFutureVehiclesQueryVariables>;
export const RevertBetaInterfaceOptInDocument = gql`
    mutation revertBetaInterfaceOptIn {
  updateUserProfile(input: {usesNewInterface: false}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useRevertBetaInterfaceOptInMutation__
 *
 * To run a mutation, you first call `useRevertBetaInterfaceOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertBetaInterfaceOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertBetaInterfaceOptInMutation, { data, loading, error }] = useRevertBetaInterfaceOptInMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevertBetaInterfaceOptInMutation(baseOptions?: Apollo.MutationHookOptions<IRevertBetaInterfaceOptInMutation, IRevertBetaInterfaceOptInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRevertBetaInterfaceOptInMutation, IRevertBetaInterfaceOptInMutationVariables>(RevertBetaInterfaceOptInDocument, options);
      }
export type RevertBetaInterfaceOptInMutationHookResult = ReturnType<typeof useRevertBetaInterfaceOptInMutation>;
export type RevertBetaInterfaceOptInMutationResult = Apollo.MutationResult<IRevertBetaInterfaceOptInMutation>;
export type RevertBetaInterfaceOptInMutationOptions = Apollo.BaseMutationOptions<IRevertBetaInterfaceOptInMutation, IRevertBetaInterfaceOptInMutationVariables>;
export const GetTaskListDocument = gql`
    query GetTaskList {
  me {
    id
    ...EmployeeTasks
    mobilityEventsAggregate(filterByStatus: confirmable) {
      count
    }
  }
}
    ${EmployeeTasksFragmentDoc}`;

/**
 * __useGetTaskListQuery__
 *
 * To run a query within a React component, call `useGetTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskListQuery(baseOptions?: Apollo.QueryHookOptions<IGetTaskListQuery, IGetTaskListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTaskListQuery, IGetTaskListQueryVariables>(GetTaskListDocument, options);
      }
export function useGetTaskListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTaskListQuery, IGetTaskListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTaskListQuery, IGetTaskListQueryVariables>(GetTaskListDocument, options);
        }
export function useGetTaskListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTaskListQuery, IGetTaskListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTaskListQuery, IGetTaskListQueryVariables>(GetTaskListDocument, options);
        }
export type GetTaskListQueryHookResult = ReturnType<typeof useGetTaskListQuery>;
export type GetTaskListLazyQueryHookResult = ReturnType<typeof useGetTaskListLazyQuery>;
export type GetTaskListSuspenseQueryHookResult = ReturnType<typeof useGetTaskListSuspenseQuery>;
export type GetTaskListQueryResult = Apollo.QueryResult<IGetTaskListQuery, IGetTaskListQueryVariables>;
export const SetAgreeTermsDocument = gql`
    mutation setAgreeTerms($ids: [ID!]!) {
  setAgreeTerms(input: {ids: $ids}) {
    result {
      success
      error
    }
  }
}
    `;

/**
 * __useSetAgreeTermsMutation__
 *
 * To run a mutation, you first call `useSetAgreeTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAgreeTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAgreeTermsMutation, { data, loading, error }] = useSetAgreeTermsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSetAgreeTermsMutation(baseOptions?: Apollo.MutationHookOptions<ISetAgreeTermsMutation, ISetAgreeTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISetAgreeTermsMutation, ISetAgreeTermsMutationVariables>(SetAgreeTermsDocument, options);
      }
export type SetAgreeTermsMutationHookResult = ReturnType<typeof useSetAgreeTermsMutation>;
export type SetAgreeTermsMutationResult = Apollo.MutationResult<ISetAgreeTermsMutation>;
export type SetAgreeTermsMutationOptions = Apollo.BaseMutationOptions<ISetAgreeTermsMutation, ISetAgreeTermsMutationVariables>;
export const GetAvailableFeaturesDocument = gql`
    query getAvailableFeatures($status: MobilityCardOrderStatusEnum) {
  me {
    id
    availableFeatures(status: $status) {
      id
      motivation
      urls {
        web
      }
      icon {
        name
      }
      name {
        humanized
        value
      }
      status {
        humanized
        value
      }
      completedAt
      agreedTermsAndConditions {
        requiresAgreement
        document {
          id
          pdfUrl
          humanizedKind {
            humanized
          }
        }
      }
      ... on RequestableFeature {
        requiresMotivation
        termsAndConditions {
          requiresAgreement
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAvailableFeaturesQuery__
 *
 * To run a query within a React component, call `useGetAvailableFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableFeaturesQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAvailableFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>(GetAvailableFeaturesDocument, options);
      }
export function useGetAvailableFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>(GetAvailableFeaturesDocument, options);
        }
export function useGetAvailableFeaturesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>(GetAvailableFeaturesDocument, options);
        }
export type GetAvailableFeaturesQueryHookResult = ReturnType<typeof useGetAvailableFeaturesQuery>;
export type GetAvailableFeaturesLazyQueryHookResult = ReturnType<typeof useGetAvailableFeaturesLazyQuery>;
export type GetAvailableFeaturesSuspenseQueryHookResult = ReturnType<typeof useGetAvailableFeaturesSuspenseQuery>;
export type GetAvailableFeaturesQueryResult = Apollo.QueryResult<IGetAvailableFeaturesQuery, IGetAvailableFeaturesQueryVariables>;
export const GetHelpUrlDocument = gql`
    query getHelpUrl {
  me {
    id
    organization {
      id
      customerService {
        helpUrl
      }
    }
  }
}
    `;

/**
 * __useGetHelpUrlQuery__
 *
 * To run a query within a React component, call `useGetHelpUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHelpUrlQuery(baseOptions?: Apollo.QueryHookOptions<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>(GetHelpUrlDocument, options);
      }
export function useGetHelpUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>(GetHelpUrlDocument, options);
        }
export function useGetHelpUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>(GetHelpUrlDocument, options);
        }
export type GetHelpUrlQueryHookResult = ReturnType<typeof useGetHelpUrlQuery>;
export type GetHelpUrlLazyQueryHookResult = ReturnType<typeof useGetHelpUrlLazyQuery>;
export type GetHelpUrlSuspenseQueryHookResult = ReturnType<typeof useGetHelpUrlSuspenseQuery>;
export type GetHelpUrlQueryResult = Apollo.QueryResult<IGetHelpUrlQuery, IGetHelpUrlQueryVariables>;
export const GetMinPasswordLengthDocument = gql`
    query getMinPasswordLength {
  me {
    id
    organization {
      id
      minPasswordLength
    }
  }
}
    `;

/**
 * __useGetMinPasswordLengthQuery__
 *
 * To run a query within a React component, call `useGetMinPasswordLengthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinPasswordLengthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinPasswordLengthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMinPasswordLengthQuery(baseOptions?: Apollo.QueryHookOptions<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>(GetMinPasswordLengthDocument, options);
      }
export function useGetMinPasswordLengthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>(GetMinPasswordLengthDocument, options);
        }
export function useGetMinPasswordLengthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>(GetMinPasswordLengthDocument, options);
        }
export type GetMinPasswordLengthQueryHookResult = ReturnType<typeof useGetMinPasswordLengthQuery>;
export type GetMinPasswordLengthLazyQueryHookResult = ReturnType<typeof useGetMinPasswordLengthLazyQuery>;
export type GetMinPasswordLengthSuspenseQueryHookResult = ReturnType<typeof useGetMinPasswordLengthSuspenseQuery>;
export type GetMinPasswordLengthQueryResult = Apollo.QueryResult<IGetMinPasswordLengthQuery, IGetMinPasswordLengthQueryVariables>;
export const GetPermissionPerNavigationItemDocument = gql`
    query getPermissionPerNavigationItem {
  me {
    id
    permissions {
      approvals
      manageMobilityCards
      manageWorkFromHome
      orderMobilityCards
      requestSharedVehicleFeature
      requestTaxiFeature
      sharedVehicleFeature
      taxiFeature
      viewDirectDebitInvoices
      viewSplitBillingInvoices
      canViewMileages
      canViewLeaseBikeFeature
      rentalCarFeature
      allowLoginByQrCode
      canRemoveReplacementManager
      canAssignReplacementManager
    }
  }
}
    `;

/**
 * __useGetPermissionPerNavigationItemQuery__
 *
 * To run a query within a React component, call `useGetPermissionPerNavigationItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionPerNavigationItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionPerNavigationItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionPerNavigationItemQuery(baseOptions?: Apollo.QueryHookOptions<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>(GetPermissionPerNavigationItemDocument, options);
      }
export function useGetPermissionPerNavigationItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>(GetPermissionPerNavigationItemDocument, options);
        }
export function useGetPermissionPerNavigationItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>(GetPermissionPerNavigationItemDocument, options);
        }
export type GetPermissionPerNavigationItemQueryHookResult = ReturnType<typeof useGetPermissionPerNavigationItemQuery>;
export type GetPermissionPerNavigationItemLazyQueryHookResult = ReturnType<typeof useGetPermissionPerNavigationItemLazyQuery>;
export type GetPermissionPerNavigationItemSuspenseQueryHookResult = ReturnType<typeof useGetPermissionPerNavigationItemSuspenseQuery>;
export type GetPermissionPerNavigationItemQueryResult = Apollo.QueryResult<IGetPermissionPerNavigationItemQuery, IGetPermissionPerNavigationItemQueryVariables>;
export const GetRequiredFieldsTransactionFormDocument = gql`
    query getRequiredFieldsTransactionForm {
  me {
    id
    requiredFields {
      descriptionForRideTransactions
      descriptionAllowed
      projectForTravelpurposes
      projectAllowed
      descriptionForRideByAmountPersonal
      descriptionForRideByAmountCommute
      descriptionForRideByAmountBusiness
      descriptionForRideByDistancePersonal
      descriptionForRideByDistanceCommute
      descriptionForRideByDistanceBusiness
    }
  }
}
    `;

/**
 * __useGetRequiredFieldsTransactionFormQuery__
 *
 * To run a query within a React component, call `useGetRequiredFieldsTransactionFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequiredFieldsTransactionFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequiredFieldsTransactionFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRequiredFieldsTransactionFormQuery(baseOptions?: Apollo.QueryHookOptions<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>(GetRequiredFieldsTransactionFormDocument, options);
      }
export function useGetRequiredFieldsTransactionFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>(GetRequiredFieldsTransactionFormDocument, options);
        }
export function useGetRequiredFieldsTransactionFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>(GetRequiredFieldsTransactionFormDocument, options);
        }
export type GetRequiredFieldsTransactionFormQueryHookResult = ReturnType<typeof useGetRequiredFieldsTransactionFormQuery>;
export type GetRequiredFieldsTransactionFormLazyQueryHookResult = ReturnType<typeof useGetRequiredFieldsTransactionFormLazyQuery>;
export type GetRequiredFieldsTransactionFormSuspenseQueryHookResult = ReturnType<typeof useGetRequiredFieldsTransactionFormSuspenseQuery>;
export type GetRequiredFieldsTransactionFormQueryResult = Apollo.QueryResult<IGetRequiredFieldsTransactionFormQuery, IGetRequiredFieldsTransactionFormQueryVariables>;
export const GetTermsAndConditionsDocument = gql`
    query getTermsAndConditions($kinds: [TermsAndConditionsDocumentKindEnum!]) {
  me {
    id
    termsAndConditions(kinds: $kinds) {
      agreed
      agreedAt
      document {
        id
        pdfUrl
        humanizedKind {
          humanized
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetTermsAndConditionsQuery__
 *
 * To run a query within a React component, call `useGetTermsAndConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsAndConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsAndConditionsQuery({
 *   variables: {
 *      kinds: // value for 'kinds'
 *   },
 * });
 */
export function useGetTermsAndConditionsQuery(baseOptions?: Apollo.QueryHookOptions<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>(GetTermsAndConditionsDocument, options);
      }
export function useGetTermsAndConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>(GetTermsAndConditionsDocument, options);
        }
export function useGetTermsAndConditionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>(GetTermsAndConditionsDocument, options);
        }
export type GetTermsAndConditionsQueryHookResult = ReturnType<typeof useGetTermsAndConditionsQuery>;
export type GetTermsAndConditionsLazyQueryHookResult = ReturnType<typeof useGetTermsAndConditionsLazyQuery>;
export type GetTermsAndConditionsSuspenseQueryHookResult = ReturnType<typeof useGetTermsAndConditionsSuspenseQuery>;
export type GetTermsAndConditionsQueryResult = Apollo.QueryResult<IGetTermsAndConditionsQuery, IGetTermsAndConditionsQueryVariables>;
export const GetFeaturePermissionsDocument = gql`
    query getFeaturePermissions {
  me {
    id
    permissions {
      sharedVehicleFeature
      taxiFeature
      rentalCarFeature
    }
  }
}
    `;

/**
 * __useGetFeaturePermissionsQuery__
 *
 * To run a query within a React component, call `useGetFeaturePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>(GetFeaturePermissionsDocument, options);
      }
export function useGetFeaturePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>(GetFeaturePermissionsDocument, options);
        }
export function useGetFeaturePermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>(GetFeaturePermissionsDocument, options);
        }
export type GetFeaturePermissionsQueryHookResult = ReturnType<typeof useGetFeaturePermissionsQuery>;
export type GetFeaturePermissionsLazyQueryHookResult = ReturnType<typeof useGetFeaturePermissionsLazyQuery>;
export type GetFeaturePermissionsSuspenseQueryHookResult = ReturnType<typeof useGetFeaturePermissionsSuspenseQuery>;
export type GetFeaturePermissionsQueryResult = Apollo.QueryResult<IGetFeaturePermissionsQuery, IGetFeaturePermissionsQueryVariables>;
export const GetTermsStatusDocument = gql`
    query getTermsStatus {
  me {
    id
    becameEmployee
    hasNotAgreedToUpdatedGeneralTerms
    hasNotAgreedToUpdatedPrivacyStatement
    hasAgreedToGeneralTerms
    hasAgreedToPrivacyStatement
  }
}
    `;

/**
 * __useGetTermsStatusQuery__
 *
 * To run a query within a React component, call `useGetTermsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTermsStatusQuery(baseOptions?: Apollo.QueryHookOptions<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>(GetTermsStatusDocument, options);
      }
export function useGetTermsStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>(GetTermsStatusDocument, options);
        }
export function useGetTermsStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>(GetTermsStatusDocument, options);
        }
export type GetTermsStatusQueryHookResult = ReturnType<typeof useGetTermsStatusQuery>;
export type GetTermsStatusLazyQueryHookResult = ReturnType<typeof useGetTermsStatusLazyQuery>;
export type GetTermsStatusSuspenseQueryHookResult = ReturnType<typeof useGetTermsStatusSuspenseQuery>;
export type GetTermsStatusQueryResult = Apollo.QueryResult<IGetTermsStatusQuery, IGetTermsStatusQueryVariables>;
export const AcceptMobilityEventsDocument = gql`
    mutation AcceptMobilityEvents($input: AcceptMobilityEventsInput!) {
  acceptMobilityEvents(input: $input) {
    result {
      success
      error
    }
    acceptedMobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useAcceptMobilityEventsMutation__
 *
 * To run a mutation, you first call `useAcceptMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMobilityEventsMutation, { data, loading, error }] = useAcceptMobilityEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<IAcceptMobilityEventsMutation, IAcceptMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IAcceptMobilityEventsMutation, IAcceptMobilityEventsMutationVariables>(AcceptMobilityEventsDocument, options);
      }
export type AcceptMobilityEventsMutationHookResult = ReturnType<typeof useAcceptMobilityEventsMutation>;
export type AcceptMobilityEventsMutationResult = Apollo.MutationResult<IAcceptMobilityEventsMutation>;
export type AcceptMobilityEventsMutationOptions = Apollo.BaseMutationOptions<IAcceptMobilityEventsMutation, IAcceptMobilityEventsMutationVariables>;
export const RejectMobilityEventsDocument = gql`
    mutation RejectMobilityEvents($input: RejectMobilityEventsInput!) {
  rejectMobilityEvents(input: $input) {
    result {
      success
      error
    }
    rejectedMobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useRejectMobilityEventsMutation__
 *
 * To run a mutation, you first call `useRejectMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMobilityEventsMutation, { data, loading, error }] = useRejectMobilityEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<IRejectMobilityEventsMutation, IRejectMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRejectMobilityEventsMutation, IRejectMobilityEventsMutationVariables>(RejectMobilityEventsDocument, options);
      }
export type RejectMobilityEventsMutationHookResult = ReturnType<typeof useRejectMobilityEventsMutation>;
export type RejectMobilityEventsMutationResult = Apollo.MutationResult<IRejectMobilityEventsMutation>;
export type RejectMobilityEventsMutationOptions = Apollo.BaseMutationOptions<IRejectMobilityEventsMutation, IRejectMobilityEventsMutationVariables>;
export const GetSubordinateDocument = gql`
    query getSubordinate($id: ID!) {
  me {
    id
    subordinate(id: $id) {
      id
      fullName
      numAcceptableMobilityEvents
      acceptableMobilityEvents {
        ...ShowFragment
      }
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useGetSubordinateQuery__
 *
 * To run a query within a React component, call `useGetSubordinateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubordinateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubordinateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubordinateQuery(baseOptions: Apollo.QueryHookOptions<IGetSubordinateQuery, IGetSubordinateQueryVariables> & ({ variables: IGetSubordinateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSubordinateQuery, IGetSubordinateQueryVariables>(GetSubordinateDocument, options);
      }
export function useGetSubordinateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSubordinateQuery, IGetSubordinateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSubordinateQuery, IGetSubordinateQueryVariables>(GetSubordinateDocument, options);
        }
export function useGetSubordinateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSubordinateQuery, IGetSubordinateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetSubordinateQuery, IGetSubordinateQueryVariables>(GetSubordinateDocument, options);
        }
export type GetSubordinateQueryHookResult = ReturnType<typeof useGetSubordinateQuery>;
export type GetSubordinateLazyQueryHookResult = ReturnType<typeof useGetSubordinateLazyQuery>;
export type GetSubordinateSuspenseQueryHookResult = ReturnType<typeof useGetSubordinateSuspenseQuery>;
export type GetSubordinateQueryResult = Apollo.QueryResult<IGetSubordinateQuery, IGetSubordinateQueryVariables>;
export const GetSubordinatesDocument = gql`
    query getSubordinates {
  me {
    id
    subordinates {
      id
      fullName
      numAcceptableMobilityEvents
    }
  }
}
    `;

/**
 * __useGetSubordinatesQuery__
 *
 * To run a query within a React component, call `useGetSubordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubordinatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubordinatesQuery(baseOptions?: Apollo.QueryHookOptions<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>(GetSubordinatesDocument, options);
      }
export function useGetSubordinatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>(GetSubordinatesDocument, options);
        }
export function useGetSubordinatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>(GetSubordinatesDocument, options);
        }
export type GetSubordinatesQueryHookResult = ReturnType<typeof useGetSubordinatesQuery>;
export type GetSubordinatesLazyQueryHookResult = ReturnType<typeof useGetSubordinatesLazyQuery>;
export type GetSubordinatesSuspenseQueryHookResult = ReturnType<typeof useGetSubordinatesSuspenseQuery>;
export type GetSubordinatesQueryResult = Apollo.QueryResult<IGetSubordinatesQuery, IGetSubordinatesQueryVariables>;
export const RevokeDirectDebitMandateDocument = gql`
    mutation revokeDirectDebitMandate($id: ID!) {
  directDebitMandates {
    revoke(input: {id: $id}) {
      result {
        error
        success
      }
      directDebitMandate {
        ...DirectDebitMandate
      }
    }
  }
}
    ${DirectDebitMandateFragmentDoc}`;

/**
 * __useRevokeDirectDebitMandateMutation__
 *
 * To run a mutation, you first call `useRevokeDirectDebitMandateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeDirectDebitMandateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeDirectDebitMandateMutation, { data, loading, error }] = useRevokeDirectDebitMandateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeDirectDebitMandateMutation(baseOptions?: Apollo.MutationHookOptions<IRevokeDirectDebitMandateMutation, IRevokeDirectDebitMandateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRevokeDirectDebitMandateMutation, IRevokeDirectDebitMandateMutationVariables>(RevokeDirectDebitMandateDocument, options);
      }
export type RevokeDirectDebitMandateMutationHookResult = ReturnType<typeof useRevokeDirectDebitMandateMutation>;
export type RevokeDirectDebitMandateMutationResult = Apollo.MutationResult<IRevokeDirectDebitMandateMutation>;
export type RevokeDirectDebitMandateMutationOptions = Apollo.BaseMutationOptions<IRevokeDirectDebitMandateMutation, IRevokeDirectDebitMandateMutationVariables>;
export const RevokeSplitBillingContractsDocument = gql`
    mutation revokeSplitBillingContracts($splitBillingContractIds: [ID!]!, $reason: String) {
  splitBillingContracts {
    revoke(
      input: {splitBillingContractIds: $splitBillingContractIds, reason: $reason}
    ) {
      result {
        error
        success
      }
      revokedContracts {
        ...SplitBillingContract
      }
    }
  }
}
    ${SplitBillingContractFragmentDoc}`;

/**
 * __useRevokeSplitBillingContractsMutation__
 *
 * To run a mutation, you first call `useRevokeSplitBillingContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSplitBillingContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSplitBillingContractsMutation, { data, loading, error }] = useRevokeSplitBillingContractsMutation({
 *   variables: {
 *      splitBillingContractIds: // value for 'splitBillingContractIds'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useRevokeSplitBillingContractsMutation(baseOptions?: Apollo.MutationHookOptions<IRevokeSplitBillingContractsMutation, IRevokeSplitBillingContractsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRevokeSplitBillingContractsMutation, IRevokeSplitBillingContractsMutationVariables>(RevokeSplitBillingContractsDocument, options);
      }
export type RevokeSplitBillingContractsMutationHookResult = ReturnType<typeof useRevokeSplitBillingContractsMutation>;
export type RevokeSplitBillingContractsMutationResult = Apollo.MutationResult<IRevokeSplitBillingContractsMutation>;
export type RevokeSplitBillingContractsMutationOptions = Apollo.BaseMutationOptions<IRevokeSplitBillingContractsMutation, IRevokeSplitBillingContractsMutationVariables>;
export const CreateDirectDebitMandateDocument = gql`
    mutation createDirectDebitMandate {
  directDebitMandates {
    create(input: {}) {
      result {
        error
        success
      }
      directDebitMandate {
        ...DirectDebitMandate
      }
    }
  }
}
    ${DirectDebitMandateFragmentDoc}`;

/**
 * __useCreateDirectDebitMandateMutation__
 *
 * To run a mutation, you first call `useCreateDirectDebitMandateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectDebitMandateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectDebitMandateMutation, { data, loading, error }] = useCreateDirectDebitMandateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDirectDebitMandateMutation(baseOptions?: Apollo.MutationHookOptions<ICreateDirectDebitMandateMutation, ICreateDirectDebitMandateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateDirectDebitMandateMutation, ICreateDirectDebitMandateMutationVariables>(CreateDirectDebitMandateDocument, options);
      }
export type CreateDirectDebitMandateMutationHookResult = ReturnType<typeof useCreateDirectDebitMandateMutation>;
export type CreateDirectDebitMandateMutationResult = Apollo.MutationResult<ICreateDirectDebitMandateMutation>;
export type CreateDirectDebitMandateMutationOptions = Apollo.BaseMutationOptions<ICreateDirectDebitMandateMutation, ICreateDirectDebitMandateMutationVariables>;
export const GetDirectDebitMandatePermissionDocument = gql`
    query getDirectDebitMandatePermission {
  me {
    id
    permissions {
      requestDirectDebitMandate
    }
  }
}
    `;

/**
 * __useGetDirectDebitMandatePermissionQuery__
 *
 * To run a query within a React component, call `useGetDirectDebitMandatePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDirectDebitMandatePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDirectDebitMandatePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDirectDebitMandatePermissionQuery(baseOptions?: Apollo.QueryHookOptions<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>(GetDirectDebitMandatePermissionDocument, options);
      }
export function useGetDirectDebitMandatePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>(GetDirectDebitMandatePermissionDocument, options);
        }
export function useGetDirectDebitMandatePermissionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>(GetDirectDebitMandatePermissionDocument, options);
        }
export type GetDirectDebitMandatePermissionQueryHookResult = ReturnType<typeof useGetDirectDebitMandatePermissionQuery>;
export type GetDirectDebitMandatePermissionLazyQueryHookResult = ReturnType<typeof useGetDirectDebitMandatePermissionLazyQuery>;
export type GetDirectDebitMandatePermissionSuspenseQueryHookResult = ReturnType<typeof useGetDirectDebitMandatePermissionSuspenseQuery>;
export type GetDirectDebitMandatePermissionQueryResult = Apollo.QueryResult<IGetDirectDebitMandatePermissionQuery, IGetDirectDebitMandatePermissionQueryVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices($isSplitBilling: Boolean!) {
  me {
    id
    eCurringInvoices: directDebitInvoices @skip(if: $isSplitBilling) {
      ...DirectDebitInvoice
    }
    twikeyInvoices: splitBillingInvoices @include(if: $isSplitBilling) {
      ...SplitBillingInvoice
    }
  }
}
    ${DirectDebitInvoiceFragmentDoc}
${SplitBillingInvoiceFragmentDoc}`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      isSplitBilling: // value for 'isSplitBilling'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<IGetInvoicesQuery, IGetInvoicesQueryVariables> & ({ variables: IGetInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetInvoicesQuery, IGetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetInvoicesQuery, IGetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetInvoicesQuery, IGetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export function useGetInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetInvoicesQuery, IGetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetInvoicesQuery, IGetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesSuspenseQueryHookResult = ReturnType<typeof useGetInvoicesSuspenseQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<IGetInvoicesQuery, IGetInvoicesQueryVariables>;
export const GetMandatesDocument = gql`
    query getMandates($isSplitBilling: Boolean!) {
  me {
    id
    eCurringMandates: directDebitMandates @skip(if: $isSplitBilling) {
      ...DirectDebitMandate
    }
    twikeyContracts: splitBillingContracts @include(if: $isSplitBilling) {
      ...SplitBillingContract
    }
  }
}
    ${DirectDebitMandateFragmentDoc}
${SplitBillingContractFragmentDoc}`;

/**
 * __useGetMandatesQuery__
 *
 * To run a query within a React component, call `useGetMandatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMandatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMandatesQuery({
 *   variables: {
 *      isSplitBilling: // value for 'isSplitBilling'
 *   },
 * });
 */
export function useGetMandatesQuery(baseOptions: Apollo.QueryHookOptions<IGetMandatesQuery, IGetMandatesQueryVariables> & ({ variables: IGetMandatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMandatesQuery, IGetMandatesQueryVariables>(GetMandatesDocument, options);
      }
export function useGetMandatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMandatesQuery, IGetMandatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMandatesQuery, IGetMandatesQueryVariables>(GetMandatesDocument, options);
        }
export function useGetMandatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMandatesQuery, IGetMandatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMandatesQuery, IGetMandatesQueryVariables>(GetMandatesDocument, options);
        }
export type GetMandatesQueryHookResult = ReturnType<typeof useGetMandatesQuery>;
export type GetMandatesLazyQueryHookResult = ReturnType<typeof useGetMandatesLazyQuery>;
export type GetMandatesSuspenseQueryHookResult = ReturnType<typeof useGetMandatesSuspenseQuery>;
export type GetMandatesQueryResult = Apollo.QueryResult<IGetMandatesQuery, IGetMandatesQueryVariables>;
export const GetAccessUrlDocument = gql`
    mutation getAccessUrl($input: GetAccessUrlInput!) {
  splitBillingContracts {
    getAccessUrl(input: $input) {
      accessUrl
      contract {
        splitBillingContractId
      }
      result {
        success
        error
      }
    }
  }
}
    `;

/**
 * __useGetAccessUrlMutation__
 *
 * To run a mutation, you first call `useGetAccessUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAccessUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAccessUrlMutation, { data, loading, error }] = useGetAccessUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccessUrlMutation(baseOptions?: Apollo.MutationHookOptions<IGetAccessUrlMutation, IGetAccessUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IGetAccessUrlMutation, IGetAccessUrlMutationVariables>(GetAccessUrlDocument, options);
      }
export type GetAccessUrlMutationHookResult = ReturnType<typeof useGetAccessUrlMutation>;
export type GetAccessUrlMutationResult = Apollo.MutationResult<IGetAccessUrlMutation>;
export type GetAccessUrlMutationOptions = Apollo.BaseMutationOptions<IGetAccessUrlMutation, IGetAccessUrlMutationVariables>;
export const GetSplitBillingContractDocument = gql`
    query getSplitBillingContract($contractId: String!) {
  me {
    id
    splitBillingContract(contractId: $contractId) {
      id
      splitBillingContractId
      status {
        humanized
        value
      }
      accessUrl
      statusReason
    }
  }
}
    `;

/**
 * __useGetSplitBillingContractQuery__
 *
 * To run a query within a React component, call `useGetSplitBillingContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSplitBillingContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSplitBillingContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetSplitBillingContractQuery(baseOptions: Apollo.QueryHookOptions<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables> & ({ variables: IGetSplitBillingContractQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables>(GetSplitBillingContractDocument, options);
      }
export function useGetSplitBillingContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables>(GetSplitBillingContractDocument, options);
        }
export function useGetSplitBillingContractSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables>(GetSplitBillingContractDocument, options);
        }
export type GetSplitBillingContractQueryHookResult = ReturnType<typeof useGetSplitBillingContractQuery>;
export type GetSplitBillingContractLazyQueryHookResult = ReturnType<typeof useGetSplitBillingContractLazyQuery>;
export type GetSplitBillingContractSuspenseQueryHookResult = ReturnType<typeof useGetSplitBillingContractSuspenseQuery>;
export type GetSplitBillingContractQueryResult = Apollo.QueryResult<IGetSplitBillingContractQuery, IGetSplitBillingContractQueryVariables>;
export const GetBillingDataDocument = gql`
    query getBillingData($isSplitBilling: Boolean!) {
  me {
    id
    financialConsequences @skip(if: $isSplitBilling) {
      tentativeSettlement {
        totalGrossAndNetCharge {
          valueInCents
        }
        totalGrossAndNetReimbursement {
          valueInCents
        }
      }
    }
    eCurringInvoices: directDebitInvoices @skip(if: $isSplitBilling) {
      ...DirectDebitInvoice
    }
    eCurringMandates: directDebitMandates @skip(if: $isSplitBilling) {
      ...DirectDebitMandate
    }
    eMandateRequired @skip(if: $isSplitBilling)
    permissions {
      requestSplitBillingContract @include(if: $isSplitBilling)
      requestDirectDebitMandate @skip(if: $isSplitBilling)
    }
    twikeyInvoices: splitBillingInvoices @include(if: $isSplitBilling) {
      ...SplitBillingInvoice
    }
    twikeyContracts: splitBillingContracts @include(if: $isSplitBilling) {
      ...SplitBillingContract
    }
  }
}
    ${DirectDebitInvoiceFragmentDoc}
${DirectDebitMandateFragmentDoc}
${SplitBillingInvoiceFragmentDoc}
${SplitBillingContractFragmentDoc}`;

/**
 * __useGetBillingDataQuery__
 *
 * To run a query within a React component, call `useGetBillingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingDataQuery({
 *   variables: {
 *      isSplitBilling: // value for 'isSplitBilling'
 *   },
 * });
 */
export function useGetBillingDataQuery(baseOptions: Apollo.QueryHookOptions<IGetBillingDataQuery, IGetBillingDataQueryVariables> & ({ variables: IGetBillingDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetBillingDataQuery, IGetBillingDataQueryVariables>(GetBillingDataDocument, options);
      }
export function useGetBillingDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetBillingDataQuery, IGetBillingDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetBillingDataQuery, IGetBillingDataQueryVariables>(GetBillingDataDocument, options);
        }
export function useGetBillingDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetBillingDataQuery, IGetBillingDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetBillingDataQuery, IGetBillingDataQueryVariables>(GetBillingDataDocument, options);
        }
export type GetBillingDataQueryHookResult = ReturnType<typeof useGetBillingDataQuery>;
export type GetBillingDataLazyQueryHookResult = ReturnType<typeof useGetBillingDataLazyQuery>;
export type GetBillingDataSuspenseQueryHookResult = ReturnType<typeof useGetBillingDataSuspenseQuery>;
export type GetBillingDataQueryResult = Apollo.QueryResult<IGetBillingDataQuery, IGetBillingDataQueryVariables>;
export const CarrierCreateDocument = gql`
    mutation carrierCreate($input: MSACreateCarrierRequestBodyRequestInput!) {
  MobilityServices {
    carrierCreate(input: $input) {
      ...MSACarrier
    }
  }
}
    ${MsaCarrierFragmentDoc}`;

/**
 * __useCarrierCreateMutation__
 *
 * To run a mutation, you first call `useCarrierCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarrierCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carrierCreateMutation, { data, loading, error }] = useCarrierCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarrierCreateMutation(baseOptions?: Apollo.MutationHookOptions<ICarrierCreateMutation, ICarrierCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICarrierCreateMutation, ICarrierCreateMutationVariables>(CarrierCreateDocument, options);
      }
export type CarrierCreateMutationHookResult = ReturnType<typeof useCarrierCreateMutation>;
export type CarrierCreateMutationResult = Apollo.MutationResult<ICarrierCreateMutation>;
export type CarrierCreateMutationOptions = Apollo.BaseMutationOptions<ICarrierCreateMutation, ICarrierCreateMutationVariables>;
export const ProductGroupGetDocument = gql`
    query productGroupGet($productGroupId: UUID!) {
  MobilityServices {
    productGroupGet(productGroupId: $productGroupId) {
      carriers {
        ...MSACarrier
      }
    }
  }
}
    ${MsaCarrierFragmentDoc}`;

/**
 * __useProductGroupGetQuery__
 *
 * To run a query within a React component, call `useProductGroupGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupGetQuery({
 *   variables: {
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useProductGroupGetQuery(baseOptions: Apollo.QueryHookOptions<IProductGroupGetQuery, IProductGroupGetQueryVariables> & ({ variables: IProductGroupGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IProductGroupGetQuery, IProductGroupGetQueryVariables>(ProductGroupGetDocument, options);
      }
export function useProductGroupGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IProductGroupGetQuery, IProductGroupGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IProductGroupGetQuery, IProductGroupGetQueryVariables>(ProductGroupGetDocument, options);
        }
export function useProductGroupGetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IProductGroupGetQuery, IProductGroupGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IProductGroupGetQuery, IProductGroupGetQueryVariables>(ProductGroupGetDocument, options);
        }
export type ProductGroupGetQueryHookResult = ReturnType<typeof useProductGroupGetQuery>;
export type ProductGroupGetLazyQueryHookResult = ReturnType<typeof useProductGroupGetLazyQuery>;
export type ProductGroupGetSuspenseQueryHookResult = ReturnType<typeof useProductGroupGetSuspenseQuery>;
export type ProductGroupGetQueryResult = Apollo.QueryResult<IProductGroupGetQuery, IProductGroupGetQueryVariables>;
export const GetYellowbricksUrlDocument = gql`
    query getYellowbricksUrl {
  externalUrls {
    yellowbrickForgotPasswordUrl {
      title
      url
    }
  }
}
    `;

/**
 * __useGetYellowbricksUrlQuery__
 *
 * To run a query within a React component, call `useGetYellowbricksUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYellowbricksUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYellowbricksUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetYellowbricksUrlQuery(baseOptions?: Apollo.QueryHookOptions<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>(GetYellowbricksUrlDocument, options);
      }
export function useGetYellowbricksUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>(GetYellowbricksUrlDocument, options);
        }
export function useGetYellowbricksUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>(GetYellowbricksUrlDocument, options);
        }
export type GetYellowbricksUrlQueryHookResult = ReturnType<typeof useGetYellowbricksUrlQuery>;
export type GetYellowbricksUrlLazyQueryHookResult = ReturnType<typeof useGetYellowbricksUrlLazyQuery>;
export type GetYellowbricksUrlSuspenseQueryHookResult = ReturnType<typeof useGetYellowbricksUrlSuspenseQuery>;
export type GetYellowbricksUrlQueryResult = Apollo.QueryResult<IGetYellowbricksUrlQuery, IGetYellowbricksUrlQueryVariables>;
export const SetMobilityCardProfileDocument = gql`
    mutation setMobilityCardProfile($mobilityCardOrderId: ID!, $homeAddress: EmployeeAddressMutation!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $infix: String, $dateOfBirth: Date!, $initials: String!) {
  mobilityCardOrders {
    setProfile(
      input: {mobilityCardOrderId: $mobilityCardOrderId, profile: {firstName: $firstName, lastName: $lastName, infix: $infix, dateOfBirth: $dateOfBirth, initials: $initials, phoneNumber: $phoneNumber, homeAddress: $homeAddress}}
    ) {
      result {
        error
        success
      }
      profile {
        employeeNumber
        firstName
        lastName
        infix
        dateOfBirth
        initials
        phoneNumber
        homeAddress {
          city
          employeeCountryIsoCode {
            humanized
            value
          }
          street
          streetNumber
          streetNumberSuffix
          zipcode
        }
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
    }
  }
}
    `;

/**
 * __useSetMobilityCardProfileMutation__
 *
 * To run a mutation, you first call `useSetMobilityCardProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMobilityCardProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMobilityCardProfileMutation, { data, loading, error }] = useSetMobilityCardProfileMutation({
 *   variables: {
 *      mobilityCardOrderId: // value for 'mobilityCardOrderId'
 *      homeAddress: // value for 'homeAddress'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      infix: // value for 'infix'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      initials: // value for 'initials'
 *   },
 * });
 */
export function useSetMobilityCardProfileMutation(baseOptions?: Apollo.MutationHookOptions<ISetMobilityCardProfileMutation, ISetMobilityCardProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISetMobilityCardProfileMutation, ISetMobilityCardProfileMutationVariables>(SetMobilityCardProfileDocument, options);
      }
export type SetMobilityCardProfileMutationHookResult = ReturnType<typeof useSetMobilityCardProfileMutation>;
export type SetMobilityCardProfileMutationResult = Apollo.MutationResult<ISetMobilityCardProfileMutation>;
export type SetMobilityCardProfileMutationOptions = Apollo.BaseMutationOptions<ISetMobilityCardProfileMutation, ISetMobilityCardProfileMutationVariables>;
export const GetStationsDocument = gql`
    query getStations {
  stations {
    humanized
    value
  }
}
    `;

/**
 * __useGetStationsQuery__
 *
 * To run a query within a React component, call `useGetStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStationsQuery(baseOptions?: Apollo.QueryHookOptions<IGetStationsQuery, IGetStationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetStationsQuery, IGetStationsQueryVariables>(GetStationsDocument, options);
      }
export function useGetStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetStationsQuery, IGetStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetStationsQuery, IGetStationsQueryVariables>(GetStationsDocument, options);
        }
export function useGetStationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetStationsQuery, IGetStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetStationsQuery, IGetStationsQueryVariables>(GetStationsDocument, options);
        }
export type GetStationsQueryHookResult = ReturnType<typeof useGetStationsQuery>;
export type GetStationsLazyQueryHookResult = ReturnType<typeof useGetStationsLazyQuery>;
export type GetStationsSuspenseQueryHookResult = ReturnType<typeof useGetStationsSuspenseQuery>;
export type GetStationsQueryResult = Apollo.QueryResult<IGetStationsQuery, IGetStationsQueryVariables>;
export const SetOptionsDocument = gql`
    mutation setOptions($id: ID!, $options: OptionsMutation!) {
  mobilityCardOrders {
    setOptions(input: {mobilityCardOrderId: $id, options: $options}) {
      result {
        success
        error
      }
      validationErrors {
        messages
        attribute
        fullMessages
      }
      mobilityCardOrder {
        ...MobilityCardOrder
      }
    }
  }
}
    ${MobilityCardOrderFragmentDoc}`;

/**
 * __useSetOptionsMutation__
 *
 * To run a mutation, you first call `useSetOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOptionsMutation, { data, loading, error }] = useSetOptionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSetOptionsMutation(baseOptions?: Apollo.MutationHookOptions<ISetOptionsMutation, ISetOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISetOptionsMutation, ISetOptionsMutationVariables>(SetOptionsDocument, options);
      }
export type SetOptionsMutationHookResult = ReturnType<typeof useSetOptionsMutation>;
export type SetOptionsMutationResult = Apollo.MutationResult<ISetOptionsMutation>;
export type SetOptionsMutationOptions = Apollo.BaseMutationOptions<ISetOptionsMutation, ISetOptionsMutationVariables>;
export const UploadAvatarDocument = gql`
    mutation uploadAvatar($id: ID!, $photo: Photo!) {
  mobilityCardOrders {
    setPhoto(input: {mobilityCardOrderId: $id, photo: $photo}) {
      result {
        error
        success
      }
      mobilityCardOrder {
        ...MobilityCardOrder
      }
    }
  }
}
    ${MobilityCardOrderFragmentDoc}`;

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      id: // value for 'id'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUploadAvatarMutation(baseOptions?: Apollo.MutationHookOptions<IUploadAvatarMutation, IUploadAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUploadAvatarMutation, IUploadAvatarMutationVariables>(UploadAvatarDocument, options);
      }
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>;
export type UploadAvatarMutationResult = Apollo.MutationResult<IUploadAvatarMutation>;
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<IUploadAvatarMutation, IUploadAvatarMutationVariables>;
export const ConfirmMobilityCardOrderDocument = gql`
    mutation confirmMobilityCardOrder($mobilityCardOrderId: ID!) {
  mobilityCardOrders {
    confirm(input: {mobilityCardOrderId: $mobilityCardOrderId}) {
      mobilityCardOrder {
        ...MobilityCardOrder
      }
      result {
        success
        error
      }
    }
  }
}
    ${MobilityCardOrderFragmentDoc}`;

/**
 * __useConfirmMobilityCardOrderMutation__
 *
 * To run a mutation, you first call `useConfirmMobilityCardOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMobilityCardOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMobilityCardOrderMutation, { data, loading, error }] = useConfirmMobilityCardOrderMutation({
 *   variables: {
 *      mobilityCardOrderId: // value for 'mobilityCardOrderId'
 *   },
 * });
 */
export function useConfirmMobilityCardOrderMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmMobilityCardOrderMutation, IConfirmMobilityCardOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmMobilityCardOrderMutation, IConfirmMobilityCardOrderMutationVariables>(ConfirmMobilityCardOrderDocument, options);
      }
export type ConfirmMobilityCardOrderMutationHookResult = ReturnType<typeof useConfirmMobilityCardOrderMutation>;
export type ConfirmMobilityCardOrderMutationResult = Apollo.MutationResult<IConfirmMobilityCardOrderMutation>;
export type ConfirmMobilityCardOrderMutationOptions = Apollo.BaseMutationOptions<IConfirmMobilityCardOrderMutation, IConfirmMobilityCardOrderMutationVariables>;
export const SetTermsDocument = gql`
    mutation setTerms($documentIds: [ID!]!, $mobilityCardOrderId: ID!) {
  mobilityCardOrders {
    setTerms(
      input: {termsAndConditionsDocumentIds: $documentIds, mobilityCardOrderId: $mobilityCardOrderId}
    ) {
      mobilityCardOrder {
        ...MobilityCardOrder
      }
      result {
        error
        success
      }
    }
  }
}
    ${MobilityCardOrderFragmentDoc}`;

/**
 * __useSetTermsMutation__
 *
 * To run a mutation, you first call `useSetTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTermsMutation, { data, loading, error }] = useSetTermsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      mobilityCardOrderId: // value for 'mobilityCardOrderId'
 *   },
 * });
 */
export function useSetTermsMutation(baseOptions?: Apollo.MutationHookOptions<ISetTermsMutation, ISetTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISetTermsMutation, ISetTermsMutationVariables>(SetTermsDocument, options);
      }
export type SetTermsMutationHookResult = ReturnType<typeof useSetTermsMutation>;
export type SetTermsMutationResult = Apollo.MutationResult<ISetTermsMutation>;
export type SetTermsMutationOptions = Apollo.BaseMutationOptions<ISetTermsMutation, ISetTermsMutationVariables>;
export const GetMobilityCardOrderDocument = gql`
    query getMobilityCardOrder($id: ID!) {
  me {
    id
    mobilityCardOrder(id: $id) {
      ...MobilityCardOrder
    }
  }
}
    ${MobilityCardOrderFragmentDoc}`;

/**
 * __useGetMobilityCardOrderQuery__
 *
 * To run a query within a React component, call `useGetMobilityCardOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityCardOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityCardOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMobilityCardOrderQuery(baseOptions: Apollo.QueryHookOptions<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables> & ({ variables: IGetMobilityCardOrderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables>(GetMobilityCardOrderDocument, options);
      }
export function useGetMobilityCardOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables>(GetMobilityCardOrderDocument, options);
        }
export function useGetMobilityCardOrderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables>(GetMobilityCardOrderDocument, options);
        }
export type GetMobilityCardOrderQueryHookResult = ReturnType<typeof useGetMobilityCardOrderQuery>;
export type GetMobilityCardOrderLazyQueryHookResult = ReturnType<typeof useGetMobilityCardOrderLazyQuery>;
export type GetMobilityCardOrderSuspenseQueryHookResult = ReturnType<typeof useGetMobilityCardOrderSuspenseQuery>;
export type GetMobilityCardOrderQueryResult = Apollo.QueryResult<IGetMobilityCardOrderQuery, IGetMobilityCardOrderQueryVariables>;
export const ActivateCardDocument = gql`
    mutation activateCard($id: ID!) {
  activatePersonalMeansOfRegistration(input: {id: $id}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useActivateCardMutation__
 *
 * To run a mutation, you first call `useActivateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardMutation, { data, loading, error }] = useActivateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateCardMutation(baseOptions?: Apollo.MutationHookOptions<IActivateCardMutation, IActivateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IActivateCardMutation, IActivateCardMutationVariables>(ActivateCardDocument, options);
      }
export type ActivateCardMutationHookResult = ReturnType<typeof useActivateCardMutation>;
export type ActivateCardMutationResult = Apollo.MutationResult<IActivateCardMutation>;
export type ActivateCardMutationOptions = Apollo.BaseMutationOptions<IActivateCardMutation, IActivateCardMutationVariables>;
export const TerminateCardDocument = gql`
    mutation terminateCard($id: ID!) {
  terminatePersonalMeansOfRegistration(input: {id: $id}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useTerminateCardMutation__
 *
 * To run a mutation, you first call `useTerminateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateCardMutation, { data, loading, error }] = useTerminateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTerminateCardMutation(baseOptions?: Apollo.MutationHookOptions<ITerminateCardMutation, ITerminateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITerminateCardMutation, ITerminateCardMutationVariables>(TerminateCardDocument, options);
      }
export type TerminateCardMutationHookResult = ReturnType<typeof useTerminateCardMutation>;
export type TerminateCardMutationResult = Apollo.MutationResult<ITerminateCardMutation>;
export type TerminateCardMutationOptions = Apollo.BaseMutationOptions<ITerminateCardMutation, ITerminateCardMutationVariables>;
export const ReuploadPhotoDocument = gql`
    mutation reuploadPhoto($input: ReuploadPhotoInput!) {
  reuploadPhoto(input: $input) {
    result {
      success
      error
    }
    profile {
      avatar
      employeeNumber
    }
  }
}
    `;

/**
 * __useReuploadPhotoMutation__
 *
 * To run a mutation, you first call `useReuploadPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReuploadPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reuploadPhotoMutation, { data, loading, error }] = useReuploadPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReuploadPhotoMutation(baseOptions?: Apollo.MutationHookOptions<IReuploadPhotoMutation, IReuploadPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IReuploadPhotoMutation, IReuploadPhotoMutationVariables>(ReuploadPhotoDocument, options);
      }
export type ReuploadPhotoMutationHookResult = ReturnType<typeof useReuploadPhotoMutation>;
export type ReuploadPhotoMutationResult = Apollo.MutationResult<IReuploadPhotoMutation>;
export type ReuploadPhotoMutationOptions = Apollo.BaseMutationOptions<IReuploadPhotoMutation, IReuploadPhotoMutationVariables>;
export const GetCardInformationDocument = gql`
    query getCardInformation($id: ID!) {
  me {
    id
    personalMeansOfRegistration(id: $id) {
      ...PersonalMeansOfRegistration
    }
  }
}
    ${PersonalMeansOfRegistrationFragmentDoc}`;

/**
 * __useGetCardInformationQuery__
 *
 * To run a query within a React component, call `useGetCardInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardInformationQuery(baseOptions: Apollo.QueryHookOptions<IGetCardInformationQuery, IGetCardInformationQueryVariables> & ({ variables: IGetCardInformationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCardInformationQuery, IGetCardInformationQueryVariables>(GetCardInformationDocument, options);
      }
export function useGetCardInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCardInformationQuery, IGetCardInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCardInformationQuery, IGetCardInformationQueryVariables>(GetCardInformationDocument, options);
        }
export function useGetCardInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCardInformationQuery, IGetCardInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetCardInformationQuery, IGetCardInformationQueryVariables>(GetCardInformationDocument, options);
        }
export type GetCardInformationQueryHookResult = ReturnType<typeof useGetCardInformationQuery>;
export type GetCardInformationLazyQueryHookResult = ReturnType<typeof useGetCardInformationLazyQuery>;
export type GetCardInformationSuspenseQueryHookResult = ReturnType<typeof useGetCardInformationSuspenseQuery>;
export type GetCardInformationQueryResult = Apollo.QueryResult<IGetCardInformationQuery, IGetCardInformationQueryVariables>;
export const ReportCardDocument = gql`
    mutation reportCard($id: ID!, $needReplacement: Boolean) {
  reportPersonalMeansOfRegistration(
    input: {id: $id, reportType: LOST_OR_STOLEN, needReplacement: $needReplacement}
  ) {
    result {
      error
      success
    }
    personalMeansOfRegistration {
      ...PersonalMeansOfRegistration
    }
  }
}
    ${PersonalMeansOfRegistrationFragmentDoc}`;

/**
 * __useReportCardMutation__
 *
 * To run a mutation, you first call `useReportCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCardMutation, { data, loading, error }] = useReportCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      needReplacement: // value for 'needReplacement'
 *   },
 * });
 */
export function useReportCardMutation(baseOptions?: Apollo.MutationHookOptions<IReportCardMutation, IReportCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IReportCardMutation, IReportCardMutationVariables>(ReportCardDocument, options);
      }
export type ReportCardMutationHookResult = ReturnType<typeof useReportCardMutation>;
export type ReportCardMutationResult = Apollo.MutationResult<IReportCardMutation>;
export type ReportCardMutationOptions = Apollo.BaseMutationOptions<IReportCardMutation, IReportCardMutationVariables>;
export const ActivateEnecoDongleDocument = gql`
    mutation activateEnecoDongle($input: ActivateEnecoDongleInput!) {
  activateEnecoDongle(input: $input) {
    enecoDongle {
      id
      activatable
      mobideskOwnershipToken
      motivation
      icon {
        name
      }
      name {
        humanized
        value
      }
      status {
        humanized
        value
      }
      completedAt
      urls {
        web
      }
      agreedTermsAndConditions {
        requiresAgreement
        document {
          id
          pdfUrl
          humanizedKind {
            humanized
          }
        }
      }
    }
    result {
      success
      error
    }
  }
}
    `;

/**
 * __useActivateEnecoDongleMutation__
 *
 * To run a mutation, you first call `useActivateEnecoDongleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateEnecoDongleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateEnecoDongleMutation, { data, loading, error }] = useActivateEnecoDongleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateEnecoDongleMutation(baseOptions?: Apollo.MutationHookOptions<IActivateEnecoDongleMutation, IActivateEnecoDongleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IActivateEnecoDongleMutation, IActivateEnecoDongleMutationVariables>(ActivateEnecoDongleDocument, options);
      }
export type ActivateEnecoDongleMutationHookResult = ReturnType<typeof useActivateEnecoDongleMutation>;
export type ActivateEnecoDongleMutationResult = Apollo.MutationResult<IActivateEnecoDongleMutation>;
export type ActivateEnecoDongleMutationOptions = Apollo.BaseMutationOptions<IActivateEnecoDongleMutation, IActivateEnecoDongleMutationVariables>;
export const GetAvailableFeatureDocument = gql`
    query getAvailableFeature($id: ID!) {
  me {
    id
    availableFeature(id: $id) {
      id
      mobideskOwnershipToken
      motivation
      description
      icon {
        name
      }
      name {
        humanized
        value
      }
      status {
        humanized
        value
      }
      completedAt
      urls {
        web
      }
      agreedTermsAndConditions {
        requiresAgreement
        agreed
        document {
          id
          pdfUrl
          humanizedKind {
            humanized
          }
        }
      }
      ... on RequestableFeature {
        requiresMotivation
        termsAndConditions {
          requiresAgreement
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
      ... on EnecoDongle {
        activatable
      }
      ... on Greenwheels {
        activatable
        activationLink
        registerable
        driversLicenseCountries {
          humanized
          value
        }
        agreedTermsAndConditions {
          requiresAgreement
          agreed
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
        greenwheelsTermsAndConditionsUrl
      }
    }
  }
}
    `;

/**
 * __useGetAvailableFeatureQuery__
 *
 * To run a query within a React component, call `useGetAvailableFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableFeatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAvailableFeatureQuery(baseOptions: Apollo.QueryHookOptions<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables> & ({ variables: IGetAvailableFeatureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables>(GetAvailableFeatureDocument, options);
      }
export function useGetAvailableFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables>(GetAvailableFeatureDocument, options);
        }
export function useGetAvailableFeatureSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables>(GetAvailableFeatureDocument, options);
        }
export type GetAvailableFeatureQueryHookResult = ReturnType<typeof useGetAvailableFeatureQuery>;
export type GetAvailableFeatureLazyQueryHookResult = ReturnType<typeof useGetAvailableFeatureLazyQuery>;
export type GetAvailableFeatureSuspenseQueryHookResult = ReturnType<typeof useGetAvailableFeatureSuspenseQuery>;
export type GetAvailableFeatureQueryResult = Apollo.QueryResult<IGetAvailableFeatureQuery, IGetAvailableFeatureQueryVariables>;
export const UpdateTrainSubscriptionDocument = gql`
    mutation updateTrainSubscription($input: RequestTrainClassChangeInput!) {
  requestTrainClassChange(input: $input) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
  }
}
    `;

/**
 * __useUpdateTrainSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTrainSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainSubscriptionMutation, { data, loading, error }] = useUpdateTrainSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateTrainSubscriptionMutation, IUpdateTrainSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateTrainSubscriptionMutation, IUpdateTrainSubscriptionMutationVariables>(UpdateTrainSubscriptionDocument, options);
      }
export type UpdateTrainSubscriptionMutationHookResult = ReturnType<typeof useUpdateTrainSubscriptionMutation>;
export type UpdateTrainSubscriptionMutationResult = Apollo.MutationResult<IUpdateTrainSubscriptionMutation>;
export type UpdateTrainSubscriptionMutationOptions = Apollo.BaseMutationOptions<IUpdateTrainSubscriptionMutation, IUpdateTrainSubscriptionMutationVariables>;
export const GetGreenwheelsFleetsDocument = gql`
    query getGreenwheelsFleets {
  me {
    id
    greenwheelsFleets {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGreenwheelsFleetsQuery__
 *
 * To run a query within a React component, call `useGetGreenwheelsFleetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGreenwheelsFleetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGreenwheelsFleetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGreenwheelsFleetsQuery(baseOptions?: Apollo.QueryHookOptions<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>(GetGreenwheelsFleetsDocument, options);
      }
export function useGetGreenwheelsFleetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>(GetGreenwheelsFleetsDocument, options);
        }
export function useGetGreenwheelsFleetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>(GetGreenwheelsFleetsDocument, options);
        }
export type GetGreenwheelsFleetsQueryHookResult = ReturnType<typeof useGetGreenwheelsFleetsQuery>;
export type GetGreenwheelsFleetsLazyQueryHookResult = ReturnType<typeof useGetGreenwheelsFleetsLazyQuery>;
export type GetGreenwheelsFleetsSuspenseQueryHookResult = ReturnType<typeof useGetGreenwheelsFleetsSuspenseQuery>;
export type GetGreenwheelsFleetsQueryResult = Apollo.QueryResult<IGetGreenwheelsFleetsQuery, IGetGreenwheelsFleetsQueryVariables>;
export const ConfirmGreenwheelsDocument = gql`
    mutation confirmGreenwheels($input: ConfirmGreenwheelsInput!) {
  services {
    confirmGreenwheels(input: $input) {
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
    }
  }
}
    `;

/**
 * __useConfirmGreenwheelsMutation__
 *
 * To run a mutation, you first call `useConfirmGreenwheelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmGreenwheelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmGreenwheelsMutation, { data, loading, error }] = useConfirmGreenwheelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmGreenwheelsMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmGreenwheelsMutation, IConfirmGreenwheelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmGreenwheelsMutation, IConfirmGreenwheelsMutationVariables>(ConfirmGreenwheelsDocument, options);
      }
export type ConfirmGreenwheelsMutationHookResult = ReturnType<typeof useConfirmGreenwheelsMutation>;
export type ConfirmGreenwheelsMutationResult = Apollo.MutationResult<IConfirmGreenwheelsMutation>;
export type ConfirmGreenwheelsMutationOptions = Apollo.BaseMutationOptions<IConfirmGreenwheelsMutation, IConfirmGreenwheelsMutationVariables>;
export const TerminateOwnershipDocument = gql`
    mutation terminateOwnership($input: TerminateOwnershipInput!) {
  terminateOwnership(input: $input) {
    result {
      success
      error
    }
  }
}
    `;

/**
 * __useTerminateOwnershipMutation__
 *
 * To run a mutation, you first call `useTerminateOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateOwnershipMutation, { data, loading, error }] = useTerminateOwnershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<ITerminateOwnershipMutation, ITerminateOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITerminateOwnershipMutation, ITerminateOwnershipMutationVariables>(TerminateOwnershipDocument, options);
      }
export type TerminateOwnershipMutationHookResult = ReturnType<typeof useTerminateOwnershipMutation>;
export type TerminateOwnershipMutationResult = Apollo.MutationResult<ITerminateOwnershipMutation>;
export type TerminateOwnershipMutationOptions = Apollo.BaseMutationOptions<ITerminateOwnershipMutation, ITerminateOwnershipMutationVariables>;
export const GetAvailableServiceDocument = gql`
    query getAvailableService($id: ID!) {
  me {
    id
    availableService(id: $id) {
      mobideskOwnershipToken
      endsOn {
        humanized
      }
      kind {
        sub
        humanized
      }
      description {
        humanized
      }
      recordId
      identity {
        humanized
      }
      icon {
        name
      }
      status {
        humanized
        metaStatus {
          value
          humanized
        }
      }
      personalMeansOfRegistration {
        id
        identity {
          humanized
        }
        status {
          value
          humanized
        }
      }
      ... on RequestableService {
        termsAndConditions {
          requiresAgreement
          agreed
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
      ... on ActiveGreenwheels {
        activatable
        activationLink
        registerable
        driversLicenseCountries {
          humanized
          value
        }
        termsAndConditions {
          requiresAgreement
          agreed
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
        greenwheelsTermsAndConditionsUrl
      }
      ... on ActiveSubscription {
        trainClassChange {
          trainClassStatus
          canRequestTrainClassChange
          requestableTrainClass {
            humanized
            value
          }
          firstClassReasons {
            humanized
            value
          }
          requiresTrainClassChangeMotivation
        }
        trainClass {
          humanized
          value
        }
        traject {
          from
          to
        }
      }
    }
  }
}
    `;

/**
 * __useGetAvailableServiceQuery__
 *
 * To run a query within a React component, call `useGetAvailableServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAvailableServiceQuery(baseOptions: Apollo.QueryHookOptions<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables> & ({ variables: IGetAvailableServiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables>(GetAvailableServiceDocument, options);
      }
export function useGetAvailableServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables>(GetAvailableServiceDocument, options);
        }
export function useGetAvailableServiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables>(GetAvailableServiceDocument, options);
        }
export type GetAvailableServiceQueryHookResult = ReturnType<typeof useGetAvailableServiceQuery>;
export type GetAvailableServiceLazyQueryHookResult = ReturnType<typeof useGetAvailableServiceLazyQuery>;
export type GetAvailableServiceSuspenseQueryHookResult = ReturnType<typeof useGetAvailableServiceSuspenseQuery>;
export type GetAvailableServiceQueryResult = Apollo.QueryResult<IGetAvailableServiceQuery, IGetAvailableServiceQueryVariables>;
export const RequestFeatureDocument = gql`
    mutation requestFeature($input: RequestFeatureInput!) {
  requestFeature(input: $input) {
    result {
      success
      error
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
  }
}
    `;

/**
 * __useRequestFeatureMutation__
 *
 * To run a mutation, you first call `useRequestFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFeatureMutation, { data, loading, error }] = useRequestFeatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestFeatureMutation(baseOptions?: Apollo.MutationHookOptions<IRequestFeatureMutation, IRequestFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRequestFeatureMutation, IRequestFeatureMutationVariables>(RequestFeatureDocument, options);
      }
export type RequestFeatureMutationHookResult = ReturnType<typeof useRequestFeatureMutation>;
export type RequestFeatureMutationResult = Apollo.MutationResult<IRequestFeatureMutation>;
export type RequestFeatureMutationOptions = Apollo.BaseMutationOptions<IRequestFeatureMutation, IRequestFeatureMutationVariables>;
export const CreateParkingOrderDocument = gql`
    mutation createParkingOrder($input: CreateParkingOrderInput!) {
  parking {
    create(input: $input) {
      result {
        success
        error
      }
      validationErrors {
        attribute
        messages
        fullMessages
      }
    }
  }
}
    `;

/**
 * __useCreateParkingOrderMutation__
 *
 * To run a mutation, you first call `useCreateParkingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParkingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParkingOrderMutation, { data, loading, error }] = useCreateParkingOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParkingOrderMutation(baseOptions?: Apollo.MutationHookOptions<ICreateParkingOrderMutation, ICreateParkingOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateParkingOrderMutation, ICreateParkingOrderMutationVariables>(CreateParkingOrderDocument, options);
      }
export type CreateParkingOrderMutationHookResult = ReturnType<typeof useCreateParkingOrderMutation>;
export type CreateParkingOrderMutationResult = Apollo.MutationResult<ICreateParkingOrderMutation>;
export type CreateParkingOrderMutationOptions = Apollo.BaseMutationOptions<ICreateParkingOrderMutation, ICreateParkingOrderMutationVariables>;
export const RequestServiceDocument = gql`
    mutation requestService($input: RequestServiceInput!) {
  services {
    request(input: $input) {
      validationErrors {
        attribute
        messages
        fullMessages
      }
      result {
        error
        success
      }
    }
  }
}
    `;

/**
 * __useRequestServiceMutation__
 *
 * To run a mutation, you first call `useRequestServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestServiceMutation, { data, loading, error }] = useRequestServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestServiceMutation(baseOptions?: Apollo.MutationHookOptions<IRequestServiceMutation, IRequestServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IRequestServiceMutation, IRequestServiceMutationVariables>(RequestServiceDocument, options);
      }
export type RequestServiceMutationHookResult = ReturnType<typeof useRequestServiceMutation>;
export type RequestServiceMutationResult = Apollo.MutationResult<IRequestServiceMutation>;
export type RequestServiceMutationOptions = Apollo.BaseMutationOptions<IRequestServiceMutation, IRequestServiceMutationVariables>;
export const AllowedOvPasTypesDocument = gql`
    query allowedOvPasTypes {
  reisbalansV2 {
    ovPas {
      allowedOvPasTypes
    }
  }
}
    `;

/**
 * __useAllowedOvPasTypesQuery__
 *
 * To run a query within a React component, call `useAllowedOvPasTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedOvPasTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedOvPasTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowedOvPasTypesQuery(baseOptions?: Apollo.QueryHookOptions<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>(AllowedOvPasTypesDocument, options);
      }
export function useAllowedOvPasTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>(AllowedOvPasTypesDocument, options);
        }
export function useAllowedOvPasTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>(AllowedOvPasTypesDocument, options);
        }
export type AllowedOvPasTypesQueryHookResult = ReturnType<typeof useAllowedOvPasTypesQuery>;
export type AllowedOvPasTypesLazyQueryHookResult = ReturnType<typeof useAllowedOvPasTypesLazyQuery>;
export type AllowedOvPasTypesSuspenseQueryHookResult = ReturnType<typeof useAllowedOvPasTypesSuspenseQuery>;
export type AllowedOvPasTypesQueryResult = Apollo.QueryResult<IAllowedOvPasTypesQuery, IAllowedOvPasTypesQueryVariables>;
export const CreateMobilityCardOrderDocument = gql`
    mutation createMobilityCardOrder {
  mobilityCardOrders {
    create(input: {}) {
      mobilityCardOrder {
        id
        actions {
          isEditable
        }
        status {
          value
        }
      }
      result {
        error
        success
      }
    }
  }
}
    `;

/**
 * __useCreateMobilityCardOrderMutation__
 *
 * To run a mutation, you first call `useCreateMobilityCardOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobilityCardOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobilityCardOrderMutation, { data, loading, error }] = useCreateMobilityCardOrderMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateMobilityCardOrderMutation(baseOptions?: Apollo.MutationHookOptions<ICreateMobilityCardOrderMutation, ICreateMobilityCardOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateMobilityCardOrderMutation, ICreateMobilityCardOrderMutationVariables>(CreateMobilityCardOrderDocument, options);
      }
export type CreateMobilityCardOrderMutationHookResult = ReturnType<typeof useCreateMobilityCardOrderMutation>;
export type CreateMobilityCardOrderMutationResult = Apollo.MutationResult<ICreateMobilityCardOrderMutation>;
export type CreateMobilityCardOrderMutationOptions = Apollo.BaseMutationOptions<ICreateMobilityCardOrderMutation, ICreateMobilityCardOrderMutationVariables>;
export const GetCardsAndServicesDataDocument = gql`
    query getCardsAndServicesData {
  me {
    id
    permissions {
      canCreateNewMobilityCardOrder
    }
    mobilityCardOrders {
      id
      actions {
        isEditable
      }
      status {
        value
      }
    }
    personalMeansOfRegistrations {
      id
      identity {
        humanized
      }
      kind {
        humanized
        sub
      }
      status {
        value
      }
    }
    availableServices {
      __typename
      endsOn {
        humanized
      }
      kind {
        sub
        humanized
      }
      description {
        humanized
      }
      recordId
      identity {
        humanized
      }
      icon {
        name
      }
      status {
        humanized
        enumValue
        metaStatus {
          value
        }
      }
      personalMeansOfRegistration {
        id
        identity {
          humanized
        }
        status {
          humanized
        }
      }
      ... on RequestableYellowbrickProduct {
        requiresMotivation
        termsAndConditions {
          requiresAgreement
          agreed
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
      ... on RequestableService {
        requiresMotivation
        termsAndConditions {
          requiresAgreement
          agreed
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
      ... on ActiveGreenwheels {
        activatable
        activationLink
        registerable
        driversLicenseCountries {
          humanized
          value
        }
        termsAndConditions {
          requiresAgreement
          agreed
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
        greenwheelsTermsAndConditionsUrl
      }
      ... on ActiveSubscription {
        trainClassChange {
          canRequestTrainClassChange
          firstClassReasons {
            humanized
            value
          }
          requiresTrainClassChangeMotivation
        }
        trainClass {
          humanized
          value
        }
        traject {
          from
          to
        }
      }
    }
    availableFeatures {
      id
      icon {
        name
      }
      name {
        humanized
        value
      }
      urls {
        web
      }
      status {
        value
        humanized
      }
      motivation
      completedAt
      agreedTermsAndConditions {
        requiresAgreement
        document {
          id
          pdfUrl
          humanizedKind {
            humanized
          }
        }
      }
      ... on RequestableFeature {
        requiresMotivation
        requiresPhoneNumber
        termsAndConditions {
          requiresAgreement
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
      ... on RequestableDonkeyRepublic {
        requiresMotivation
        requiresPhoneNumber
        termsAndConditions {
          requiresAgreement
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
      ... on EnecoDongle {
        activatable
      }
      ... on Greenwheels {
        activatable
        registerable
      }
    }
  }
}
    `;

/**
 * __useGetCardsAndServicesDataQuery__
 *
 * To run a query within a React component, call `useGetCardsAndServicesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsAndServicesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsAndServicesDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardsAndServicesDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>(GetCardsAndServicesDataDocument, options);
      }
export function useGetCardsAndServicesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>(GetCardsAndServicesDataDocument, options);
        }
export function useGetCardsAndServicesDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>(GetCardsAndServicesDataDocument, options);
        }
export type GetCardsAndServicesDataQueryHookResult = ReturnType<typeof useGetCardsAndServicesDataQuery>;
export type GetCardsAndServicesDataLazyQueryHookResult = ReturnType<typeof useGetCardsAndServicesDataLazyQuery>;
export type GetCardsAndServicesDataSuspenseQueryHookResult = ReturnType<typeof useGetCardsAndServicesDataSuspenseQuery>;
export type GetCardsAndServicesDataQueryResult = Apollo.QueryResult<IGetCardsAndServicesDataQuery, IGetCardsAndServicesDataQueryVariables>;
export const CreateWorkFromHomeAllowanceDocument = gql`
    mutation createWorkFromHomeAllowance($date: Date!, $confirm: Boolean) {
  createWorkFromHome(input: {date: $date, confirm: $confirm}) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
    mobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useCreateWorkFromHomeAllowanceMutation__
 *
 * To run a mutation, you first call `useCreateWorkFromHomeAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkFromHomeAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkFromHomeAllowanceMutation, { data, loading, error }] = useCreateWorkFromHomeAllowanceMutation({
 *   variables: {
 *      date: // value for 'date'
 *      confirm: // value for 'confirm'
 *   },
 * });
 */
export function useCreateWorkFromHomeAllowanceMutation(baseOptions?: Apollo.MutationHookOptions<ICreateWorkFromHomeAllowanceMutation, ICreateWorkFromHomeAllowanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateWorkFromHomeAllowanceMutation, ICreateWorkFromHomeAllowanceMutationVariables>(CreateWorkFromHomeAllowanceDocument, options);
      }
export type CreateWorkFromHomeAllowanceMutationHookResult = ReturnType<typeof useCreateWorkFromHomeAllowanceMutation>;
export type CreateWorkFromHomeAllowanceMutationResult = Apollo.MutationResult<ICreateWorkFromHomeAllowanceMutation>;
export type CreateWorkFromHomeAllowanceMutationOptions = Apollo.BaseMutationOptions<ICreateWorkFromHomeAllowanceMutation, ICreateWorkFromHomeAllowanceMutationVariables>;
export const GetDayDocument = gql`
    query getDay($date: Date!) {
  me {
    id
    permissions(on: $date) {
      manageWorkFromHome
      manageCommuteCompensation
      manageMobilityEvents
      manageCommuteRides
    }
    day(date: $date) {
      ... on DayWithMobilityEvents {
        date
        commuteCompensationAddable
        eventsAddable
        eventsConfirmable
        commuteRidesAddable
        commuteRideAddable
        workFromHomeCompensationAddable
      }
    }
  }
}
    `;

/**
 * __useGetDayQuery__
 *
 * To run a query within a React component, call `useGetDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDayQuery(baseOptions: Apollo.QueryHookOptions<IGetDayQuery, IGetDayQueryVariables> & ({ variables: IGetDayQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDayQuery, IGetDayQueryVariables>(GetDayDocument, options);
      }
export function useGetDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDayQuery, IGetDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDayQuery, IGetDayQueryVariables>(GetDayDocument, options);
        }
export function useGetDaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDayQuery, IGetDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDayQuery, IGetDayQueryVariables>(GetDayDocument, options);
        }
export type GetDayQueryHookResult = ReturnType<typeof useGetDayQuery>;
export type GetDayLazyQueryHookResult = ReturnType<typeof useGetDayLazyQuery>;
export type GetDaySuspenseQueryHookResult = ReturnType<typeof useGetDaySuspenseQuery>;
export type GetDayQueryResult = Apollo.QueryResult<IGetDayQuery, IGetDayQueryVariables>;
export const GetApprovableMobilityEventsDocument = gql`
    query getApprovableMobilityEvents {
  me {
    id
    approvableMobilityEventsAggregate
  }
}
    `;

/**
 * __useGetApprovableMobilityEventsQuery__
 *
 * To run a query within a React component, call `useGetApprovableMobilityEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovableMobilityEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovableMobilityEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApprovableMobilityEventsQuery(baseOptions?: Apollo.QueryHookOptions<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>(GetApprovableMobilityEventsDocument, options);
      }
export function useGetApprovableMobilityEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>(GetApprovableMobilityEventsDocument, options);
        }
export function useGetApprovableMobilityEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>(GetApprovableMobilityEventsDocument, options);
        }
export type GetApprovableMobilityEventsQueryHookResult = ReturnType<typeof useGetApprovableMobilityEventsQuery>;
export type GetApprovableMobilityEventsLazyQueryHookResult = ReturnType<typeof useGetApprovableMobilityEventsLazyQuery>;
export type GetApprovableMobilityEventsSuspenseQueryHookResult = ReturnType<typeof useGetApprovableMobilityEventsSuspenseQuery>;
export type GetApprovableMobilityEventsQueryResult = Apollo.QueryResult<IGetApprovableMobilityEventsQuery, IGetApprovableMobilityEventsQueryVariables>;
export const GetCo2EmissionSummaryDocument = gql`
    query getCo2EmissionSummary {
  me {
    id
    co2EmissionSummary(dateRangeString: {dateRange: month}) {
      totalEmission {
        humanized
      }
    }
  }
}
    `;

/**
 * __useGetCo2EmissionSummaryQuery__
 *
 * To run a query within a React component, call `useGetCo2EmissionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCo2EmissionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCo2EmissionSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCo2EmissionSummaryQuery(baseOptions?: Apollo.QueryHookOptions<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>(GetCo2EmissionSummaryDocument, options);
      }
export function useGetCo2EmissionSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>(GetCo2EmissionSummaryDocument, options);
        }
export function useGetCo2EmissionSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>(GetCo2EmissionSummaryDocument, options);
        }
export type GetCo2EmissionSummaryQueryHookResult = ReturnType<typeof useGetCo2EmissionSummaryQuery>;
export type GetCo2EmissionSummaryLazyQueryHookResult = ReturnType<typeof useGetCo2EmissionSummaryLazyQuery>;
export type GetCo2EmissionSummarySuspenseQueryHookResult = ReturnType<typeof useGetCo2EmissionSummarySuspenseQuery>;
export type GetCo2EmissionSummaryQueryResult = Apollo.QueryResult<IGetCo2EmissionSummaryQuery, IGetCo2EmissionSummaryQueryVariables>;
export const GetFinancialConsequencesDocument = gql`
    query getFinancialConsequences {
  me {
    id
    financialConsequences {
      tentativeSettlement {
        totalGrossAndNetCharge {
          valueInCents
        }
        totalGrossAndNetReimbursement {
          valueInCents
        }
      }
    }
  }
}
    `;

/**
 * __useGetFinancialConsequencesQuery__
 *
 * To run a query within a React component, call `useGetFinancialConsequencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialConsequencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialConsequencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialConsequencesQuery(baseOptions?: Apollo.QueryHookOptions<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>(GetFinancialConsequencesDocument, options);
      }
export function useGetFinancialConsequencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>(GetFinancialConsequencesDocument, options);
        }
export function useGetFinancialConsequencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>(GetFinancialConsequencesDocument, options);
        }
export type GetFinancialConsequencesQueryHookResult = ReturnType<typeof useGetFinancialConsequencesQuery>;
export type GetFinancialConsequencesLazyQueryHookResult = ReturnType<typeof useGetFinancialConsequencesLazyQuery>;
export type GetFinancialConsequencesSuspenseQueryHookResult = ReturnType<typeof useGetFinancialConsequencesSuspenseQuery>;
export type GetFinancialConsequencesQueryResult = Apollo.QueryResult<IGetFinancialConsequencesQuery, IGetFinancialConsequencesQueryVariables>;
export const GetMobilityCardPermissionDocument = gql`
    query getMobilityCardPermission {
  me {
    id
    permissions {
      canCreateNewMobilityCardOrder
    }
  }
}
    `;

/**
 * __useGetMobilityCardPermissionQuery__
 *
 * To run a query within a React component, call `useGetMobilityCardPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityCardPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityCardPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobilityCardPermissionQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>(GetMobilityCardPermissionDocument, options);
      }
export function useGetMobilityCardPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>(GetMobilityCardPermissionDocument, options);
        }
export function useGetMobilityCardPermissionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>(GetMobilityCardPermissionDocument, options);
        }
export type GetMobilityCardPermissionQueryHookResult = ReturnType<typeof useGetMobilityCardPermissionQuery>;
export type GetMobilityCardPermissionLazyQueryHookResult = ReturnType<typeof useGetMobilityCardPermissionLazyQuery>;
export type GetMobilityCardPermissionSuspenseQueryHookResult = ReturnType<typeof useGetMobilityCardPermissionSuspenseQuery>;
export type GetMobilityCardPermissionQueryResult = Apollo.QueryResult<IGetMobilityCardPermissionQuery, IGetMobilityCardPermissionQueryVariables>;
export const GetMobilityCardsDocument = gql`
    query getMobilityCards {
  me {
    id
    personalMeansOfRegistrations(filterByKindMain: mobility_card) {
      endsOn {
        humanized
      }
      abilities {
        terminatable
        activatable
        lostOrStolenReportable
      }
      identity {
        humanized
      }
      kind {
        humanized
        sub
      }
      status {
        humanized
        value
      }
      id
    }
    permissions {
      canCreateNewMobilityCardOrder
    }
    mobilityCardOrders {
      id
      actions {
        isEditable
      }
      status {
        humanized
        value
      }
    }
  }
}
    `;

/**
 * __useGetMobilityCardsQuery__
 *
 * To run a query within a React component, call `useGetMobilityCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobilityCardsQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>(GetMobilityCardsDocument, options);
      }
export function useGetMobilityCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>(GetMobilityCardsDocument, options);
        }
export function useGetMobilityCardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>(GetMobilityCardsDocument, options);
        }
export type GetMobilityCardsQueryHookResult = ReturnType<typeof useGetMobilityCardsQuery>;
export type GetMobilityCardsLazyQueryHookResult = ReturnType<typeof useGetMobilityCardsLazyQuery>;
export type GetMobilityCardsSuspenseQueryHookResult = ReturnType<typeof useGetMobilityCardsSuspenseQuery>;
export type GetMobilityCardsQueryResult = Apollo.QueryResult<IGetMobilityCardsQuery, IGetMobilityCardsQueryVariables>;
export const MobilityEventsAggregateDocument = gql`
    query mobilityEventsAggregate {
  me {
    id
    mobilityEventsAggregate(filterByStatus: confirmable) {
      count
    }
    rejectedEvents: mobilityEventsAggregate(filterByStatus: rejected) {
      count
    }
    draftEvents: mobilityEventsAggregate(filterByStatus: draft) {
      count
    }
  }
}
    `;

/**
 * __useMobilityEventsAggregateQuery__
 *
 * To run a query within a React component, call `useMobilityEventsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobilityEventsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobilityEventsAggregateQuery({
 *   variables: {
 *   },
 * });
 */
export function useMobilityEventsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>(MobilityEventsAggregateDocument, options);
      }
export function useMobilityEventsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>(MobilityEventsAggregateDocument, options);
        }
export function useMobilityEventsAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>(MobilityEventsAggregateDocument, options);
        }
export type MobilityEventsAggregateQueryHookResult = ReturnType<typeof useMobilityEventsAggregateQuery>;
export type MobilityEventsAggregateLazyQueryHookResult = ReturnType<typeof useMobilityEventsAggregateLazyQuery>;
export type MobilityEventsAggregateSuspenseQueryHookResult = ReturnType<typeof useMobilityEventsAggregateSuspenseQuery>;
export type MobilityEventsAggregateQueryResult = Apollo.QueryResult<IMobilityEventsAggregateQuery, IMobilityEventsAggregateQueryVariables>;
export const GetDashboardReservationDataDocument = gql`
    query getDashboardReservationData {
  me {
    id
    reservations(filterBy: CURRENT, order: ASC) {
      ...Reservation
    }
    availableFeatures(status: APPROVED) {
      id
      motivation
      icon {
        name
      }
      name {
        humanized
        value
      }
      status {
        humanized
        value
      }
      completedAt
      urls {
        web
      }
      ... on RequestableFeature {
        requiresMotivation
        termsAndConditions {
          requiresAgreement
          document {
            id
            pdfUrl
            humanizedKind {
              humanized
            }
          }
        }
      }
    }
  }
}
    ${ReservationFragmentDoc}`;

/**
 * __useGetDashboardReservationDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardReservationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardReservationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardReservationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardReservationDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>(GetDashboardReservationDataDocument, options);
      }
export function useGetDashboardReservationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>(GetDashboardReservationDataDocument, options);
        }
export function useGetDashboardReservationDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>(GetDashboardReservationDataDocument, options);
        }
export type GetDashboardReservationDataQueryHookResult = ReturnType<typeof useGetDashboardReservationDataQuery>;
export type GetDashboardReservationDataLazyQueryHookResult = ReturnType<typeof useGetDashboardReservationDataLazyQuery>;
export type GetDashboardReservationDataSuspenseQueryHookResult = ReturnType<typeof useGetDashboardReservationDataSuspenseQuery>;
export type GetDashboardReservationDataQueryResult = Apollo.QueryResult<IGetDashboardReservationDataQuery, IGetDashboardReservationDataQueryVariables>;
export const GetDashboardDataDocument = gql`
    query GetDashboardData {
  me {
    id
    orderMessages: messages(withKey: MOBILITY_CARD_ORDER) {
      id
      title
      content
    }
    allMessages: messages(withKey: ALL) {
      id
      title
      content
    }
    permissions {
      canCreateNewMobilityCardOrder
      approvals
    }
  }
}
    `;

/**
 * __useGetDashboardDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>(GetDashboardDataDocument, options);
      }
export function useGetDashboardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>(GetDashboardDataDocument, options);
        }
export function useGetDashboardDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>(GetDashboardDataDocument, options);
        }
export type GetDashboardDataQueryHookResult = ReturnType<typeof useGetDashboardDataQuery>;
export type GetDashboardDataLazyQueryHookResult = ReturnType<typeof useGetDashboardDataLazyQuery>;
export type GetDashboardDataSuspenseQueryHookResult = ReturnType<typeof useGetDashboardDataSuspenseQuery>;
export type GetDashboardDataQueryResult = Apollo.QueryResult<IGetDashboardDataQuery, IGetDashboardDataQueryVariables>;
export const MarkMessageAsReadDocument = gql`
    mutation markMessageAsRead($id: ID!) {
  markMessageAsRead(input: {messageId: $id}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(baseOptions?: Apollo.MutationHookOptions<IMarkMessageAsReadMutation, IMarkMessageAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMarkMessageAsReadMutation, IMarkMessageAsReadMutationVariables>(MarkMessageAsReadDocument, options);
      }
export type MarkMessageAsReadMutationHookResult = ReturnType<typeof useMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationResult = Apollo.MutationResult<IMarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<IMarkMessageAsReadMutation, IMarkMessageAsReadMutationVariables>;
export const GetCo2EmissionDataDocument = gql`
    query getCO2EmissionData($dateRangeInput: DateRangeStringInput) {
  me {
    id
    co2EmissionSummary(dateRangeString: $dateRangeInput) {
      ...co2EmissionSummary
    }
    distanceSummary(dateRangeString: $dateRangeInput) {
      ...distanceSummary
    }
  }
}
    ${Co2EmissionSummaryFragmentDoc}
${DistanceSummaryFragmentDoc}`;

/**
 * __useGetCo2EmissionDataQuery__
 *
 * To run a query within a React component, call `useGetCo2EmissionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCo2EmissionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCo2EmissionDataQuery({
 *   variables: {
 *      dateRangeInput: // value for 'dateRangeInput'
 *   },
 * });
 */
export function useGetCo2EmissionDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>(GetCo2EmissionDataDocument, options);
      }
export function useGetCo2EmissionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>(GetCo2EmissionDataDocument, options);
        }
export function useGetCo2EmissionDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>(GetCo2EmissionDataDocument, options);
        }
export type GetCo2EmissionDataQueryHookResult = ReturnType<typeof useGetCo2EmissionDataQuery>;
export type GetCo2EmissionDataLazyQueryHookResult = ReturnType<typeof useGetCo2EmissionDataLazyQuery>;
export type GetCo2EmissionDataSuspenseQueryHookResult = ReturnType<typeof useGetCo2EmissionDataSuspenseQuery>;
export type GetCo2EmissionDataQueryResult = Apollo.QueryResult<IGetCo2EmissionDataQuery, IGetCo2EmissionDataQueryVariables>;
export const UpdateMobilityEventsDocument = gql`
    mutation UpdateMobilityEvents($input: UpdateMobilityEventsInput!) {
  updateMobilityEvents(input: $input) {
    days {
      ... on DayWithMobilityEvents {
        date
        eventsConfirmable
        eventsAddable
        mobilityEvents {
          ...IndexMobilityEvent
        }
        permissions {
          manageWorkFromHome
          manageCommuteCompensation
        }
      }
    }
    updatedMobilityEvents {
      ...ShowFragment
    }
    result {
      success
      error
    }
  }
}
    ${IndexMobilityEventFragmentDoc}
${ShowFragmentFragmentDoc}`;

/**
 * __useUpdateMobilityEventsMutation__
 *
 * To run a mutation, you first call `useUpdateMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMobilityEventsMutation, { data, loading, error }] = useUpdateMobilityEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateMobilityEventsMutation, IUpdateMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateMobilityEventsMutation, IUpdateMobilityEventsMutationVariables>(UpdateMobilityEventsDocument, options);
      }
export type UpdateMobilityEventsMutationHookResult = ReturnType<typeof useUpdateMobilityEventsMutation>;
export type UpdateMobilityEventsMutationResult = Apollo.MutationResult<IUpdateMobilityEventsMutation>;
export type UpdateMobilityEventsMutationOptions = Apollo.BaseMutationOptions<IUpdateMobilityEventsMutation, IUpdateMobilityEventsMutationVariables>;
export const CreateMobilityEventDocument = gql`
    mutation createMobilityEvent($input: CreateMobilityEventInput!) {
  createMobilityEvent(input: $input) {
    result {
      success
      error
    }
    validationErrors {
      attribute
      messages
      fullMessages
    }
    mobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useCreateMobilityEventMutation__
 *
 * To run a mutation, you first call `useCreateMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobilityEventMutation, { data, loading, error }] = useCreateMobilityEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<ICreateMobilityEventMutation, ICreateMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateMobilityEventMutation, ICreateMobilityEventMutationVariables>(CreateMobilityEventDocument, options);
      }
export type CreateMobilityEventMutationHookResult = ReturnType<typeof useCreateMobilityEventMutation>;
export type CreateMobilityEventMutationResult = Apollo.MutationResult<ICreateMobilityEventMutation>;
export type CreateMobilityEventMutationOptions = Apollo.BaseMutationOptions<ICreateMobilityEventMutation, ICreateMobilityEventMutationVariables>;
export const UpdateMobilityEventDocument = gql`
    mutation UpdateMobilityEvent($input: UpdateMobilityEventInput!) {
  updateMobilityEvent(input: $input) {
    result {
      success
      error
    }
    validationErrors {
      attribute
      messages
      fullMessages
    }
    mobilityEvent {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useUpdateMobilityEventMutation__
 *
 * To run a mutation, you first call `useUpdateMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMobilityEventMutation, { data, loading, error }] = useUpdateMobilityEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateMobilityEventMutation, IUpdateMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateMobilityEventMutation, IUpdateMobilityEventMutationVariables>(UpdateMobilityEventDocument, options);
      }
export type UpdateMobilityEventMutationHookResult = ReturnType<typeof useUpdateMobilityEventMutation>;
export type UpdateMobilityEventMutationResult = Apollo.MutationResult<IUpdateMobilityEventMutation>;
export type UpdateMobilityEventMutationOptions = Apollo.BaseMutationOptions<IUpdateMobilityEventMutation, IUpdateMobilityEventMutationVariables>;
export const GetMultipleEmploymentsDocument = gql`
    query getMultipleEmployments {
  me {
    id
    permissions {
      selectEmployment
    }
    employments(withChildren: true) {
      commuteMonthlyMoneyBudget
      commuteMonthlyKmBudget
      costCenter
      department
      endsOn
      id
      startsOn
      main
      budgetGroupName
    }
  }
}
    `;

/**
 * __useGetMultipleEmploymentsQuery__
 *
 * To run a query within a React component, call `useGetMultipleEmploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleEmploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleEmploymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMultipleEmploymentsQuery(baseOptions?: Apollo.QueryHookOptions<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>(GetMultipleEmploymentsDocument, options);
      }
export function useGetMultipleEmploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>(GetMultipleEmploymentsDocument, options);
        }
export function useGetMultipleEmploymentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>(GetMultipleEmploymentsDocument, options);
        }
export type GetMultipleEmploymentsQueryHookResult = ReturnType<typeof useGetMultipleEmploymentsQuery>;
export type GetMultipleEmploymentsLazyQueryHookResult = ReturnType<typeof useGetMultipleEmploymentsLazyQuery>;
export type GetMultipleEmploymentsSuspenseQueryHookResult = ReturnType<typeof useGetMultipleEmploymentsSuspenseQuery>;
export type GetMultipleEmploymentsQueryResult = Apollo.QueryResult<IGetMultipleEmploymentsQuery, IGetMultipleEmploymentsQueryVariables>;
export const LoadAvailableProjectsDocument = gql`
    query loadAvailableProjects($on: Date!, $input: String = "") {
  me {
    id
    organization(on: $on) {
      id
      projects(textFilter: $input, on: $on) {
        id
        code
        humanized
      }
    }
  }
}
    `;

/**
 * __useLoadAvailableProjectsQuery__
 *
 * To run a query within a React component, call `useLoadAvailableProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAvailableProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAvailableProjectsQuery({
 *   variables: {
 *      on: // value for 'on'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadAvailableProjectsQuery(baseOptions: Apollo.QueryHookOptions<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables> & ({ variables: ILoadAvailableProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables>(LoadAvailableProjectsDocument, options);
      }
export function useLoadAvailableProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables>(LoadAvailableProjectsDocument, options);
        }
export function useLoadAvailableProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables>(LoadAvailableProjectsDocument, options);
        }
export type LoadAvailableProjectsQueryHookResult = ReturnType<typeof useLoadAvailableProjectsQuery>;
export type LoadAvailableProjectsLazyQueryHookResult = ReturnType<typeof useLoadAvailableProjectsLazyQuery>;
export type LoadAvailableProjectsSuspenseQueryHookResult = ReturnType<typeof useLoadAvailableProjectsSuspenseQuery>;
export type LoadAvailableProjectsQueryResult = Apollo.QueryResult<ILoadAvailableProjectsQuery, ILoadAvailableProjectsQueryVariables>;
export const HasProjectsDocument = gql`
    query hasProjects($on: Date!) {
  me {
    id
    organization(on: $on) {
      id
      hasActiveProjects(on: $on)
    }
  }
}
    `;

/**
 * __useHasProjectsQuery__
 *
 * To run a query within a React component, call `useHasProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasProjectsQuery({
 *   variables: {
 *      on: // value for 'on'
 *   },
 * });
 */
export function useHasProjectsQuery(baseOptions: Apollo.QueryHookOptions<IHasProjectsQuery, IHasProjectsQueryVariables> & ({ variables: IHasProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IHasProjectsQuery, IHasProjectsQueryVariables>(HasProjectsDocument, options);
      }
export function useHasProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IHasProjectsQuery, IHasProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IHasProjectsQuery, IHasProjectsQueryVariables>(HasProjectsDocument, options);
        }
export function useHasProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IHasProjectsQuery, IHasProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IHasProjectsQuery, IHasProjectsQueryVariables>(HasProjectsDocument, options);
        }
export type HasProjectsQueryHookResult = ReturnType<typeof useHasProjectsQuery>;
export type HasProjectsLazyQueryHookResult = ReturnType<typeof useHasProjectsLazyQuery>;
export type HasProjectsSuspenseQueryHookResult = ReturnType<typeof useHasProjectsSuspenseQuery>;
export type HasProjectsQueryResult = Apollo.QueryResult<IHasProjectsQuery, IHasProjectsQueryVariables>;
export const GetWarningForOver40KmWalkOrBikeDocument = gql`
    query getWarningForOver40KmWalkOrBike($modalityId: ID, $fromLocationId: ID, $toLocationId: ID) {
  me {
    id
    mobilityEventFormValidations(
      modalityId: $modalityId
      fromLocationId: $fromLocationId
      toLocationId: $toLocationId
    ) {
      showWarningForOver40KmWalkOrBike
    }
  }
}
    `;

/**
 * __useGetWarningForOver40KmWalkOrBikeQuery__
 *
 * To run a query within a React component, call `useGetWarningForOver40KmWalkOrBikeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarningForOver40KmWalkOrBikeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarningForOver40KmWalkOrBikeQuery({
 *   variables: {
 *      modalityId: // value for 'modalityId'
 *      fromLocationId: // value for 'fromLocationId'
 *      toLocationId: // value for 'toLocationId'
 *   },
 * });
 */
export function useGetWarningForOver40KmWalkOrBikeQuery(baseOptions?: Apollo.QueryHookOptions<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>(GetWarningForOver40KmWalkOrBikeDocument, options);
      }
export function useGetWarningForOver40KmWalkOrBikeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>(GetWarningForOver40KmWalkOrBikeDocument, options);
        }
export function useGetWarningForOver40KmWalkOrBikeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>(GetWarningForOver40KmWalkOrBikeDocument, options);
        }
export type GetWarningForOver40KmWalkOrBikeQueryHookResult = ReturnType<typeof useGetWarningForOver40KmWalkOrBikeQuery>;
export type GetWarningForOver40KmWalkOrBikeLazyQueryHookResult = ReturnType<typeof useGetWarningForOver40KmWalkOrBikeLazyQuery>;
export type GetWarningForOver40KmWalkOrBikeSuspenseQueryHookResult = ReturnType<typeof useGetWarningForOver40KmWalkOrBikeSuspenseQuery>;
export type GetWarningForOver40KmWalkOrBikeQueryResult = Apollo.QueryResult<IGetWarningForOver40KmWalkOrBikeQuery, IGetWarningForOver40KmWalkOrBikeQueryVariables>;
export const DistanceAndCo2Document = gql`
    query distanceAndCo2($on: Date!, $from: CoordinatesMutation!, $to: CoordinatesMutation!, $modalityId: ID!, $vehicleId: ID, $travelPurpose: TravelPurposeEnum) {
  me {
    id
    routeMetadata(on: $on) {
      calculateDistance(
        on: $on
        from: $from
        to: $to
        modalityId: $modalityId
        travelPurpose: $travelPurpose
      ) {
        value
      }
      co2Emission(
        from: $from
        to: $to
        modalityId: $modalityId
        vehicleId: $vehicleId
      ) {
        humanized
      }
    }
  }
}
    `;

/**
 * __useDistanceAndCo2Query__
 *
 * To run a query within a React component, call `useDistanceAndCo2Query` and pass it any options that fit your needs.
 * When your component renders, `useDistanceAndCo2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistanceAndCo2Query({
 *   variables: {
 *      on: // value for 'on'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      modalityId: // value for 'modalityId'
 *      vehicleId: // value for 'vehicleId'
 *      travelPurpose: // value for 'travelPurpose'
 *   },
 * });
 */
export function useDistanceAndCo2Query(baseOptions: Apollo.QueryHookOptions<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables> & ({ variables: IDistanceAndCo2QueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables>(DistanceAndCo2Document, options);
      }
export function useDistanceAndCo2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables>(DistanceAndCo2Document, options);
        }
export function useDistanceAndCo2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables>(DistanceAndCo2Document, options);
        }
export type DistanceAndCo2QueryHookResult = ReturnType<typeof useDistanceAndCo2Query>;
export type DistanceAndCo2LazyQueryHookResult = ReturnType<typeof useDistanceAndCo2LazyQuery>;
export type DistanceAndCo2SuspenseQueryHookResult = ReturnType<typeof useDistanceAndCo2SuspenseQuery>;
export type DistanceAndCo2QueryResult = Apollo.QueryResult<IDistanceAndCo2Query, IDistanceAndCo2QueryVariables>;
export const Co2ByDistanceDocument = gql`
    query co2ByDistance($on: Date!, $distance: Float!, $modalityId: ID!, $vehicleId: ID) {
  me {
    id
    routeMetadata(on: $on) {
      co2EmissionFromDistance(
        distance: $distance
        modalityId: $modalityId
        vehicleId: $vehicleId
      ) {
        humanized
      }
    }
  }
}
    `;

/**
 * __useCo2ByDistanceQuery__
 *
 * To run a query within a React component, call `useCo2ByDistanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCo2ByDistanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCo2ByDistanceQuery({
 *   variables: {
 *      on: // value for 'on'
 *      distance: // value for 'distance'
 *      modalityId: // value for 'modalityId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCo2ByDistanceQuery(baseOptions: Apollo.QueryHookOptions<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables> & ({ variables: ICo2ByDistanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables>(Co2ByDistanceDocument, options);
      }
export function useCo2ByDistanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables>(Co2ByDistanceDocument, options);
        }
export function useCo2ByDistanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables>(Co2ByDistanceDocument, options);
        }
export type Co2ByDistanceQueryHookResult = ReturnType<typeof useCo2ByDistanceQuery>;
export type Co2ByDistanceLazyQueryHookResult = ReturnType<typeof useCo2ByDistanceLazyQuery>;
export type Co2ByDistanceSuspenseQueryHookResult = ReturnType<typeof useCo2ByDistanceSuspenseQuery>;
export type Co2ByDistanceQueryResult = Apollo.QueryResult<ICo2ByDistanceQuery, ICo2ByDistanceQueryVariables>;
export const DistanceEditableAndNonCombinedDocument = gql`
    query distanceEditableAndNonCombined($on: Date!) {
  me {
    id
    routeMetadata(on: $on) {
      distanceOverridable
      nonCombinedAllowed
    }
  }
}
    `;

/**
 * __useDistanceEditableAndNonCombinedQuery__
 *
 * To run a query within a React component, call `useDistanceEditableAndNonCombinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistanceEditableAndNonCombinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistanceEditableAndNonCombinedQuery({
 *   variables: {
 *      on: // value for 'on'
 *   },
 * });
 */
export function useDistanceEditableAndNonCombinedQuery(baseOptions: Apollo.QueryHookOptions<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables> & ({ variables: IDistanceEditableAndNonCombinedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables>(DistanceEditableAndNonCombinedDocument, options);
      }
export function useDistanceEditableAndNonCombinedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables>(DistanceEditableAndNonCombinedDocument, options);
        }
export function useDistanceEditableAndNonCombinedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables>(DistanceEditableAndNonCombinedDocument, options);
        }
export type DistanceEditableAndNonCombinedQueryHookResult = ReturnType<typeof useDistanceEditableAndNonCombinedQuery>;
export type DistanceEditableAndNonCombinedLazyQueryHookResult = ReturnType<typeof useDistanceEditableAndNonCombinedLazyQuery>;
export type DistanceEditableAndNonCombinedSuspenseQueryHookResult = ReturnType<typeof useDistanceEditableAndNonCombinedSuspenseQuery>;
export type DistanceEditableAndNonCombinedQueryResult = Apollo.QueryResult<IDistanceEditableAndNonCombinedQuery, IDistanceEditableAndNonCombinedQueryVariables>;
export const GetMandatoryLocationPostalCodeDocument = gql`
    query getMandatoryLocationPostalCode {
  me {
    id
    organization {
      id
      mandatoryLocationPostalCode
    }
  }
}
    `;

/**
 * __useGetMandatoryLocationPostalCodeQuery__
 *
 * To run a query within a React component, call `useGetMandatoryLocationPostalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMandatoryLocationPostalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMandatoryLocationPostalCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMandatoryLocationPostalCodeQuery(baseOptions?: Apollo.QueryHookOptions<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>(GetMandatoryLocationPostalCodeDocument, options);
      }
export function useGetMandatoryLocationPostalCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>(GetMandatoryLocationPostalCodeDocument, options);
        }
export function useGetMandatoryLocationPostalCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>(GetMandatoryLocationPostalCodeDocument, options);
        }
export type GetMandatoryLocationPostalCodeQueryHookResult = ReturnType<typeof useGetMandatoryLocationPostalCodeQuery>;
export type GetMandatoryLocationPostalCodeLazyQueryHookResult = ReturnType<typeof useGetMandatoryLocationPostalCodeLazyQuery>;
export type GetMandatoryLocationPostalCodeSuspenseQueryHookResult = ReturnType<typeof useGetMandatoryLocationPostalCodeSuspenseQuery>;
export type GetMandatoryLocationPostalCodeQueryResult = Apollo.QueryResult<IGetMandatoryLocationPostalCodeQuery, IGetMandatoryLocationPostalCodeQueryVariables>;
export const GetRefundableIncompleteRidesDocument = gql`
    query getRefundableIncompleteRides {
  me {
    id
    refundableIncompleteRides {
      id
      what {
        humanized
      }
      happenedAt
    }
  }
}
    `;

/**
 * __useGetRefundableIncompleteRidesQuery__
 *
 * To run a query within a React component, call `useGetRefundableIncompleteRidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundableIncompleteRidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundableIncompleteRidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRefundableIncompleteRidesQuery(baseOptions?: Apollo.QueryHookOptions<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>(GetRefundableIncompleteRidesDocument, options);
      }
export function useGetRefundableIncompleteRidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>(GetRefundableIncompleteRidesDocument, options);
        }
export function useGetRefundableIncompleteRidesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>(GetRefundableIncompleteRidesDocument, options);
        }
export type GetRefundableIncompleteRidesQueryHookResult = ReturnType<typeof useGetRefundableIncompleteRidesQuery>;
export type GetRefundableIncompleteRidesLazyQueryHookResult = ReturnType<typeof useGetRefundableIncompleteRidesLazyQuery>;
export type GetRefundableIncompleteRidesSuspenseQueryHookResult = ReturnType<typeof useGetRefundableIncompleteRidesSuspenseQuery>;
export type GetRefundableIncompleteRidesQueryResult = Apollo.QueryResult<IGetRefundableIncompleteRidesQuery, IGetRefundableIncompleteRidesQueryVariables>;
export const GetDefaultTravelPurposeDocument = gql`
    query getDefaultTravelPurpose($date: Date) {
  me {
    id
    defaultTravelPurpose(on: $date)
  }
}
    `;

/**
 * __useGetDefaultTravelPurposeQuery__
 *
 * To run a query within a React component, call `useGetDefaultTravelPurposeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultTravelPurposeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultTravelPurposeQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDefaultTravelPurposeQuery(baseOptions?: Apollo.QueryHookOptions<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>(GetDefaultTravelPurposeDocument, options);
      }
export function useGetDefaultTravelPurposeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>(GetDefaultTravelPurposeDocument, options);
        }
export function useGetDefaultTravelPurposeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>(GetDefaultTravelPurposeDocument, options);
        }
export type GetDefaultTravelPurposeQueryHookResult = ReturnType<typeof useGetDefaultTravelPurposeQuery>;
export type GetDefaultTravelPurposeLazyQueryHookResult = ReturnType<typeof useGetDefaultTravelPurposeLazyQuery>;
export type GetDefaultTravelPurposeSuspenseQueryHookResult = ReturnType<typeof useGetDefaultTravelPurposeSuspenseQuery>;
export type GetDefaultTravelPurposeQueryResult = Apollo.QueryResult<IGetDefaultTravelPurposeQuery, IGetDefaultTravelPurposeQueryVariables>;
export const LoadEditMobilityEventDocument = gql`
    query loadEditMobilityEvent($id: ID!) {
  me {
    id
    mobilityEvent(mobilityEventId: $id) {
      isRefund
      employment {
        budgetGroupName
        commuteMonthlyKmBudget
        commuteMonthlyMoneyBudget
        costCenter
        department
        endsOn
        id
        main
        startsOn
      }
      happenedAt
      what {
        reimbursableCategoryMethod {
          value
        }
        category {
          id
          requiresModality
          mainCategory {
            value
            humanized
          }
          subCategory {
            value
            humanized
          }
        }
        modality {
          id
          kind
          modalityOwnership {
            humanized
            value
          }
          humanizedWithCapital
          requiredPassengerCount
          requiresVehicle
          travelMode
          clazz
        }
        vehicle {
          id
          humanized
        }
        sharedRidePassengers
      }
      why {
        refundedMobilityEventId
        editable
        travelPurpose {
          value
        }
        explanation {
          humanized
          required {
            business
            commute
            personal
          }
        }
        project {
          id
          code
          humanized
        }
      }
      howMuch {
        consumption {
          amount
        }
        co2Emission {
          humanized
        }
      }
      where {
        ... on Route {
          from {
            humanized
            coordinates {
              latitude
              longitude
            }
            favoriteId
            locationId
          }
          to {
            humanized
            coordinates {
              latitude
              longitude
            }
            favoriteId
            locationId
          }
          track {
            longitude
            latitude
          }
          diverted {
            value
          }
          nonCombined {
            value
          }
        }
      }
      financial {
        fact {
          inclVat {
            value
          }
          receipt {
            contentType
            filename
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoadEditMobilityEventQuery__
 *
 * To run a query within a React component, call `useLoadEditMobilityEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditMobilityEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditMobilityEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadEditMobilityEventQuery(baseOptions: Apollo.QueryHookOptions<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables> & ({ variables: ILoadEditMobilityEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables>(LoadEditMobilityEventDocument, options);
      }
export function useLoadEditMobilityEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables>(LoadEditMobilityEventDocument, options);
        }
export function useLoadEditMobilityEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables>(LoadEditMobilityEventDocument, options);
        }
export type LoadEditMobilityEventQueryHookResult = ReturnType<typeof useLoadEditMobilityEventQuery>;
export type LoadEditMobilityEventLazyQueryHookResult = ReturnType<typeof useLoadEditMobilityEventLazyQuery>;
export type LoadEditMobilityEventSuspenseQueryHookResult = ReturnType<typeof useLoadEditMobilityEventSuspenseQuery>;
export type LoadEditMobilityEventQueryResult = Apollo.QueryResult<ILoadEditMobilityEventQuery, ILoadEditMobilityEventQueryVariables>;
export const TerminateMobilityEventDocument = gql`
    mutation terminateMobilityEvent($id: ID!) {
  destroyMobilityEvent(input: {id: $id}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useTerminateMobilityEventMutation__
 *
 * To run a mutation, you first call `useTerminateMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateMobilityEventMutation, { data, loading, error }] = useTerminateMobilityEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTerminateMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<ITerminateMobilityEventMutation, ITerminateMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITerminateMobilityEventMutation, ITerminateMobilityEventMutationVariables>(TerminateMobilityEventDocument, options);
      }
export type TerminateMobilityEventMutationHookResult = ReturnType<typeof useTerminateMobilityEventMutation>;
export type TerminateMobilityEventMutationResult = Apollo.MutationResult<ITerminateMobilityEventMutation>;
export type TerminateMobilityEventMutationOptions = Apollo.BaseMutationOptions<ITerminateMobilityEventMutation, ITerminateMobilityEventMutationVariables>;
export const ResetMobilityEventDocument = gql`
    mutation resetMobilityEvent($mobilityEventId: ID!) {
  unacceptMobilityEvent(input: {mobilityEventId: $mobilityEventId}) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
    mobilityEvent {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useResetMobilityEventMutation__
 *
 * To run a mutation, you first call `useResetMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMobilityEventMutation, { data, loading, error }] = useResetMobilityEventMutation({
 *   variables: {
 *      mobilityEventId: // value for 'mobilityEventId'
 *   },
 * });
 */
export function useResetMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<IResetMobilityEventMutation, IResetMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IResetMobilityEventMutation, IResetMobilityEventMutationVariables>(ResetMobilityEventDocument, options);
      }
export type ResetMobilityEventMutationHookResult = ReturnType<typeof useResetMobilityEventMutation>;
export type ResetMobilityEventMutationResult = Apollo.MutationResult<IResetMobilityEventMutation>;
export type ResetMobilityEventMutationOptions = Apollo.BaseMutationOptions<IResetMobilityEventMutation, IResetMobilityEventMutationVariables>;
export const NonCombinedAllowedDocument = gql`
    query nonCombinedAllowed($on: Date!) {
  me {
    id
    routeMetadata(on: $on) {
      nonCombinedAllowed
    }
  }
}
    `;

/**
 * __useNonCombinedAllowedQuery__
 *
 * To run a query within a React component, call `useNonCombinedAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonCombinedAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonCombinedAllowedQuery({
 *   variables: {
 *      on: // value for 'on'
 *   },
 * });
 */
export function useNonCombinedAllowedQuery(baseOptions: Apollo.QueryHookOptions<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables> & ({ variables: INonCombinedAllowedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables>(NonCombinedAllowedDocument, options);
      }
export function useNonCombinedAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables>(NonCombinedAllowedDocument, options);
        }
export function useNonCombinedAllowedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables>(NonCombinedAllowedDocument, options);
        }
export type NonCombinedAllowedQueryHookResult = ReturnType<typeof useNonCombinedAllowedQuery>;
export type NonCombinedAllowedLazyQueryHookResult = ReturnType<typeof useNonCombinedAllowedLazyQuery>;
export type NonCombinedAllowedSuspenseQueryHookResult = ReturnType<typeof useNonCombinedAllowedSuspenseQuery>;
export type NonCombinedAllowedQueryResult = Apollo.QueryResult<INonCombinedAllowedQuery, INonCombinedAllowedQueryVariables>;
export const LoadMobilityEventDocument = gql`
    query loadMobilityEvent($mobilityEventId: ID!) {
  me {
    id
    mobilityEvent(mobilityEventId: $mobilityEventId) {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useLoadMobilityEventQuery__
 *
 * To run a query within a React component, call `useLoadMobilityEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMobilityEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMobilityEventQuery({
 *   variables: {
 *      mobilityEventId: // value for 'mobilityEventId'
 *   },
 * });
 */
export function useLoadMobilityEventQuery(baseOptions: Apollo.QueryHookOptions<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables> & ({ variables: ILoadMobilityEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables>(LoadMobilityEventDocument, options);
      }
export function useLoadMobilityEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables>(LoadMobilityEventDocument, options);
        }
export function useLoadMobilityEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables>(LoadMobilityEventDocument, options);
        }
export type LoadMobilityEventQueryHookResult = ReturnType<typeof useLoadMobilityEventQuery>;
export type LoadMobilityEventLazyQueryHookResult = ReturnType<typeof useLoadMobilityEventLazyQuery>;
export type LoadMobilityEventSuspenseQueryHookResult = ReturnType<typeof useLoadMobilityEventSuspenseQuery>;
export type LoadMobilityEventQueryResult = Apollo.QueryResult<ILoadMobilityEventQuery, ILoadMobilityEventQueryVariables>;
export const GetMobilityEventFormValidationsDocument = gql`
    query getMobilityEventFormValidations($date: Date!) {
  me {
    id
    mobilityEventFormValidations(date: $date) {
      showWarningForWfhOnHoliday
      showWarningForWfhOnWeekend
    }
  }
}
    `;

/**
 * __useGetMobilityEventFormValidationsQuery__
 *
 * To run a query within a React component, call `useGetMobilityEventFormValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityEventFormValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityEventFormValidationsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetMobilityEventFormValidationsQuery(baseOptions: Apollo.QueryHookOptions<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables> & ({ variables: IGetMobilityEventFormValidationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables>(GetMobilityEventFormValidationsDocument, options);
      }
export function useGetMobilityEventFormValidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables>(GetMobilityEventFormValidationsDocument, options);
        }
export function useGetMobilityEventFormValidationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables>(GetMobilityEventFormValidationsDocument, options);
        }
export type GetMobilityEventFormValidationsQueryHookResult = ReturnType<typeof useGetMobilityEventFormValidationsQuery>;
export type GetMobilityEventFormValidationsLazyQueryHookResult = ReturnType<typeof useGetMobilityEventFormValidationsLazyQuery>;
export type GetMobilityEventFormValidationsSuspenseQueryHookResult = ReturnType<typeof useGetMobilityEventFormValidationsSuspenseQuery>;
export type GetMobilityEventFormValidationsQueryResult = Apollo.QueryResult<IGetMobilityEventFormValidationsQuery, IGetMobilityEventFormValidationsQueryVariables>;
export const GetDaysDocument = gql`
    query getDays($from: Date!, $to: Date!, $includeHomeWorkRides: Boolean!, $includeWorkHomeAllowance: Boolean!, $includeDailyAllowanceWorkHome: Boolean!) {
  me {
    id
    days(from: $from, to: $to) {
      ... on DayWithMobilityEvents {
        date
        commuteRideAddable @include(if: $includeHomeWorkRides)
        workFromHomeCompensationAddable @include(if: $includeWorkHomeAllowance)
        commuteCompensationAddable @include(if: $includeDailyAllowanceWorkHome)
      }
    }
  }
}
    `;

/**
 * __useGetDaysQuery__
 *
 * To run a query within a React component, call `useGetDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDaysQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      includeHomeWorkRides: // value for 'includeHomeWorkRides'
 *      includeWorkHomeAllowance: // value for 'includeWorkHomeAllowance'
 *      includeDailyAllowanceWorkHome: // value for 'includeDailyAllowanceWorkHome'
 *   },
 * });
 */
export function useGetDaysQuery(baseOptions: Apollo.QueryHookOptions<IGetDaysQuery, IGetDaysQueryVariables> & ({ variables: IGetDaysQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDaysQuery, IGetDaysQueryVariables>(GetDaysDocument, options);
      }
export function useGetDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDaysQuery, IGetDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDaysQuery, IGetDaysQueryVariables>(GetDaysDocument, options);
        }
export function useGetDaysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDaysQuery, IGetDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDaysQuery, IGetDaysQueryVariables>(GetDaysDocument, options);
        }
export type GetDaysQueryHookResult = ReturnType<typeof useGetDaysQuery>;
export type GetDaysLazyQueryHookResult = ReturnType<typeof useGetDaysLazyQuery>;
export type GetDaysSuspenseQueryHookResult = ReturnType<typeof useGetDaysSuspenseQuery>;
export type GetDaysQueryResult = Apollo.QueryResult<IGetDaysQuery, IGetDaysQueryVariables>;
export const GetMobilityEventsCalendarListDocument = gql`
    query getMobilityEventsCalendarList($happenedBetween: DateRangeInput, $limit: Int = 999) {
  me {
    id
    mobilityEvents(happenedBetween: $happenedBetween, limit: $limit) @connection(key: "mobilityEventsCalendarList") {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useGetMobilityEventsCalendarListQuery__
 *
 * To run a query within a React component, call `useGetMobilityEventsCalendarListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityEventsCalendarListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityEventsCalendarListQuery({
 *   variables: {
 *      happenedBetween: // value for 'happenedBetween'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMobilityEventsCalendarListQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>(GetMobilityEventsCalendarListDocument, options);
      }
export function useGetMobilityEventsCalendarListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>(GetMobilityEventsCalendarListDocument, options);
        }
export function useGetMobilityEventsCalendarListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>(GetMobilityEventsCalendarListDocument, options);
        }
export type GetMobilityEventsCalendarListQueryHookResult = ReturnType<typeof useGetMobilityEventsCalendarListQuery>;
export type GetMobilityEventsCalendarListLazyQueryHookResult = ReturnType<typeof useGetMobilityEventsCalendarListLazyQuery>;
export type GetMobilityEventsCalendarListSuspenseQueryHookResult = ReturnType<typeof useGetMobilityEventsCalendarListSuspenseQuery>;
export type GetMobilityEventsCalendarListQueryResult = Apollo.QueryResult<IGetMobilityEventsCalendarListQuery, IGetMobilityEventsCalendarListQueryVariables>;
export const GetMobilityEventsFilteredListDocument = gql`
    query getMobilityEventsFilteredList($filterByStatus: FilterStatusEnum, $limit: Int = 20, $offset: Int = 0, $modalities: [ModalityKindEnum!], $subCategories: [SubCategoryEnum!], $travelPurposes: [TravelPurposeEnum!], $filterByStatuses: [FilterStatusEnum!], $happenedBetween: DateRangeInput) {
  me {
    id
    mobilityEvents(
      filterByStatus: $filterByStatus
      travelPurposes: $travelPurposes
      subCategories: $subCategories
      modalities: $modalities
      offset: $offset
      filterByStatuses: $filterByStatuses
      limit: $limit
      happenedBetween: $happenedBetween
    ) @connection(key: "mobilityEventsFilteredList", filter: ["filterByStatus", "travelPurposes", "subCategories", "modalities", "filterByStatuses", "happenedBetween"]) {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useGetMobilityEventsFilteredListQuery__
 *
 * To run a query within a React component, call `useGetMobilityEventsFilteredListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityEventsFilteredListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityEventsFilteredListQuery({
 *   variables: {
 *      filterByStatus: // value for 'filterByStatus'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      modalities: // value for 'modalities'
 *      subCategories: // value for 'subCategories'
 *      travelPurposes: // value for 'travelPurposes'
 *      filterByStatuses: // value for 'filterByStatuses'
 *      happenedBetween: // value for 'happenedBetween'
 *   },
 * });
 */
export function useGetMobilityEventsFilteredListQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>(GetMobilityEventsFilteredListDocument, options);
      }
export function useGetMobilityEventsFilteredListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>(GetMobilityEventsFilteredListDocument, options);
        }
export function useGetMobilityEventsFilteredListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>(GetMobilityEventsFilteredListDocument, options);
        }
export type GetMobilityEventsFilteredListQueryHookResult = ReturnType<typeof useGetMobilityEventsFilteredListQuery>;
export type GetMobilityEventsFilteredListLazyQueryHookResult = ReturnType<typeof useGetMobilityEventsFilteredListLazyQuery>;
export type GetMobilityEventsFilteredListSuspenseQueryHookResult = ReturnType<typeof useGetMobilityEventsFilteredListSuspenseQuery>;
export type GetMobilityEventsFilteredListQueryResult = Apollo.QueryResult<IGetMobilityEventsFilteredListQuery, IGetMobilityEventsFilteredListQueryVariables>;
export const DestroyMobilityEventDocument = gql`
    mutation destroyMobilityEvent($id: ID!) {
  destroyMobilityEvent(input: {id: $id}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useDestroyMobilityEventMutation__
 *
 * To run a mutation, you first call `useDestroyMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyMobilityEventMutation, { data, loading, error }] = useDestroyMobilityEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<IDestroyMobilityEventMutation, IDestroyMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDestroyMobilityEventMutation, IDestroyMobilityEventMutationVariables>(DestroyMobilityEventDocument, options);
      }
export type DestroyMobilityEventMutationHookResult = ReturnType<typeof useDestroyMobilityEventMutation>;
export type DestroyMobilityEventMutationResult = Apollo.MutationResult<IDestroyMobilityEventMutation>;
export type DestroyMobilityEventMutationOptions = Apollo.BaseMutationOptions<IDestroyMobilityEventMutation, IDestroyMobilityEventMutationVariables>;
export const UnacceptMobilityEventDocument = gql`
    mutation unacceptMobilityEvent($id: ID!) {
  unacceptMobilityEvent(input: {mobilityEventId: $id}) {
    result {
      success
      error
    }
    mobilityEvent {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useUnacceptMobilityEventMutation__
 *
 * To run a mutation, you first call `useUnacceptMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnacceptMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unacceptMobilityEventMutation, { data, loading, error }] = useUnacceptMobilityEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnacceptMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<IUnacceptMobilityEventMutation, IUnacceptMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUnacceptMobilityEventMutation, IUnacceptMobilityEventMutationVariables>(UnacceptMobilityEventDocument, options);
      }
export type UnacceptMobilityEventMutationHookResult = ReturnType<typeof useUnacceptMobilityEventMutation>;
export type UnacceptMobilityEventMutationResult = Apollo.MutationResult<IUnacceptMobilityEventMutation>;
export type UnacceptMobilityEventMutationOptions = Apollo.BaseMutationOptions<IUnacceptMobilityEventMutation, IUnacceptMobilityEventMutationVariables>;
export const ConfirmSingleMobilityEventDocument = gql`
    mutation confirmSingleMobilityEvent($id: ID!) {
  confirmMobilityEvent(input: {id: $id}) {
    result {
      error
      success
    }
    mobilityEvent {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useConfirmSingleMobilityEventMutation__
 *
 * To run a mutation, you first call `useConfirmSingleMobilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmSingleMobilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmSingleMobilityEventMutation, { data, loading, error }] = useConfirmSingleMobilityEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmSingleMobilityEventMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmSingleMobilityEventMutation, IConfirmSingleMobilityEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmSingleMobilityEventMutation, IConfirmSingleMobilityEventMutationVariables>(ConfirmSingleMobilityEventDocument, options);
      }
export type ConfirmSingleMobilityEventMutationHookResult = ReturnType<typeof useConfirmSingleMobilityEventMutation>;
export type ConfirmSingleMobilityEventMutationResult = Apollo.MutationResult<IConfirmSingleMobilityEventMutation>;
export type ConfirmSingleMobilityEventMutationOptions = Apollo.BaseMutationOptions<IConfirmSingleMobilityEventMutation, IConfirmSingleMobilityEventMutationVariables>;
export const GetConfirmationModalPermissionDocument = gql`
    query getConfirmationModalPermission {
  me {
    id
    permissions {
      unacceptMobilityEvents
    }
  }
}
    `;

/**
 * __useGetConfirmationModalPermissionQuery__
 *
 * To run a query within a React component, call `useGetConfirmationModalPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfirmationModalPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfirmationModalPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfirmationModalPermissionQuery(baseOptions?: Apollo.QueryHookOptions<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>(GetConfirmationModalPermissionDocument, options);
      }
export function useGetConfirmationModalPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>(GetConfirmationModalPermissionDocument, options);
        }
export function useGetConfirmationModalPermissionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>(GetConfirmationModalPermissionDocument, options);
        }
export type GetConfirmationModalPermissionQueryHookResult = ReturnType<typeof useGetConfirmationModalPermissionQuery>;
export type GetConfirmationModalPermissionLazyQueryHookResult = ReturnType<typeof useGetConfirmationModalPermissionLazyQuery>;
export type GetConfirmationModalPermissionSuspenseQueryHookResult = ReturnType<typeof useGetConfirmationModalPermissionSuspenseQuery>;
export type GetConfirmationModalPermissionQueryResult = Apollo.QueryResult<IGetConfirmationModalPermissionQuery, IGetConfirmationModalPermissionQueryVariables>;
export const ConfirmMobilityEventsDocument = gql`
    mutation confirmMobilityEvents($mobilityEventIds: [ID!]!) {
  confirmMobilityEvents(input: {mobilityEventIds: $mobilityEventIds}) {
    result {
      error
      success
    }
    confirmedMobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useConfirmMobilityEventsMutation__
 *
 * To run a mutation, you first call `useConfirmMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMobilityEventsMutation, { data, loading, error }] = useConfirmMobilityEventsMutation({
 *   variables: {
 *      mobilityEventIds: // value for 'mobilityEventIds'
 *   },
 * });
 */
export function useConfirmMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmMobilityEventsMutation, IConfirmMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmMobilityEventsMutation, IConfirmMobilityEventsMutationVariables>(ConfirmMobilityEventsDocument, options);
      }
export type ConfirmMobilityEventsMutationHookResult = ReturnType<typeof useConfirmMobilityEventsMutation>;
export type ConfirmMobilityEventsMutationResult = Apollo.MutationResult<IConfirmMobilityEventsMutation>;
export type ConfirmMobilityEventsMutationOptions = Apollo.BaseMutationOptions<IConfirmMobilityEventsMutation, IConfirmMobilityEventsMutationVariables>;
export const CreateCopiedMobilityEventsDocument = gql`
    mutation createCopiedMobilityEvents($from: Date!, $to: [Date!]!) {
  copyMobilityEventsForDay(input: {from: $from, to: $to}) {
    result {
      error
      success
    }
    days {
      ... on DayWithMobilityEvents {
        date
        mobilityEvents {
          ...ShowFragment
        }
      }
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useCreateCopiedMobilityEventsMutation__
 *
 * To run a mutation, you first call `useCreateCopiedMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCopiedMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCopiedMobilityEventsMutation, { data, loading, error }] = useCreateCopiedMobilityEventsMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCreateCopiedMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<ICreateCopiedMobilityEventsMutation, ICreateCopiedMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateCopiedMobilityEventsMutation, ICreateCopiedMobilityEventsMutationVariables>(CreateCopiedMobilityEventsDocument, options);
      }
export type CreateCopiedMobilityEventsMutationHookResult = ReturnType<typeof useCreateCopiedMobilityEventsMutation>;
export type CreateCopiedMobilityEventsMutationResult = Apollo.MutationResult<ICreateCopiedMobilityEventsMutation>;
export type CreateCopiedMobilityEventsMutationOptions = Apollo.BaseMutationOptions<ICreateCopiedMobilityEventsMutation, ICreateCopiedMobilityEventsMutationVariables>;
export const CreateCommuteCompensationDocument = gql`
    mutation createCommuteCompensation($input: CreateCommuteCompensationInput!) {
  createCommuteCompensation(input: $input) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
    mobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useCreateCommuteCompensationMutation__
 *
 * To run a mutation, you first call `useCreateCommuteCompensationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommuteCompensationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommuteCompensationMutation, { data, loading, error }] = useCreateCommuteCompensationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommuteCompensationMutation(baseOptions?: Apollo.MutationHookOptions<ICreateCommuteCompensationMutation, ICreateCommuteCompensationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateCommuteCompensationMutation, ICreateCommuteCompensationMutationVariables>(CreateCommuteCompensationDocument, options);
      }
export type CreateCommuteCompensationMutationHookResult = ReturnType<typeof useCreateCommuteCompensationMutation>;
export type CreateCommuteCompensationMutationResult = Apollo.MutationResult<ICreateCommuteCompensationMutation>;
export type CreateCommuteCompensationMutationOptions = Apollo.BaseMutationOptions<ICreateCommuteCompensationMutation, ICreateCommuteCompensationMutationVariables>;
export const CreateCommuteRidesDocument = gql`
    mutation createCommuteRides($dates: [Date!], $confirm: Boolean) {
  createCommuteRides(input: {dates: $dates, confirm: $confirm}) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
    mobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useCreateCommuteRidesMutation__
 *
 * To run a mutation, you first call `useCreateCommuteRidesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommuteRidesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommuteRidesMutation, { data, loading, error }] = useCreateCommuteRidesMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      confirm: // value for 'confirm'
 *   },
 * });
 */
export function useCreateCommuteRidesMutation(baseOptions?: Apollo.MutationHookOptions<ICreateCommuteRidesMutation, ICreateCommuteRidesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateCommuteRidesMutation, ICreateCommuteRidesMutationVariables>(CreateCommuteRidesDocument, options);
      }
export type CreateCommuteRidesMutationHookResult = ReturnType<typeof useCreateCommuteRidesMutation>;
export type CreateCommuteRidesMutationResult = Apollo.MutationResult<ICreateCommuteRidesMutation>;
export type CreateCommuteRidesMutationOptions = Apollo.BaseMutationOptions<ICreateCommuteRidesMutation, ICreateCommuteRidesMutationVariables>;
export const CreateWorkFromHomeDocument = gql`
    mutation createWorkFromHome($dates: [Date!], $confirm: Boolean) {
  createWorkFromHome(input: {dates: $dates, confirm: $confirm}) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
    mobilityEvents {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useCreateWorkFromHomeMutation__
 *
 * To run a mutation, you first call `useCreateWorkFromHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkFromHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkFromHomeMutation, { data, loading, error }] = useCreateWorkFromHomeMutation({
 *   variables: {
 *      dates: // value for 'dates'
 *      confirm: // value for 'confirm'
 *   },
 * });
 */
export function useCreateWorkFromHomeMutation(baseOptions?: Apollo.MutationHookOptions<ICreateWorkFromHomeMutation, ICreateWorkFromHomeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateWorkFromHomeMutation, ICreateWorkFromHomeMutationVariables>(CreateWorkFromHomeDocument, options);
      }
export type CreateWorkFromHomeMutationHookResult = ReturnType<typeof useCreateWorkFromHomeMutation>;
export type CreateWorkFromHomeMutationResult = Apollo.MutationResult<ICreateWorkFromHomeMutation>;
export type CreateWorkFromHomeMutationOptions = Apollo.BaseMutationOptions<ICreateWorkFromHomeMutation, ICreateWorkFromHomeMutationVariables>;
export const GetMobilityEventMenuInformationDocument = gql`
    query getMobilityEventMenuInformation {
  me {
    id
    profile {
      activeSince
      employeeNumber
    }
    permissions {
      manageWorkFromHome
      manageCommuteCompensation
      manageMobilityEvents
      manageCommuteRides
    }
  }
}
    `;

/**
 * __useGetMobilityEventMenuInformationQuery__
 *
 * To run a query within a React component, call `useGetMobilityEventMenuInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityEventMenuInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityEventMenuInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobilityEventMenuInformationQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>(GetMobilityEventMenuInformationDocument, options);
      }
export function useGetMobilityEventMenuInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>(GetMobilityEventMenuInformationDocument, options);
        }
export function useGetMobilityEventMenuInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>(GetMobilityEventMenuInformationDocument, options);
        }
export type GetMobilityEventMenuInformationQueryHookResult = ReturnType<typeof useGetMobilityEventMenuInformationQuery>;
export type GetMobilityEventMenuInformationLazyQueryHookResult = ReturnType<typeof useGetMobilityEventMenuInformationLazyQuery>;
export type GetMobilityEventMenuInformationSuspenseQueryHookResult = ReturnType<typeof useGetMobilityEventMenuInformationSuspenseQuery>;
export type GetMobilityEventMenuInformationQueryResult = Apollo.QueryResult<IGetMobilityEventMenuInformationQuery, IGetMobilityEventMenuInformationQueryVariables>;
export const GetActiveSinceDocument = gql`
    query getActiveSince {
  me {
    id
    profile {
      activeSince
      employeeNumber
    }
  }
}
    `;

/**
 * __useGetActiveSinceQuery__
 *
 * To run a query within a React component, call `useGetActiveSinceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSinceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSinceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveSinceQuery(baseOptions?: Apollo.QueryHookOptions<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>(GetActiveSinceDocument, options);
      }
export function useGetActiveSinceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>(GetActiveSinceDocument, options);
        }
export function useGetActiveSinceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>(GetActiveSinceDocument, options);
        }
export type GetActiveSinceQueryHookResult = ReturnType<typeof useGetActiveSinceQuery>;
export type GetActiveSinceLazyQueryHookResult = ReturnType<typeof useGetActiveSinceLazyQuery>;
export type GetActiveSinceSuspenseQueryHookResult = ReturnType<typeof useGetActiveSinceSuspenseQuery>;
export type GetActiveSinceQueryResult = Apollo.QueryResult<IGetActiveSinceQuery, IGetActiveSinceQueryVariables>;
export const GetMobilityEventsAggregateDocument = gql`
    query getMobilityEventsAggregate {
  me {
    id
    mobilityEventsAggregate(includeAll: true) {
      count
    }
  }
}
    `;

/**
 * __useGetMobilityEventsAggregateQuery__
 *
 * To run a query within a React component, call `useGetMobilityEventsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityEventsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityEventsAggregateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobilityEventsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>(GetMobilityEventsAggregateDocument, options);
      }
export function useGetMobilityEventsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>(GetMobilityEventsAggregateDocument, options);
        }
export function useGetMobilityEventsAggregateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>(GetMobilityEventsAggregateDocument, options);
        }
export type GetMobilityEventsAggregateQueryHookResult = ReturnType<typeof useGetMobilityEventsAggregateQuery>;
export type GetMobilityEventsAggregateLazyQueryHookResult = ReturnType<typeof useGetMobilityEventsAggregateLazyQuery>;
export type GetMobilityEventsAggregateSuspenseQueryHookResult = ReturnType<typeof useGetMobilityEventsAggregateSuspenseQuery>;
export type GetMobilityEventsAggregateQueryResult = Apollo.QueryResult<IGetMobilityEventsAggregateQuery, IGetMobilityEventsAggregateQueryVariables>;
export const GetMobilityEventsExportDocument = gql`
    query getMobilityEventsExport {
  me {
    id
    exportFiles {
      transactionsExport
    }
  }
}
    `;

/**
 * __useGetMobilityEventsExportQuery__
 *
 * To run a query within a React component, call `useGetMobilityEventsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilityEventsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilityEventsExportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobilityEventsExportQuery(baseOptions?: Apollo.QueryHookOptions<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>(GetMobilityEventsExportDocument, options);
      }
export function useGetMobilityEventsExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>(GetMobilityEventsExportDocument, options);
        }
export function useGetMobilityEventsExportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>(GetMobilityEventsExportDocument, options);
        }
export type GetMobilityEventsExportQueryHookResult = ReturnType<typeof useGetMobilityEventsExportQuery>;
export type GetMobilityEventsExportLazyQueryHookResult = ReturnType<typeof useGetMobilityEventsExportLazyQuery>;
export type GetMobilityEventsExportSuspenseQueryHookResult = ReturnType<typeof useGetMobilityEventsExportSuspenseQuery>;
export type GetMobilityEventsExportQueryResult = Apollo.QueryResult<IGetMobilityEventsExportQuery, IGetMobilityEventsExportQueryVariables>;
export const MergeMobilityEventsDocument = gql`
    mutation mergeMobilityEvents($mobilityEventIds: [ID!]!) {
  mergeMobilityEvents(input: {mobilityEventIds: $mobilityEventIds}) {
    result {
      error
      success
    }
    mergedMobilityEvent {
      ...ShowFragment
    }
  }
}
    ${ShowFragmentFragmentDoc}`;

/**
 * __useMergeMobilityEventsMutation__
 *
 * To run a mutation, you first call `useMergeMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeMobilityEventsMutation, { data, loading, error }] = useMergeMobilityEventsMutation({
 *   variables: {
 *      mobilityEventIds: // value for 'mobilityEventIds'
 *   },
 * });
 */
export function useMergeMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<IMergeMobilityEventsMutation, IMergeMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IMergeMobilityEventsMutation, IMergeMobilityEventsMutationVariables>(MergeMobilityEventsDocument, options);
      }
export type MergeMobilityEventsMutationHookResult = ReturnType<typeof useMergeMobilityEventsMutation>;
export type MergeMobilityEventsMutationResult = Apollo.MutationResult<IMergeMobilityEventsMutation>;
export type MergeMobilityEventsMutationOptions = Apollo.BaseMutationOptions<IMergeMobilityEventsMutation, IMergeMobilityEventsMutationVariables>;
export const MergedMobilityEventsPreviewDocument = gql`
    query mergedMobilityEventsPreview($mobilityEventIds: [ID!]!) {
  me {
    id
    mergedMobilityEventsPreview(mobilityEventIds: $mobilityEventIds) {
      mobilityEventPreview {
        id
        happenedAt
        when {
          ... on Timestamp {
            at
          }
          ... on Duration {
            startsAt
            endsAt
          }
        }
        where {
          ... on Route {
            from {
              humanized
            }
            to {
              humanized
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMergedMobilityEventsPreviewQuery__
 *
 * To run a query within a React component, call `useMergedMobilityEventsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergedMobilityEventsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergedMobilityEventsPreviewQuery({
 *   variables: {
 *      mobilityEventIds: // value for 'mobilityEventIds'
 *   },
 * });
 */
export function useMergedMobilityEventsPreviewQuery(baseOptions: Apollo.QueryHookOptions<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables> & ({ variables: IMergedMobilityEventsPreviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables>(MergedMobilityEventsPreviewDocument, options);
      }
export function useMergedMobilityEventsPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables>(MergedMobilityEventsPreviewDocument, options);
        }
export function useMergedMobilityEventsPreviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables>(MergedMobilityEventsPreviewDocument, options);
        }
export type MergedMobilityEventsPreviewQueryHookResult = ReturnType<typeof useMergedMobilityEventsPreviewQuery>;
export type MergedMobilityEventsPreviewLazyQueryHookResult = ReturnType<typeof useMergedMobilityEventsPreviewLazyQuery>;
export type MergedMobilityEventsPreviewSuspenseQueryHookResult = ReturnType<typeof useMergedMobilityEventsPreviewSuspenseQuery>;
export type MergedMobilityEventsPreviewQueryResult = Apollo.QueryResult<IMergedMobilityEventsPreviewQuery, IMergedMobilityEventsPreviewQueryVariables>;
export const DeleteMobilityEventsDocument = gql`
    mutation deleteMobilityEvents($mobilityEventIds: [ID!]!) {
  destroyMobilityEvents(input: {mobilityEventIds: $mobilityEventIds}) {
    destroyedMobilityEventIds
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useDeleteMobilityEventsMutation__
 *
 * To run a mutation, you first call `useDeleteMobilityEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobilityEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobilityEventsMutation, { data, loading, error }] = useDeleteMobilityEventsMutation({
 *   variables: {
 *      mobilityEventIds: // value for 'mobilityEventIds'
 *   },
 * });
 */
export function useDeleteMobilityEventsMutation(baseOptions?: Apollo.MutationHookOptions<IDeleteMobilityEventsMutation, IDeleteMobilityEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDeleteMobilityEventsMutation, IDeleteMobilityEventsMutationVariables>(DeleteMobilityEventsDocument, options);
      }
export type DeleteMobilityEventsMutationHookResult = ReturnType<typeof useDeleteMobilityEventsMutation>;
export type DeleteMobilityEventsMutationResult = Apollo.MutationResult<IDeleteMobilityEventsMutation>;
export type DeleteMobilityEventsMutationOptions = Apollo.BaseMutationOptions<IDeleteMobilityEventsMutation, IDeleteMobilityEventsMutationVariables>;
export const GetFilterOptionsMobilityEventsModalityDocument = gql`
    query getFilterOptionsMobilityEventsModality {
  me {
    id
    filterOptions {
      mobilityEventsModality {
        humanized
        value
      }
    }
  }
}
    `;

/**
 * __useGetFilterOptionsMobilityEventsModalityQuery__
 *
 * To run a query within a React component, call `useGetFilterOptionsMobilityEventsModalityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterOptionsMobilityEventsModalityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterOptionsMobilityEventsModalityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterOptionsMobilityEventsModalityQuery(baseOptions?: Apollo.QueryHookOptions<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>(GetFilterOptionsMobilityEventsModalityDocument, options);
      }
export function useGetFilterOptionsMobilityEventsModalityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>(GetFilterOptionsMobilityEventsModalityDocument, options);
        }
export function useGetFilterOptionsMobilityEventsModalitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>(GetFilterOptionsMobilityEventsModalityDocument, options);
        }
export type GetFilterOptionsMobilityEventsModalityQueryHookResult = ReturnType<typeof useGetFilterOptionsMobilityEventsModalityQuery>;
export type GetFilterOptionsMobilityEventsModalityLazyQueryHookResult = ReturnType<typeof useGetFilterOptionsMobilityEventsModalityLazyQuery>;
export type GetFilterOptionsMobilityEventsModalitySuspenseQueryHookResult = ReturnType<typeof useGetFilterOptionsMobilityEventsModalitySuspenseQuery>;
export type GetFilterOptionsMobilityEventsModalityQueryResult = Apollo.QueryResult<IGetFilterOptionsMobilityEventsModalityQuery, IGetFilterOptionsMobilityEventsModalityQueryVariables>;
export const GetManageMobilityEventsDocument = gql`
    query getManageMobilityEvents {
  me {
    id
    permissions {
      manageMobilityEvents
      manageCommuteCompensation
      manageCommuteRides
      manageWorkFromHome
    }
  }
}
    `;

/**
 * __useGetManageMobilityEventsQuery__
 *
 * To run a query within a React component, call `useGetManageMobilityEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageMobilityEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageMobilityEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManageMobilityEventsQuery(baseOptions?: Apollo.QueryHookOptions<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>(GetManageMobilityEventsDocument, options);
      }
export function useGetManageMobilityEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>(GetManageMobilityEventsDocument, options);
        }
export function useGetManageMobilityEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>(GetManageMobilityEventsDocument, options);
        }
export type GetManageMobilityEventsQueryHookResult = ReturnType<typeof useGetManageMobilityEventsQuery>;
export type GetManageMobilityEventsLazyQueryHookResult = ReturnType<typeof useGetManageMobilityEventsLazyQuery>;
export type GetManageMobilityEventsSuspenseQueryHookResult = ReturnType<typeof useGetManageMobilityEventsSuspenseQuery>;
export type GetManageMobilityEventsQueryResult = Apollo.QueryResult<IGetManageMobilityEventsQuery, IGetManageMobilityEventsQueryVariables>;
export const CreateFavoriteDocument = gql`
    mutation createFavorite($input: CreateFavoriteInput!) {
  favorites {
    create(input: $input) {
      favorite {
        ...Favorite
      }
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
    }
  }
}
    ${FavoriteFragmentDoc}`;

/**
 * __useCreateFavoriteMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteMutation, { data, loading, error }] = useCreateFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<ICreateFavoriteMutation, ICreateFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateFavoriteMutation, ICreateFavoriteMutationVariables>(CreateFavoriteDocument, options);
      }
export type CreateFavoriteMutationHookResult = ReturnType<typeof useCreateFavoriteMutation>;
export type CreateFavoriteMutationResult = Apollo.MutationResult<ICreateFavoriteMutation>;
export type CreateFavoriteMutationOptions = Apollo.BaseMutationOptions<ICreateFavoriteMutation, ICreateFavoriteMutationVariables>;
export const DestroyFavoriteDocument = gql`
    mutation destroyFavorite($id: ID!) {
  favorites {
    destroy(input: {id: $id}) {
      result {
        error
        success
      }
    }
  }
}
    `;

/**
 * __useDestroyFavoriteMutation__
 *
 * To run a mutation, you first call `useDestroyFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyFavoriteMutation, { data, loading, error }] = useDestroyFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<IDestroyFavoriteMutation, IDestroyFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDestroyFavoriteMutation, IDestroyFavoriteMutationVariables>(DestroyFavoriteDocument, options);
      }
export type DestroyFavoriteMutationHookResult = ReturnType<typeof useDestroyFavoriteMutation>;
export type DestroyFavoriteMutationResult = Apollo.MutationResult<IDestroyFavoriteMutation>;
export type DestroyFavoriteMutationOptions = Apollo.BaseMutationOptions<IDestroyFavoriteMutation, IDestroyFavoriteMutationVariables>;
export const UpdateFavoriteDocument = gql`
    mutation updateFavorite($input: UpdateFavoriteInput!) {
  favorites {
    update(input: $input) {
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
      favorite {
        ...Favorite
      }
    }
  }
}
    ${FavoriteFragmentDoc}`;

/**
 * __useUpdateFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoriteMutation, { data, loading, error }] = useUpdateFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateFavoriteMutation, IUpdateFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateFavoriteMutation, IUpdateFavoriteMutationVariables>(UpdateFavoriteDocument, options);
      }
export type UpdateFavoriteMutationHookResult = ReturnType<typeof useUpdateFavoriteMutation>;
export type UpdateFavoriteMutationResult = Apollo.MutationResult<IUpdateFavoriteMutation>;
export type UpdateFavoriteMutationOptions = Apollo.BaseMutationOptions<IUpdateFavoriteMutation, IUpdateFavoriteMutationVariables>;
export const GetFavoritesDocument = gql`
    query getFavorites {
  me {
    id
    favorites {
      ...Favorite
    }
  }
}
    ${FavoriteFragmentDoc}`;

/**
 * __useGetFavoritesQuery__
 *
 * To run a query within a React component, call `useGetFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFavoritesQuery(baseOptions?: Apollo.QueryHookOptions<IGetFavoritesQuery, IGetFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFavoritesQuery, IGetFavoritesQueryVariables>(GetFavoritesDocument, options);
      }
export function useGetFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFavoritesQuery, IGetFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFavoritesQuery, IGetFavoritesQueryVariables>(GetFavoritesDocument, options);
        }
export function useGetFavoritesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFavoritesQuery, IGetFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFavoritesQuery, IGetFavoritesQueryVariables>(GetFavoritesDocument, options);
        }
export type GetFavoritesQueryHookResult = ReturnType<typeof useGetFavoritesQuery>;
export type GetFavoritesLazyQueryHookResult = ReturnType<typeof useGetFavoritesLazyQuery>;
export type GetFavoritesSuspenseQueryHookResult = ReturnType<typeof useGetFavoritesSuspenseQuery>;
export type GetFavoritesQueryResult = Apollo.QueryResult<IGetFavoritesQuery, IGetFavoritesQueryVariables>;
export const GetFinancialTentativeSettlementsDocument = gql`
    query getFinancialTentativeSettlements {
  me {
    id
    financialConsequences {
      tentativeSettlement {
        totalGrossAndNetCharge {
          valueInCents
          label
        }
        totalGrossAndNetReimbursement {
          valueInCents
          label
        }
        travelPurposes {
          totalGrossAndNetCharge {
            valueInCents
          }
          travelPurpose {
            humanized
            value
          }
          totalGrossAndNetReimbursement {
            valueInCents
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFinancialTentativeSettlementsQuery__
 *
 * To run a query within a React component, call `useGetFinancialTentativeSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialTentativeSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialTentativeSettlementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancialTentativeSettlementsQuery(baseOptions?: Apollo.QueryHookOptions<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>(GetFinancialTentativeSettlementsDocument, options);
      }
export function useGetFinancialTentativeSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>(GetFinancialTentativeSettlementsDocument, options);
        }
export function useGetFinancialTentativeSettlementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>(GetFinancialTentativeSettlementsDocument, options);
        }
export type GetFinancialTentativeSettlementsQueryHookResult = ReturnType<typeof useGetFinancialTentativeSettlementsQuery>;
export type GetFinancialTentativeSettlementsLazyQueryHookResult = ReturnType<typeof useGetFinancialTentativeSettlementsLazyQuery>;
export type GetFinancialTentativeSettlementsSuspenseQueryHookResult = ReturnType<typeof useGetFinancialTentativeSettlementsSuspenseQuery>;
export type GetFinancialTentativeSettlementsQueryResult = Apollo.QueryResult<IGetFinancialTentativeSettlementsQuery, IGetFinancialTentativeSettlementsQueryVariables>;
export const GetRemainingBudgetDocument = gql`
    query getRemainingBudget {
  me {
    id
    financialConsequences {
      budgetTransactionRemainder {
        valueInCents
      }
    }
  }
}
    `;

/**
 * __useGetRemainingBudgetQuery__
 *
 * To run a query within a React component, call `useGetRemainingBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemainingBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemainingBudgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRemainingBudgetQuery(baseOptions?: Apollo.QueryHookOptions<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>(GetRemainingBudgetDocument, options);
      }
export function useGetRemainingBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>(GetRemainingBudgetDocument, options);
        }
export function useGetRemainingBudgetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>(GetRemainingBudgetDocument, options);
        }
export type GetRemainingBudgetQueryHookResult = ReturnType<typeof useGetRemainingBudgetQuery>;
export type GetRemainingBudgetLazyQueryHookResult = ReturnType<typeof useGetRemainingBudgetLazyQuery>;
export type GetRemainingBudgetSuspenseQueryHookResult = ReturnType<typeof useGetRemainingBudgetSuspenseQuery>;
export type GetRemainingBudgetQueryResult = Apollo.QueryResult<IGetRemainingBudgetQuery, IGetRemainingBudgetQueryVariables>;
export const UpdatePasswordSendInformationDocument = gql`
    mutation updatePasswordSendInformation($email: String!) {
  sendPasswordResetMail(input: {email: $email}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useUpdatePasswordSendInformationMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordSendInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordSendInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordSendInformationMutation, { data, loading, error }] = useUpdatePasswordSendInformationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdatePasswordSendInformationMutation(baseOptions?: Apollo.MutationHookOptions<IUpdatePasswordSendInformationMutation, IUpdatePasswordSendInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdatePasswordSendInformationMutation, IUpdatePasswordSendInformationMutationVariables>(UpdatePasswordSendInformationDocument, options);
      }
export type UpdatePasswordSendInformationMutationHookResult = ReturnType<typeof useUpdatePasswordSendInformationMutation>;
export type UpdatePasswordSendInformationMutationResult = Apollo.MutationResult<IUpdatePasswordSendInformationMutation>;
export type UpdatePasswordSendInformationMutationOptions = Apollo.BaseMutationOptions<IUpdatePasswordSendInformationMutation, IUpdatePasswordSendInformationMutationVariables>;
export const GetAuthorizedHelpDataDocument = gql`
    query GetAuthorizedHelpData {
  me {
    id
    organization {
      policyDocumentUrl
      customerService {
        emailAddress
        helpUrlName
        helpUrl
        telephone
        privacyPolicyUrl
      }
    }
  }
  externalUrls {
    nsRefundUrl {
      title
      url
    }
  }
}
    `;

/**
 * __useGetAuthorizedHelpDataQuery__
 *
 * To run a query within a React component, call `useGetAuthorizedHelpDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorizedHelpDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorizedHelpDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthorizedHelpDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>(GetAuthorizedHelpDataDocument, options);
      }
export function useGetAuthorizedHelpDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>(GetAuthorizedHelpDataDocument, options);
        }
export function useGetAuthorizedHelpDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>(GetAuthorizedHelpDataDocument, options);
        }
export type GetAuthorizedHelpDataQueryHookResult = ReturnType<typeof useGetAuthorizedHelpDataQuery>;
export type GetAuthorizedHelpDataLazyQueryHookResult = ReturnType<typeof useGetAuthorizedHelpDataLazyQuery>;
export type GetAuthorizedHelpDataSuspenseQueryHookResult = ReturnType<typeof useGetAuthorizedHelpDataSuspenseQuery>;
export type GetAuthorizedHelpDataQueryResult = Apollo.QueryResult<IGetAuthorizedHelpDataQuery, IGetAuthorizedHelpDataQueryVariables>;
export const GetUnauthorizedHelpDataDocument = gql`
    query GetUnauthorizedHelpData($hostname: String!) {
  customerService(host: $hostname) {
    telephone
    emailAddress
    helpUrlName
    privacyPolicyUrl
  }
  externalUrls {
    nsRefundUrl {
      title
      url
    }
  }
}
    `;

/**
 * __useGetUnauthorizedHelpDataQuery__
 *
 * To run a query within a React component, call `useGetUnauthorizedHelpDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnauthorizedHelpDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnauthorizedHelpDataQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useGetUnauthorizedHelpDataQuery(baseOptions: Apollo.QueryHookOptions<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables> & ({ variables: IGetUnauthorizedHelpDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables>(GetUnauthorizedHelpDataDocument, options);
      }
export function useGetUnauthorizedHelpDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables>(GetUnauthorizedHelpDataDocument, options);
        }
export function useGetUnauthorizedHelpDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables>(GetUnauthorizedHelpDataDocument, options);
        }
export type GetUnauthorizedHelpDataQueryHookResult = ReturnType<typeof useGetUnauthorizedHelpDataQuery>;
export type GetUnauthorizedHelpDataLazyQueryHookResult = ReturnType<typeof useGetUnauthorizedHelpDataLazyQuery>;
export type GetUnauthorizedHelpDataSuspenseQueryHookResult = ReturnType<typeof useGetUnauthorizedHelpDataSuspenseQuery>;
export type GetUnauthorizedHelpDataQueryResult = Apollo.QueryResult<IGetUnauthorizedHelpDataQuery, IGetUnauthorizedHelpDataQueryVariables>;
export const CreateMileageDocument = gql`
    mutation createMileage($licensePlate: String!, $amountInKilometers: Int!, $recordedAt: DateTime!) {
  mileages {
    create(
      input: {licensePlate: $licensePlate, amountInKilometers: $amountInKilometers, recordedAt: $recordedAt}
    ) {
      result {
        error
        success
      }
      validationErrors {
        ...ValidationError
      }
      mileage {
        ...Mileage
      }
    }
  }
}
    ${ValidationErrorFragmentDoc}
${MileageFragmentDoc}`;

/**
 * __useCreateMileageMutation__
 *
 * To run a mutation, you first call `useCreateMileageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMileageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMileageMutation, { data, loading, error }] = useCreateMileageMutation({
 *   variables: {
 *      licensePlate: // value for 'licensePlate'
 *      amountInKilometers: // value for 'amountInKilometers'
 *      recordedAt: // value for 'recordedAt'
 *   },
 * });
 */
export function useCreateMileageMutation(baseOptions?: Apollo.MutationHookOptions<ICreateMileageMutation, ICreateMileageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateMileageMutation, ICreateMileageMutationVariables>(CreateMileageDocument, options);
      }
export type CreateMileageMutationHookResult = ReturnType<typeof useCreateMileageMutation>;
export type CreateMileageMutationResult = Apollo.MutationResult<ICreateMileageMutation>;
export type CreateMileageMutationOptions = Apollo.BaseMutationOptions<ICreateMileageMutation, ICreateMileageMutationVariables>;
export const UpdateMileageDocument = gql`
    mutation updateMileage($id: ID!, $licensePlate: String, $amountInKilometers: Int, $recordedAt: DateTime) {
  mileages {
    update(
      input: {id: $id, licensePlate: $licensePlate, amountInKilometers: $amountInKilometers, recordedAt: $recordedAt}
    ) {
      result {
        error
        success
      }
      validationErrors {
        ...ValidationError
      }
      mileage {
        ...Mileage
      }
    }
  }
}
    ${ValidationErrorFragmentDoc}
${MileageFragmentDoc}`;

/**
 * __useUpdateMileageMutation__
 *
 * To run a mutation, you first call `useUpdateMileageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMileageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMileageMutation, { data, loading, error }] = useUpdateMileageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      licensePlate: // value for 'licensePlate'
 *      amountInKilometers: // value for 'amountInKilometers'
 *      recordedAt: // value for 'recordedAt'
 *   },
 * });
 */
export function useUpdateMileageMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateMileageMutation, IUpdateMileageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateMileageMutation, IUpdateMileageMutationVariables>(UpdateMileageDocument, options);
      }
export type UpdateMileageMutationHookResult = ReturnType<typeof useUpdateMileageMutation>;
export type UpdateMileageMutationResult = Apollo.MutationResult<IUpdateMileageMutation>;
export type UpdateMileageMutationOptions = Apollo.BaseMutationOptions<IUpdateMileageMutation, IUpdateMileageMutationVariables>;
export const TerminatePersonalVehicleDocument = gql`
    mutation terminatePersonalVehicle($id: ID!, $endsOn: Date) {
  vehicle {
    terminate(input: {id: $id, endsOn: $endsOn}) {
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
      vehicle {
        ...PersonalVehicle
      }
    }
  }
}
    ${PersonalVehicleFragmentDoc}`;

/**
 * __useTerminatePersonalVehicleMutation__
 *
 * To run a mutation, you first call `useTerminatePersonalVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminatePersonalVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminatePersonalVehicleMutation, { data, loading, error }] = useTerminatePersonalVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      endsOn: // value for 'endsOn'
 *   },
 * });
 */
export function useTerminatePersonalVehicleMutation(baseOptions?: Apollo.MutationHookOptions<ITerminatePersonalVehicleMutation, ITerminatePersonalVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ITerminatePersonalVehicleMutation, ITerminatePersonalVehicleMutationVariables>(TerminatePersonalVehicleDocument, options);
      }
export type TerminatePersonalVehicleMutationHookResult = ReturnType<typeof useTerminatePersonalVehicleMutation>;
export type TerminatePersonalVehicleMutationResult = Apollo.MutationResult<ITerminatePersonalVehicleMutation>;
export type TerminatePersonalVehicleMutationOptions = Apollo.BaseMutationOptions<ITerminatePersonalVehicleMutation, ITerminatePersonalVehicleMutationVariables>;
export const GetLicensePlateDocument = gql`
    query getLicensePlate($licensePlate: String!) {
  me {
    id
    licensePlate(licensePlate: $licensePlate) {
      ...LicensePlate
    }
  }
}
    ${LicensePlateFragmentDoc}`;

/**
 * __useGetLicensePlateQuery__
 *
 * To run a query within a React component, call `useGetLicensePlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensePlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensePlateQuery({
 *   variables: {
 *      licensePlate: // value for 'licensePlate'
 *   },
 * });
 */
export function useGetLicensePlateQuery(baseOptions: Apollo.QueryHookOptions<IGetLicensePlateQuery, IGetLicensePlateQueryVariables> & ({ variables: IGetLicensePlateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetLicensePlateQuery, IGetLicensePlateQueryVariables>(GetLicensePlateDocument, options);
      }
export function useGetLicensePlateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetLicensePlateQuery, IGetLicensePlateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetLicensePlateQuery, IGetLicensePlateQueryVariables>(GetLicensePlateDocument, options);
        }
export function useGetLicensePlateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetLicensePlateQuery, IGetLicensePlateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetLicensePlateQuery, IGetLicensePlateQueryVariables>(GetLicensePlateDocument, options);
        }
export type GetLicensePlateQueryHookResult = ReturnType<typeof useGetLicensePlateQuery>;
export type GetLicensePlateLazyQueryHookResult = ReturnType<typeof useGetLicensePlateLazyQuery>;
export type GetLicensePlateSuspenseQueryHookResult = ReturnType<typeof useGetLicensePlateSuspenseQuery>;
export type GetLicensePlateQueryResult = Apollo.QueryResult<IGetLicensePlateQuery, IGetLicensePlateQueryVariables>;
export const GetPersonalVehicleDocument = gql`
    query getPersonalVehicle($personalVehicleId: ID!) {
  me {
    id
    personalVehicle(id: $personalVehicleId) {
      ...PersonalVehicle
    }
  }
}
    ${PersonalVehicleFragmentDoc}`;

/**
 * __useGetPersonalVehicleQuery__
 *
 * To run a query within a React component, call `useGetPersonalVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalVehicleQuery({
 *   variables: {
 *      personalVehicleId: // value for 'personalVehicleId'
 *   },
 * });
 */
export function useGetPersonalVehicleQuery(baseOptions: Apollo.QueryHookOptions<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables> & ({ variables: IGetPersonalVehicleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables>(GetPersonalVehicleDocument, options);
      }
export function useGetPersonalVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables>(GetPersonalVehicleDocument, options);
        }
export function useGetPersonalVehicleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables>(GetPersonalVehicleDocument, options);
        }
export type GetPersonalVehicleQueryHookResult = ReturnType<typeof useGetPersonalVehicleQuery>;
export type GetPersonalVehicleLazyQueryHookResult = ReturnType<typeof useGetPersonalVehicleLazyQuery>;
export type GetPersonalVehicleSuspenseQueryHookResult = ReturnType<typeof useGetPersonalVehicleSuspenseQuery>;
export type GetPersonalVehicleQueryResult = Apollo.QueryResult<IGetPersonalVehicleQuery, IGetPersonalVehicleQueryVariables>;
export const CreatePersonalVehicleDocument = gql`
    mutation createPersonalVehicle($input: CreateVehicleInput!) {
  vehicle {
    create(input: $input) {
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
      vehicle {
        ...PersonalVehicle
      }
    }
  }
}
    ${PersonalVehicleFragmentDoc}`;

/**
 * __useCreatePersonalVehicleMutation__
 *
 * To run a mutation, you first call `useCreatePersonalVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalVehicleMutation, { data, loading, error }] = useCreatePersonalVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePersonalVehicleMutation(baseOptions?: Apollo.MutationHookOptions<ICreatePersonalVehicleMutation, ICreatePersonalVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreatePersonalVehicleMutation, ICreatePersonalVehicleMutationVariables>(CreatePersonalVehicleDocument, options);
      }
export type CreatePersonalVehicleMutationHookResult = ReturnType<typeof useCreatePersonalVehicleMutation>;
export type CreatePersonalVehicleMutationResult = Apollo.MutationResult<ICreatePersonalVehicleMutation>;
export type CreatePersonalVehicleMutationOptions = Apollo.BaseMutationOptions<ICreatePersonalVehicleMutation, ICreatePersonalVehicleMutationVariables>;
export const GetFuelTypesDocument = gql`
    query getFuelTypes {
  fuelTypes {
    value
    humanized
  }
}
    `;

/**
 * __useGetFuelTypesQuery__
 *
 * To run a query within a React component, call `useGetFuelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFuelTypesQuery(baseOptions?: Apollo.QueryHookOptions<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>(GetFuelTypesDocument, options);
      }
export function useGetFuelTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>(GetFuelTypesDocument, options);
        }
export function useGetFuelTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>(GetFuelTypesDocument, options);
        }
export type GetFuelTypesQueryHookResult = ReturnType<typeof useGetFuelTypesQuery>;
export type GetFuelTypesLazyQueryHookResult = ReturnType<typeof useGetFuelTypesLazyQuery>;
export type GetFuelTypesSuspenseQueryHookResult = ReturnType<typeof useGetFuelTypesSuspenseQuery>;
export type GetFuelTypesQueryResult = Apollo.QueryResult<IGetFuelTypesQuery, IGetFuelTypesQueryVariables>;
export const GetPersonalVehicleFormPermissionsDocument = gql`
    query getPersonalVehicleFormPermissions {
  me {
    id
    permissions {
      addableVehicles {
        name {
          value
          humanized
        }
        icon {
          name
        }
        allowedFuelTypes {
          value
          humanized
        }
      }
    }
  }
}
    `;

/**
 * __useGetPersonalVehicleFormPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPersonalVehicleFormPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalVehicleFormPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalVehicleFormPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonalVehicleFormPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>(GetPersonalVehicleFormPermissionsDocument, options);
      }
export function useGetPersonalVehicleFormPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>(GetPersonalVehicleFormPermissionsDocument, options);
        }
export function useGetPersonalVehicleFormPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>(GetPersonalVehicleFormPermissionsDocument, options);
        }
export type GetPersonalVehicleFormPermissionsQueryHookResult = ReturnType<typeof useGetPersonalVehicleFormPermissionsQuery>;
export type GetPersonalVehicleFormPermissionsLazyQueryHookResult = ReturnType<typeof useGetPersonalVehicleFormPermissionsLazyQuery>;
export type GetPersonalVehicleFormPermissionsSuspenseQueryHookResult = ReturnType<typeof useGetPersonalVehicleFormPermissionsSuspenseQuery>;
export type GetPersonalVehicleFormPermissionsQueryResult = Apollo.QueryResult<IGetPersonalVehicleFormPermissionsQuery, IGetPersonalVehicleFormPermissionsQueryVariables>;
export const GetVehicleInformationDocument = gql`
    query getVehicleInformation($licensePlate: String!) {
  vehicleInformation(licensePlate: $licensePlate) {
    brand
    co2EmissionPerKilometer
    energyLabel
    model
    fuelType {
      humanized
      value
    }
  }
}
    `;

/**
 * __useGetVehicleInformationQuery__
 *
 * To run a query within a React component, call `useGetVehicleInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleInformationQuery({
 *   variables: {
 *      licensePlate: // value for 'licensePlate'
 *   },
 * });
 */
export function useGetVehicleInformationQuery(baseOptions: Apollo.QueryHookOptions<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables> & ({ variables: IGetVehicleInformationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables>(GetVehicleInformationDocument, options);
      }
export function useGetVehicleInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables>(GetVehicleInformationDocument, options);
        }
export function useGetVehicleInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables>(GetVehicleInformationDocument, options);
        }
export type GetVehicleInformationQueryHookResult = ReturnType<typeof useGetVehicleInformationQuery>;
export type GetVehicleInformationLazyQueryHookResult = ReturnType<typeof useGetVehicleInformationLazyQuery>;
export type GetVehicleInformationSuspenseQueryHookResult = ReturnType<typeof useGetVehicleInformationSuspenseQuery>;
export type GetVehicleInformationQueryResult = Apollo.QueryResult<IGetVehicleInformationQuery, IGetVehicleInformationQueryVariables>;
export const UpdatePersonalVehicleDocument = gql`
    mutation updatePersonalVehicle($input: UpdateVehicleInput!) {
  vehicle {
    update(input: $input) {
      result {
        error
        success
      }
      validationErrors {
        attribute
        fullMessages
        messages
      }
      vehicle {
        ...PersonalVehicle
      }
    }
  }
}
    ${PersonalVehicleFragmentDoc}`;

/**
 * __useUpdatePersonalVehicleMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalVehicleMutation, { data, loading, error }] = useUpdatePersonalVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonalVehicleMutation(baseOptions?: Apollo.MutationHookOptions<IUpdatePersonalVehicleMutation, IUpdatePersonalVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdatePersonalVehicleMutation, IUpdatePersonalVehicleMutationVariables>(UpdatePersonalVehicleDocument, options);
      }
export type UpdatePersonalVehicleMutationHookResult = ReturnType<typeof useUpdatePersonalVehicleMutation>;
export type UpdatePersonalVehicleMutationResult = Apollo.MutationResult<IUpdatePersonalVehicleMutation>;
export type UpdatePersonalVehicleMutationOptions = Apollo.BaseMutationOptions<IUpdatePersonalVehicleMutation, IUpdatePersonalVehicleMutationVariables>;
export const GetRidesExportDocument = gql`
    query getRidesExport($year: Int) {
  me {
    id
    exportFiles(year: $year) {
      ridesExport
    }
  }
}
    `;

/**
 * __useGetRidesExportQuery__
 *
 * To run a query within a React component, call `useGetRidesExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRidesExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRidesExportQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetRidesExportQuery(baseOptions?: Apollo.QueryHookOptions<IGetRidesExportQuery, IGetRidesExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetRidesExportQuery, IGetRidesExportQueryVariables>(GetRidesExportDocument, options);
      }
export function useGetRidesExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetRidesExportQuery, IGetRidesExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetRidesExportQuery, IGetRidesExportQueryVariables>(GetRidesExportDocument, options);
        }
export function useGetRidesExportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetRidesExportQuery, IGetRidesExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetRidesExportQuery, IGetRidesExportQueryVariables>(GetRidesExportDocument, options);
        }
export type GetRidesExportQueryHookResult = ReturnType<typeof useGetRidesExportQuery>;
export type GetRidesExportLazyQueryHookResult = ReturnType<typeof useGetRidesExportLazyQuery>;
export type GetRidesExportSuspenseQueryHookResult = ReturnType<typeof useGetRidesExportSuspenseQuery>;
export type GetRidesExportQueryResult = Apollo.QueryResult<IGetRidesExportQuery, IGetRidesExportQueryVariables>;
export const GetModalitiesDataDocument = gql`
    query getModalitiesData {
  me {
    id
    current: personalVehicles(filterBy: CURRENT, order: ASC) {
      ...PersonalVehicle
    }
    archived: personalVehicles(filterBy: ARCHIVED, order: DESC) {
      ...PersonalVehicle
    }
    licensePlates {
      ...LicensePlate
    }
    mileagesYears
    permissions {
      addableVehicles {
        icon {
          name
        }
        name {
          value
          humanized
        }
      }
    }
  }
}
    ${PersonalVehicleFragmentDoc}
${LicensePlateFragmentDoc}`;

/**
 * __useGetModalitiesDataQuery__
 *
 * To run a query within a React component, call `useGetModalitiesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModalitiesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModalitiesDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModalitiesDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>(GetModalitiesDataDocument, options);
      }
export function useGetModalitiesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>(GetModalitiesDataDocument, options);
        }
export function useGetModalitiesDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>(GetModalitiesDataDocument, options);
        }
export type GetModalitiesDataQueryHookResult = ReturnType<typeof useGetModalitiesDataQuery>;
export type GetModalitiesDataLazyQueryHookResult = ReturnType<typeof useGetModalitiesDataLazyQuery>;
export type GetModalitiesDataSuspenseQueryHookResult = ReturnType<typeof useGetModalitiesDataSuspenseQuery>;
export type GetModalitiesDataQueryResult = Apollo.QueryResult<IGetModalitiesDataQuery, IGetModalitiesDataQueryVariables>;
export const SendPrivacyRequestMailDocument = gql`
    mutation SendPrivacyRequestMail($input: SendPrivacyRequestMailInput!) {
  sendPrivacyRequestMail(input: $input) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useSendPrivacyRequestMailMutation__
 *
 * To run a mutation, you first call `useSendPrivacyRequestMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPrivacyRequestMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPrivacyRequestMailMutation, { data, loading, error }] = useSendPrivacyRequestMailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPrivacyRequestMailMutation(baseOptions?: Apollo.MutationHookOptions<ISendPrivacyRequestMailMutation, ISendPrivacyRequestMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISendPrivacyRequestMailMutation, ISendPrivacyRequestMailMutationVariables>(SendPrivacyRequestMailDocument, options);
      }
export type SendPrivacyRequestMailMutationHookResult = ReturnType<typeof useSendPrivacyRequestMailMutation>;
export type SendPrivacyRequestMailMutationResult = Apollo.MutationResult<ISendPrivacyRequestMailMutation>;
export type SendPrivacyRequestMailMutationOptions = Apollo.BaseMutationOptions<ISendPrivacyRequestMailMutation, ISendPrivacyRequestMailMutationVariables>;
export const GetPrivacyOptionsDocument = gql`
    query getPrivacyOptions {
  me {
    id
    profile {
      employeeNumber
      privacyTypes {
        ...privacyType
      }
    }
  }
}
    ${PrivacyTypeFragmentDoc}`;

/**
 * __useGetPrivacyOptionsQuery__
 *
 * To run a query within a React component, call `useGetPrivacyOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivacyOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivacyOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrivacyOptionsQuery(baseOptions?: Apollo.QueryHookOptions<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>(GetPrivacyOptionsDocument, options);
      }
export function useGetPrivacyOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>(GetPrivacyOptionsDocument, options);
        }
export function useGetPrivacyOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>(GetPrivacyOptionsDocument, options);
        }
export type GetPrivacyOptionsQueryHookResult = ReturnType<typeof useGetPrivacyOptionsQuery>;
export type GetPrivacyOptionsLazyQueryHookResult = ReturnType<typeof useGetPrivacyOptionsLazyQuery>;
export type GetPrivacyOptionsSuspenseQueryHookResult = ReturnType<typeof useGetPrivacyOptionsSuspenseQuery>;
export type GetPrivacyOptionsQueryResult = Apollo.QueryResult<IGetPrivacyOptionsQuery, IGetPrivacyOptionsQueryVariables>;
export const DestroyProfileAddressDocument = gql`
    mutation DestroyProfileAddress($addressType: DestroyProfileAddressEnum!) {
  destroyProfileAddress(input: {addressType: $addressType}) {
    result {
      success
      error
    }
  }
}
    `;

/**
 * __useDestroyProfileAddressMutation__
 *
 * To run a mutation, you first call `useDestroyProfileAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyProfileAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyProfileAddressMutation, { data, loading, error }] = useDestroyProfileAddressMutation({
 *   variables: {
 *      addressType: // value for 'addressType'
 *   },
 * });
 */
export function useDestroyProfileAddressMutation(baseOptions?: Apollo.MutationHookOptions<IDestroyProfileAddressMutation, IDestroyProfileAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IDestroyProfileAddressMutation, IDestroyProfileAddressMutationVariables>(DestroyProfileAddressDocument, options);
      }
export type DestroyProfileAddressMutationHookResult = ReturnType<typeof useDestroyProfileAddressMutation>;
export type DestroyProfileAddressMutationResult = Apollo.MutationResult<IDestroyProfileAddressMutation>;
export type DestroyProfileAddressMutationOptions = Apollo.BaseMutationOptions<IDestroyProfileAddressMutation, IDestroyProfileAddressMutationVariables>;
export const GetQrCodeForLoginDocument = gql`
    query getQrCodeForLogin {
  me {
    id
    qrCodeForApiToken
  }
}
    `;

/**
 * __useGetQrCodeForLoginQuery__
 *
 * To run a query within a React component, call `useGetQrCodeForLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrCodeForLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrCodeForLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQrCodeForLoginQuery(baseOptions?: Apollo.QueryHookOptions<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>(GetQrCodeForLoginDocument, options);
      }
export function useGetQrCodeForLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>(GetQrCodeForLoginDocument, options);
        }
export function useGetQrCodeForLoginSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>(GetQrCodeForLoginDocument, options);
        }
export type GetQrCodeForLoginQueryHookResult = ReturnType<typeof useGetQrCodeForLoginQuery>;
export type GetQrCodeForLoginLazyQueryHookResult = ReturnType<typeof useGetQrCodeForLoginLazyQuery>;
export type GetQrCodeForLoginSuspenseQueryHookResult = ReturnType<typeof useGetQrCodeForLoginSuspenseQuery>;
export type GetQrCodeForLoginQueryResult = Apollo.QueryResult<IGetQrCodeForLoginQuery, IGetQrCodeForLoginQueryVariables>;
export const GetGpsInformationDocument = gql`
    query getGPSInformation {
  me {
    id
    profile {
      preferredTravelPurpose
      employeeNumber
    }
  }
}
    `;

/**
 * __useGetGpsInformationQuery__
 *
 * To run a query within a React component, call `useGetGpsInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGpsInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGpsInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGpsInformationQuery(baseOptions?: Apollo.QueryHookOptions<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>(GetGpsInformationDocument, options);
      }
export function useGetGpsInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>(GetGpsInformationDocument, options);
        }
export function useGetGpsInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>(GetGpsInformationDocument, options);
        }
export type GetGpsInformationQueryHookResult = ReturnType<typeof useGetGpsInformationQuery>;
export type GetGpsInformationLazyQueryHookResult = ReturnType<typeof useGetGpsInformationLazyQuery>;
export type GetGpsInformationSuspenseQueryHookResult = ReturnType<typeof useGetGpsInformationSuspenseQuery>;
export type GetGpsInformationQueryResult = Apollo.QueryResult<IGetGpsInformationQuery, IGetGpsInformationQueryVariables>;
export const UpdateGpsInformationDocument = gql`
    mutation updateGPSInformation($preferredTravelPurpose: TravelPurposeEnum) {
  updateUserProfile(input: {preferredTravelPurpose: $preferredTravelPurpose}) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
  }
}
    `;

/**
 * __useUpdateGpsInformationMutation__
 *
 * To run a mutation, you first call `useUpdateGpsInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGpsInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGpsInformationMutation, { data, loading, error }] = useUpdateGpsInformationMutation({
 *   variables: {
 *      preferredTravelPurpose: // value for 'preferredTravelPurpose'
 *   },
 * });
 */
export function useUpdateGpsInformationMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateGpsInformationMutation, IUpdateGpsInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateGpsInformationMutation, IUpdateGpsInformationMutationVariables>(UpdateGpsInformationDocument, options);
      }
export type UpdateGpsInformationMutationHookResult = ReturnType<typeof useUpdateGpsInformationMutation>;
export type UpdateGpsInformationMutationResult = Apollo.MutationResult<IUpdateGpsInformationMutation>;
export type UpdateGpsInformationMutationOptions = Apollo.BaseMutationOptions<IUpdateGpsInformationMutation, IUpdateGpsInformationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    me {
      id
    }
    validationErrors {
      attribute
      messages
      fullMessages
    }
  }
}
    `;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<IResetPasswordMutation, IResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IResetPasswordMutation, IResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<IResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<IResetPasswordMutation, IResetPasswordMutationVariables>;
export const GetTranzerQuotesDocument = gql`
    query getTranzerQuotes($from: String!, $to: String!, $startsAt: DateTime!, $classNumber: TrainClassEnum) {
  me {
    id
    tranzerQuotes(
      startsAt: $startsAt
      fromLocationName: $from
      toLocationName: $to
      passengerCount: 1
      classNumber: $classNumber
      preferredModalities: [train, tram, metro, bus]
    ) {
      id
      legs {
        modality {
          humanized
          value
        }
        agency
        agencyLogo
        co2Emission
        description
        endTime
        fromLocation {
          coordinates {
            latitude
            longitude
          }
          humanized
        }
        id
        polyline
        service
        startTime
        toLocation {
          coordinates {
            latitude
            longitude
          }
          humanized
        }
        tickets {
          price {
            amount {
              humanized
              valueInCents
            }
          }
          ticketName
          conditionsOneliner
        }
      }
      status {
        humanized
        value
      }
      validUntil
    }
  }
}
    `;

/**
 * __useGetTranzerQuotesQuery__
 *
 * To run a query within a React component, call `useGetTranzerQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranzerQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranzerQuotesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      startsAt: // value for 'startsAt'
 *      classNumber: // value for 'classNumber'
 *   },
 * });
 */
export function useGetTranzerQuotesQuery(baseOptions: Apollo.QueryHookOptions<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables> & ({ variables: IGetTranzerQuotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables>(GetTranzerQuotesDocument, options);
      }
export function useGetTranzerQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables>(GetTranzerQuotesDocument, options);
        }
export function useGetTranzerQuotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables>(GetTranzerQuotesDocument, options);
        }
export type GetTranzerQuotesQueryHookResult = ReturnType<typeof useGetTranzerQuotesQuery>;
export type GetTranzerQuotesLazyQueryHookResult = ReturnType<typeof useGetTranzerQuotesLazyQuery>;
export type GetTranzerQuotesSuspenseQueryHookResult = ReturnType<typeof useGetTranzerQuotesSuspenseQuery>;
export type GetTranzerQuotesQueryResult = Apollo.QueryResult<IGetTranzerQuotesQuery, IGetTranzerQuotesQueryVariables>;
export const GetAllSettlementsDocument = gql`
    query getAllSettlements {
  me {
    id
    financialConsequences {
      settlements {
        id
        happenedAt
        type {
          enumValue
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllSettlementsQuery__
 *
 * To run a query within a React component, call `useGetAllSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSettlementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSettlementsQuery(baseOptions?: Apollo.QueryHookOptions<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>(GetAllSettlementsDocument, options);
      }
export function useGetAllSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>(GetAllSettlementsDocument, options);
        }
export function useGetAllSettlementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>(GetAllSettlementsDocument, options);
        }
export type GetAllSettlementsQueryHookResult = ReturnType<typeof useGetAllSettlementsQuery>;
export type GetAllSettlementsLazyQueryHookResult = ReturnType<typeof useGetAllSettlementsLazyQuery>;
export type GetAllSettlementsSuspenseQueryHookResult = ReturnType<typeof useGetAllSettlementsSuspenseQuery>;
export type GetAllSettlementsQueryResult = Apollo.QueryResult<IGetAllSettlementsQuery, IGetAllSettlementsQueryVariables>;
export const GetSettlementDocument = gql`
    query getSettlement($id: ID!) {
  me {
    id
    financialConsequences {
      settlement(id: $id) {
        id
        balance {
          totalFiscalAmount
          totalGrossAmount
          totalNetAmount
        }
        grossAmount {
          label
          valueInCents
        }
        netAmount {
          label
          valueInCents
        }
        financialSummary {
          fiscal
          gross
          net
          mobilityEvent {
            id
            happenedAt
            what {
              humanized
              icon {
                name
              }
            }
          }
        }
        previousBalance {
          totalFiscalAmount
          totalGrossAmount
          totalNetAmount
        }
      }
    }
  }
}
    `;

/**
 * __useGetSettlementQuery__
 *
 * To run a query within a React component, call `useGetSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettlementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSettlementQuery(baseOptions: Apollo.QueryHookOptions<IGetSettlementQuery, IGetSettlementQueryVariables> & ({ variables: IGetSettlementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSettlementQuery, IGetSettlementQueryVariables>(GetSettlementDocument, options);
      }
export function useGetSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSettlementQuery, IGetSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSettlementQuery, IGetSettlementQueryVariables>(GetSettlementDocument, options);
        }
export function useGetSettlementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSettlementQuery, IGetSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetSettlementQuery, IGetSettlementQueryVariables>(GetSettlementDocument, options);
        }
export type GetSettlementQueryHookResult = ReturnType<typeof useGetSettlementQuery>;
export type GetSettlementLazyQueryHookResult = ReturnType<typeof useGetSettlementLazyQuery>;
export type GetSettlementSuspenseQueryHookResult = ReturnType<typeof useGetSettlementSuspenseQuery>;
export type GetSettlementQueryResult = Apollo.QueryResult<IGetSettlementQuery, IGetSettlementQueryVariables>;
export const GetTentativeSettlementDocument = gql`
    query getTentativeSettlement {
  me {
    id
    financialConsequences {
      tentativeSettlement {
        balance {
          totalFiscalAmount
          totalGrossAmount
          totalNetAmount
        }
        grossAmount {
          label
          valueInCents
        }
        netAmount {
          label
          valueInCents
        }
        financialSummary {
          fiscal
          gross
          net
          mobilityEvent {
            id
            happenedAt
            what {
              humanized
              icon {
                name
              }
            }
          }
        }
        previousBalance {
          totalFiscalAmount
          totalGrossAmount
          totalNetAmount
        }
      }
    }
  }
}
    `;

/**
 * __useGetTentativeSettlementQuery__
 *
 * To run a query within a React component, call `useGetTentativeSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTentativeSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTentativeSettlementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTentativeSettlementQuery(baseOptions?: Apollo.QueryHookOptions<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>(GetTentativeSettlementDocument, options);
      }
export function useGetTentativeSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>(GetTentativeSettlementDocument, options);
        }
export function useGetTentativeSettlementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>(GetTentativeSettlementDocument, options);
        }
export type GetTentativeSettlementQueryHookResult = ReturnType<typeof useGetTentativeSettlementQuery>;
export type GetTentativeSettlementLazyQueryHookResult = ReturnType<typeof useGetTentativeSettlementLazyQuery>;
export type GetTentativeSettlementSuspenseQueryHookResult = ReturnType<typeof useGetTentativeSettlementSuspenseQuery>;
export type GetTentativeSettlementQueryResult = Apollo.QueryResult<IGetTentativeSettlementQuery, IGetTentativeSettlementQueryVariables>;
export const GetSettlementsDataDocument = gql`
    query getSettlementsData {
  me {
    id
    financialConsequences {
      budgetTransactionRemainder {
        amount {
          humanized
        }
      }
    }
  }
}
    `;

/**
 * __useGetSettlementsDataQuery__
 *
 * To run a query within a React component, call `useGetSettlementsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettlementsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettlementsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettlementsDataQuery(baseOptions?: Apollo.QueryHookOptions<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>(GetSettlementsDataDocument, options);
      }
export function useGetSettlementsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>(GetSettlementsDataDocument, options);
        }
export function useGetSettlementsDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>(GetSettlementsDataDocument, options);
        }
export type GetSettlementsDataQueryHookResult = ReturnType<typeof useGetSettlementsDataQuery>;
export type GetSettlementsDataLazyQueryHookResult = ReturnType<typeof useGetSettlementsDataLazyQuery>;
export type GetSettlementsDataSuspenseQueryHookResult = ReturnType<typeof useGetSettlementsDataSuspenseQuery>;
export type GetSettlementsDataQueryResult = Apollo.QueryResult<IGetSettlementsDataQuery, IGetSettlementsDataQueryVariables>;
export const GetReplacementManagersDocument = gql`
    query getReplacementManagers {
  me {
    id
    replacementManagers {
      fullName
      approvalPeriodStart
      approvalPeriodEnd
      id
      managerId
      label {
        humanized
        value
      }
    }
  }
}
    `;

/**
 * __useGetReplacementManagersQuery__
 *
 * To run a query within a React component, call `useGetReplacementManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReplacementManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplacementManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReplacementManagersQuery(baseOptions?: Apollo.QueryHookOptions<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>(GetReplacementManagersDocument, options);
      }
export function useGetReplacementManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>(GetReplacementManagersDocument, options);
        }
export function useGetReplacementManagersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>(GetReplacementManagersDocument, options);
        }
export type GetReplacementManagersQueryHookResult = ReturnType<typeof useGetReplacementManagersQuery>;
export type GetReplacementManagersLazyQueryHookResult = ReturnType<typeof useGetReplacementManagersLazyQuery>;
export type GetReplacementManagersSuspenseQueryHookResult = ReturnType<typeof useGetReplacementManagersSuspenseQuery>;
export type GetReplacementManagersQueryResult = Apollo.QueryResult<IGetReplacementManagersQuery, IGetReplacementManagersQueryVariables>;
export const SearchAvailableSharedVehiclesDocument = gql`
    query searchAvailableSharedVehicles($startsAt: DateTime!, $endsAt: DateTime!) {
  me {
    id
    availableSharedVehicles(startsAt: $startsAt, endsAt: $endsAt) {
      id
      icon {
        name
      }
      fromLocation {
        humanized
        coordinates {
          latitude
          longitude
        }
      }
      startsAt
      endsAt
      vehicleName
    }
  }
}
    `;

/**
 * __useSearchAvailableSharedVehiclesQuery__
 *
 * To run a query within a React component, call `useSearchAvailableSharedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAvailableSharedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAvailableSharedVehiclesQuery({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useSearchAvailableSharedVehiclesQuery(baseOptions: Apollo.QueryHookOptions<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables> & ({ variables: ISearchAvailableSharedVehiclesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables>(SearchAvailableSharedVehiclesDocument, options);
      }
export function useSearchAvailableSharedVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables>(SearchAvailableSharedVehiclesDocument, options);
        }
export function useSearchAvailableSharedVehiclesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables>(SearchAvailableSharedVehiclesDocument, options);
        }
export type SearchAvailableSharedVehiclesQueryHookResult = ReturnType<typeof useSearchAvailableSharedVehiclesQuery>;
export type SearchAvailableSharedVehiclesLazyQueryHookResult = ReturnType<typeof useSearchAvailableSharedVehiclesLazyQuery>;
export type SearchAvailableSharedVehiclesSuspenseQueryHookResult = ReturnType<typeof useSearchAvailableSharedVehiclesSuspenseQuery>;
export type SearchAvailableSharedVehiclesQueryResult = Apollo.QueryResult<ISearchAvailableSharedVehiclesQuery, ISearchAvailableSharedVehiclesQueryVariables>;
export const CreateTaxiReservationDocument = gql`
    mutation CreateTaxiReservation($input: CreateTaxiReservationInput!) {
  taxi {
    create(input: $input) {
      taxiReservation {
        ...TaxiReservation
      }
      result {
        success
        error
      }
      validationErrors {
        attribute
        messages
        fullMessages
      }
    }
  }
}
    ${TaxiReservationFragmentDoc}`;

/**
 * __useCreateTaxiReservationMutation__
 *
 * To run a mutation, you first call `useCreateTaxiReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxiReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxiReservationMutation, { data, loading, error }] = useCreateTaxiReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxiReservationMutation(baseOptions?: Apollo.MutationHookOptions<ICreateTaxiReservationMutation, ICreateTaxiReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateTaxiReservationMutation, ICreateTaxiReservationMutationVariables>(CreateTaxiReservationDocument, options);
      }
export type CreateTaxiReservationMutationHookResult = ReturnType<typeof useCreateTaxiReservationMutation>;
export type CreateTaxiReservationMutationResult = Apollo.MutationResult<ICreateTaxiReservationMutation>;
export type CreateTaxiReservationMutationOptions = Apollo.BaseMutationOptions<ICreateTaxiReservationMutation, ICreateTaxiReservationMutationVariables>;
export const GetPhoneNumberDocument = gql`
    query getPhoneNumber {
  me {
    id
    profile {
      employeeNumber
      phoneNumber
    }
  }
}
    `;

/**
 * __useGetPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGetPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhoneNumberQuery(baseOptions?: Apollo.QueryHookOptions<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>(GetPhoneNumberDocument, options);
      }
export function useGetPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>(GetPhoneNumberDocument, options);
        }
export function useGetPhoneNumberSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>(GetPhoneNumberDocument, options);
        }
export type GetPhoneNumberQueryHookResult = ReturnType<typeof useGetPhoneNumberQuery>;
export type GetPhoneNumberLazyQueryHookResult = ReturnType<typeof useGetPhoneNumberLazyQuery>;
export type GetPhoneNumberSuspenseQueryHookResult = ReturnType<typeof useGetPhoneNumberSuspenseQuery>;
export type GetPhoneNumberQueryResult = Apollo.QueryResult<IGetPhoneNumberQuery, IGetPhoneNumberQueryVariables>;
export const GetDonkeyRepublicReservationDocument = gql`
    query getDonkeyRepublicReservation($id: ID!) {
  me {
    id
    donkeyRepublicReservation(id: $id) {
      id
      assetName
      status {
        value
      }
      legs {
        from {
          id
          name
          geoLocation {
            latitude
            longitude
          }
        }
        mode {
          humanized
          value
        }
        price {
          fromMinutes
          tillMinutes
          duration
          amount {
            humanized
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDonkeyRepublicReservationQuery__
 *
 * To run a query within a React component, call `useGetDonkeyRepublicReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonkeyRepublicReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonkeyRepublicReservationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDonkeyRepublicReservationQuery(baseOptions: Apollo.QueryHookOptions<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables> & ({ variables: IGetDonkeyRepublicReservationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables>(GetDonkeyRepublicReservationDocument, options);
      }
export function useGetDonkeyRepublicReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables>(GetDonkeyRepublicReservationDocument, options);
        }
export function useGetDonkeyRepublicReservationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables>(GetDonkeyRepublicReservationDocument, options);
        }
export type GetDonkeyRepublicReservationQueryHookResult = ReturnType<typeof useGetDonkeyRepublicReservationQuery>;
export type GetDonkeyRepublicReservationLazyQueryHookResult = ReturnType<typeof useGetDonkeyRepublicReservationLazyQuery>;
export type GetDonkeyRepublicReservationSuspenseQueryHookResult = ReturnType<typeof useGetDonkeyRepublicReservationSuspenseQuery>;
export type GetDonkeyRepublicReservationQueryResult = Apollo.QueryResult<IGetDonkeyRepublicReservationQuery, IGetDonkeyRepublicReservationQueryVariables>;
export const CancelSharedVehicleReservationDocument = gql`
    mutation CancelSharedVehicleReservation($input: CancelSharedVehicleReservationInput!) {
  cancelSharedVehicleReservation(input: $input) {
    result {
      success
      error
    }
    sharedVehicleReservation {
      ...SharedVehicleReservation
    }
  }
}
    ${SharedVehicleReservationFragmentDoc}`;

/**
 * __useCancelSharedVehicleReservationMutation__
 *
 * To run a mutation, you first call `useCancelSharedVehicleReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSharedVehicleReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSharedVehicleReservationMutation, { data, loading, error }] = useCancelSharedVehicleReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSharedVehicleReservationMutation(baseOptions?: Apollo.MutationHookOptions<ICancelSharedVehicleReservationMutation, ICancelSharedVehicleReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICancelSharedVehicleReservationMutation, ICancelSharedVehicleReservationMutationVariables>(CancelSharedVehicleReservationDocument, options);
      }
export type CancelSharedVehicleReservationMutationHookResult = ReturnType<typeof useCancelSharedVehicleReservationMutation>;
export type CancelSharedVehicleReservationMutationResult = Apollo.MutationResult<ICancelSharedVehicleReservationMutation>;
export type CancelSharedVehicleReservationMutationOptions = Apollo.BaseMutationOptions<ICancelSharedVehicleReservationMutation, ICancelSharedVehicleReservationMutationVariables>;
export const GetSharedVehicleReservationDocument = gql`
    query getSharedVehicleReservation($id: ID!) {
  me {
    id
    sharedVehicleReservation(id: $id) {
      ...SharedVehicleReservation
    }
  }
}
    ${SharedVehicleReservationFragmentDoc}`;

/**
 * __useGetSharedVehicleReservationQuery__
 *
 * To run a query within a React component, call `useGetSharedVehicleReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedVehicleReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedVehicleReservationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSharedVehicleReservationQuery(baseOptions: Apollo.QueryHookOptions<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables> & ({ variables: IGetSharedVehicleReservationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables>(GetSharedVehicleReservationDocument, options);
      }
export function useGetSharedVehicleReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables>(GetSharedVehicleReservationDocument, options);
        }
export function useGetSharedVehicleReservationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables>(GetSharedVehicleReservationDocument, options);
        }
export type GetSharedVehicleReservationQueryHookResult = ReturnType<typeof useGetSharedVehicleReservationQuery>;
export type GetSharedVehicleReservationLazyQueryHookResult = ReturnType<typeof useGetSharedVehicleReservationLazyQuery>;
export type GetSharedVehicleReservationSuspenseQueryHookResult = ReturnType<typeof useGetSharedVehicleReservationSuspenseQuery>;
export type GetSharedVehicleReservationQueryResult = Apollo.QueryResult<IGetSharedVehicleReservationQuery, IGetSharedVehicleReservationQueryVariables>;
export const ConfirmSharedVehicleReservationDocument = gql`
    mutation ConfirmSharedVehicleReservation($input: ConfirmSharedVehicleReservationInput!) {
  confirmSharedVehicleReservation(input: $input) {
    result {
      success
      error
    }
    sharedVehicleReservation {
      ...SharedVehicleReservation
    }
  }
}
    ${SharedVehicleReservationFragmentDoc}`;

/**
 * __useConfirmSharedVehicleReservationMutation__
 *
 * To run a mutation, you first call `useConfirmSharedVehicleReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmSharedVehicleReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmSharedVehicleReservationMutation, { data, loading, error }] = useConfirmSharedVehicleReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmSharedVehicleReservationMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmSharedVehicleReservationMutation, IConfirmSharedVehicleReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmSharedVehicleReservationMutation, IConfirmSharedVehicleReservationMutationVariables>(ConfirmSharedVehicleReservationDocument, options);
      }
export type ConfirmSharedVehicleReservationMutationHookResult = ReturnType<typeof useConfirmSharedVehicleReservationMutation>;
export type ConfirmSharedVehicleReservationMutationResult = Apollo.MutationResult<IConfirmSharedVehicleReservationMutation>;
export type ConfirmSharedVehicleReservationMutationOptions = Apollo.BaseMutationOptions<IConfirmSharedVehicleReservationMutation, IConfirmSharedVehicleReservationMutationVariables>;
export const ConfirmTaxiReservationDocument = gql`
    mutation ConfirmTaxiReservation($input: ConfirmTaxiReservationInput!) {
  taxi {
    confirm(input: $input) {
      result {
        success
        error
      }
      taxiReservation {
        ...TaxiReservation
      }
    }
  }
}
    ${TaxiReservationFragmentDoc}`;

/**
 * __useConfirmTaxiReservationMutation__
 *
 * To run a mutation, you first call `useConfirmTaxiReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmTaxiReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmTaxiReservationMutation, { data, loading, error }] = useConfirmTaxiReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmTaxiReservationMutation(baseOptions?: Apollo.MutationHookOptions<IConfirmTaxiReservationMutation, IConfirmTaxiReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfirmTaxiReservationMutation, IConfirmTaxiReservationMutationVariables>(ConfirmTaxiReservationDocument, options);
      }
export type ConfirmTaxiReservationMutationHookResult = ReturnType<typeof useConfirmTaxiReservationMutation>;
export type ConfirmTaxiReservationMutationResult = Apollo.MutationResult<IConfirmTaxiReservationMutation>;
export type ConfirmTaxiReservationMutationOptions = Apollo.BaseMutationOptions<IConfirmTaxiReservationMutation, IConfirmTaxiReservationMutationVariables>;
export const CancelTaxiReservationDocument = gql`
    mutation CancelTaxiReservation($input: CancelTaxiReservationInput!) {
  taxi {
    cancel(input: $input) {
      result {
        success
        error
      }
      taxiReservation {
        ...TaxiReservation
      }
    }
  }
}
    ${TaxiReservationFragmentDoc}`;

/**
 * __useCancelTaxiReservationMutation__
 *
 * To run a mutation, you first call `useCancelTaxiReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTaxiReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTaxiReservationMutation, { data, loading, error }] = useCancelTaxiReservationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelTaxiReservationMutation(baseOptions?: Apollo.MutationHookOptions<ICancelTaxiReservationMutation, ICancelTaxiReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICancelTaxiReservationMutation, ICancelTaxiReservationMutationVariables>(CancelTaxiReservationDocument, options);
      }
export type CancelTaxiReservationMutationHookResult = ReturnType<typeof useCancelTaxiReservationMutation>;
export type CancelTaxiReservationMutationResult = Apollo.MutationResult<ICancelTaxiReservationMutation>;
export type CancelTaxiReservationMutationOptions = Apollo.BaseMutationOptions<ICancelTaxiReservationMutation, ICancelTaxiReservationMutationVariables>;
export const LoadTaxiReservationDocument = gql`
    query loadTaxiReservation($id: ID!) {
  me {
    id
    taxiReservation(id: $id) {
      ...TaxiReservation
    }
  }
}
    ${TaxiReservationFragmentDoc}`;

/**
 * __useLoadTaxiReservationQuery__
 *
 * To run a query within a React component, call `useLoadTaxiReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaxiReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaxiReservationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadTaxiReservationQuery(baseOptions: Apollo.QueryHookOptions<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables> & ({ variables: ILoadTaxiReservationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables>(LoadTaxiReservationDocument, options);
      }
export function useLoadTaxiReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables>(LoadTaxiReservationDocument, options);
        }
export function useLoadTaxiReservationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables>(LoadTaxiReservationDocument, options);
        }
export type LoadTaxiReservationQueryHookResult = ReturnType<typeof useLoadTaxiReservationQuery>;
export type LoadTaxiReservationLazyQueryHookResult = ReturnType<typeof useLoadTaxiReservationLazyQuery>;
export type LoadTaxiReservationSuspenseQueryHookResult = ReturnType<typeof useLoadTaxiReservationSuspenseQuery>;
export type LoadTaxiReservationQueryResult = Apollo.QueryResult<ILoadTaxiReservationQuery, ILoadTaxiReservationQueryVariables>;
export const GetTranzerReservationDetailsDocument = gql`
    query getTranzerReservationDetails($id: ID!) {
  me {
    id
    tranzerReservation(id: $id) {
      startTime
      endTime
      id
      legs {
        id
        fromLocation {
          coordinates {
            latitude
            longitude
          }
          humanized
        }
        toLocation {
          coordinates {
            latitude
            longitude
          }
          humanized
        }
        icon {
          name
        }
      }
      status {
        humanized
        value
      }
    }
  }
}
    `;

/**
 * __useGetTranzerReservationDetailsQuery__
 *
 * To run a query within a React component, call `useGetTranzerReservationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranzerReservationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranzerReservationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTranzerReservationDetailsQuery(baseOptions: Apollo.QueryHookOptions<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables> & ({ variables: IGetTranzerReservationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables>(GetTranzerReservationDetailsDocument, options);
      }
export function useGetTranzerReservationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables>(GetTranzerReservationDetailsDocument, options);
        }
export function useGetTranzerReservationDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables>(GetTranzerReservationDetailsDocument, options);
        }
export type GetTranzerReservationDetailsQueryHookResult = ReturnType<typeof useGetTranzerReservationDetailsQuery>;
export type GetTranzerReservationDetailsLazyQueryHookResult = ReturnType<typeof useGetTranzerReservationDetailsLazyQuery>;
export type GetTranzerReservationDetailsSuspenseQueryHookResult = ReturnType<typeof useGetTranzerReservationDetailsSuspenseQuery>;
export type GetTranzerReservationDetailsQueryResult = Apollo.QueryResult<IGetTranzerReservationDetailsQuery, IGetTranzerReservationDetailsQueryVariables>;
export const GetReservationsDocument = gql`
    query getReservations($filterByState: ReservationStateEnum!, $order: OrderEnum!) {
  me {
    id
    reservations(filterByState: $filterByState, order: $order) {
      ...Reservation
    }
  }
}
    ${ReservationFragmentDoc}`;

/**
 * __useGetReservationsQuery__
 *
 * To run a query within a React component, call `useGetReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsQuery({
 *   variables: {
 *      filterByState: // value for 'filterByState'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetReservationsQuery(baseOptions: Apollo.QueryHookOptions<IGetReservationsQuery, IGetReservationsQueryVariables> & ({ variables: IGetReservationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetReservationsQuery, IGetReservationsQueryVariables>(GetReservationsDocument, options);
      }
export function useGetReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetReservationsQuery, IGetReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetReservationsQuery, IGetReservationsQueryVariables>(GetReservationsDocument, options);
        }
export function useGetReservationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetReservationsQuery, IGetReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetReservationsQuery, IGetReservationsQueryVariables>(GetReservationsDocument, options);
        }
export type GetReservationsQueryHookResult = ReturnType<typeof useGetReservationsQuery>;
export type GetReservationsLazyQueryHookResult = ReturnType<typeof useGetReservationsLazyQuery>;
export type GetReservationsSuspenseQueryHookResult = ReturnType<typeof useGetReservationsSuspenseQuery>;
export type GetReservationsQueryResult = Apollo.QueryResult<IGetReservationsQuery, IGetReservationsQueryVariables>;
export const GetOrganisationUrlsDocument = gql`
    query getOrganisationUrls {
  me {
    id
    organization {
      policyDocumentUrl
      customerService {
        helpUrl
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationUrlsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationUrlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationUrlsQuery(baseOptions?: Apollo.QueryHookOptions<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>(GetOrganisationUrlsDocument, options);
      }
export function useGetOrganisationUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>(GetOrganisationUrlsDocument, options);
        }
export function useGetOrganisationUrlsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>(GetOrganisationUrlsDocument, options);
        }
export type GetOrganisationUrlsQueryHookResult = ReturnType<typeof useGetOrganisationUrlsQuery>;
export type GetOrganisationUrlsLazyQueryHookResult = ReturnType<typeof useGetOrganisationUrlsLazyQuery>;
export type GetOrganisationUrlsSuspenseQueryHookResult = ReturnType<typeof useGetOrganisationUrlsSuspenseQuery>;
export type GetOrganisationUrlsQueryResult = Apollo.QueryResult<IGetOrganisationUrlsQuery, IGetOrganisationUrlsQueryVariables>;
export const GetUserProfileInformationDocument = gql`
    query getUserProfileInformation {
  me {
    id
    token
    profile {
      avatar
      employeeNumber
      phoneNumber
      firstName
      infix
      initials
      lastName
      email
      dateOfBirth
      defaultMobilityCardWeekendTransactionTravelPurpose
      defaultMobilityCardTransactionTravelPurpose
      homeAddress {
        city
        street
        streetNumber
        streetNumberSuffix
        zipcode
        employeeCountryIsoCode {
          humanized
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserProfileInformationQuery__
 *
 * To run a query within a React component, call `useGetUserProfileInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileInformationQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>(GetUserProfileInformationDocument, options);
      }
export function useGetUserProfileInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>(GetUserProfileInformationDocument, options);
        }
export function useGetUserProfileInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>(GetUserProfileInformationDocument, options);
        }
export type GetUserProfileInformationQueryHookResult = ReturnType<typeof useGetUserProfileInformationQuery>;
export type GetUserProfileInformationLazyQueryHookResult = ReturnType<typeof useGetUserProfileInformationLazyQuery>;
export type GetUserProfileInformationSuspenseQueryHookResult = ReturnType<typeof useGetUserProfileInformationSuspenseQuery>;
export type GetUserProfileInformationQueryResult = Apollo.QueryResult<IGetUserProfileInformationQuery, IGetUserProfileInformationQueryVariables>;
export const UpdateUserProfileInformationDocument = gql`
    mutation updateUserProfileInformation($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    result {
      success
      error
    }
    validationErrors {
      fullMessages
      attribute
      messages
    }
    me {
      ...UserProfileInformation
    }
  }
}
    ${UserProfileInformationFragmentDoc}`;

/**
 * __useUpdateUserProfileInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileInformationMutation, { data, loading, error }] = useUpdateUserProfileInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileInformationMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateUserProfileInformationMutation, IUpdateUserProfileInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateUserProfileInformationMutation, IUpdateUserProfileInformationMutationVariables>(UpdateUserProfileInformationDocument, options);
      }
export type UpdateUserProfileInformationMutationHookResult = ReturnType<typeof useUpdateUserProfileInformationMutation>;
export type UpdateUserProfileInformationMutationResult = Apollo.MutationResult<IUpdateUserProfileInformationMutation>;
export type UpdateUserProfileInformationMutationOptions = Apollo.BaseMutationOptions<IUpdateUserProfileInformationMutation, IUpdateUserProfileInformationMutationVariables>;
export const GetEnglishDocumentsDocument = gql`
    query getEnglishDocuments {
  externalUrls {
    englishTermsAndConditionsUrl {
      title
      url
    }
    englishPrivacyStatementUrl {
      title
      url
    }
  }
}
    `;

/**
 * __useGetEnglishDocumentsQuery__
 *
 * To run a query within a React component, call `useGetEnglishDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnglishDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnglishDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnglishDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>(GetEnglishDocumentsDocument, options);
      }
export function useGetEnglishDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>(GetEnglishDocumentsDocument, options);
        }
export function useGetEnglishDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>(GetEnglishDocumentsDocument, options);
        }
export type GetEnglishDocumentsQueryHookResult = ReturnType<typeof useGetEnglishDocumentsQuery>;
export type GetEnglishDocumentsLazyQueryHookResult = ReturnType<typeof useGetEnglishDocumentsLazyQuery>;
export type GetEnglishDocumentsSuspenseQueryHookResult = ReturnType<typeof useGetEnglishDocumentsSuspenseQuery>;
export type GetEnglishDocumentsQueryResult = Apollo.QueryResult<IGetEnglishDocumentsQuery, IGetEnglishDocumentsQueryVariables>;
export const CreateUserProfileDocument = gql`
    mutation createUserProfile($input: CreateUserProfileInput!) {
  createUserProfile(input: $input) {
    result {
      error
      success
    }
    validationErrors {
      attribute
      fullMessages
      messages
    }
    me {
      id
      profile {
        employeeNumber
        email
      }
    }
  }
}
    `;

/**
 * __useCreateUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileMutation, { data, loading, error }] = useCreateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<ICreateUserProfileMutation, ICreateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ICreateUserProfileMutation, ICreateUserProfileMutationVariables>(CreateUserProfileDocument, options);
      }
export type CreateUserProfileMutationHookResult = ReturnType<typeof useCreateUserProfileMutation>;
export type CreateUserProfileMutationResult = Apollo.MutationResult<ICreateUserProfileMutation>;
export type CreateUserProfileMutationOptions = Apollo.BaseMutationOptions<ICreateUserProfileMutation, ICreateUserProfileMutationVariables>;
export const SendNewInvitationLinkDocument = gql`
    mutation sendNewInvitationLink($email: String!) {
  sendNewInvitationLink(input: {email: $email}) {
    result {
      success
      error
    }
  }
}
    `;

/**
 * __useSendNewInvitationLinkMutation__
 *
 * To run a mutation, you first call `useSendNewInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewInvitationLinkMutation, { data, loading, error }] = useSendNewInvitationLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendNewInvitationLinkMutation(baseOptions?: Apollo.MutationHookOptions<ISendNewInvitationLinkMutation, ISendNewInvitationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISendNewInvitationLinkMutation, ISendNewInvitationLinkMutationVariables>(SendNewInvitationLinkDocument, options);
      }
export type SendNewInvitationLinkMutationHookResult = ReturnType<typeof useSendNewInvitationLinkMutation>;
export type SendNewInvitationLinkMutationResult = Apollo.MutationResult<ISendNewInvitationLinkMutation>;
export type SendNewInvitationLinkMutationOptions = Apollo.BaseMutationOptions<ISendNewInvitationLinkMutation, ISendNewInvitationLinkMutationVariables>;
export const GetTokenValidityDocument = gql`
    query getTokenValidity($token: String!) {
  inviteTokenValid(inviteToken: $token)
}
    `;

/**
 * __useGetTokenValidityQuery__
 *
 * To run a query within a React component, call `useGetTokenValidityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenValidityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenValidityQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetTokenValidityQuery(baseOptions: Apollo.QueryHookOptions<IGetTokenValidityQuery, IGetTokenValidityQueryVariables> & ({ variables: IGetTokenValidityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetTokenValidityQuery, IGetTokenValidityQueryVariables>(GetTokenValidityDocument, options);
      }
export function useGetTokenValidityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetTokenValidityQuery, IGetTokenValidityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetTokenValidityQuery, IGetTokenValidityQueryVariables>(GetTokenValidityDocument, options);
        }
export function useGetTokenValiditySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTokenValidityQuery, IGetTokenValidityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetTokenValidityQuery, IGetTokenValidityQueryVariables>(GetTokenValidityDocument, options);
        }
export type GetTokenValidityQueryHookResult = ReturnType<typeof useGetTokenValidityQuery>;
export type GetTokenValidityLazyQueryHookResult = ReturnType<typeof useGetTokenValidityLazyQuery>;
export type GetTokenValiditySuspenseQueryHookResult = ReturnType<typeof useGetTokenValiditySuspenseQuery>;
export type GetTokenValidityQueryResult = Apollo.QueryResult<IGetTokenValidityQuery, IGetTokenValidityQueryVariables>;
export const GetUserWorkInformationDocument = gql`
    query getUserWorkInformation {
  me {
    ...UserWorkInformation
  }
}
    ${UserWorkInformationFragmentDoc}`;

/**
 * __useGetUserWorkInformationQuery__
 *
 * To run a query within a React component, call `useGetUserWorkInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWorkInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWorkInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserWorkInformationQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>(GetUserWorkInformationDocument, options);
      }
export function useGetUserWorkInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>(GetUserWorkInformationDocument, options);
        }
export function useGetUserWorkInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>(GetUserWorkInformationDocument, options);
        }
export type GetUserWorkInformationQueryHookResult = ReturnType<typeof useGetUserWorkInformationQuery>;
export type GetUserWorkInformationLazyQueryHookResult = ReturnType<typeof useGetUserWorkInformationLazyQuery>;
export type GetUserWorkInformationSuspenseQueryHookResult = ReturnType<typeof useGetUserWorkInformationSuspenseQuery>;
export type GetUserWorkInformationQueryResult = Apollo.QueryResult<IGetUserWorkInformationQuery, IGetUserWorkInformationQueryVariables>;
export const UpdateUserWorkProfileInformationDocument = gql`
    mutation updateUserWorkProfileInformation($employeeNumber: String, $street: String!, $streetNumber: String!, $streetNumberSuffix: String, $zipcode: String, $city: String, $countryIsoCode: CountryIsoCodeEnum) {
  updateUserProfile(
    input: {employeeNumber: $employeeNumber, workAddress: {street: $street, streetNumber: $streetNumber, streetNumberSuffix: $streetNumberSuffix, zipcode: $zipcode, city: $city, countryIsoCode: $countryIsoCode}}
  ) {
    result {
      success
      error
    }
    validationErrors {
      fullMessages
      attribute
      messages
    }
    me {
      ...UserWorkInformation
    }
  }
}
    ${UserWorkInformationFragmentDoc}`;

/**
 * __useUpdateUserWorkProfileInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserWorkProfileInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserWorkProfileInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserWorkProfileInformationMutation, { data, loading, error }] = useUpdateUserWorkProfileInformationMutation({
 *   variables: {
 *      employeeNumber: // value for 'employeeNumber'
 *      street: // value for 'street'
 *      streetNumber: // value for 'streetNumber'
 *      streetNumberSuffix: // value for 'streetNumberSuffix'
 *      zipcode: // value for 'zipcode'
 *      city: // value for 'city'
 *      countryIsoCode: // value for 'countryIsoCode'
 *   },
 * });
 */
export function useUpdateUserWorkProfileInformationMutation(baseOptions?: Apollo.MutationHookOptions<IUpdateUserWorkProfileInformationMutation, IUpdateUserWorkProfileInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IUpdateUserWorkProfileInformationMutation, IUpdateUserWorkProfileInformationMutationVariables>(UpdateUserWorkProfileInformationDocument, options);
      }
export type UpdateUserWorkProfileInformationMutationHookResult = ReturnType<typeof useUpdateUserWorkProfileInformationMutation>;
export type UpdateUserWorkProfileInformationMutationResult = Apollo.MutationResult<IUpdateUserWorkProfileInformationMutation>;
export type UpdateUserWorkProfileInformationMutationOptions = Apollo.BaseMutationOptions<IUpdateUserWorkProfileInformationMutation, IUpdateUserWorkProfileInformationMutationVariables>;
export const ConfigureMfaDocument = gql`
    mutation configureMfa($input: ConfigureMfaInput!) {
  configureMfa(input: $input) {
    result {
      error
      success
    }
    otpUri
    configurationSuccess
  }
}
    `;

/**
 * __useConfigureMfaMutation__
 *
 * To run a mutation, you first call `useConfigureMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureMfaMutation, { data, loading, error }] = useConfigureMfaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigureMfaMutation(baseOptions?: Apollo.MutationHookOptions<IConfigureMfaMutation, IConfigureMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IConfigureMfaMutation, IConfigureMfaMutationVariables>(ConfigureMfaDocument, options);
      }
export type ConfigureMfaMutationHookResult = ReturnType<typeof useConfigureMfaMutation>;
export type ConfigureMfaMutationResult = Apollo.MutationResult<IConfigureMfaMutation>;
export type ConfigureMfaMutationOptions = Apollo.BaseMutationOptions<IConfigureMfaMutation, IConfigureMfaMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!, $otpToken: String) {
  login(input: {email: $email, password: $password, otpToken: $otpToken}) {
    result {
      success
      error
    }
    hasOtpConfigured
    otpRequired
    tokens {
      accessToken
      refreshToken
    }
  }
}
    `;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      otpToken: // value for 'otpToken'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<ILoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout(input: {}) {
    result {
      error
      success
    }
  }
}
    `;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<ILogoutMutation, ILogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ILogoutMutation, ILogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<ILogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<ILogoutMutation, ILogoutMutationVariables>;
export const ReauthorizeDocument = gql`
    query reauthorize($refreshToken: String = "") {
  reauthorize(refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;

/**
 * __useReauthorizeQuery__
 *
 * To run a query within a React component, call `useReauthorizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReauthorizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReauthorizeQuery({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useReauthorizeQuery(baseOptions?: Apollo.QueryHookOptions<IReauthorizeQuery, IReauthorizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IReauthorizeQuery, IReauthorizeQueryVariables>(ReauthorizeDocument, options);
      }
export function useReauthorizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IReauthorizeQuery, IReauthorizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IReauthorizeQuery, IReauthorizeQueryVariables>(ReauthorizeDocument, options);
        }
export function useReauthorizeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IReauthorizeQuery, IReauthorizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IReauthorizeQuery, IReauthorizeQueryVariables>(ReauthorizeDocument, options);
        }
export type ReauthorizeQueryHookResult = ReturnType<typeof useReauthorizeQuery>;
export type ReauthorizeLazyQueryHookResult = ReturnType<typeof useReauthorizeLazyQuery>;
export type ReauthorizeSuspenseQueryHookResult = ReturnType<typeof useReauthorizeSuspenseQuery>;
export type ReauthorizeQueryResult = Apollo.QueryResult<IReauthorizeQuery, IReauthorizeQueryVariables>;
export const GetUserInformationDocument = gql`
    query getUserInformation {
  me {
    id
    becameEmployee
    profile {
      avatar
      employeeNumber
      firstName
      infix
      initials
      lastName
      email
      phoneNumber
      usesNewInterface
      dateOfBirth
      preferredTravelPurpose
      defaultMobilityCardTransactionTravelPurpose
      defaultMobilityCardWeekendTransactionTravelPurpose
      homeAddress {
        city
        street
        streetNumber
        streetNumberSuffix
        zipcode
        employeeCountryIsoCode {
          humanized
          value
        }
      }
      workAddress {
        city
        street
        streetNumber
        streetNumberSuffix
        zipcode
        employeeCountryIsoCode {
          humanized
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserInformationQuery__
 *
 * To run a query within a React component, call `useGetUserInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInformationQuery(baseOptions?: Apollo.QueryHookOptions<IGetUserInformationQuery, IGetUserInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetUserInformationQuery, IGetUserInformationQueryVariables>(GetUserInformationDocument, options);
      }
export function useGetUserInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetUserInformationQuery, IGetUserInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetUserInformationQuery, IGetUserInformationQueryVariables>(GetUserInformationDocument, options);
        }
export function useGetUserInformationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetUserInformationQuery, IGetUserInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetUserInformationQuery, IGetUserInformationQueryVariables>(GetUserInformationDocument, options);
        }
export type GetUserInformationQueryHookResult = ReturnType<typeof useGetUserInformationQuery>;
export type GetUserInformationLazyQueryHookResult = ReturnType<typeof useGetUserInformationLazyQuery>;
export type GetUserInformationSuspenseQueryHookResult = ReturnType<typeof useGetUserInformationSuspenseQuery>;
export type GetUserInformationQueryResult = Apollo.QueryResult<IGetUserInformationQuery, IGetUserInformationQueryVariables>;
export const LoadWhatOptionsDocument = gql`
    query loadWhatOptions($on: Date!) {
  me {
    id
    whatOptions(on: $on) {
      icon {
        name
      }
      category {
        id
        requiresModality
        mainCategory {
          value
          humanized
        }
        subCategory {
          value
          humanized
        }
      }
      modality {
        id
        clazz
        kind
        modalityOwnership {
          humanized
          value
        }
        humanizedWithCapital
        requiresVehicle
        travelMode
        requiredPassengerCount
      }
      vehicle {
        id
        humanized
      }
      reimbursableCategoryMethod {
        value
      }
    }
  }
}
    `;

/**
 * __useLoadWhatOptionsQuery__
 *
 * To run a query within a React component, call `useLoadWhatOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWhatOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWhatOptionsQuery({
 *   variables: {
 *      on: // value for 'on'
 *   },
 * });
 */
export function useLoadWhatOptionsQuery(baseOptions: Apollo.QueryHookOptions<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables> & ({ variables: ILoadWhatOptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables>(LoadWhatOptionsDocument, options);
      }
export function useLoadWhatOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables>(LoadWhatOptionsDocument, options);
        }
export function useLoadWhatOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables>(LoadWhatOptionsDocument, options);
        }
export type LoadWhatOptionsQueryHookResult = ReturnType<typeof useLoadWhatOptionsQuery>;
export type LoadWhatOptionsLazyQueryHookResult = ReturnType<typeof useLoadWhatOptionsLazyQuery>;
export type LoadWhatOptionsSuspenseQueryHookResult = ReturnType<typeof useLoadWhatOptionsSuspenseQuery>;
export type LoadWhatOptionsQueryResult = Apollo.QueryResult<ILoadWhatOptionsQuery, ILoadWhatOptionsQueryVariables>;
export const GetEmployeeDatesDocument = gql`
    query getEmployeeDates {
  me {
    id
    employments {
      startsOn
      endsOn
    }
  }
}
    `;

/**
 * __useGetEmployeeDatesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeDatesQuery(baseOptions?: Apollo.QueryHookOptions<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>(GetEmployeeDatesDocument, options);
      }
export function useGetEmployeeDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>(GetEmployeeDatesDocument, options);
        }
export function useGetEmployeeDatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>(GetEmployeeDatesDocument, options);
        }
export type GetEmployeeDatesQueryHookResult = ReturnType<typeof useGetEmployeeDatesQuery>;
export type GetEmployeeDatesLazyQueryHookResult = ReturnType<typeof useGetEmployeeDatesLazyQuery>;
export type GetEmployeeDatesSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeDatesSuspenseQuery>;
export type GetEmployeeDatesQueryResult = Apollo.QueryResult<IGetEmployeeDatesQuery, IGetEmployeeDatesQueryVariables>;
export const GetThemeDocument = gql`
    query GetTheme($hostname: String!) {
  __typename
  configurationProfileForHost(host: $hostname) {
    customerLabelTheme {
      primaryColor {
        hsla {
          alpha
          hue
          lightness
          saturation
        }
        rgba {
          blue
          alpha
          green
          red
        }
      }
      secondaryColor {
        hsla {
          hue
          alpha
          lightness
          saturation
        }
        rgba {
          alpha
          blue
          green
          red
        }
      }
      poster {
        uri
        base64
        width
        height
      }
      logo {
        uri
        base64
        height
        width
      }
      icon {
        uri
        base64
        height
        width
      }
      favicon {
        uri
        base64
      }
    }
  }
}
    `;

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      hostname: // value for 'hostname'
 *   },
 * });
 */
export function useGetThemeQuery(baseOptions: Apollo.QueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables> & ({ variables: IGetThemeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options);
      }
export function useGetThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options);
        }
export function useGetThemeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options);
        }
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeLazyQueryHookResult = ReturnType<typeof useGetThemeLazyQuery>;
export type GetThemeSuspenseQueryHookResult = ReturnType<typeof useGetThemeSuspenseQuery>;
export type GetThemeQueryResult = Apollo.QueryResult<IGetThemeQuery, IGetThemeQueryVariables>;