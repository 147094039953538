import './Lib/i18n'
// Any css imports here should also be added to the Decorator.jsx in ~/.storybook
import 'react-simple-flex-grid/lib/main.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import './toast_override.scss'

import { ApolloProvider } from '@apollo/client'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import React, { memo, useContext } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { prefixer } from 'stylis'

import { Icon } from './Components'
import { SideDrawerContainer } from './Components/SideDrawerContainer'
import { AuthorizedLayout, UnauthorizedLayout } from './Layouts'
import { authorizedClient, unauthorizedClient } from './Lib/apolloClient'
import { TLocale } from './Lib/sharedInterfaces'
import { AuthContext, AuthProvider, MediaQueriesProvider, ThemeProvider } from './Providers'
import Styles from './styles.module.scss'

const LoginOrMain = memo((): JSX.Element => {
  const { loggedIn } = useContext(AuthContext)

  return loggedIn ? <AuthorizedLayout /> : <UnauthorizedLayout />
})

const customPlugin = (): void => {}

const myCache = createCache({
  key: 'react-select-emotion',
  nonce: 'react-select-emotion-nonce',
  stylisPlugins: [
    customPlugin,
    // has to be included manually when customizing `stylisPlugins` if you want to have vendor prefixes added automatically
    prefixer,
  ],
})

export const Wrapper: React.FC = (): JSX.Element => {
  const { loggedIn, accessToken, reauthorizeWithRefreshToken } = useContext(AuthContext)
  const { link } = createNetworkStatusNotifier()
  const { i18n } = useTranslation()
  const locale = i18n.language as TLocale

  const renderCloseButton = (): JSX.Element => {
    return <Icon width={10} height={10} className={Styles.toastCloseIcon} name="cross" />
  }

  return (
    <ApolloProvider
      client={
        loggedIn ? authorizedClient(accessToken, link, locale, reauthorizeWithRefreshToken) : unauthorizedClient(locale)
      }
    >
      <ThemeProvider>
        <CacheProvider value={myCache}>
          <MediaQueriesProvider>
            <ToastContainer
              position="bottom-left"
              icon={false}
              closeButton={renderCloseButton}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />

            <SideDrawerContainer id="sideDrawerContainer" />

            <LoginOrMain />
          </MediaQueriesProvider>
        </CacheProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export const App: React.FC = (): JSX.Element => {
  // @ts-ignore
  const cssClasses = [Styles.app, import.meta.env.REACT_APP_PLATFORM?.toLowerCase()]
  // NOTE: Remember if you make changes here, update .storybook/Decorator.jsx as well!
  return (
    <div className={cssClasses.join(' ')} id="AppWrapper">
      <Router>
        <AuthProvider>
          <Wrapper />
        </AuthProvider>
      </Router>
    </div>
  )
}
