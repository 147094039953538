import React, { useContext } from 'react'

import { MediaQueriesContext } from '../../Providers'
import { FullPathBreadcrumbs } from './Components/FullPathBreadcrumbs'
import { MobileBreadcrumbs } from './Components/MobileBreadcrumbs'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Breadcrumbs: React.FC<IProps> = ({ breadcrumbs = [] }): JSX.Element | null => {
  const { isSMScreen } = useContext(MediaQueriesContext)
  if (breadcrumbs.length <= 1) return null

  return (
    <div className={Styles.breadcrumbs} data-testid="breadcrumbs">
      {isSMScreen ? <FullPathBreadcrumbs breadcrumbs={breadcrumbs} /> : <MobileBreadcrumbs breadcrumbs={breadcrumbs} />}
    </div>
  )
}
